// boolean
export const loggerEnabled = true
export const useMockDataForEpisode = false
export const useMockDataForJourneysList = false
export const useMockDataForMediaList = false
export const isDev = process.env.REACT_APP_ENV === "dev"

// configuration
export const admins = [
  "alessio.m@aworld.org",
  "roberto.s@aworld.org",
  "simone.e@aworld.org",
]

// utility
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

// other
export const mediaRoomForError = 3
export const mediaConstraints = {
  background: {
    width: 1501,
    height: 2668,
  },
  decor1: {
    width: 210,
    height: 210,
  },
  decor2: {
    width: 814,
    height: 220,
  },
  content: {
    XS: {
      width: 320,
      height: 453,
    },
    S: {
      width: 601,
      height: 376,
    },
    M: {
      width: 684,
      height: 501,
    },
    L: {
      width: 684,
      height: 543,
    },
    XL: {
      width: 684,
      height: 637,
    },
    XXL: {
      width: 814,
      height: 1076,
    },
  },
  thumbnail: {
    width: 835,
    height: 835,
  },
  challenge: {
    width: 1600,
    height: 900,
  },
}
export const otherImagesMinConstraint = 100
export const otherImagesMaxConstraint = 2000
export const mobileWidthBreakpoint = 415

// mobility challenges metrics
export const mobilityMetrics = [
  "minSaving",
  "minWalkSaving",
  "minBicycleSaving",
  "minTramSaving",
  "minTrainSaving",
  "minSubwaySaving",
  "minBusSaving",
]
