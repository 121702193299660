import {
  Chip,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { Dispatch, Fragment, SetStateAction, useContext, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import HomeIcon from "@mui/icons-material/Home"
import {
  AccessTimeRounded,
  AddAlarmRounded,
  BrowseGalleryRounded,
  CalendarMonthRounded,
  DynamicFeedRounded,
  EmojiEventsRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  FeedRounded,
  FolderRounded,
  GroupsRounded,
  HomeRepairServiceRounded,
  ListRounded,
  LocalOfferRounded,
  MilitaryTechRounded,
  MoneyOffRounded,
  NotificationsRounded,
  PhotoLibraryRounded,
  PollRounded,
  QrCode2Rounded,
  SensorsRounded,
  SpaRounded,
  StoreMallDirectoryRounded,
  TaskAltRounded,
  TheatersRounded,
  TranslateRounded,
  WebRounded,
} from "@mui/icons-material"
import episodeIcon from "../../assets/images/episode-icon.png"
import clocksIcon from "../../assets/icons/clocks.png"
import { MainContext } from "../../controllers/main"
import { mobileWidthBreakpoint } from "../config/constants"
import BetaBadge from "../../components/global/betaBadge"

const DrawerItems = ({ drawerOpen }: { drawerOpen: boolean }) => {
  const location = useLocation()
  const { toggleDrawer, windowWidth } = useContext(MainContext)

  // contents sub menu (episodes and journeys)
  const [contentsOpen, setContentsOpen] = useState<boolean>(
    location.pathname.includes("episodes") ||
      location.pathname.includes("journeys") ||
      location.pathname.includes("translationJobs")
      ? true
      : false
  )

  // actions sub menu
  const [actionsOpen, setActionsOpen] = useState<boolean>(
    location.pathname.includes("actions") ||
      location.pathname.includes("series")
      ? true
      : false
  )

  // news sub menu
  const [newsOpen, setNewsOpen] = useState<boolean>(
    location.pathname.includes("news") ? true : false
  )

  // notifications sub menu
  const [notificationsOpen, setNotificationsOpen] = useState<boolean>(
    location.pathname.includes("templates") ? true : false
  )

  return (
    <Fragment>
      <ItemMenu
        drawerOpen={drawerOpen}
        icon={<HomeIcon style={{ color: "#757575" }} />}
        name="Dashboard"
        path="/"
        selected={location.pathname === "/"}
        setContentsOpen={setContentsOpen}
        setActionsOpen={setActionsOpen}
        setNewsOpen={setNewsOpen}
        setNotificationsOpen={setNotificationsOpen}
      />
      <ItemMenu
        drawerOpen={drawerOpen}
        icon={<GroupsRounded style={{ color: "#757575" }} />}
        name="Teams"
        path="/teams"
        selected={
          location.pathname.includes("teams") &&
          !location.pathname.includes("teamsjourneys")
        }
        setContentsOpen={setContentsOpen}
        setActionsOpen={setActionsOpen}
        setNewsOpen={setNewsOpen}
        setNotificationsOpen={setNotificationsOpen}
      />
      <Link
        to="/episodes"
        style={{
          color: "black",
          textDecoration: "none",
        }}
      >
        <ListItemButton
          onClick={() => {
            if (windowWidth < mobileWidthBreakpoint) {
              toggleDrawer()
            }
            setContentsOpen((current) => !current)
            setActionsOpen(false)
            setNewsOpen(false)
            setNotificationsOpen(false)
          }}
          selected={
            location.pathname.includes("episodes") ||
            location.pathname.includes("journeys")
          }
          style={{ maxHeight: 40 }}
        >
          <ListItemIcon>
            <FolderRounded style={{ color: "#757575" }} />
          </ListItemIcon>
          <ListItemText
            className="drawer-item"
            primary="Contents"
            style={{
              ...(!drawerOpen && { opacity: 0 }),
              transition: "150ms",
            }}
          />
          {contentsOpen ? (
            <ExpandLessRounded
              style={{
                color: "#757575",
                ...(!drawerOpen && { display: "none" }),
                height: 21,
              }}
            />
          ) : (
            <ExpandMoreRounded
              style={{
                color: "#757575",
                ...(!drawerOpen && { display: "none" }),
                height: 21,
              }}
            />
          )}
        </ListItemButton>
      </Link>
      <Collapse in={contentsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link
            to="/episodes"
            style={{
              color: "black",
              textDecoration: "none",
            }}
          >
            <ListItemButton
              sx={{ pl: 4 }}
              selected={
                location.pathname.includes("/episodes") &&
                !location.pathname.includes("/dailyepisodes")
              }
              style={{ maxHeight: 40 }}
            >
              <ListItemIcon>
                <div
                  style={{
                    width: 24,
                    height: 24,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={episodeIcon}
                    style={{
                      width: 18,
                      height: 18,
                      objectFit: "contain",
                      marginLeft: -3,
                    }}
                  />
                </div>
              </ListItemIcon>
              <ListItemText
                className="drawer-item"
                primary="Episodes"
                style={{ marginLeft: -3 }}
              />
            </ListItemButton>
          </Link>
          <Link
            to="/dailyepisodes"
            style={{
              color: "black",
              textDecoration: "none",
            }}
          >
            <ListItemButton
              sx={{ pl: 4 }}
              selected={location.pathname.includes("/dailyepisodes")}
              style={{ maxHeight: 40 }}
            >
              <ListItemIcon>
                <CalendarMonthRounded
                  style={{
                    fontSize: "1.3rem",
                    color: "#757575",
                  }}
                />
              </ListItemIcon>
              <ListItemText className="drawer-item" primary="Daily Episodes" />
            </ListItemButton>
          </Link>
          <Link
            to="/journeys"
            style={{
              color: "black",
              textDecoration: "none",
            }}
          >
            <ListItemButton
              sx={{ pl: 4 }}
              selected={
                location.pathname.includes("/journeys") &&
                !location.pathname.includes("/teamsjourneys")
              }
              style={{ maxHeight: 40 }}
            >
              <ListItemIcon>
                <DynamicFeedRounded style={{ color: "#757575" }} />
              </ListItemIcon>
              <ListItemText
                className="drawer-item"
                primary="Journeys"
                style={{ marginLeft: -3 }}
              />
            </ListItemButton>
          </Link>
          <Link
            to="/freejourneys"
            style={{
              color: "black",
              textDecoration: "none",
            }}
          >
            <ListItemButton
              sx={{ pl: 4 }}
              selected={location.pathname.includes("/freejourneys")}
              style={{ maxHeight: 40 }}
            >
              <ListItemIcon>
                <MoneyOffRounded
                  style={{ fontSize: "1.3rem", color: "#757575" }}
                />
              </ListItemIcon>
              <ListItemText className="drawer-item" primary="Free Journeys" />
            </ListItemButton>
          </Link>
          <Link
            to="/translationJobs"
            style={{
              color: "black",
              textDecoration: "none",
            }}
          >
            <ListItemButton
              sx={{ pl: 4 }}
              selected={location.pathname.includes("/translationJobs")}
              style={{
                maxHeight: 40,
                position: "relative",
              }}
            >
              <ListItemIcon>
                <TranslateRounded style={{ color: "#757575" }} />
              </ListItemIcon>
              <ListItemText className="drawer-item" primary="Translations" />
              <BetaBadge
                style={{
                  position: "absolute",
                  right: 6,
                  opacity: drawerOpen ? 1 : 0,
                  transition: "100ms",
                }}
              />
            </ListItemButton>
          </Link>
        </List>
      </Collapse>
      <Link
        to="/actions"
        style={{
          color: "black",
          textDecoration: "none",
        }}
      >
        <ListItemButton
          onClick={() => {
            if (windowWidth < mobileWidthBreakpoint) {
              toggleDrawer()
            }
            setActionsOpen((current) => !current)
            setContentsOpen(false)
            setNewsOpen(false)
            setNotificationsOpen(false)
          }}
          selected={
            location.pathname.includes("actions") ||
            location.pathname.includes("series")
          }
          style={{ maxHeight: 40 }}
        >
          <ListItemIcon>
            <BrowseGalleryRounded style={{ color: "#757575" }} />
          </ListItemIcon>
          <ListItemText
            className="drawer-item"
            primary="Actions"
            style={{
              ...(!drawerOpen && { opacity: 0 }),
              transition: "150ms",
            }}
          />
          {actionsOpen ? (
            <ExpandLessRounded
              style={{
                color: "#757575",
                ...(!drawerOpen && { display: "none" }),
                height: 21,
              }}
            />
          ) : (
            <ExpandMoreRounded
              style={{
                color: "#757575",
                ...(!drawerOpen && { display: "none" }),
                height: 21,
              }}
            />
          )}
        </ListItemButton>
      </Link>
      <Collapse in={actionsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link
            to="/actions"
            style={{
              color: "black",
              textDecoration: "none",
            }}
          >
            <ListItemButton
              sx={{ pl: 4 }}
              selected={location.pathname.includes("/actions")}
              style={{ maxHeight: 40 }}
            >
              <ListItemIcon>
                <AddAlarmRounded style={{ color: "#757575" }} />
              </ListItemIcon>
              <ListItemText className="drawer-item" primary="Actions" />
            </ListItemButton>
          </Link>
          <Link
            to="/series"
            style={{
              color: "black",
              textDecoration: "none",
            }}
          >
            <ListItemButton
              sx={{ pl: 4 }}
              selected={location.pathname.includes("/series")}
              style={{ maxHeight: 40 }}
            >
              <ListItemIcon>
                <img src={clocksIcon} style={{ width: 24, height: 24 }} />
              </ListItemIcon>
              <ListItemText className="drawer-item" primary="Series" />
            </ListItemButton>
          </Link>
        </List>
      </Collapse>
      <Link
        to="/news"
        style={{
          color: "black",
          textDecoration: "none",
        }}
      >
        <ListItemButton
          onClick={() => {
            if (windowWidth < mobileWidthBreakpoint) {
              toggleDrawer()
            }
            setNewsOpen((current) => !current)
            setActionsOpen(false)
            setContentsOpen(false)
            setNotificationsOpen(false)
          }}
          selected={location.pathname.includes("news")}
          style={{ maxHeight: 40 }}
        >
          <ListItemIcon>
            <div
              style={{
                width: 24,
                height: 24,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FeedRounded style={{ fontSize: "1.3rem", color: "#757575" }} />
            </div>
          </ListItemIcon>
          <ListItemText
            className="drawer-item"
            primary="News"
            style={{
              ...(!drawerOpen && { opacity: 0 }),
              transition: "150ms",
            }}
          />
          {newsOpen ? (
            <ExpandLessRounded
              style={{
                color: "#757575",
                ...(!drawerOpen && { display: "none" }),
                height: 21,
              }}
            />
          ) : (
            <ExpandMoreRounded
              style={{
                color: "#757575",
                ...(!drawerOpen && { display: "none" }),
                height: 21,
              }}
            />
          )}
        </ListItemButton>
      </Link>
      <Collapse in={newsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <Link
            to="/news"
            style={{
              color: "black",
              textDecoration: "none",
            }}
          >
            <ListItemButton
              sx={{ pl: 4 }}
              selected={
                location.pathname.includes("/news") &&
                !location.pathname.includes("/currentnews")
              }
              style={{ maxHeight: 40 }}
            >
              <ListItemIcon>
                <ListRounded style={{ fontSize: "1.3rem", color: "#757575" }} />
              </ListItemIcon>
              <ListItemText className="drawer-item" primary="Library" />
            </ListItemButton>
          </Link>
          <Link
            to="/currentnews"
            style={{
              color: "black",
              textDecoration: "none",
            }}
          >
            <ListItemButton
              sx={{ pl: 4 }}
              selected={location.pathname.includes("/currentnews")}
              style={{ maxHeight: 40 }}
            >
              <ListItemIcon>
                <AccessTimeRounded
                  style={{
                    fontSize: "1.2rem",
                    marginLeft: 1,
                    color: "#757575",
                  }}
                />
              </ListItemIcon>
              <ListItemText className="drawer-item" primary="Current" />
            </ListItemButton>
          </Link>
        </List>
      </Collapse>
      <ItemMenu
        drawerOpen={drawerOpen}
        icon={<MilitaryTechRounded style={{ color: "#757575" }} />}
        name="Badges"
        path="/badges"
        selected={location.pathname.includes("/badges")}
        setContentsOpen={setContentsOpen}
        setActionsOpen={setActionsOpen}
        setNewsOpen={setNewsOpen}
        setNotificationsOpen={setNotificationsOpen}
      />
      <ItemMenu
        drawerOpen={drawerOpen}
        icon={<SpaRounded style={{ color: "#757575" }} />}
        name="Challenges"
        path="/challenges"
        selected={location.pathname.includes("/challenges")}
        setContentsOpen={setContentsOpen}
        setActionsOpen={setActionsOpen}
        setNewsOpen={setNewsOpen}
        setNotificationsOpen={setNotificationsOpen}
      />
      <ItemMenu
        drawerOpen={drawerOpen}
        icon={<StoreMallDirectoryRounded style={{ color: "#757575" }} />}
        name="Marketplace"
        path="/marketplace"
        selected={location.pathname.includes("/marketplace")}
        setContentsOpen={setContentsOpen}
        setActionsOpen={setActionsOpen}
        setNewsOpen={setNewsOpen}
        setNotificationsOpen={setNotificationsOpen}
      />
      <ItemMenu
        drawerOpen={drawerOpen}
        icon={<TheatersRounded style={{ color: "#757575" }} />}
        name="NFT catalogs"
        path="/nftcatalogs"
        selected={location.pathname.includes("/nftcatalogs")}
        setContentsOpen={setContentsOpen}
        setActionsOpen={setActionsOpen}
        setNewsOpen={setNewsOpen}
        setNotificationsOpen={setNotificationsOpen}
      />
      <ItemMenu
        drawerOpen={drawerOpen}
        icon={<QrCode2Rounded style={{ color: "#757575" }} />}
        name="Activation codes"
        path="/activationcodes"
        selected={location.pathname.includes("/activationcodes")}
        setContentsOpen={setContentsOpen}
        setActionsOpen={setActionsOpen}
        setNewsOpen={setNewsOpen}
        setNotificationsOpen={setNotificationsOpen}
      />
      {process.env.REACT_APP_ENV === "dev" && (
        <Link
          to="/templates"
          style={{
            color: "black",
            textDecoration: "none",
          }}
        >
          <ListItemButton
            onClick={() => {
              if (windowWidth < mobileWidthBreakpoint) {
                toggleDrawer()
              }
              setNotificationsOpen((current) => !current)
              setActionsOpen(false)
              setContentsOpen(false)
              setNewsOpen(false)
            }}
            selected={location.pathname.includes("templates")}
            style={{ maxHeight: 40 }}
          >
            <ListItemIcon>
              <NotificationsRounded style={{ color: "#757575" }} />
            </ListItemIcon>
            <ListItemText
              className="drawer-item"
              primary="Notifications"
              style={{
                ...(!drawerOpen && { opacity: 0 }),
                transition: "150ms",
              }}
            />
            {notificationsOpen ? (
              <ExpandLessRounded
                style={{
                  color: "#757575",
                  ...(!drawerOpen && { display: "none" }),
                  height: 21,
                }}
              />
            ) : (
              <ExpandMoreRounded
                style={{
                  color: "#757575",
                  ...(!drawerOpen && { display: "none" }),
                  height: 21,
                }}
              />
            )}
          </ListItemButton>
        </Link>
      )}
      {process.env.REACT_APP_ENV === "dev" && (
        <Collapse in={notificationsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link
              to="/templates"
              style={{
                color: "black",
                textDecoration: "none",
              }}
            >
              <ListItemButton
                sx={{ pl: 4 }}
                selected={location.pathname.includes("/templates")}
                style={{ maxHeight: 40 }}
              >
                <ListItemIcon>
                  <WebRounded style={{ color: "#757575" }} />
                </ListItemIcon>
                <ListItemText className="drawer-item" primary="Templates" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
      )}
      <ItemMenu
        drawerOpen={drawerOpen}
        icon={<TaskAltRounded style={{ color: "#757575" }} />}
        name="Missions"
        path="/missions"
        selected={location.pathname.includes("/missions")}
        setContentsOpen={setContentsOpen}
        setActionsOpen={setActionsOpen}
        setNewsOpen={setNewsOpen}
        setNotificationsOpen={setNotificationsOpen}
      />
      <ItemMenu
        drawerOpen={drawerOpen}
        icon={<PollRounded style={{ color: "#757575" }} />}
        name="Surveys"
        path="/surveys"
        selected={location.pathname.includes("/surveys")}
        setContentsOpen={setContentsOpen}
        setActionsOpen={setActionsOpen}
        setNewsOpen={setNewsOpen}
        setNotificationsOpen={setNotificationsOpen}
      />
      <ItemMenu
        drawerOpen={drawerOpen}
        icon={<EmojiEventsRounded style={{ color: "#757575" }} />}
        name="Leagues"
        path="/leagues"
        selected={location.pathname.includes("/leagues")}
        setContentsOpen={setContentsOpen}
        setActionsOpen={setActionsOpen}
        setNewsOpen={setNewsOpen}
        setNotificationsOpen={setNotificationsOpen}
      />
      <Divider sx={{ marginTop: 1.5, marginBottom: 1.5 }} />
      <ItemMenu
        drawerOpen={drawerOpen}
        icon={<PhotoLibraryRounded style={{ color: "#757575" }} />}
        name="Media library"
        path="/medialibrary"
        selected={location.pathname.includes("/medialibrary")}
        setContentsOpen={setContentsOpen}
        setActionsOpen={setActionsOpen}
        setNewsOpen={setNewsOpen}
        setNotificationsOpen={setNotificationsOpen}
      />
      <ItemMenu
        drawerOpen={drawerOpen}
        icon={<LocalOfferRounded style={{ color: "#757575" }} />}
        name="Tags"
        path="/tags"
        selected={location.pathname.includes("/tags")}
        setContentsOpen={setContentsOpen}
        setActionsOpen={setActionsOpen}
        setNewsOpen={setNewsOpen}
        setNotificationsOpen={setNotificationsOpen}
      />
      <ItemMenu
        drawerOpen={drawerOpen}
        icon={<SensorsRounded style={{ color: "#757575" }} />}
        name="Channels"
        path="/channels"
        selected={location.pathname.includes("/channels")}
        setContentsOpen={setContentsOpen}
        setActionsOpen={setActionsOpen}
        setNewsOpen={setNewsOpen}
        setNotificationsOpen={setNotificationsOpen}
      />
      <Divider sx={{ marginTop: 1.5, marginBottom: 1.5 }} />
      <ItemMenu
        drawerOpen={drawerOpen}
        icon={<HomeRepairServiceRounded style={{ color: "#757575" }} />}
        name="Tools"
        path="/tools"
        selected={location.pathname.includes("/tools")}
        setContentsOpen={setContentsOpen}
        setActionsOpen={setActionsOpen}
        setNewsOpen={setNewsOpen}
        setNotificationsOpen={setNotificationsOpen}
      />
    </Fragment>
  )
}

const ItemMenu = ({
  name,
  path,
  selected,
  icon,
  drawerOpen,
  setContentsOpen,
  setActionsOpen,
  setNewsOpen,
  setNotificationsOpen,
}: {
  name: string
  path: string
  selected: boolean
  icon: JSX.Element
  drawerOpen: boolean
  setContentsOpen: Dispatch<SetStateAction<boolean>>
  setActionsOpen: Dispatch<SetStateAction<boolean>>
  setNewsOpen: Dispatch<SetStateAction<boolean>>
  setNotificationsOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { toggleDrawer, windowWidth } = useContext(MainContext)

  return (
    <Link
      to={path}
      style={{
        color: "black",
        textDecoration: "none",
      }}
    >
      <ListItemButton
        selected={selected}
        onClick={() => {
          if (windowWidth < mobileWidthBreakpoint) {
            toggleDrawer()
          }
          setContentsOpen(false)
          setActionsOpen(false)
          setNewsOpen(false)
          setNotificationsOpen(false)
        }}
        style={{ maxHeight: 40 }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          className="drawer-item"
          primary={name}
          style={{
            ...(!drawerOpen && { opacity: 0 }),
            transition: "150ms",
          }}
        />
      </ListItemButton>
    </Link>
  )
}

export default DrawerItems
