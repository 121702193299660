import { Button, Stack } from "@mui/material"
import { CSSProperties, Dispatch, SetStateAction, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Textfit } from "react-textfit"
import { MediaSize } from "../../services/config/enum"
import "../../styles/imageDropzone.scss"

const ImageDropzoneMultiple = ({
  title,
  droppedImages,
  setDroppedImages,
  height = "120px",
  disabled = false,
  disabledText,
  setDroppedImagesChanged,
  style,
}: {
  title?: string
  droppedImages: {
    name: string
    size: number
    dataUrl: string
    sizeString: MediaSize | null
    hasError: boolean
  }[]
  setDroppedImages: Dispatch<
    SetStateAction<
      {
        name: string
        size: number
        dataUrl: string
        sizeString: MediaSize | null
        hasError: boolean
      }[]
    >
  >
  height?: string
  disabled?: boolean
  disabledText?: string
  setDroppedImagesChanged: Dispatch<SetStateAction<boolean>>
  style?: CSSProperties
}) => {
  // too many images error
  const [tooManyImagesError, setTooManyImagesError] = useState<boolean>(false)

  // file read loading
  const [loading, setLoading] = useState<boolean>(false)

  // read file dropped in the drop zone
  const readFile = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener("load", () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  // on image dropped
  const onDrop = async (acceptedFiles: File[]) => {
    setLoading(true)

    if (droppedImages.length + acceptedFiles.length < 6) {
      for (let i = 0; i < acceptedFiles.length; i++) {
        let imageDataUrl = await readFile(acceptedFiles[i])
        droppedImages.push({
          name: acceptedFiles[i].name,
          size: acceptedFiles[i].size,
          dataUrl: imageDataUrl as string,
          sizeString: null,
          hasError: false,
        })
      }
      setDroppedImages([...droppedImages])
      setDroppedImagesChanged(true)
      setTooManyImagesError(false)
    } else {
      setTooManyImagesError(true)
    }

    setLoading(false)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
  })

  return disabled ? (
    <div
      className="image-dropzone-container-disabled"
      style={{
        borderRadius: 4,
        height: height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        padding: 15,
        ...style,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: -10,
          left: 8,
          color: "#9e9e9f",
          fontSize: 12,
          backgroundColor: "white",
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        {title}
      </div>
      <p style={{ textAlign: "center" }}>
        {disabledText && disabledText.length
          ? disabledText
          : "Drag and drop an image here or click to select"}
      </p>
    </div>
  ) : (
    <div
      {...getRootProps()}
      className={
        droppedImages.filter((image) => image.hasError).length ||
        tooManyImagesError
          ? "image-dropzone-container-error-prismic"
          : "image-dropzone-container"
      }
      style={{
        borderRadius: 4,
        height: height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        padding: 15,
        ...style,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: -10,
          left: 8,
          color:
            droppedImages.filter((image) => image.hasError).length ||
            tooManyImagesError
              ? "#d3302f"
              : "rgba(0, 0, 0, 0.6)",
          fontSize: 12,
          backgroundColor: "white",
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        {title}
      </div>
      <input {...getInputProps()} />
      {loading ? (
        <p style={{ textAlign: "center" }}>loading...</p>
      ) : !droppedImages.length ? (
        isDragActive ? (
          <p style={{ textAlign: "center" }}>Drop the images here...</p>
        ) : (
          <p style={{ textAlign: "center" }}>
            Drag and drop images here or click to select
          </p>
        )
      ) : isDragActive ? (
        <p style={{ textAlign: "center" }}>Drop the images here...</p>
      ) : (
        <Stack direction="row" spacing={1}>
          {droppedImages.map((image, index) => (
            <Stack
              spacing={0}
              key={image.dataUrl}
              style={{ position: "relative" }}
            >
              <Button
                variant="text"
                size="small"
                style={{
                  position: "absolute",
                  top: -20,
                  height: 26,
                  left: 0,
                  right: 0,
                  marginRight: "auto",
                  marginLeft: "auto",
                  width: 80,
                }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  droppedImages.splice(index, 1)
                  setDroppedImages([...droppedImages])
                }}
              >
                Remove
              </Button>
              <img
                src={image.dataUrl}
                style={{
                  maxHeight: `calc(${height} - 70px)`,
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
              <p
                style={{
                  textAlign: "center",
                  color: image.hasError ? "#d3302f" : "black",
                  fontSize: 10,
                  marginBottom: -6,
                }}
              >
                {image.name} |{" "}
                {image.size / 1000 > 999
                  ? Math.round((image.size / 1000000 + Number.EPSILON) * 10) /
                      10 +
                    "mb"
                  : Math.round(image.size / 1000 + Number.EPSILON) + "kb "}
                {image.sizeString &&
                  "| size " + image.sizeString.toString().toUpperCase()}
              </p>
            </Stack>
          ))}
        </Stack>
      )}
      <Textfit
        style={{
          color: "#d3302f",
          position: "absolute",
          bottom: -25,
          left: 8,
          opacity:
            droppedImages.filter((image) => image.hasError).length ||
            tooManyImagesError
              ? 1
              : 0,
          width: "calc(100% - 7px)",
          height: 20,
          overflow: "scroll",
        }}
        max={12}
        min={9}
        mode="single"
      >
        {tooManyImagesError
          ? "You can upload a maximum of 5 images at a time"
          : "One or more images don't respect pixel constraints for content type"}
      </Textfit>
    </div>
  )
}

export default ImageDropzoneMultiple
