import {
  Alert,
  Chip,
  LinearProgress,
  Snackbar,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import {
  calculateTimeElapsed,
  registerAnalyticsEvent,
} from "../../services/utilities/utility"
import { NewsContext } from "../../controllers/news"
import News from "../../models/news"
import { parseStringDate } from "../../services/utilities/utility"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import CreateNewsDialog from "../../components/news/createNewsDialog"
import NewsListTopBar from "../../components/news/newsListTopBar"
import TitleTableCell from "../../components/global/titleTableCell"
import TranslationsStack from "../../components/global/translationsStack"
import PrimaryBadge from "../../components/global/primaryBadge"
import { ListAltRounded } from "@mui/icons-material"

const NewsList = () => {
  const { setAnimation, showPadding, cmdPressed } = useContext(MainContext)
  const {
    loading,
    updatingList,
    setUpdatingList,
    newsList,
    getNewsList,
    newsListNextToken,
    loadMoreNews,
    hasSearched,
    searchLang,
    searchTeam,
    searchTitle,
    setSearchTitle,
    searchNewsList,
  } = useContext(NewsContext)
  const navigate = useNavigate()
  const location = useLocation()

  // list background update
  const backgroundUpdate = useCallback(() => {
    if (!loading && !hasSearched && newsList.length) {
      setUpdatingList(true)
      getNewsList(false)
    }
  }, [getNewsList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!newsList.length) {
      getNewsList()
    }
    backgroundUpdate()
  }, [backgroundUpdate])

  // handle create news dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // handle news deleted feedback
  const [newsDeletedFeedbackOpen, setNewsDeletedFeedbackOpen] =
    useState<boolean>(false)

  useEffect(() => {
    if (location.state && (location.state as any).newsDeleted) {
      setNewsDeletedFeedbackOpen(true)
    }
  }, [])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "news_list" })
  }, [])

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 143,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <NewsListTopBar
        loading={loading}
        textFieldValue={searchTitle}
        textFieldPlaceholder="Search News"
        addButtonLabel="Add News"
        textFieldOnChange={(e) => {
          setSearchTitle(e.target.value)
          if (e.target.value.length === 0 && hasSearched) {
            setUpdatingList(true)

            if (!searchLang && !searchTeam) {
              getNewsList(false)
            } else {
              searchNewsList()
            }
          }
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter" && searchTitle.length > 2) {
            searchNewsList()
          }
        }}
        cancelButtonOnClick={() => {
          setSearchTitle("")
          if (hasSearched) {
            setUpdatingList(true)

            if (!searchLang && !searchTeam) {
              getNewsList(false)
            } else {
              searchNewsList()
            }
          }
        }}
        searchButtonOnClick={() => {
          searchNewsList()
        }}
        addButtonOnClick={handleDialogChange}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
          headingItems={[
            "Image",
            "Title & ID",
            "Translations",
            "Quizzes",
            "Starts at",
            "Ends at",
            "Updated",
          ]}
          nextToken={newsListNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={
            !searchTitle.length && !searchLang && !searchTeam
              ? loadMoreNews
              : async () => {
                  const result = await searchNewsList(false, true)
                  return result
                }
          }
          tableBody={newsList.map((item: News) => (
            <TableRow
              key={item.id}
              style={{ cursor: "pointer" }}
              hover
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
              onClick={() => {
                if (cmdPressed) {
                  window.open(`${window.location.href}/${item.id}`)
                } else {
                  setAnimation(false)
                  setTimeout(() => {
                    navigate(`/news/${item.id}`)
                  }, 250)
                }
              }}
            >
              <TableCell>
                <div
                  style={{
                    width: 42,
                    height: 42,
                    borderRadius: 4,
                    backgroundImage: `url(${
                      item.document.items.filter(
                        (documentItem) => documentItem.isDefault
                      )[0].image ?? noImagePlaceholder
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </TableCell>
              <TitleTableCell
                title={
                  item.document.items.filter(
                    (documentItem) => documentItem.isDefault
                  )[0].title
                }
                id={item.id}
              />
              <TableCell>
                <TranslationsStack documentItems={item.document.items} />
              </TableCell>
              <TableCell>
                {item.document.items.find((item) => item.isDefault)
                  .redirectUrl ? (
                  <Chip
                    size="small"
                    label="none"
                    style={{ color: "#A9B1BB" }}
                  />
                ) : (
                  <PrimaryBadge
                    count={item.quizzes ? item.quizzes.length : 0}
                    icon={<ListAltRounded style={{ fontSize: 12 }} />}
                  />
                )}
              </TableCell>
              <TableCell style={{ minWidth: 110 }}>
                <Typography variant="caption">
                  {parseStringDate(item.startsAt, false)}
                </Typography>
              </TableCell>
              <TableCell style={{ minWidth: 110 }}>
                <Typography variant="caption">
                  {parseStringDate(item.endsAt, false)}
                </Typography>
              </TableCell>
              <TableCell style={{ minWidth: 110 }}>
                <Typography variant="caption">
                  {calculateTimeElapsed(item.updatedAt)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        />
      )}
      <CreateNewsDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
        topBarText="Add News"
      />
      <Snackbar
        open={newsDeletedFeedbackOpen}
        onClose={() => {
          setNewsDeletedFeedbackOpen(false)
        }}
        autoHideDuration={3000}
      >
        <Alert severity="success">News deleted</Alert>
      </Snackbar>
    </ListContainer>
  )
}

export default NewsList
