import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getDatabase, onValue, ref } from "firebase/database"
import { logger, Status } from "../services/utilities/utility"
import { getUser } from "../services/server/auth"
import { FetchUserAttributesOutput } from "aws-amplify/auth"

// firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "aworld-solar.firebaseapp.com",
  projectId: "aworld-solar",
  storageBucket: "aworld-solar.appspot.com",
  messagingSenderId: "952423226173",
  appId: "1:952423226173:web:c3382765f0b523daba0354",
  measurementId: "G-P14855SKY9",
  databaseURL:
    "https://aworld-solar-default-rtdb.europe-west1.firebasedatabase.app/",
}

// firebase initialize
export const firebaseApp = initializeApp(firebaseConfig)
getAnalytics(firebaseApp)

// firebase realtime database initialize
const database = getDatabase(firebaseApp)

interface MainContextInterface {
  drawerOpen: boolean
  toggleDrawer: () => void
  isAuth: any
  setIsAuth: Dispatch<SetStateAction<any>>
  error: boolean
  setError: Dispatch<SetStateAction<boolean>>
  errorMessage: string
  setErrorMessage: Dispatch<SetStateAction<string>>
  animation: boolean
  setAnimation: Dispatch<SetStateAction<boolean>>
  changesSaved: boolean
  setChangesSaved: Dispatch<SetStateAction<boolean>>
  panelStatus: {
    name: string
    status: string
    loading: boolean
    updateFunction?: () => void
    errorCode?: string
  }[]
  setPanelStatus: Dispatch<
    SetStateAction<
      {
        name: string
        status: string
        loading: boolean
        updateFunction?: () => void
        errorCode?: string
      }[]
    >
  >
  showPadding: string
  setShowPadding: Dispatch<SetStateAction<string>>
  showAdvancedOption: string
  setShowAdvancedOption: Dispatch<SetStateAction<string>>
  cmdPressed: boolean
  manager: {
    forceUpdate: boolean
    maintenance: boolean
    version: string
    changelog: string
    phase: "deploying" | "deployed"
  }
  ignoreManager: boolean
  setIgnoreManager: Dispatch<SetStateAction<boolean>>
  windowWidth: number
  clipboardFeedbackOpen: boolean
  setClipboardFeedbackOpen: Dispatch<SetStateAction<boolean>>
  user: FetchUserAttributesOutput | null
}

const MainContext = createContext<MainContextInterface>({
  drawerOpen: true,
  toggleDrawer: () => {},
  isAuth: undefined,
  setIsAuth: () => {},
  error: false,
  setError: () => {},
  errorMessage: "",
  setErrorMessage: () => {},
  animation: true,
  setAnimation: () => {},
  changesSaved: false,
  setChangesSaved: () => {},
  panelStatus: [],
  setPanelStatus: () => {},
  showPadding: "",
  setShowPadding: () => {},
  showAdvancedOption: "",
  setShowAdvancedOption: () => {},
  cmdPressed: false,
  manager: {
    forceUpdate: false,
    maintenance: false,
    version: process.env.REACT_APP_VERSION,
    changelog: "",
    phase: "deployed",
  },
  ignoreManager: false,
  setIgnoreManager: () => {},
  windowWidth: window.innerWidth,
  clipboardFeedbackOpen: false,
  setClipboardFeedbackOpen: () => {},
  user: null,
})

const MainController = ({ children }: { children: ReactNode }) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(
    localStorage.getItem("drawerOpen")
      ? eval(localStorage.getItem("drawerOpen"))
      : true
  ) // left drawer expanded or not
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
    localStorage.setItem("drawerOpen", (!drawerOpen).toString())
  }
  const [isAuth, setIsAuth] = useState<any>() // is user authenticated or not
  const [error, setError] = useState<boolean>(false) // is there an error or not
  const [errorMessage, setErrorMessage] = useState<string>("") // error message
  const [errorTimeoutId, setErrorTimeoutId] = useState<
    ReturnType<typeof setTimeout> | undefined
  >() // timeout to make error snackbar disappear
  const [animation, setAnimation] = useState(true) // for enter and exit animations
  const [changesSaved, setChangesSaved] = useState<boolean>(false) // changes saved snackbar
  const [changesSavedTimeoutId, setChangesSavedTimeoutId] = useState<
    ReturnType<typeof setTimeout> | undefined
  >() // timeout to make changes saved snackbar disappear
  const [cmdPressed, setCmdPressed] = useState<boolean>(false) // if command or control button on keyboard has been pressed
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth) // current window width
  const [clipboardFeedbackOpen, setClipboardFeedbackOpen] =
    useState<boolean>(false) // copied to clipboard snackbar
  const [user, setUser] = useState<FetchUserAttributesOutput | null>(null)

  // intercept cmd or control button press
  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Meta") {
        setCmdPressed(true)
      }
    })
    document.addEventListener("keyup", (e) => {
      if (e.key === "Meta") {
        setCmdPressed(false)
      }
    })
  }, [])

  // make error snackbar disappear after 4 seconds
  useEffect(() => {
    if (error) {
      if (errorTimeoutId) {
        clearTimeout(errorTimeoutId)
      }
      let id = setTimeout(() => {
        setError(false)
      }, 5000)
      setErrorTimeoutId(id)
    }
  }, [error])

  // make save changes snackbar disappear after 3 seconds
  useEffect(() => {
    if (changesSaved) {
      if (changesSavedTimeoutId) {
        clearTimeout(changesSavedTimeoutId)
      }
      let id = setTimeout(() => {
        setChangesSaved(false)
      }, 4000)
      setChangesSavedTimeoutId(id)
    }
  }, [changesSaved])

  // panel status managment
  const [panelStatus, setPanelStatus] = useState<
    {
      name: string
      status: string
      loading: boolean
      updateFunction?: () => void
      errorCode?: string
    }[]
  >([
    {
      name: "Topics",
      status: "loading",
      loading: false,
    },
    {
      name: "Main Topics",
      status: "loading",
      loading: false,
    },
    {
      name: "SDGs",
      status: "loading",
      loading: false,
    },
    {
      name: "SDG targets",
      status: "loading",
      loading: false,
    },
    {
      name: "Media tags",
      status: "loading",
      loading: false,
    },
    {
      name: "Regions",
      status: "loading",
      loading: false,
    },
    {
      name: "SubRegions",
      status: "loading",
      loading: false,
    },
    {
      name: "Countries",
      status: "loading",
      loading: false,
    },
    {
      name: "Categories",
      status: "loading",
      loading: false,
    },
    {
      name: "Main Categories",
      status: "loading",
      loading: false,
    },
    {
      name: "Metrics",
      status: "loading",
      loading: false,
    },
    {
      name: "Footprint Questions",
      status: "loading",
      loading: false,
    },
    {
      name: "Media Categories",
      status: "loading",
      loading: false,
    },
    {
      name: "Channels",
      status: "loading",
      loading: false,
    },
    ...(process.env.REACT_APP_ENV === "dev"
      ? [
          {
            name: "Enrichers",
            status: "loading",
            loading: false,
          },
        ]
      : []),
    { name: "Currencies", status: "loading", loading: false },
    { name: "Mission Types", status: "loading", loading: false },
    {
      name: "Episodes",
      status: "loading",
      loading: false,
    },
    {
      name: "Daily Episodes",
      status: "loading",
      loading: false,
    },
    {
      name: "Journeys",
      status: "loading",
      loading: false,
    },
    {
      name: "Media",
      status: "loading",
      loading: false,
    },
    {
      name: "Teams",
      status: "loading",
      loading: false,
    },
    {
      name: "Free release",
      status: "loading",
      loading: false,
    },
    {
      name: "News",
      status: "loading",
      loading: false,
    },
    {
      name: "Current News",
      status: "loading",
      loading: false,
    },
    {
      name: "Actions",
      status: "loading",
      loading: false,
    },
    {
      name: "Series",
      status: "loading",
      loading: false,
    },
    {
      name: "Badges",
      status: "loading",
      loading: false,
    },
    {
      name: "Challenges",
      status: "loading",
      loading: false,
    },
    {
      name: "Marketplace",
      status: "loading",
      loading: false,
    },
    {
      name: "NFT catalogs",
      status: "loading",
      loading: false,
    },
    {
      name: "Activation codes",
      status: "loading",
      loading: false,
    },
    ...(process.env.REACT_APP_ENV === "dev"
      ? [
          {
            name: "Templates",
            status: "loading",
            loading: false,
          },
        ]
      : []),
    {
      name: "Missions",
      status: "loading",
      loading: false,
    },
    {
      name: "Surveys",
      status: "loading",
      loading: false,
    },
    {
      name: "Leagues",
      status: "loading",
      loading: false,
    },
  ])

  // show padding
  const [showPadding, setShowPadding] = useState<string>(
    localStorage.getItem("showPadding")
      ? localStorage.getItem("showPadding")
      : "yes"
  )

  useEffect(() => {
    localStorage.setItem("showPadding", showPadding)
  }, [showPadding])

  // show advanced options
  const [showAdvancedOption, setShowAdvancedOption] = useState<string>(
    localStorage.getItem("showAdvancedOption")
      ? localStorage.getItem("showAdvancedOption")
      : "no"
  )

  useEffect(() => {
    localStorage.setItem("showAdvancedOption", showAdvancedOption)
  }, [showAdvancedOption])

  // subscribe to firebase realtime database for version changes
  const [manager, setManager] = useState<{
    forceUpdate: boolean
    maintenance: boolean
    version: string
    changelog: string
    phase: "deploying" | "deployed"
  }>({
    forceUpdate: false,
    maintenance: false,
    version: process.env.REACT_APP_VERSION,
    changelog: "",
    phase: "deployed",
  })
  const [ignoreManager, setIgnoreManager] = useState<boolean>(false)

  useEffect(() => {
    onValue(
      ref(database, `solar-client/${process.env.REACT_APP_ENV}`),
      (snapshot) => {
        const data = snapshot.val()
        logger(Status.Important, "manager status", data)
        setIgnoreManager(false)
        setManager(data)
      }
    )
  }, [])

  // event on page resize to get the current width
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth)
    })
  }, [])

  // get current user
  useEffect(() => {
    const callGetUser = async () => {
      const currentUser = await getUser()
      setUser(currentUser)
    }

    if (isAuth) {
      callGetUser()
    }
  }, [isAuth])

  return (
    <MainContext.Provider
      value={{
        drawerOpen,
        toggleDrawer,
        isAuth,
        setIsAuth,
        error,
        setError,
        errorMessage,
        setErrorMessage,
        animation,
        setAnimation,
        changesSaved,
        setChangesSaved,
        panelStatus,
        setPanelStatus,
        showPadding,
        setShowPadding,
        showAdvancedOption,
        setShowAdvancedOption,
        cmdPressed,
        manager,
        ignoreManager,
        setIgnoreManager,
        windowWidth,
        clipboardFeedbackOpen,
        setClipboardFeedbackOpen,
        user,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}

export { MainController, MainContext }
