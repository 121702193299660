import { useContext, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import { TranslationsContext } from "../../controllers/translations"
import EditContainer from "../../components/global/editContainer"
import EditHeadingFade from "../../components/global/editHeadingFade"
import {
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Grow,
  Stack,
  Typography,
} from "@mui/material"
import { JobStatus, TranslationJobType } from "../../services/config/enum"
import {
  AccessTimeRounded,
  CheckCircleOutlineRounded,
  ErrorOutlineRounded,
  EastRounded,
  SyncRounded,
} from "@mui/icons-material"
import { calculateTimeElapsed } from "../../services/utilities/utility"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import PageSpinner from "../../components/global/pageSpinner"
import { DateTime } from "luxon"
import CopyToClipboardButton from "../../components/global/copyToClipboardButton"

const TranslationJobDetails = () => {
  const { jobId } = useParams()
  const navigate = useNavigate()
  const { animation, showPadding } = useContext(MainContext)
  const { startPollingJob, stopPollingJob, polledJob, jobRunning, loading } =
    useContext(TranslationsContext)

  useEffect(() => {
    if (jobId) {
      startPollingJob(jobId)
      return () => {
        stopPollingJob()
      }
    }
  }, [jobId])

  const getStatusIcon = (status: JobStatus) => {
    switch (status) {
      case JobStatus.PENDING:
        return <AccessTimeRounded style={{ fontSize: 14 }} />
      case JobStatus.RUNNING:
        return <SyncRounded style={{ fontSize: 14 }} />
      case JobStatus.COMPLETED:
        return <CheckCircleOutlineRounded style={{ fontSize: 14 }} />
      case JobStatus.ERROR:
        return <ErrorOutlineRounded style={{ fontSize: 14 }} />
    }
  }

  const getStatusProps = (
    status: JobStatus
  ): { color: "success" | "warning" | "error"; label: string } => {
    switch (status) {
      case JobStatus.COMPLETED:
        return {
          color: "success",
          label: status.charAt(0).toUpperCase() + status.slice(1).toLowerCase(),
        }
      case JobStatus.ERROR:
        return {
          color: "error",
          label: status.charAt(0).toUpperCase() + status.slice(1).toLowerCase(),
        }
      default:
        return {
          color: "warning",
          label: status.charAt(0).toUpperCase() + status.slice(1).toLowerCase(),
        }
    }
  }

  const formatJobType = (type: TranslationJobType) => {
    if (type === TranslationJobType.AI_TRANSLATE) {
      return "AI Translate"
    }
    return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
  }

  const getStageChip = (stage: string, hasSibling: boolean) => {
    if (stage === "PUBLISHED" || hasSibling) {
      return <Chip size="small" label="Live" color="success" />
    } else if (stage === "ARCHIVED") {
      return <Chip size="small" label="Archived" />
    } else {
      return <Chip size="small" label="Draft" color="warning" />
    }
  }

  const calculateExecutionTime = (
    createdAt: string,
    updatedAt: string,
    status: JobStatus
  ) => {
    const startTime = DateTime.fromISO(createdAt)
    const endTime =
      status === JobStatus.COMPLETED || status === JobStatus.ERROR
        ? DateTime.fromISO(updatedAt)
        : DateTime.now()

    const diff = endTime.diff(startTime, [
      "days",
      "hours",
      "minutes",
      "seconds",
    ])
    const parts = []

    if (diff.days > 0) parts.push(`${diff.days}d`)
    if (diff.hours > 0) parts.push(`${diff.hours}h`)
    if (diff.minutes > 0) parts.push(`${diff.minutes}m`)
    if (diff.seconds > 0) parts.push(`${Math.floor(diff.seconds)}s`)

    return parts.join(" ")
  }

  if (loading || !polledJob) {
    return (
      <EditContainer>
        <EditHeadingFade
          showBackButton
          title="Translation Job Details"
          backButtonOnClick={() => navigate("/translationJobs")}
        />
        <PageSpinner />
      </EditContainer>
    )
  }

  const statusProps = getStatusProps(polledJob.status)
  const shouldShowSpinner =
    jobRunning &&
    (polledJob.status === JobStatus.PENDING ||
      polledJob.status === JobStatus.RUNNING)

  return (
    <EditContainer>
      <EditHeadingFade
        showBackButton
        title="Translation Job Details"
        backButtonOnClick={() => navigate("/translationJobs")}
      />
      <Grow
        in={animation}
        timeout={300}
        style={{ margin: showPadding === "yes" ? 16 : 0, marginTop: 0 }}
      >
        <Stack gap={2}>
          <Card>
            <CardContent>
              <Stack gap={2}>
                <Stack direction="row" alignItems="center" gap={2}>
                  <Typography variant="h6" className="card-title">
                    General
                  </Typography>
                  {shouldShowSpinner && (
                    <CircularProgress size={20} style={{ marginLeft: 8 }} />
                  )}
                </Stack>
                <Stack
                  direction="row"
                  spacing={3}
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography variant="subtitle1">Status:</Typography>
                    <Chip
                      size="small"
                      icon={getStatusIcon(polledJob.status)}
                      label={statusProps.label}
                      color={statusProps.color}
                    />
                  </Stack>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography variant="subtitle1">Job Type:</Typography>
                    <Chip
                      size="small"
                      style={{ backgroundColor: "#dee3fc" }}
                      label={formatJobType(polledJob.jobType)}
                    />
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <Typography variant="subtitle1">Execution Time:</Typography>
                    <Typography>
                      {calculateExecutionTime(
                        polledJob.createdAt,
                        polledJob.updatedAt,
                        polledJob.status
                      )}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <Typography variant="subtitle1">Created:</Typography>
                    <Typography>
                      {calculateTimeElapsed(polledJob.createdAt)}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <Typography variant="subtitle1">Updated:</Typography>
                    <Typography>
                      {calculateTimeElapsed(polledJob.updatedAt)}
                    </Typography>
                  </Stack>
                </Stack>
                {polledJob.fileLocation && (
                  <Stack direction="row" gap={2}>
                    <Typography variant="subtitle1">File Location:</Typography>
                    <Typography>{polledJob.fileLocation}</Typography>
                  </Stack>
                )}
                {polledJob.error && (
                  <Stack direction="row" gap={2}>
                    <Typography variant="subtitle1">Error:</Typography>
                    <Typography color="error">{polledJob.error}</Typography>
                  </Stack>
                )}
              </Stack>
            </CardContent>
          </Card>
          {(polledJob.translatedEntities?.episodes?.length > 0 ||
            polledJob.translatedEntities?.journeys?.length > 0) && (
            <Card>
              <CardContent>
                <Stack gap={2}>
                  <Typography variant="h6" className="card-title">
                    Translated Content
                  </Typography>

                  {polledJob.translatedEntities?.episodes?.length > 0 && (
                    <Stack gap={2}>
                      <Typography variant="subtitle1">Episodes</Typography>
                      {polledJob.translatedEntities.episodes.map(
                        (item: any) => (
                          <Card
                            variant="outlined"
                            key={item.episode.id}
                            onClick={() =>
                              window.open(`/episodes/${item.episode.id}`)
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            <CardContent
                              style={{ paddingTop: 16, paddingBottom: 16 }}
                            >
                              <Stack
                                direction="row"
                                gap={2}
                                alignItems="center"
                              >
                                <div style={{ minWidth: 48, maxWidth: 48 }}>
                                  {getStageChip(
                                    item.episode.stage,
                                    item.episode.hasSibling
                                  )}
                                </div>
                                <div
                                  style={{
                                    minWidth: 40,
                                    height: 40,
                                    borderRadius: 4,
                                    backgroundImage: `url(${
                                      item.episode.image ?? noImagePlaceholder
                                    })`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                  }}
                                />
                                <Stack sx={{ flex: 1, gap: -0.5 }}>
                                  <Typography>
                                    {item.episode.title || item.episode.id}
                                  </Typography>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    style={{ marginLeft: -3 }}
                                  >
                                    <CopyToClipboardButton
                                      textToCopy={item.episode.id}
                                      size="small"
                                    />
                                    <Typography
                                      variant="caption"
                                      style={{
                                        fontSize: 10,
                                        color: "gray",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {item.episode.id}
                                    </Typography>
                                  </Stack>
                                </Stack>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <Chip
                                    size="small"
                                    label={item.input.sourceLang.toUpperCase()}
                                    style={{ backgroundColor: "#e3f2fd" }}
                                  />
                                  <EastRounded
                                    style={{ color: "#757575", fontSize: 20 }}
                                  />
                                  {item.input.langs.map((lang: string) => (
                                    <Chip
                                      key={lang}
                                      size="small"
                                      label={lang.toUpperCase()}
                                      color="warning"
                                    />
                                  ))}
                                </Stack>
                              </Stack>
                            </CardContent>
                          </Card>
                        )
                      )}
                    </Stack>
                  )}
                  {polledJob.translatedEntities?.journeys?.length > 0 && (
                    <Stack gap={2}>
                      <Typography variant="subtitle1">Journeys</Typography>
                      {polledJob.translatedEntities.journeys.map(
                        (item: any) => (
                          <Card
                            variant="outlined"
                            key={item.journey.id}
                            onClick={() =>
                              window.open(`/journeys/${item.journey.id}`)
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            <CardContent>
                              <Stack
                                direction="row"
                                gap={2}
                                alignItems="center"
                              >
                                <div style={{ minWidth: 48, maxWidth: 48 }}>
                                  {getStageChip(
                                    item.journey.stage,
                                    item.journey.hasSibling
                                  )}
                                </div>
                                <div
                                  style={{
                                    minWidth: 40,
                                    height: 40,
                                    borderRadius: 4,
                                    backgroundImage: `url(${
                                      item.journey.image ?? noImagePlaceholder
                                    })`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                  }}
                                />
                                <Stack sx={{ flex: 1, gap: -0.5 }}>
                                  <Typography>
                                    {item.journey.title || item.journey.id}
                                  </Typography>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    style={{ marginLeft: -3 }}
                                  >
                                    <CopyToClipboardButton
                                      textToCopy={item.journey.id}
                                      size="small"
                                    />
                                    <Typography
                                      variant="caption"
                                      style={{
                                        fontSize: 10,
                                        color: "gray",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {item.journey.id}
                                    </Typography>
                                  </Stack>
                                </Stack>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <Chip
                                    size="small"
                                    label={item.input.sourceLang.toUpperCase()}
                                    style={{ backgroundColor: "#e3f2fd" }}
                                  />
                                  <EastRounded
                                    style={{ color: "#757575", fontSize: 20 }}
                                  />
                                  {item.input.langs.map((lang: string) => (
                                    <Chip
                                      key={lang}
                                      size="small"
                                      label={lang.toUpperCase()}
                                      color="warning"
                                    />
                                  ))}
                                </Stack>
                              </Stack>
                            </CardContent>
                          </Card>
                        )
                      )}
                    </Stack>
                  )}
                </Stack>
              </CardContent>
            </Card>
          )}
        </Stack>
      </Grow>
    </EditContainer>
  )
}

export default TranslationJobDetails
