import { Card, CardContent, Grow, Stack, Tab, Tabs } from "@mui/material"
import EditContainer from "../../components/global/editContainer"
import { useContext, useState, useEffect } from "react"
import { MainContext } from "../../controllers/main"
import FileDropzone from "../../components/global/fileDropzone"
import EditHeadingFade from "../../components/global/editHeadingFade"
import { TranslationsContext } from "../../controllers/translations"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import { TranslationJobType } from "../../services/config/enum"
import JobsList from "../../components/translations/jobsList"
import { useLazyQuery } from "@apollo/client"
import { translationsJobsList } from "../../services/graphql/queries"
import { useNavigate } from "react-router-dom"

const TranslationJobsList = () => {
  const { animation, showPadding } = useContext(MainContext)
  const { importTranslations, jobRunning, currentJobId } =
    useContext(TranslationsContext)
  const navigate = useNavigate()

  // loading states
  const [loading, setLoading] = useState<boolean>(false)
  const [jobsLoading, setJobsLoading] = useState<boolean>(false)

  // dropped file
  const [droppedFile, setDroppedFile] = useState<{
    name: string
    size: number
    type: string
    dataUrl: string
  } | null>(null)

  // current tab
  const [currentTab, setCurrentTab] = useState<
    TranslationJobType | "NEW_IMPORT"
  >("NEW_IMPORT")

  // jobs list query
  const [translationsJobsListQuery] = useLazyQuery(translationsJobsList)
  const [jobs, setJobs] = useState<any[]>([])
  const [nextToken, setNextToken] = useState<string | null>(null)
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // get jobs list
  const getJobsList = async () => {
    try {
      setJobsLoading(true)
      const { data } = await translationsJobsListQuery({
        variables: {
          input: {
            jobType:
              currentTab === "NEW_IMPORT"
                ? TranslationJobType.IMPORT
                : currentTab,
            limit: 30,
          },
        },
        fetchPolicy: "no-cache",
      })
      setJobs(data.translationsJobsList.items)
      setNextToken(data.translationsJobsList.nextToken)
    } catch (error) {
      console.error("Error fetching jobs:", error)
    } finally {
      setJobsLoading(false)
    }
  }

  // load more jobs
  const loadMoreJobs = async () => {
    try {
      setLoadMoreLoading(true)
      const { data } = await translationsJobsListQuery({
        variables: {
          input: {
            jobType:
              currentTab === "NEW_IMPORT"
                ? TranslationJobType.IMPORT
                : currentTab,
            limit: 30,
            nextToken,
          },
        },
        fetchPolicy: "no-cache",
      })
      setJobs([...jobs, ...data.translationsJobsList.items])
      setNextToken(data.translationsJobsList.nextToken)
      setLoadMoreLoading(false)
      return true
    } catch (error) {
      console.error("Error loading more jobs:", error)
      setLoadMoreLoading(false)
      return false
    }
  }

  // refresh jobs list when tab changes
  useEffect(() => {
    // Clear the list first to avoid showing stale data
    setJobs([])
    setNextToken(null)

    if (currentTab !== "NEW_IMPORT") {
      getJobsList()
    }
  }, [currentTab])

  // Watch for currentJobId changes to handle redirect after import
  useEffect(() => {
    if (currentJobId && loading) {
      setLoading(false)
      setDroppedFile(null)
      navigate(`/translationJobs/${currentJobId}`)
    }
  }, [currentJobId, loading, navigate])

  return (
    <EditContainer>
      <EditHeadingFade
        showBackButton={false}
        title="Translations"
        showImportButton={currentTab === "NEW_IMPORT"}
        importButtonDisabled={jobRunning || !droppedFile}
        importButtonOnClick={async () => {
          setLoading(true)

          console.log("file", droppedFile)
          await importTranslations(
            { contentType: droppedFile.type, fileName: droppedFile.name },
            droppedFile.dataUrl
          )
          // Redirect will happen in the useEffect when currentJobId is updated
        }}
        withBetaBadge
      />
      <Grow
        in={animation}
        timeout={300}
        style={{ margin: showPadding === "yes" ? 16 : 0, marginTop: 0 }}
      >
        <Stack spacing={0}>
          <Stack direction="row" justifyContent="flex-start">
            <Card
              variant="outlined"
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: showPadding === "yes" ? undefined : 0,
                borderBottom: "none",
                borderLeft: showPadding === "yes" ? undefined : "none",
                width: "100%",
              }}
            >
              <Tabs
                value={currentTab}
                onChange={(_, value) => setCurrentTab(value)}
                indicatorColor="primary"
                textColor="primary"
                sx={{
                  minHeight: 48,
                  "& .MuiTab-root": {
                    minWidth: 180,
                    minHeight: 48,
                  },
                }}
              >
                <Tab
                  label="New Import"
                  value="NEW_IMPORT"
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  label="Import"
                  value={TranslationJobType.IMPORT}
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  label="Export"
                  value={TranslationJobType.EXPORT}
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  label="AI Translate"
                  value={TranslationJobType.AI_TRANSLATE}
                  sx={{ textTransform: "none" }}
                />
              </Tabs>
            </Card>
          </Stack>
          {currentTab === "NEW_IMPORT" ? (
            <Card sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
              <CardContent>
                <div style={{ height: "calc(100vh - 245px)" }}>
                  <FileDropzone
                    title="File"
                    droppedFile={droppedFile}
                    setDroppedFile={setDroppedFile}
                    height="100%"
                    disabled={jobRunning}
                  />
                </div>
              </CardContent>
            </Card>
          ) : (
            <Card
              sx={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderLeft: showPadding === "yes" ? undefined : "none",
                borderRight: showPadding === "yes" ? undefined : "none",
                backgroundColor: "#fff",
              }}
            >
              <JobsList
                jobs={jobs}
                jobType={currentTab}
                nextToken={nextToken}
                loadMoreLoading={loadMoreLoading}
                setLoadMoreLoading={setLoadMoreLoading}
                loadMore={loadMoreJobs}
                loading={jobsLoading}
              />
            </Card>
          )}
        </Stack>
      </Grow>
      <LoadingBackdrop open={loading} />
    </EditContainer>
  )
}

export default TranslationJobsList
