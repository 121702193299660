import { BugReportRounded } from "@mui/icons-material"
import { Chip, Stack, Typography } from "@mui/material"
import { CSSProperties } from "react"
import "../../styles/betaBadge.scss"

const BetaBadge = ({ style }: { style?: CSSProperties }) => {
  return (
    <Chip
      className="beta-badge-container"
      label={
        <Stack gap={0.2} direction="row" alignItems="center">
          <BugReportRounded style={{ fontSize: 10 }} />
          <Typography
            style={{
              fontSize: 10,
              fontWeight: 900,
            }}
          >
            Beta
          </Typography>
        </Stack>
      }
      color="primary"
      style={{
        height: 16,
        ...style,
      }}
    />
  )
}

export default BetaBadge
