import { CardContent, Stack } from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { MainContext } from "../../controllers/main"
import { useSearchParams } from "react-router-dom"
import "../../styles/teamGeneralEdit.scss"
import TeamGeneralEditGeneralTab from "./teamGeneralEditGeneralTab"
import TeamGeneralEditTabs from "./teamGeneralEditTabs"
import TeamGeneralEditGroupsTab from "./teamGeneralEditGroupsTab"
import TeamGeneralEditJourneysTab from "./teamGeneralEditJourneysTab"
import TeamGeneralEditNewsTab from "./teamGeneralEditNewsTab"
import TeamGeneralEditChallengesTab from "./teamGeneralEditChallengesTab"
import TeamGeneralEditSurveysTab from "./teamGeneralEditSurveysTab"

const TeamGeneralEdit = ({
  setFeedbackOpen,
  setGlobalPreviewOpen,
}: {
  setFeedbackOpen: Dispatch<SetStateAction<boolean>>
  setGlobalPreviewOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const [searchParams] = useSearchParams()
  const { showPadding } = useContext(MainContext)

  // switch
  const [selectedSwitchPage, setSelectedSwitchPage] = useState<number>(0)

  useEffect(() => {
    setSearchGroupsValue("")
    setSearchJourneysValue("")
  }, [selectedSwitchPage])

  // view groups if user is coming from activation codes list (groupId query param)
  useEffect(() => {
    const groupId = searchParams.get("groupId")

    if (groupId) {
      setSelectedSwitchPage(1)

      // scroll group card into view and make it blink
      setTimeout(() => {
        const groupCard = document.getElementById(groupId)
        groupCard.scrollIntoView({ behavior: "smooth" })
        const intervalId = setInterval(() => {
          if (
            !groupCard.style.backgroundColor ||
            groupCard.style.backgroundColor === "white"
          ) {
            groupCard.style.backgroundColor = "lightgray"
          } else {
            groupCard.style.backgroundColor = "white"
          }
        }, 200)
        setTimeout(() => {
          clearInterval(intervalId)
        }, 1200)
      }, 100)
    }
  }, [])

  // search groups
  const [searchGroupsValue, setSearchGroupsValue] = useState<string>("")

  // search journeys
  const [searchJourneysValue, setSearchJourneysValue] = useState<string>("")

  return (
    <Stack>
      {/* switch between various items */}
      <TeamGeneralEditTabs
        selectedSwitchPage={selectedSwitchPage}
        setSelectedSwitchPage={setSelectedSwitchPage}
      />
      {/* edit main card */}
      <CardContent
        style={{
          height:
            showPadding === "yes"
              ? "calc(100vh - 256px)"
              : "calc(100vh - 239px)",
          maxHeight:
            showPadding === "yes"
              ? "calc(100vh - 256px)"
              : "calc(100vh - 239px)",
          overflowY: "scroll",
        }}
      >
        {selectedSwitchPage === 0 ? (
          <TeamGeneralEditGeneralTab
            setFeedbackOpen={setFeedbackOpen}
            setGlobalPreviewOpen={setGlobalPreviewOpen}
          />
        ) : selectedSwitchPage === 1 ? (
          <TeamGeneralEditGroupsTab
            searchGroupsValue={searchGroupsValue}
            setSearchGroupsValue={setSearchGroupsValue}
          />
        ) : selectedSwitchPage === 2 ? (
          <TeamGeneralEditJourneysTab
            searchJourneysValue={searchJourneysValue}
            setSearchJourneysValue={setSearchJourneysValue}
          />
        ) : selectedSwitchPage === 3 ? (
          <TeamGeneralEditNewsTab />
        ) : selectedSwitchPage === 4 ? (
          <TeamGeneralEditChallengesTab />
        ) : (
          <TeamGeneralEditSurveysTab />
        )}
      </CardContent>
    </Stack>
  )
}

export default TeamGeneralEdit
