import {
  Alert,
  Snackbar,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ListContainer from "../../../components/global/listContainer"
import ListTable from "../../../components/global/listTable"
import TableSpinner from "../../../components/global/tableSpinner"
import { MainContext } from "../../../controllers/main"
import {
  calculateTimeElapsed,
  registerAnalyticsEvent,
} from "../../../services/utilities/utility"
import TitleTableCell from "../../../components/global/titleTableCell"
import { NewsQuizzesContext } from "../../../controllers/newsQuizzes"
import NewsQuiz from "../../../models/newsQuiz"
import EditHeading from "../../../components/global/editHeading"
import CreateNewsQuizDialog from "../../../components/newsQuizzes/createNewsQuizDialog"
import { NewsContext } from "../../../controllers/news"
import TranslationsStack from "../../../components/global/translationsStack"

const NewsQuizzesList = () => {
  const { setAnimation, showPadding, cmdPressed } = useContext(MainContext)
  const { currentNews, getCurrentNews } = useContext(NewsContext)
  const {
    loading,
    newsQuizzesList,
    getNewsQuizzesList,
    newsQuizzesListNextToken,
    loadMoreNewsQuizzes,
  } = useContext(NewsQuizzesContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { newsId } = useParams()

  // get quizzes list and current news if not present
  const [loadingCurrentNews, setLoadingCurrentNews] = useState<boolean>(true)

  useEffect(() => {
    const getNews = async () => {
      await getCurrentNews(newsId)
      setLoadingCurrentNews(false)
    }

    getNewsQuizzesList(newsId)

    if (!currentNews.id) {
      getNews()
    } else {
      setLoadingCurrentNews(false)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create news quiz dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // handle news quiz deleted feedback
  const [deletedFeedbackOpen, setDeletedFeedbackOpen] = useState<boolean>(false)

  useEffect(() => {
    if (location.state && (location.state as any).newsQuizDeleted) {
      setDeletedFeedbackOpen(true)
    }
  }, [])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "news_quizzes_list" })
  }, [])

  return (
    <Stack style={{ paddingTop: 1 }}>
      <Stack direction="row" alignItems="flex-end" style={{ paddingRight: 16 }}>
        <EditHeading
          backButtonOnClick={() => {
            const pathname = window.location.pathname

            setAnimation(false)
            setTimeout(() => {
              navigate(pathname.slice(0, pathname.indexOf("/quizzes")))
            }, 300)
          }}
          title={
            currentNews.document
              ? `${
                  currentNews.document.items.find((item) => item.isDefault)
                    .title
                } / Quizzes`
              : "Quizzes"
          }
          titleLoading={loadingCurrentNews}
          showCustomButton
          customButtonOnClick={handleDialogChange}
          customButtonLabel="Add News Quiz"
        />
      </Stack>
      <ListContainer style={{ position: "relative" }}>
        {loading ? (
          <TableSpinner
            height={
              showPadding === "yes"
                ? "calc(100vh - 149px)"
                : "calc(100vh - 116px)"
            }
          />
        ) : (
          <ListTable
            height={
              showPadding === "yes"
                ? "calc(100vh - 149px)"
                : "calc(100vh - 116px)"
            }
            headingItems={["Question & ID", "Translations", "Updated"]}
            nextToken={newsQuizzesListNextToken}
            loadingMoreItems={loadMoreLoading}
            setLoadingMoreItems={setLoadMoreLoading}
            loadMore={() => loadMoreNewsQuizzes(newsId)}
            tableBody={newsQuizzesList.map((item: NewsQuiz) => (
              <TableRow
                key={item.id}
                style={{ cursor: "pointer" }}
                hover
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                onClick={() => {
                  if (cmdPressed) {
                    window.open(`${window.location.href}/${item.id}`)
                  } else {
                    setAnimation(false)
                    setTimeout(() => {
                      navigate(`${window.location.pathname}/${item.id}`)
                    }, 250)
                  }
                }}
              >
                <TitleTableCell
                  title={
                    item.document.items.find(
                      (documentItem) => documentItem.isDefault
                    ).question
                  }
                  id={item.id}
                />
                <TableCell>
                  <TranslationsStack documentItems={item.document.items} />
                </TableCell>
                <TableCell style={{ minWidth: 110 }}>
                  <Typography variant="caption">
                    {calculateTimeElapsed(item.updatedAt)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          />
        )}
        <CreateNewsQuizDialog
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          handleDialogChange={handleDialogChange}
        />
        <Snackbar
          open={deletedFeedbackOpen}
          onClose={() => {
            setDeletedFeedbackOpen(false)
          }}
          autoHideDuration={3000}
        >
          <Alert severity="success">News Quiz deleted</Alert>
        </Snackbar>
      </ListContainer>
    </Stack>
  )
}

export default NewsQuizzesList
