import { LoadingButton } from "@mui/lab"
import {
  Autocomplete,
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Typography,
  Dialog,
  MenuItem,
  Select,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react"
import {
  DialogTransition,
  capitalizeFirstCharacter,
  enumAsArray,
} from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import Survey, { SurveyProvider, SurveyLinkMode } from "../../models/survey"
import Team from "../../models/team"
import { isDev } from "../../services/config/constants"
import { CloseRounded, OpenInNewRounded } from "@mui/icons-material"
import { TeamsContext } from "../../controllers/teams"
import TeamsList from "../../views/team/teamsList"
import { SurveysContext } from "../../controllers/surveys"
import { LanguagesContext } from "../../controllers/languages"
import BlockEditor from "../blockEditor/blockEditor"
import { BodySliceType } from "../../services/config/enum"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import ImageSlice from "../../models/bodySlices/imageSlice"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import {
  validateSurveyFormUrl,
  cleanTypeformUrl,
} from "../../services/utilities/surveyUtils"
import { SURVEY_URL_PREFIXES } from "../../services/config/surveyConstants"
import ISODatePicker from "../global/isoDatePicker"
import ISOTimePicker from "../global/isoTimePicker"

const CreateSurveyDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
  preSelectedTeam,
  currentTeamSurveysList,
  setCurrentTeamSurveysList,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
  preSelectedTeam?: Team
  currentTeamSurveysList?: Survey[]
  setCurrentTeamSurveysList?: Dispatch<SetStateAction<Survey[]>>
}) => {
  const { setAnimation } = useContext(MainContext)
  const { loading, setLoading, createSurvey, createSurveyDocument } =
    useContext(SurveysContext)
  const { resetTeamsFilters } = useContext(TeamsContext)
  const { languages, languagesForEpisodeTabs } = useContext(LanguagesContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // configuration
  const [handle, setHandle] = useState<string>("")
  const [projectId, setProjectId] = useState<string>("")
  const [provider, setProvider] = useState<SurveyProvider>(
    SurveyProvider.typeform
  )
  const [team, setTeam] = useState<Team | null>(preSelectedTeam ?? null)
  const [title, setTitle] = useState<string>("")
  const [formId, setFormId] = useState<string>("")
  const [formUrl, setFormUrl] = useState<string>("")
  const [defaultTranslation, setDefaultTranslation] = useState<string>("")
  const [body, setBody] = useState<
    (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
  >([])
  const [startsAt, setStartsAt] = useState<string | null>(null)
  const [endsAt, setEndsAt] = useState<string | null>(null)

  useEffect(() => {
    if (dialogOpen) {
      setTeam(preSelectedTeam ?? null)
    }
  }, [dialogOpen])

  // Track if formUrl was manually edited
  const formUrlManuallyEdited = useRef(false)
  // Track if formUrl is being updated
  const isFormUrlUpdating = useRef(false)

  // Auto-fill formUrl when formId changes (but not when formUrl is manually edited)
  useEffect(() => {
    if (formId && !formUrlManuallyEdited.current) {
      isFormUrlUpdating.current = true
      setFormUrl(`${SURVEY_URL_PREFIXES[provider]}${formId}`)
      // Give time for formUrl to update before allowing error checks
      setTimeout(() => {
        isFormUrlUpdating.current = false
      }, 0)
    }
  }, [formId, provider])

  // Track which fields have been touched/edited
  const [touchedFields, setTouchedFields] = useState<{
    handle: boolean
    title: boolean
    formId: boolean
    formUrl: boolean
    startsAt: boolean
    endsAt: boolean
  }>({
    handle: false,
    title: false,
    formId: false,
    formUrl: false,
    startsAt: false,
    endsAt: false,
  })

  const markFieldAsTouched = (fieldName: keyof typeof touchedFields) => {
    setTouchedFields((prev) => ({
      ...prev,
      [fieldName]: true,
    }))
  }

  // check errors in body
  const [bodyError, setBodyError] = useState<boolean>(false)
  // Add useSecret and storeResponses states with defaults
  const [useSecret, setUseSecret] = useState<boolean>(true)
  const [storeResponses, setStoreResponses] = useState<boolean>(true)

  useEffect(() => {
    let errorInBody = false
    body.forEach((bodyItem) => {
      if (
        (bodyItem.sliceType === BodySliceType.BodyTextSlice &&
          !bodyItem.text?.length) ||
        (bodyItem.sliceType === BodySliceType.BodyQuoteSlice &&
          !bodyItem.quoteText?.length) ||
        (bodyItem.sliceType === BodySliceType.BodyImageSlice &&
          !bodyItem.imgUrl?.length) ||
        (bodyItem.sliceType === BodySliceType.BodyEmbedSlice &&
          !bodyItem.embedUrl?.length)
      ) {
        errorInBody = true
      }
    })
    if (errorInBody) {
      setBodyError(true)
    } else {
      setBodyError(false)
    }
  }, [body])

  // change team dialog
  const [changeTeamDialogOpen, setChangeTeamDialogOpen] =
    useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  const slides = [
    {
      label: "Configuration",
      component: (
        <Stack spacing={1.5}>
          <Typography variant="h6" className="card-title">
            Configuration
          </Typography>
          <Stack direction="row" gap={2}>
            <TextField
              fullWidth
              size="small"
              label="Handle"
              value={handle}
              onChange={(e) => {
                setHandle(e.target.value)
              }}
              onBlur={() => markFieldAsTouched("handle")}
              error={touchedFields.handle && !handle}
              helperText={
                touchedFields.handle && !handle ? "Handle is required" : ""
              }
            />
            <Autocomplete
              fullWidth
              size="small"
              value={projectId}
              disablePortal
              options={["aworld"]}
              renderInput={(params) => (
                <TextField {...params} label="Project ID" />
              )}
              onChange={(e, newValue) => {
                setProjectId(newValue || "")
              }}
              freeSolo
              autoSelect
            />
          </Stack>
          <FormControl fullWidth size="small">
            <InputLabel id="provider">Provider</InputLabel>
            <Select
              labelId="provider"
              label="Provider"
              value={provider}
              onChange={(e) => {
                const newProvider = e.target.value as SurveyProvider
                setProvider(newProvider)
                // Set useSecret true by default for typeform
                setUseSecret(newProvider === SurveyProvider.typeform)
              }}
            >
              {enumAsArray(SurveyProvider).map((item: string, index) => (
                <MenuItem key={index} value={item}>
                  {capitalizeFirstCharacter(item)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Stack direction="row" spacing={0}>
                  {team ? (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        if (isDev) {
                          window.open(
                            window.location.protocol +
                              "//" +
                              window.location.host +
                              "/teams/" +
                              team.id
                          )
                        } else {
                          window.open(
                            window.location.protocol +
                              "//" +
                              window.location.host +
                              "/teams/" +
                              team.id
                          )
                        }
                      }}
                    >
                      <OpenInNewRounded
                        style={{
                          fontSize: 15,
                          opacity: 0.9,
                        }}
                      />
                    </Button>
                  ) : null}
                  <Button
                    sx={{ paddingInline: 2 }}
                    disabled={preSelectedTeam ? true : false}
                    onClick={() => {
                      setChangeTeamDialogOpen(true)
                    }}
                  >
                    Change
                  </Button>
                  {team ? (
                    <Button
                      sx={{ paddingInline: 2 }}
                      disabled={preSelectedTeam ? true : false}
                      onClick={() => {
                        setTeam(null)
                      }}
                    >
                      Reset
                    </Button>
                  ) : null}
                </Stack>
              ),
            }}
            fullWidth
            label="Team"
            size="small"
            value={
              team
                ? team.document.items.find((item) => item.isDefault)?.title
                : "Global (App)"
            }
          />
        </Stack>
      ),
    },
    {
      label: "Duration",
      component: (
        <Stack spacing={1.5}>
          <Stack direction="row" spacing={2}>
            <ISODatePicker
              label="Start date"
              date={startsAt}
              setDate={(newDate: string) => {
                const newStartsAt = newDate
                  ? newDate.split("T")[0] +
                    "T" +
                    (startsAt ? startsAt.split("T")[1] : "12:00:00.000Z")
                  : newDate
                setStartsAt(newStartsAt)
                markFieldAsTouched("startsAt")
              }}
              error={touchedFields.startsAt && !startsAt}
              hour={12}
            />
            <ISOTimePicker
              label="Start time"
              time={startsAt}
              setTime={(newTime: string) => {
                setStartsAt(newTime)
              }}
              error={touchedFields.startsAt && !startsAt}
            />
          </Stack>
          <Typography
            style={{
              lineHeight: 1.5,
              fontSize: "0.75rem",
              color: "#667380",
              marginLeft: 14,
              marginTop: 6,
            }}
          >
            {startsAt &&
              `The Survey will start on ${new Date(
                startsAt
              ).toLocaleDateString()} at ${new Date(
                startsAt
              ).toLocaleTimeString("it", {
                hour12: true,
              })} in local time, ${new Date(
                new Date(startsAt).getTime() +
                  new Date().getTimezoneOffset() * 60000
              ).toLocaleDateString()} at ${new Date(
                new Date(startsAt).getTime() +
                  new Date().getTimezoneOffset() * 60000
              ).toLocaleTimeString("it", {
                hour12: true,
              })} UTC`}
          </Typography>
          <Stack direction="row" spacing={2}>
            <ISODatePicker
              label="End date"
              date={endsAt}
              setDate={(newDate: string) => {
                const newEndsAt = newDate
                  ? newDate.split("T")[0] +
                    "T" +
                    (endsAt ? endsAt.split("T")[1] : "12:00:00.000Z")
                  : newDate
                setEndsAt(newEndsAt)
                markFieldAsTouched("endsAt")
              }}
              error={
                touchedFields.endsAt &&
                (!endsAt || (startsAt && endsAt && endsAt <= startsAt))
              }
              minDate={startsAt}
              hour={12}
            />
            <ISOTimePicker
              label="End time"
              time={endsAt}
              setTime={(newTime: string) => {
                setEndsAt(newTime)
              }}
              error={
                touchedFields.endsAt &&
                (!endsAt || (startsAt && endsAt && endsAt <= startsAt))
              }
            />
          </Stack>
          <Typography
            style={{
              lineHeight: 1.5,
              fontSize: "0.75rem",
              color: "#667380",
              marginLeft: 14,
              marginTop: 6,
            }}
          >
            {endsAt &&
              `The Survey will end on ${new Date(
                endsAt
              ).toLocaleDateString()} at ${new Date(endsAt).toLocaleTimeString(
                "it",
                {
                  hour12: true,
                }
              )} in local time, ${new Date(
                new Date(endsAt).getTime() +
                  new Date().getTimezoneOffset() * 60000
              ).toLocaleDateString()} at ${new Date(
                new Date(endsAt).getTime() +
                  new Date().getTimezoneOffset() * 60000
              ).toLocaleTimeString("it", {
                hour12: true,
              })} UTC`}
          </Typography>
        </Stack>
      ),
    },
    {
      label: "Language",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Default language
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="language-select">Default language</InputLabel>
            <Select
              labelId="language-select"
              label="Default language"
              value={defaultTranslation}
              onChange={(e) => {
                setDefaultTranslation(e.target.value)
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      ),
    },
    {
      label: "Details",
      component: (
        <Stack spacing={1.5} style={{ maxHeight: 300, overflowY: "auto" }}>
          <Typography variant="h6" className="card-title">
            Details in {defaultTranslation.toUpperCase()}
          </Typography>
          <TextField
            fullWidth
            size="small"
            label="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            onBlur={() => markFieldAsTouched("title")}
            error={touchedFields.title && !title}
            helperText={
              touchedFields.title && !title ? "Title is required" : ""
            }
          />
          <TextField
            fullWidth
            size="small"
            label="Form ID"
            value={formId}
            onChange={(e) => {
              formUrlManuallyEdited.current = false
              setFormId(e.target.value)
            }}
            onBlur={() => markFieldAsTouched("formId")}
            error={touchedFields.formId && !formId}
            helperText={
              touchedFields.formId && !formId ? "Form ID is required" : ""
            }
          />
          <TextField
            fullWidth
            size="small"
            label="Form URL"
            value={formUrl}
            onChange={(e) => {
              formUrlManuallyEdited.current = true
              const newUrl =
                provider === SurveyProvider.typeform
                  ? cleanTypeformUrl(e.target.value)
                  : e.target.value
              setFormUrl(newUrl)
            }}
            onBlur={() => {
              markFieldAsTouched("formUrl")
              if (formUrl) {
                const cleanedUrl =
                  provider === SurveyProvider.typeform
                    ? cleanTypeformUrl(formUrl)
                    : formUrl
                setFormUrl(cleanedUrl.trim())
              }
            }}
            error={
              !isFormUrlUpdating.current &&
              touchedFields.formUrl &&
              (!formUrl || (formId && !validateSurveyFormUrl(formUrl, formId)))
            }
            helperText={
              !isFormUrlUpdating.current &&
              touchedFields.formUrl &&
              (!formUrl
                ? "Form URL is required"
                : !validateSurveyFormUrl(formUrl, formId)
                ? "Form URL must include Form ID"
                : "")
            }
            InputProps={{
              endAdornment: formUrl && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation()
                    window.open(formUrl)
                  }}
                >
                  <OpenInNewRounded
                    style={{
                      fontSize: 15,
                      opacity: 0.9,
                    }}
                  />
                </Button>
              ),
            }}
          />
          <Stack spacing={1}>
            <FormControl fullWidth size="small">
              <FormControlLabel
                control={
                  <Switch
                    checked={storeResponses}
                    onChange={(e) => setStoreResponses(e.target.checked)}
                  />
                }
                label="Store Responses"
              />
            </FormControl>
            {provider === SurveyProvider.typeform && (
              <FormControl fullWidth size="small">
                <FormControlLabel
                  control={
                    <Switch
                      checked={useSecret}
                      onChange={(e) => setUseSecret(e.target.checked)}
                    />
                  }
                  label="Use Secret for Webhook"
                />
              </FormControl>
            )}
          </Stack>
        </Stack>
      ),
    },
    {
      label: "Body",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Body in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <div
            style={{
              height: 260,
              maxHeight: 260,
              overflowY: "scroll",
            }}
          >
            <BlockEditor
              body={body}
              setBody={(
                body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
              ) => {
                setBody([...body])
              }}
            />
          </div>
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setHandle("")
        setProjectId("")
        setProvider(SurveyProvider.typeform)
        setTeam(null)
        setTitle("")
        setFormId("")
        setFormUrl("")
        setDefaultTranslation("")
        setBody([])
        setCurrentSlide(0)
        setStartsAt(null)
        setEndsAt(null)
        // Reset useSecret and storeResponses to true
        setUseSecret(true)
        setStoreResponses(true)
        // Reset touched fields state
        setTouchedFields({
          handle: false,
          title: false,
          formId: false,
          formUrl: false,
          startsAt: false,
          endsAt: false,
        })
        // Reset formUrl manual edit flag
        formUrlManuallyEdited.current = false
        isFormUrlUpdating.current = false
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (
      handle ||
      projectId ||
      team ||
      title ||
      formId ||
      formUrl ||
      defaultTranslation ||
      body.length > 0 ||
      startsAt ||
      endsAt
    ) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [
    handle,
    projectId,
    team,
    title,
    formId,
    formUrl,
    defaultTranslation,
    body,
    startsAt,
    endsAt,
  ])

  // create survey
  const createNewSurvey = async () => {
    // Mark all fields as touched before creating
    setTouchedFields({
      handle: true,
      title: true,
      formId: true,
      formUrl: true,
      startsAt: true,
      endsAt: true,
    })

    // Only proceed if all required fields are filled
    if (
      !handle ||
      !title ||
      !formId ||
      !formUrl ||
      !defaultTranslation ||
      !startsAt ||
      !endsAt
    ) {
      return
    }

    // Validate that endsAt is after startsAt
    if (endsAt <= startsAt) {
      return
    }

    setLoading(true)

    // First create the survey
    const surveyInput: {
      handle: string
      provider: SurveyProvider
      projectId?: string
      teamId?: string
      useSecret?: boolean
      storeResponses: boolean
      startsAt: string
      endsAt: string
    } = {
      handle,
      provider,
      projectId: projectId || undefined,
      storeResponses,
      startsAt,
      endsAt,
    }

    // Only add teamId if a specific team is selected
    if (team) {
      surveyInput.teamId = team.id
    }

    // Only add useSecret if provider is typeform and useSecret is true
    if (provider === SurveyProvider.typeform && useSecret) {
      surveyInput.useSecret = true
    }

    const survey = await createSurvey(surveyInput)

    if (survey) {
      // Then create its document
      const documentInput = {
        parentId: `#id#${survey.id}#stage#DRAFT`,
        type: "Survey",
        surveyDocumentItems: [
          {
            default: true,
            lang: defaultTranslation,
            title,
            formId,
            formUrl,
            body: body.map((item) => {
              const { sliceType, ...rest } = item
              return {
                [sliceType.charAt(0).toLowerCase() + sliceType.slice(1)]: rest,
              }
            }),
            linkMode: SurveyLinkMode.unlinked,
          },
        ],
      }

      const result = await createSurveyDocument(documentInput)

      if (result) {
        setDialogOpen(false)

        if (preSelectedTeam) {
          currentTeamSurveysList.push(survey)
          setCurrentTeamSurveysList([...currentTeamSurveysList])
        } else {
          setAnimation(false)
          setTimeout(() => {
            navigate(`/surveys/${survey.id}`)
          }, 250)
        }
      }
    }

    setLoading(false)
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText="Add Survey"
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            Add Survey
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 && !handle) ||
                  (currentSlide === 1 &&
                    (!startsAt ||
                      !endsAt ||
                      (startsAt && endsAt && endsAt <= startsAt))) ||
                  (currentSlide === 2 && !defaultTranslation) ||
                  (currentSlide === 3 && (!title || !formId || !formUrl)) ||
                  (currentSlide === 4 && bodyError)
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? () => {
                        createNewSurvey()
                      }
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={loading} />
      {/* change team dialog */}
      <Dialog
        fullScreen
        open={changeTeamDialogOpen}
        onClose={() => {
          setChangeTeamDialogOpen(false)
          setSelectedTeam(null)
          resetTeamsFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Team
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                setTeam(selectedTeam)

                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              disabled={!selectedTeam}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <TeamsList
            forDialog
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
          />
        </Paper>
      </Dialog>
    </Dialog>
  )
}

export default CreateSurveyDialog
