import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react"
import Team from "../models/team"
import {
  capitalizeFirstCharacter,
  deepCopy,
  logger,
  lowercaseFirstCharacter,
  Status,
  wait,
} from "../services/utilities/utility"
import { MainContext } from "./main"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  searchChallenges,
  searchNews,
  surveysStagedList,
  teamContractsList,
  teamGet,
  teamJourneys,
  teamSurveysList,
  teamUsersSearch,
  teamsList,
  teamsSearch,
} from "../services/graphql/queries"
import { AutocompleteOption } from "../services/config/interfaces"
import {
  TeamAccessType,
  TeamOrganizationType,
  TeamStage,
  TeamStakeholderType,
  TeamVisibilityOptionType,
} from "../services/config/enum"
import {
  documentUpsertTeam,
  teamContractActivate,
  teamContractCreate,
  teamContractDelete,
  teamContractUpdate,
  teamCreate,
  teamDelete,
  teamUpdate,
  teamUserDelete,
  teamUserUpsert,
  upsertTeamJourney,
} from "../services/graphql/mutations"
import Document from "../models/document"
import TeamsDocument from "../models/teamsDocument"
import TeamJourney from "../models/teamJourney"
import TeamUser from "../models/teamUser"
import News from "../models/news"
import Challenge from "../models/challenge"
import TeamContract from "../models/teamContract"
import Survey from "../models/survey"

interface TeamsContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  currentTeamsList: Team[]
  getTeamsList: (withLoading?: boolean) => void
  searchTeamsList: (withNextToken?: boolean) => Promise<boolean>
  hasSearched: boolean
  getCurrentTeam: (teamId: string) => Promise<boolean>
  currentTeam: Team | null
  setCurrentTeam: Dispatch<SetStateAction<Team | null>>
  preChangesCurrentTeam: Team | null
  doneChanges: boolean
  cancelChanges: () => void
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  teamsListNextToken: string | null
  loadMoreTeams: () => Promise<boolean>
  searchTitle: string
  setSearchTitle: Dispatch<SetStateAction<string>>
  searchLang: AutocompleteOption | null
  setSearchLang: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchStakeholderType: AutocompleteOption | null
  setSearchStakeholderType: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchAccessType: AutocompleteOption | null
  setSearchAccessType: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchIsPrimary: boolean | null
  setSearchIsPrimary: Dispatch<SetStateAction<boolean | null>>
  searchOrganizationType: AutocompleteOption | null
  setSearchOrganizationType: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchStage: AutocompleteOption | null
  setSearchStage: Dispatch<SetStateAction<AutocompleteOption | null>>
  createTeam: (input: {
    accessType: TeamAccessType
    name: string
    uid: string
    stakeholderType: TeamStakeholderType
    organizationType: TeamOrganizationType
    canLeave: boolean
    visibilityOptions: {
      leaderboard: TeamVisibilityOptionType
      members: TeamVisibilityOptionType
      metric: TeamVisibilityOptionType
    }
    isPrimary: boolean
    suggestFootprint: boolean
    availableAtLocations:
      | {
          id: string
          type: "Country" | "Region" | "SubRegion" | "DefaultLocation"
        }[]
      | null
    restrictedAtLocations:
      | {
          id: string
          type: "Country" | "Region" | "SubRegion" | "DefaultLocation"
        }[]
      | null
  }) => Promise<Team | boolean>
  createTeamDocument: (input: {
    parentId: string
    type: string
    teamDocumentItems: {
      default: boolean
      lang: string
      title: string
      logo: string
      cover: string
      body: object[]
    }[]
  }) => Promise<boolean>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  addTranslation: (translationToAdd: string) => void
  hasError: boolean
  nameError: boolean
  setNameError: Dispatch<SetStateAction<boolean>>
  expirationDateError: boolean
  setExpirationDateError: Dispatch<SetStateAction<boolean>>
  translationsErrors: { lang: string; hasErrors: boolean }[]
  setTranslationsErrors: Dispatch<
    SetStateAction<{ lang: string; hasErrors: boolean }[]>
  >
  copyHomeDetailsFromDefault: (itemToCopyToIndex: number) => void
  copyDetailsFromDefault: (itemToCopyToIndex: number) => void
  copyBodyFromDefault: (itemToCopyToIndex: number) => void
  upsertTeamParent: (customInput?: {
    id: string
    stage?: TeamStage
    stakeholderType?: TeamStakeholderType
    isPrimary?: boolean
    organizationType?: TeamOrganizationType
  }) => Promise<boolean>
  upsertTeamDocument: () => Promise<boolean>
  getTeamJourneys: (teamId: string) => Promise<boolean>
  currentTeamJourneysList: TeamJourney[]
  setCurrentTeamJourneysList: Dispatch<SetStateAction<TeamJourney[]>>
  resetTeamsFilters: () => void
  getTeamUsers: (
    teamId: string,
    searchGroupParam: AutocompleteOption | null,
    searchIsAdminParam: boolean | null,
    searchTextParam: string
  ) => Promise<boolean>
  usersList: TeamUser[]
  usersListNextToken: string | null
  hasSearchedMembers: boolean
  setHasSearchedMembers: Dispatch<SetStateAction<boolean>>
  searchGroup: AutocompleteOption | null
  setSearchGroup: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchIsAdmin: boolean | null
  setSearchIsAdmin: Dispatch<SetStateAction<boolean | null>>
  searchText: string
  setSearchText: Dispatch<SetStateAction<string>>
  searchTextType: "email" | "sub"
  setSearchTextType: Dispatch<SetStateAction<"email" | "sub">>
  updatingMembersList: boolean
  setUpdatingMembersList: Dispatch<SetStateAction<boolean>>
  upsertUsers: (
    teamId: string,
    inputList: {
      sub?: string
      email?: string
      isAdmin?: boolean
      groupId?: string
    }[]
  ) => Promise<boolean>
  loadMoreUsers: (teamId: string) => Promise<boolean>
  currentTeamNewsList: News[]
  setCurrentTeamNewsList: Dispatch<SetStateAction<News[]>>
  getTeamNews: (teamId: string) => Promise<boolean>
  currentTeamChallengesList: Challenge[]
  setCurrentTeamChallengesList: Dispatch<SetStateAction<Challenge[]>>
  currentTeamSurveysList: Survey[]
  setCurrentTeamSurveysList: Dispatch<SetStateAction<Survey[]>>
  getTeamChallenges: (teamId: string) => Promise<boolean>
  getTeamSurveys: (teamId: string) => Promise<boolean>
  deleteTeam: (teamToDeleteId: string) => Promise<boolean>
  loadAllUsers: (teamId: string, withEmails: boolean) => Promise<string[][]>
  removeUsers: (teamId: string, subs: string[]) => Promise<boolean>
  getTeamContracts: (teamId: string) => Promise<boolean>
  currentTeamContractsList: TeamContract[]
  setCurrentTeamContractsList: Dispatch<SetStateAction<TeamContract[]>>
  createTeamContract: (
    teamId: string,
    startDate: string,
    endDate: string,
    sellingPrice?: number,
    targetMembers?: number
  ) => Promise<boolean>
  updateTeamContract: (
    id: string,
    startDate: string,
    endDate: string,
    sellingPrice?: number,
    targetMembers?: number
  ) => Promise<boolean>
  deleteTeamContract: (id: string) => Promise<boolean>
  activateTeamContract: (id: string) => Promise<boolean>
  getAllTeams: () => Promise<string[][]>
}

const TeamsContext = createContext<TeamsContextInterface>({
  loading: true,
  setLoading: () => {},
  currentTeamsList: [],
  getTeamsList: () => {},
  searchTeamsList: async () => true,
  hasSearched: false,
  getCurrentTeam: async () => true,
  currentTeam: null,
  setCurrentTeam: () => {},
  preChangesCurrentTeam: null,
  doneChanges: false,
  cancelChanges: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  teamsListNextToken: null,
  loadMoreTeams: async () => true,
  searchTitle: "",
  setSearchTitle: () => {},
  searchLang: null,
  setSearchLang: () => {},
  searchStakeholderType: null,
  setSearchStakeholderType: () => {},
  searchAccessType: null,
  setSearchAccessType: () => {},
  searchIsPrimary: null,
  setSearchIsPrimary: () => {},
  searchOrganizationType: null,
  setSearchOrganizationType: () => {},
  searchStage: null,
  setSearchStage: () => {},
  createTeam: async () => true,
  createTeamDocument: async () => true,
  editMode: true,
  setEditMode: () => {},
  addTranslation: () => {},
  hasError: false,
  nameError: false,
  setNameError: () => {},
  expirationDateError: false,
  setExpirationDateError: () => {},
  translationsErrors: [],
  setTranslationsErrors: () => {},
  copyHomeDetailsFromDefault: () => {},
  copyDetailsFromDefault: () => {},
  copyBodyFromDefault: () => {},
  upsertTeamParent: async () => true,
  upsertTeamDocument: async () => true,
  getTeamJourneys: async () => true,
  currentTeamJourneysList: [],
  setCurrentTeamJourneysList: () => {},
  resetTeamsFilters: () => {},
  getTeamUsers: async () => true,
  usersList: [],
  usersListNextToken: null,
  hasSearchedMembers: false,
  setHasSearchedMembers: () => {},
  searchGroup: null,
  setSearchGroup: () => {},
  searchIsAdmin: null,
  setSearchIsAdmin: () => {},
  searchText: "",
  setSearchText: () => {},
  searchTextType: "email",
  setSearchTextType: () => {},
  updatingMembersList: false,
  setUpdatingMembersList: () => {},
  upsertUsers: async () => true,
  loadMoreUsers: async () => true,
  currentTeamNewsList: [],
  setCurrentTeamNewsList: () => {},
  getTeamNews: async () => true,
  currentTeamChallengesList: [],
  setCurrentTeamChallengesList: () => {},
  currentTeamSurveysList: [],
  setCurrentTeamSurveysList: () => {},
  getTeamChallenges: async () => true,
  getTeamSurveys: async () => true,
  deleteTeam: async () => true,
  loadAllUsers: async () => [],
  removeUsers: async () => true,
  getTeamContracts: async () => true,
  currentTeamContractsList: [],
  setCurrentTeamContractsList: () => {},
  createTeamContract: async () => true,
  updateTeamContract: async () => true,
  deleteTeamContract: async () => true,
  activateTeamContract: async () => true,
  getAllTeams: async () => [],
})

const TeamsController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [currentTeamsList, setCurrentTeamsList] = useState<Team[]>([]) // all teams list
  const [teamsListNextToken, setTeamsListNextToken] = useState<string | null>(
    null
  ) // teams list next token
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not
  const [updatingList, setUpdatingList] = useState<boolean>(false) // if list is updating or not
  const [currentTeam, setCurrentTeam] = useState<Team | null>(null) // current team details
  const [preChangesCurrentTeam, setPreChangesCurrentTeam] =
    useState<Team | null>(null) // fetched current team details
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes to team details
  const [editMode, setEditMode] = useState<boolean>(true) // if is edit mode or not
  const [currentTeamJourneysList, setCurrentTeamJourneysList] = useState<
    TeamJourney[]
  >([]) // list of journeys associated with current team
  const [
    preChangesCurrentTeamJourneysList,
    setPreChangesCurrentTeamJourneysList,
  ] = useState<TeamJourney[]>([]) // fetched list of journeys associated with current team
  const [currentTeamNewsList, setCurrentTeamNewsList] = useState<News[]>([]) // list of news associated with current team
  const [currentTeamChallengesList, setCurrentTeamChallengesList] = useState<
    Challenge[]
  >([]) // list of challenges associated with current team
  const [currentTeamSurveysList, setCurrentTeamSurveysList] = useState<
    Survey[]
  >([]) // list of surveys associated with current team
  const [usersList, setUsersList] = useState<TeamUser[]>([]) // list of current team users
  const [usersListNextToken, setUsersListNextToken] = useState<string | null>(
    null
  ) // team users list next token
  const [updatingMembersList, setUpdatingMembersList] = useState<boolean>(false) // if members list is updating or not
  const [currentTeamContractsList, setCurrentTeamContractsList] = useState<
    TeamContract[]
  >([]) // list of contracts associated with current team

  // search states
  const [searchTitle, setSearchTitle] = useState<string>("")
  const [searchLang, setSearchLang] = useState<AutocompleteOption | null>(null)
  const [searchStakeholderType, setSearchStakeholderType] =
    useState<AutocompleteOption | null>(null)
  const [searchAccessType, setSearchAccessType] =
    useState<AutocompleteOption | null>(null)
  const [searchIsPrimary, setSearchIsPrimary] = useState<boolean | null>(null)
  const [searchOrganizationType, setSearchOrganizationType] =
    useState<AutocompleteOption | null>(null)
  const [searchStage, setSearchStage] = useState<AutocompleteOption | null>(
    null
  )

  // search states for members list
  const [hasSearchedMembers, setHasSearchedMembers] = useState<boolean>(false)
  const [searchGroup, setSearchGroup] = useState<AutocompleteOption | null>(
    null
  )
  const [searchIsAdmin, setSearchIsAdmin] = useState<boolean | null>(null)
  const [searchText, setSearchText] = useState<string>("")
  const [searchTextType, setSearchTextType] = useState<"email" | "sub">("email")

  // errors for team edit
  const [nameError, setNameError] = useState<boolean>(false)
  const [expirationDateError, setExpirationDateError] = useState<boolean>(false)
  const [translationsErrors, setTranslationsErrors] = useState<
    { lang: string; hasErrors: boolean }[]
  >([]) // errors array for translations
  const hasError: boolean =
    nameError ||
    expirationDateError ||
    translationsErrors.filter((item) => item.hasErrors).length !== 0 // if there are errors or not

  // reset all errors
  const resetErrors = () => {
    setNameError(false)
    setExpirationDateError(false)
    const newTranslationsErrors: { lang: string; hasErrors: boolean }[] = []
    currentTeam.document.items.forEach((item) => {
      newTranslationsErrors.push({ lang: item.lang, hasErrors: false })
    })
    setTranslationsErrors(newTranslationsErrors)
  }

  // reset teams list filters
  const resetTeamsFilters = () => {
    setSearchTitle("")
    setSearchLang(null)
    setSearchStakeholderType(null)
    setSearchAccessType(null)
    setSearchIsPrimary(null)
    setSearchOrganizationType(null)
    setSearchStage(null)
    getTeamsList()
  }

  // queries
  const [teamsListQuery] = useLazyQuery(teamsList)
  const [teamsSearchQuery] = useLazyQuery(teamsSearch)
  const [teamGetQuery] = useLazyQuery(teamGet)
  const [teamJourneysQuery] = useLazyQuery(teamJourneys)
  const [teamUsersSearchQuery] = useLazyQuery(teamUsersSearch)
  const [searchNewsQuery] = useLazyQuery(searchNews)
  const [searchChallengesQuery] = useLazyQuery(searchChallenges)
  const [teamSurveysListQuery] = useLazyQuery(teamSurveysList)
  const [teamContractsListQuery] = useLazyQuery(teamContractsList)

  // mutations
  const [teamCreateMutation] = useMutation(teamCreate)
  const [documentUpsertTeamMutation] = useMutation(documentUpsertTeam)
  const [teamUpdateMutation] = useMutation(teamUpdate)
  const [upsertTeamJourneyMutation] = useMutation(upsertTeamJourney)
  const [teamUserUpsertMutation] = useMutation(teamUserUpsert)
  const [teamDeleteMutation] = useMutation(teamDelete)
  const [teamUserDeleteMutation] = useMutation(teamUserDelete)
  const [teamContractCreateMutation] = useMutation(teamContractCreate)
  const [teamContractUpdateMutation] = useMutation(teamContractUpdate)
  const [teamContractDeleteMutation] = useMutation(teamContractDelete)
  const [teamContractActivateMutation] = useMutation(teamContractActivate)

  // get teams list
  const getTeamsList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Teams")[0].loading = true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY teamsList", teamsList)
        const { data } = await teamsListQuery({
          variables: {
            input: { limit: Math.round(window.innerHeight / 74) + 10 },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "teams list", data.teamsList.items)

        setCurrentTeamsList(data.teamsList.items)
        setTeamsListNextToken(data.teamsList.nextToken)

        setHasSearched(false)
        setLoading(false)
        setUpdatingList(false)

        panelStatus.filter((item) => item.name === "Teams")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Teams")[0].loading = false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `teamsList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)

        panelStatus.filter((item) => item.name === "Teams")[0].status = "error"
        panelStatus.filter((item) => item.name === "Teams")[0].loading = false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // load more teams
  const loadMoreTeams = async () => {
    try {
      logger(Status.Api, "QUERY teamsList", teamsList)
      const { data } = await teamsListQuery({
        variables: {
          input: {
            limit: Math.round(window.innerHeight / 74) + 10,
            nextToken: teamsListNextToken,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "teams list", [
        ...currentTeamsList,
        ...data.teamsList.items,
      ])

      setCurrentTeamsList([...currentTeamsList, ...data.teamsList.items])
      setTeamsListNextToken(data.teamsList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamsList`, e.message)
      }
      return false
    }
  }

  // search teams
  const searchTeamsList = async (withNextToken = false) => {
    setUpdatingList(true)

    try {
      const input: {
        key: string
        lang?: string
        nextToken?: string
        stakeholderType?: string
        accessType?: string
        isPrimary?: boolean
        organizationType?: string
        stage?: string
      } = {
        key: searchTitle,
        lang: searchLang ? searchLang.id : null,
        stakeholderType: searchStakeholderType
          ? searchStakeholderType.id
          : null,
        accessType: searchAccessType ? searchAccessType.id : null,
        isPrimary: searchIsPrimary,
        organizationType: searchOrganizationType
          ? searchOrganizationType.id
          : null,
        stage: searchStage ? searchStage.id : null,
      }

      if (hasSearched && withNextToken) {
        input.nextToken = teamsListNextToken
      }

      logger(Status.Api, "QUERY teamsSearch", teamsSearch)
      const { data } = await teamsSearchQuery({
        variables: { input: input },
        fetchPolicy: "no-cache",
      })

      if (input.nextToken) {
        logger(Status.Info, "teams list", [
          ...currentTeamsList,
          ...data.teamsSearch.items,
        ])

        setCurrentTeamsList([...currentTeamsList, ...data.teamsSearch.items])
        setTeamsListNextToken(data.teamsSearch.nextToken)
      } else {
        logger(Status.Info, "teams list", data.teamsSearch.items)

        setCurrentTeamsList(data.teamsSearch.items)
        setTeamsListNextToken(data.teamsSearch.nextToken)
      }

      setHasSearched(true)
      setUpdatingList(false)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamsSearch`, e.message)
      }
      setUpdatingList(false)

      return false
    }
  }

  // get all teams
  const getAllTeams = async () => {
    try {
      const input: {
        key: string
        lang?: string
        stakeholderType?: string
        accessType?: string
        isPrimary?: boolean
        organizationType?: string
        stage?: string
      } = {
        key: searchTitle,
        lang: searchLang ? searchLang.id : null,
        stakeholderType: searchStakeholderType
          ? searchStakeholderType.id
          : null,
        accessType: searchAccessType ? searchAccessType.id : null,
        isPrimary: searchIsPrimary,
        organizationType: searchOrganizationType
          ? searchOrganizationType.id
          : null,
        stage: searchStage ? searchStage.id : null,
      }

      const dataToReturn = [
        [
          "Title",
          "ID",
          "Primary",
          "Stage",
          "Access",
          "Stakeholder",
          "Organization",
          "Members",
        ],
      ]
      let nextToken: string | null = null

      do {
        logger(Status.Api, "QUERY teamsSearch", teamsSearch)
        const { data } = await teamsSearchQuery({
          variables: { input: { ...input, nextToken } },
          fetchPolicy: "no-cache",
        })

        nextToken = data.teamsSearch.nextToken

        data.teamsSearch.items.forEach((item: Team) => {
          dataToReturn.push([
            item.name,
            item.id,
            item.isPrimary ? "Yes" : "No",
            capitalizeFirstCharacter(item.stage),
            item.accessType === TeamAccessType.sso
              ? "SSO"
              : capitalizeFirstCharacter(
                  item.accessType
                    .replace(/([a-z])([A-Z])/g, "$1 $2")
                    .toLowerCase()
                ),
            capitalizeFirstCharacter(item.stakeholderType),
            capitalizeFirstCharacter(
              item.organizationType
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                .toLowerCase()
            ),
            item.userCount.toString(),
          ])
        })
      } while (nextToken)

      return dataToReturn
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamsSearch`, e.message)
      }

      return []
    }
  }

  // get team details
  const getCurrentTeam = useCallback(
    async (teamId: string) => {
      try {
        logger(Status.Api, "QUERY teamGet", teamGet)
        const { data } = await teamGetQuery({
          variables: { input: { id: teamId } },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `team ${teamId}`, data.teamGet)

        setCurrentTeam(data.teamGet)
        setPreChangesCurrentTeam(deepCopy(data.teamGet))
        data.teamGet.document.items.forEach((item: Document) => {
          translationsErrors.push({ lang: item.lang, hasErrors: false })
        })
        setTranslationsErrors([...translationsErrors])

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `teamGet`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // create new team
  const createTeam = async (input: {
    accessType: TeamAccessType
    name: string
    uid: string
    stakeholderType: TeamStakeholderType
    organizationType: TeamOrganizationType
    canLeave: boolean
    visibilityOptions: {
      leaderboard: TeamVisibilityOptionType
      members: TeamVisibilityOptionType
      metric: TeamVisibilityOptionType
    }
    isPrimary: boolean
    suggestFootprint: boolean
    availableAtLocations:
      | {
          id: string
          type: "Country" | "Region" | "SubRegion" | "DefaultLocation"
        }[]
      | null
    restrictedAtLocations:
      | {
          id: string
          type: "Country" | "Region" | "SubRegion" | "DefaultLocation"
        }[]
      | null
  }) => {
    try {
      logger(Status.Api, "MUTATION teamCreate", teamCreate)
      const { data } = await teamCreateMutation({
        variables: { input: input },
      })
      logger(Status.Info, "team created", data.teamCreate)

      return data.teamCreate
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamCreate`, e.message)
      }
      return false
    }
  }

  // create new team document
  const createTeamDocument = async (input: {
    parentId: string
    type: string
    teamDocumentItems: {
      default: boolean
      lang: string
      title: string
      logo: string
      cover: string
      body: object[]
    }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION documentUpsert", documentUpsertTeam)
      const { data } = await documentUpsertTeamMutation({
        variables: { input: input },
      })
      logger(Status.Info, "team document upserted", data.documentUpsert)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // upsert team parent
  const upsertTeamParent = async (customInput?: {
    id: string
    stage?: TeamStage
    stakeholderType?: TeamStakeholderType
    isPrimary?: boolean
    organizationType?: TeamOrganizationType
  }) => {
    try {
      if (customInput) {
        // logic for team updates from teams list
        logger(Status.Api, "MUTATION teamUpdate", teamUpdate)
        const { data } = await teamUpdateMutation({
          variables: { input: customInput },
        })
        logger(
          Status.Info,
          `team ${data.teamUpdate.id} upserted`,
          data.teamUpdate
        )

        // update teams list locally
        if (currentTeamsList.some((team) => team.id === customInput.id)) {
          const teamToUpdate = currentTeamsList.find(
            (team) => team.id === customInput.id
          )
          teamToUpdate.stage = data.teamUpdate.stage
          teamToUpdate.stakeholderType = data.teamUpdate.stakeholderType
          teamToUpdate.isPrimary = data.teamUpdate.isPrimary
          teamToUpdate.organizationType = data.teamUpdate.organizationType

          setCurrentTeamsList([...currentTeamsList])
        }
      } else {
        const input: {
          accessType: TeamAccessType
          canLeave: boolean
          groups: { groupId?: string; name: string }[]
          id: string
          name: string
          stage: TeamStage
          stakeholderType: TeamStakeholderType
          organizationType: TeamOrganizationType
          isPrimary: boolean | null
          isHomeFeatured: boolean
          featuredSorting?: number
          homeFeaturedSorting?: number
          suggestFootprint?: boolean
          availableAtLocations:
            | {
                id: string
                type: "Country" | "Region" | "SubRegion" | "DefaultLocation"
              }[]
            | null
          restrictedAtLocations:
            | {
                id: string
                type: "Country" | "Region" | "SubRegion" | "DefaultLocation"
              }[]
            | null
        } = {
          accessType: currentTeam.accessType,
          canLeave: currentTeam.canLeave,
          groups: currentTeam.groups
            .filter((item) => item.name)
            .map((item) => {
              if (item.groupId.includes("temp")) {
                return {
                  name: item.name,
                }
              } else {
                return {
                  groupId: item.groupId,
                  name: item.name,
                }
              }
            }),
          id: currentTeam.id,
          name: currentTeam.name,
          stage: currentTeam.stage,
          stakeholderType: currentTeam.stakeholderType,
          organizationType: currentTeam.organizationType,
          isPrimary: currentTeam.isPrimary,
          isHomeFeatured: currentTeam.isHomeFeatured,
          suggestFootprint: currentTeam.suggestFootprint,
          availableAtLocations: currentTeam.availableAtLocations
            ? currentTeam.availableAtLocations.map((item) => {
                return {
                  id: item.id,
                  type: item.__typename,
                }
              })
            : null,
          restrictedAtLocations: currentTeam.restrictedAtLocations
            ? currentTeam.restrictedAtLocations.map((item) => {
                return {
                  id: item.id,
                  type: item.__typename,
                }
              })
            : null,
        }

        if (currentTeam.stage === TeamStage.featured) {
          input.featuredSorting =
            currentTeam.featuredSorting === null
              ? 0
              : currentTeam.featuredSorting
        } else {
          input.featuredSorting = null
        }
        if (currentTeam.isHomeFeatured) {
          input.homeFeaturedSorting =
            currentTeam.homeFeaturedSorting === null
              ? 0
              : currentTeam.homeFeaturedSorting
        } else {
          input.homeFeaturedSorting = null
        }

        logger(Status.Api, "MUTATION teamUpdate", teamUpdate)
        logger(Status.Api, "MUTATION upsertTeamJourney", upsertTeamJourney)
        const result = await Promise.all([
          teamUpdateMutation({
            variables: { input: input },
          }),
          upsertTeamJourneyMutation({
            variables: {
              input: {
                teamId: currentTeam.id,
                journeyIds: currentTeamJourneysList.map(
                  (item) => item.journey.id
                ),
              },
            },
          }),
        ])
        logger(
          Status.Info,
          `team ${result[0].data.teamUpdate.id} upserted`,
          result[0].data.teamUpdate
        )
        logger(
          Status.Info,
          `team ${result[0].data.teamUpdate.id} journeys upserted`,
          result[1].data.upsertTeamJourney.items
        )

        const currentTeamCopy: Team = deepCopy(currentTeam)
        currentTeamCopy.id = result[0].data.teamUpdate.id
        currentTeamCopy.accessType = result[0].data.teamUpdate.accessType
        currentTeamCopy.canLeave = result[0].data.teamUpdate.canLeave
        currentTeamCopy.suggestFootprint =
          result[0].data.teamUpdate.suggestFootprint
        currentTeamCopy.featuredSorting =
          result[0].data.teamUpdate.featuredSorting
        currentTeamCopy.isHomeFeatured =
          result[0].data.teamUpdate.isHomeFeatured
        currentTeamCopy.homeFeaturedSorting =
          result[0].data.teamUpdate.homeFeaturedSorting
        currentTeamCopy.groups = result[0].data.teamUpdate.groups
        currentTeamCopy.id = result[0].data.teamUpdate.id
        currentTeamCopy.name = result[0].data.teamUpdate.name
        currentTeamCopy.stage = result[0].data.teamUpdate.stage
        currentTeamCopy.stakeholderType =
          result[0].data.teamUpdate.stakeholderType
        currentTeamCopy.organizationType =
          result[0].data.teamUpdate.organizationType
        currentTeamCopy.updatedAt = result[0].data.teamUpdate.updatedAt
        currentTeamCopy.availableAtLocations =
          result[0].data.teamUpdate.availableAtLocations
        currentTeamCopy.restrictedAtLocations =
          result[0].data.teamUpdate.restrictedAtLocations
        currentTeamCopy.document.parentId = result[0].data.teamUpdate.id
        setCurrentTeam(currentTeamCopy)
        setPreChangesCurrentTeam(deepCopy(currentTeamCopy))

        setCurrentTeamJourneysList(result[1].data.upsertTeamJourney.items)
        setPreChangesCurrentTeamJourneysList(
          deepCopy(result[1].data.upsertTeamJourney.items)
        )
      }

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamUpdate`, e.message)
      }
      return false
    }
  }

  // upsert team document
  const upsertTeamDocument = async () => {
    try {
      // parse data for inpout
      const currentTeamCopy = deepCopy(currentTeam)
      currentTeamCopy.document.items.forEach((item: any) => {
        const newBody = []
        if (item.body) {
          item.body.forEach((bodyItem: any) => {
            const { sliceType, __typename, ...rest } = bodyItem
            newBody.push({
              [lowercaseFirstCharacter(bodyItem.sliceType)]: {
                ...rest,
              },
            })
          })
        }
        item.body = newBody

        item.default = item.isDefault
        delete item.isDefault
        delete item.type
        delete item.updatedAt
        delete item.parentId
        delete item.__typename
        if (item.home) {
          delete item.home.__typename
        }
      })

      const input = {
        teamDocumentItems: currentTeamCopy.document.items,
        parentId: currentTeamCopy.document.parentId,
        type: "Team",
      }

      logger(Status.Api, "MUTATION documentUpsert", documentUpsertTeam)
      const { data } = await documentUpsertTeamMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `team ${data.documentUpsert.parentId} document upserted`,
        data.documentUpsert
      )

      setCurrentTeam((current) => {
        return {
          ...current,
          document: deepCopy(data.documentUpsert),
        }
      })
      setPreChangesCurrentTeam((current) => {
        return {
          ...current,
          document: deepCopy(data.documentUpsert),
        }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // get current team journeys
  const getTeamJourneys = useCallback(
    async (teamId: string) => {
      try {
        logger(Status.Api, "QUERY teamJourneys", teamJourneys)
        const { data } = await teamJourneysQuery({
          variables: { teamId },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `team ${teamId} journeys`, data.teamJourneys.items)

        setCurrentTeamJourneysList(data.teamJourneys.items)
        setPreChangesCurrentTeamJourneysList(deepCopy(data.teamJourneys.items))

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `teamJourneys`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // get current team news
  const getTeamNews = useCallback(
    async (teamId: string) => {
      try {
        logger(Status.Api, "QUERY newsSearch", searchNews)
        const { data } = await searchNewsQuery({
          variables: { input: { teamId } },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `team ${teamId} news`, data.newsSearch.items)

        setCurrentTeamNewsList(data.newsSearch.items)

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `newsSearch`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // get current team challenges
  const getTeamChallenges = useCallback(
    async (teamId: string) => {
      try {
        logger(Status.Api, "QUERY challengesSearch", searchChallenges)
        const { data } = await searchChallengesQuery({
          variables: { input: { teamId } },
          fetchPolicy: "no-cache",
        })
        logger(
          Status.Info,
          `team ${teamId} challenges`,
          data.challengesSearch.items
        )

        setCurrentTeamChallengesList(data.challengesSearch.items)

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `challengesSearch`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // get current team surveys
  const getTeamSurveys = useCallback(
    async (teamId: string) => {
      try {
        logger(Status.Api, "QUERY teamSurveysList", teamSurveysList)
        const { data } = await teamSurveysListQuery({
          variables: { input: { teamId } },
          fetchPolicy: "no-cache",
        })
        logger(
          Status.Info,
          `team ${teamId} surveys`,
          data.teamSurveysList.items
        )

        setCurrentTeamSurveysList(data.teamSurveysList.items)

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `teamSurveysList`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // get current team users list
  const getTeamUsers = async (
    teamId: string,
    searchGroupParam: AutocompleteOption | null,
    searchIsAdminParam: boolean | null,
    searchTextParam: string
  ) => {
    try {
      const input: {
        teamId: string
        groupId?: string
        isAdmin?: boolean
        email?: string
        sub?: string
      } = {
        teamId,
        isAdmin: searchIsAdminParam,
      }

      if (searchGroupParam) {
        input.groupId = searchGroupParam.id
      }
      if (searchTextParam) {
        if (searchTextType === "email") {
          input.email = searchTextParam
        } else {
          input.sub = searchTextParam
        }
      }

      logger(Status.Api, "QUERY teamUsersSearch", teamUsersSearch)
      const { data } = await teamUsersSearchQuery({
        variables: { input: input },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `team ${teamId} members`, data.teamUsersSearch.items)

      setUsersList(data.teamUsersSearch.items)
      setUsersListNextToken(data.teamUsersSearch.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamUsersSearch`, e.message)
      }
      return false
    }
  }

  // load more team users
  const loadMoreUsers = async (teamId: string) => {
    try {
      let input: {
        teamId: string
        nextToken: string
        groupId?: string
        isAdmin?: boolean
        email?: string
        sub?: string
      } = {
        teamId,
        nextToken: usersListNextToken,
        isAdmin: searchIsAdmin,
      }

      if (searchGroup) {
        input.groupId = searchGroup.id
      }
      if (searchText) {
        if (searchTextType === "email") {
          input.email = searchText
        } else {
          input.sub = searchText
        }
      }

      logger(Status.Api, "QUERY teamUsersSearch", teamUsersSearch)
      const { data } = await teamUsersSearchQuery({
        variables: { input: input },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `team ${teamId} members`, [
        ...usersList,
        ...data.teamUsersSearch.items,
      ])

      setUsersList([...usersList, ...data.teamUsersSearch.items])
      setUsersListNextToken(data.teamUsersSearch.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamUsersSearch`, e.message)
      }

      return false
    }
  }

  // load all users for csv export
  const loadAllUsers = async (teamId: string, withEmails: boolean) => {
    try {
      logger(Status.Api, "QUERY teamUsersSearch", teamUsersSearch)

      let nextToken = null
      let result: string[][] = withEmails
        ? [["first name", "last name", "uid", "email", "group"]]
        : [["first name", "last name", "uid", "group"]]

      do {
        const input: {
          teamId: string
          groupId?: string
          isAdmin?: boolean
          nextToken?: string
          email?: string
          sub?: string
        } = {
          teamId,
          isAdmin: searchIsAdmin,
        }

        if (searchGroup) {
          input.groupId = searchGroup.id
        }
        if (searchText) {
          if (searchTextType === "email") {
            input.email = searchText
          } else {
            input.sub = searchText
          }
        }
        if (nextToken) {
          input.nextToken = nextToken
        }

        const { data } = await teamUsersSearchQuery({
          variables: { input: input },
          fetchPolicy: "no-cache",
        })

        for (let i = 0; i < data.teamUsersSearch.items.length; i++) {
          let dataToAdd = []
          dataToAdd.push(data.teamUsersSearch.items[i].user.first_name)
          dataToAdd.push(data.teamUsersSearch.items[i].user.last_name)
          dataToAdd.push(data.teamUsersSearch.items[i].user.uid)
          if (withEmails) {
            dataToAdd.push(data.teamUsersSearch.items[i].user.email)
          }
          if (data.teamUsersSearch.items[i].group) {
            dataToAdd.push(data.teamUsersSearch.items[i].group.name)
          } else {
            dataToAdd.push("")
          }
          result.push(dataToAdd)
        }
        nextToken = data.teamUsersSearch.nextToken
      } while (nextToken !== null)

      logger(Status.Info, `team ${teamId} members`, result)

      return result
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamUsersSearch`, e.message)
      }

      return []
    }
  }

  // upsert team users (single or multiple)
  const upsertUsers = async (
    teamId: string,
    inputList: {
      sub?: string
      email?: string
      isAdmin?: boolean
      groupId?: string
    }[]
  ) => {
    try {
      let calls = []

      inputList.forEach((item) => {
        let input: {
          teamId: string
          sub?: string
          email?: string
          isAdmin?: boolean
          groupId?: string
        } = {
          teamId,
        }

        if (item.sub) {
          input.sub = item.sub
        }
        if (item.email) {
          input.email = item.email
        }
        if (typeof item.isAdmin === "boolean") {
          input.isAdmin = item.isAdmin
        }
        if (item.groupId) {
          input.groupId = item.groupId
        }

        calls.push(
          teamUserUpsertMutation({
            variables: { input: input },
          })
        )
      })

      logger(Status.Api, "MUTATION teamUserUpsert", teamUserUpsert)
      await Promise.all(calls)
      logger(
        Status.Info,
        `users ${inputList.map((item, index) =>
          index ? " " + (item.sub ?? item.email) : item.sub ?? item.email
        )} upserted`
      )

      // wait 2.5 seconds and then get users list
      await wait(2500)
      await getTeamUsers(teamId, searchGroup, searchIsAdmin, searchText)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamUserUpsert`, e.message)
      }
      return false
    }
  }

  // remove users from team
  const removeUsers = async (teamId: string, subs: string[]) => {
    try {
      logger(Status.Api, "MUTATION teamUserDelete", teamUserDelete)
      let calls = []
      subs.forEach((sub) => {
        calls.push(
          teamUserDeleteMutation({
            variables: { input: { teamId, sub: sub } },
          })
        )
      })
      await Promise.all(calls)
      logger(
        Status.Info,
        `users ${subs.map((sub, index) => (index ? " " + sub : sub))} removed`
      )

      // wait 2.5 seconds and then get users list
      await wait(2500)
      await getTeamUsers(teamId, searchGroup, searchIsAdmin, searchText)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamUserDelete`, e.message)
      }
      return false
    }
  }

  // delete team
  const deleteTeam = async (teamToDeleteId: string) => {
    try {
      logger(Status.Api, "MUTATION teamDelete", teamDelete)
      await teamDeleteMutation({
        variables: { input: { id: teamToDeleteId } },
      })
      logger(Status.Info, `team ${teamToDeleteId} deleted`)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamDelete`, e.message)
      }
      return false
    }
  }

  // get current team contracts
  const getTeamContracts = useCallback(
    async (teamId: string) => {
      try {
        logger(Status.Api, "QUERY teamContractsList", teamContractsList)
        const { data } = await teamContractsListQuery({
          variables: { input: { teamId } },
          fetchPolicy: "no-cache",
        })
        logger(
          Status.Info,
          `team ${teamId} contracts`,
          data.teamContractsList.items
        )

        setCurrentTeamContractsList(data.teamContractsList.items)

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `teamContractsList`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // create new team contract
  const createTeamContract = async (
    teamId: string,
    startDate: string,
    endDate: string,
    sellingPrice?: number,
    targetMembers?: number
  ) => {
    try {
      const input: {
        teamId: string
        startDate: string
        endDate: string
        sellingPrice?: number
        targetMembers?: number
      } = {
        teamId,
        startDate: startDate,
        endDate: endDate,
      }

      if (sellingPrice || sellingPrice === 0) {
        input.sellingPrice = sellingPrice
      }
      if (targetMembers || targetMembers === 0) {
        input.targetMembers = targetMembers
      }

      logger(Status.Api, "MUTATION teamContractCreate", teamContractCreate)
      const { data } = await teamContractCreateMutation({
        variables: { input: input },
      })
      logger(Status.Info, "team contract created", data.teamContractCreate)

      // update current team contracts list locally
      currentTeamContractsList.unshift(data.teamContractCreate)
      setCurrentTeamContractsList([...currentTeamContractsList])

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamContractCreate`, e.message)
      }
      return false
    }
  }

  // update team contract
  const updateTeamContract = async (
    id: string,
    startDate: string,
    endDate: string,
    sellingPrice?: number,
    targetMembers?: number
  ) => {
    try {
      const input: {
        id: string
        startDate: string
        endDate: string
        sellingPrice?: number
        targetMembers?: number
      } = {
        id: id,
        startDate: startDate,
        endDate: endDate,
      }

      if (sellingPrice || sellingPrice === 0) {
        input.sellingPrice = sellingPrice
      }
      if (targetMembers || sellingPrice === 0) {
        input.targetMembers = targetMembers
      }

      logger(Status.Api, "MUTATION teamContractUpdate", teamContractUpdate)
      const { data } = await teamContractUpdateMutation({
        variables: { input: input },
      })
      logger(Status.Info, `contract ${id} updated`, data.teamContractUpdate)

      // update current team contracts list locally
      currentTeamContractsList[
        currentTeamContractsList.findIndex(
          (item) => item.id === data.teamContractUpdate.id
        )
      ] = data.teamContractUpdate
      setCurrentTeamContractsList([...currentTeamContractsList])

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamContractUpdate`, e.message)
      }
      return false
    }
  }

  // delete team contract
  const deleteTeamContract = async (id: string) => {
    try {
      const input = {
        id: id,
      }

      logger(Status.Api, "MUTATION teamContractDelete", teamContractDelete)
      await teamContractDeleteMutation({
        variables: { input: input },
      })
      logger(Status.Info, `contract ${id} deleted`)

      // update current team contracts list locally
      const indexToRemove = currentTeamContractsList.findIndex(
        (item) => item.id === id
      )
      currentTeamContractsList.splice(indexToRemove, 1)
      setCurrentTeamContractsList([...currentTeamContractsList])

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamContractDelete`, e.message)
      }
      return false
    }
  }

  // activate team contract
  const activateTeamContract = async (id: string) => {
    try {
      const input = {
        id: id,
      }

      logger(Status.Api, "MUTATION teamContractActivate", teamContractActivate)
      const { data } = await teamContractActivateMutation({
        variables: { input: input },
      })
      logger(Status.Info, `contract ${id} activated`)

      // update current team contracts list locally
      const indexToRemove = currentTeamContractsList.findIndex(
        (item) => item.id === id
      )
      currentTeamContractsList.splice(indexToRemove, 1)
      currentTeamContractsList.unshift(data.teamContractActivate)
      setCurrentTeamContractsList([...currentTeamContractsList])

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `teamContractActivate`, e.message)
      }
      return false
    }
  }

  // add translation
  const addTranslation = (translationToAdd: string) => {
    const documentToAdd: TeamsDocument = {
      body: [],
      isDefault: false,
      lang: translationToAdd,
      title: "Title",
      type: "Team",
      cover: currentTeam.document.items.find((item) => item.isDefault).cover,
      logo: currentTeam.document.items.find((item) => item.isDefault).logo,
      home: null,
    }

    translationsErrors.push({
      lang: translationToAdd,
      hasErrors: false,
    })
    setTranslationsErrors([...translationsErrors])

    currentTeam.document.items.push(documentToAdd)
    setCurrentTeam({ ...currentTeam })
  }

  // copy home details from default translation
  const copyHomeDetailsFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentTeam.document.items.find(
      (item) => item.isDefault
    )

    currentTeam.document.items[itemToCopyToIndex].home = defaultItem.home

    setCurrentTeam({ ...currentTeam })
  }

  // copy details from default translation
  const copyDetailsFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentTeam.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentTeam.document.items[itemToCopyToIndex].title = defaultItem.title
    currentTeam.document.items[itemToCopyToIndex].logo = defaultItem.logo
    currentTeam.document.items[itemToCopyToIndex].cover = defaultItem.cover

    setCurrentTeam({ ...currentTeam })
  }

  // copy body from default translation
  const copyBodyFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentTeam.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentTeam.document.items[itemToCopyToIndex].body = defaultItem.body

    setCurrentTeam({ ...currentTeam })
  }

  // check if user has done changes to team details
  useEffect(() => {
    if (currentTeam && preChangesCurrentTeam) {
      const currentTeamCopy: Team = deepCopy(currentTeam)
      const preChangesCurrentTeamCopy: Team = deepCopy(preChangesCurrentTeam)

      delete currentTeamCopy.accessCodes
      delete preChangesCurrentTeamCopy.accessCodes

      if (
        JSON.stringify(currentTeamCopy) ===
          JSON.stringify(preChangesCurrentTeamCopy) &&
        JSON.stringify(currentTeamJourneysList) ===
          JSON.stringify(preChangesCurrentTeamJourneysList)
      ) {
        setDoneChanges(false)
      } else {
        setDoneChanges(true)
      }
    }
  }, [
    currentTeam,
    preChangesCurrentTeam,
    currentTeamJourneysList,
    preChangesCurrentTeamJourneysList,
  ])

  // cancel team details changes
  const cancelChanges = () => {
    setCurrentTeam(deepCopy(preChangesCurrentTeam))
    setCurrentTeamJourneysList(deepCopy(preChangesCurrentTeamJourneysList))
    resetErrors()
  }

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "Teams")[0].updateFunction =
      getTeamsList
  }, [])

  return (
    <TeamsContext.Provider
      value={{
        loading,
        setLoading,
        currentTeamsList,
        getTeamsList,
        searchTeamsList,
        hasSearched,
        getCurrentTeam,
        currentTeam,
        setCurrentTeam,
        preChangesCurrentTeam,
        doneChanges,
        cancelChanges,
        updatingList,
        setUpdatingList,
        teamsListNextToken,
        loadMoreTeams,
        searchTitle,
        setSearchTitle,
        searchLang,
        setSearchLang,
        searchStakeholderType,
        setSearchStakeholderType,
        searchAccessType,
        setSearchAccessType,
        searchIsPrimary,
        setSearchIsPrimary,
        searchOrganizationType,
        setSearchOrganizationType,
        searchStage,
        setSearchStage,
        createTeam,
        createTeamDocument,
        editMode,
        setEditMode,
        addTranslation,
        hasError,
        nameError,
        setNameError,
        expirationDateError,
        setExpirationDateError,
        translationsErrors,
        setTranslationsErrors,
        copyHomeDetailsFromDefault,
        copyDetailsFromDefault,
        copyBodyFromDefault,
        upsertTeamParent,
        upsertTeamDocument,
        getTeamJourneys,
        currentTeamJourneysList,
        setCurrentTeamJourneysList,
        resetTeamsFilters,
        getTeamUsers,
        usersList,
        usersListNextToken,
        hasSearchedMembers,
        setHasSearchedMembers,
        searchGroup,
        setSearchGroup,
        searchIsAdmin,
        setSearchIsAdmin,
        searchText,
        setSearchText,
        searchTextType,
        setSearchTextType,
        updatingMembersList,
        setUpdatingMembersList,
        upsertUsers,
        loadMoreUsers,
        currentTeamNewsList,
        setCurrentTeamNewsList,
        getTeamNews,
        currentTeamChallengesList,
        setCurrentTeamChallengesList,
        currentTeamSurveysList,
        setCurrentTeamSurveysList,
        getTeamChallenges,
        getTeamSurveys,
        deleteTeam,
        loadAllUsers,
        removeUsers,
        getTeamContracts,
        currentTeamContractsList,
        setCurrentTeamContractsList,
        createTeamContract,
        updateTeamContract,
        deleteTeamContract,
        activateTeamContract,
        getAllTeams,
      }}
    >
      {children}
    </TeamsContext.Provider>
  )
}

export { TeamsController, TeamsContext }
