import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  Stack,
  Typography,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import EditContainer from "../../components/global/editContainer"
import EditHeading from "../../components/global/editHeading"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import PageSpinner from "../../components/global/pageSpinner"
import { MainContext } from "../../controllers/main"
import { JourneysContext } from "../../controllers/journeys"
import JourneyGeneralEdit from "../../components/journey/journeyGeneralEdit"
import { CloseRounded, HistoryRounded, UndoRounded } from "@mui/icons-material"
import { calculateTimeElapsed } from "../../services/utilities/utility"
import JourneyEpisodesEdit from "../../components/journey/journeyEpisodesEdit"
import JourneyLanguagesEdit from "../../components/journey/journeyLanguagesEdit"
import CantPerformActionDialog from "../../components/global/cantPerformActionDialog"
import Journey from "../../models/journey"
import AiTranslateJourneysDialog from "../../components/journey/aiTranslateJourneysDialog"

const JourneyEdit = () => {
  const { animation, setAnimation, showPadding } = useContext(MainContext)
  const {
    currentJourney,
    currentJourneyStaged,
    getJourneyDetails,
    loading: controllerLoading,
    setLoading: setControllerLoading,
    editMode,
    setEditMode,
    changeStage,
    doneChanges,
    cancelChanges,
    updateAllJourney,
    publishAllJourney,
    currentJourneyLive,
    archiveAllJourney,
    duplicateJourney,
  } = useContext(JourneysContext)
  const { journeyId } = useParams()
  const navigate = useNavigate()

  // prevent tab close
  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault()
      console.log("beforeunload event triggered")
      return (event.returnValue = "Are you sure you want to exit?")
    }

    window.addEventListener("beforeunload", handleTabClose)

    return () => {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [])

  // loading
  const [loading, setLoading] = useState<boolean>(true)

  // page error
  const [pageError, setPageError] = useState<boolean>(false)

  // fetch current journey
  const fetchAll = async (withLoading = true) => {
    if (withLoading) {
      setLoading(true)
    }
    const noErrors = await getJourneyDetails(journeyId!)
    if (noErrors) {
      setLoading(false)
      setControllerLoading(false)
    } else {
      setPageError(true)
    }
  }

  useEffect(() => {
    fetchAll()
  }, [journeyId]) // eslint-disable-line react-hooks/exhaustive-deps

  // save button disabled
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false)

  useEffect(() => {
    if (!loading) {
      if (!currentJourney.text || !currentJourney.title) {
        setSaveButtonDisabled(true)
      } else if (
        currentJourney.text.length < 3 ||
        currentJourney.title.length < 3 ||
        currentJourney.episodes
          .map((item) => item.episode.publishedLangs)
          .filter((item) => {
            for (let i = 0; i < item.length; i++) {
              if (item[i].lang === currentJourney.lang) {
                return item
              }
            }
          }).length !== currentJourney.episodes.length ||
        (currentJourney.publishedLangs.length > 0 &&
          !currentJourney.publishedLangs.filter(
            (item) => item.title.length >= 3
          )[0])
      ) {
        setSaveButtonDisabled(true)
      } else {
        setSaveButtonDisabled(false)
      }
    }
  }, [currentJourney, loading])

  // change parent stage dialog
  const [parentStageDialogOpen, setParentStageDialogOpen] =
    useState<boolean>(false)
  const [stageToSee, setStageToSee] = useState<string>("")

  // switch
  const [selectedSwitchPage, setSelectedSwitchPage] = useState<number>(0)

  // stage side bar
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)

  // undo changes dialog
  const [undoDialogOpen, setUndoDialogOpen] = useState<boolean>(false)

  // no thumnail alert dialog
  const [noThumbnailDialogOpen, setNoThumbnailDialogOpen] =
    useState<boolean>(false)

  // archive journey dialog
  const [archiveDialogOpen, setArchiveDialogOpen] = useState<boolean>(false)

  // duplicate journey dialog
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState<boolean>(false)

  // ai translate dialog
  const [aiTranslateDialogOpen, setAiTranslateDialogOpen] = useState<boolean>(false)
  const handleAiTranslateDialogChange = () => {
    setAiTranslateDialogOpen((current) => !current)
  }

  // selected journey for translation
  const [selectedJourneyForTranslation, setSelectedJourneyForTranslation] = useState<
    { journey: Journey; sourceLang?: string; langs: string[] }[]
  >([])

  useEffect(() => {
    if (currentJourney) {
      setSelectedJourneyForTranslation([{
        journey: currentJourney,
        sourceLang: currentJourney.lang,
        langs: []
      }])
    }
  }, [currentJourney])

  // save changes
  const saveChanges = async () => {
    let input = {
      id: currentJourney.id,
      lang: currentJourney.lang,
      title: currentJourney.title.trim(),
      text: currentJourney.text.trim(),
      image: currentJourney.image ?? "",
      topics: currentJourney.topics.map((item) => {
        return {
          primary: item.primary,
          id: item.topic.id,
        }
      }),
      sdgs: currentJourney.sdgs.map((item) => {
        return {
          primary: item.primary,
          id: item.sdg.id,
        }
      }),
      sdgTargets: currentJourney.sdgTargets.map((item) => {
        return {
          primary: item.primary,
          id: item.sdgTarget.id,
        }
      }),
      category: { id: currentJourney.category.id },
      episodes: currentJourney.episodes.map((item, index) => {
        return {
          id: item.episode.id,
          sorting: index + 1,
        }
      }),
      publishedLangs: currentJourney.publishedLangs.map((item) => {
        return {
          lang: item.lang,
          title: item.title.trim(),
        }
      }),
      type: currentJourney.type,
      esg: currentJourney.esg,
    }

    await updateAllJourney(input)
  }

  return loading ? (
    <PageSpinner
      showBackButton={pageError}
      backButtonOnClick={() => {
        setAnimation(false)
        navigate("/journeys")
      }}
    />
  ) : (
    <EditContainer>
      <EditHeading
        backButtonOnClick={() => {
          setAnimation(false)
          setTimeout(() => {
            navigate("/journeys")
          }, 300)
        }}
        title={currentJourneyStaged.title}
        id={currentJourneyStaged.id}
        showButtons={false}
        showEditButton
        showArchiveButton
        archiveButtonOnClick={() => {
          setArchiveDialogOpen(true)
        }}
        showDuplicateButton
        duplicateButtonOnClick={() => {
          setDuplicateDialogOpen(true)
        }}
        editModeActive={editMode}
        setEditModeActive={setEditMode}
        showAiTranslateButton
        aiTranslateButtonOnClick={handleAiTranslateDialogChange}
      />
      <Grow
        in={animation}
        timeout={300}
        style={{ margin: showPadding === "yes" ? 16 : 0, marginTop: 0 }}
      >
        <Stack>
          <Card style={{ overflowX: "hidden", position: "relative" }}>
            {/* status bar */}
            <div
              style={{
                position: "relative",
                width: "100%",
                height: 36,
                backgroundColor: saveButtonDisabled
                  ? "#d3302f"
                  : doneChanges
                  ? "#6cb3e6"
                  : currentJourney.stage === "PUBLISHED"
                  ? "#3bb97a"
                  : "#f7b043",
                color: "white",
                paddingLeft: 15,
                transition: "150ms",
                paddingTop: 5,
              }}
            >
              <Typography variant="overline" style={{ fontWeight: 500 }}>
                {doneChanges
                  ? "Edited"
                  : currentJourney.stage === "PUBLISHED"
                  ? "Live"
                  : "Draft"}
              </Typography>
              {saveButtonDisabled && (
                <Typography variant="overline" style={{ fontWeight: 500 }}>
                  {" - errors"}
                </Typography>
              )}
              <Button
                style={{
                  height: 30,
                  position: "absolute",
                  top: 3,
                  right: 144,
                  transition: "150ms",
                  opacity: doneChanges ? 1 : 0,
                }}
                disabled={!doneChanges}
                onClick={() => {
                  setUndoDialogOpen(true)
                }}
              >
                <UndoRounded
                  style={{
                    color: "white",
                    opacity: !doneChanges ? 0.3 : 1,
                    transition: "150ms",
                  }}
                />
              </Button>
              <Button
                size="small"
                style={{
                  position: "absolute",
                  color: "white",
                  opacity:
                    (currentJourney.stage === "PUBLISHED" && !doneChanges) ||
                    saveButtonDisabled ||
                    !editMode
                      ? 0.3
                      : 1,
                  top: 3,
                  height: 30,
                  right: 64,
                  width: 80,
                  transition: "150ms",
                }}
                disabled={
                  (currentJourney.stage === "PUBLISHED" && !doneChanges) ||
                  saveButtonDisabled ||
                  !editMode
                }
                onClick={async () => {
                  if (doneChanges) {
                    saveChanges()
                    return
                  }

                  if (!currentJourney.image) {
                    setNoThumbnailDialogOpen(true)
                    return
                  }

                  if (currentJourney.stage === "DRAFT" && !doneChanges) {
                    setControllerLoading(true)
                    const noErrors = await publishAllJourney(journeyId)
                    if (noErrors) {
                      fetchAll(false)
                    } else {
                      setControllerLoading(false)
                    }
                  }
                }}
              >
                {currentJourney.stage === "DRAFT" && !doneChanges
                  ? "Publish"
                  : doneChanges
                  ? "Save"
                  : "Save"}
              </Button>
              <Button
                style={{
                  height: 30,
                  position: "absolute",
                  top: 3,
                  right: 0,
                }}
                onClick={() => {
                  setSidebarOpen(!sidebarOpen)
                }}
              >
                {sidebarOpen ? (
                  <CloseRounded
                    style={{
                      color: "white",
                    }}
                  />
                ) : (
                  <HistoryRounded
                    style={{
                      color: "white",
                    }}
                  />
                )}
              </Button>
            </div>
            {/* side panel */}
            <Card
              style={{
                position: "absolute",
                top: 36,
                right: sidebarOpen ? 0 : -245,
                height: "100%",
                width: 240,
                zIndex: 2,
                transition: "200ms",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeft: "1px solid #e0e0e0",
              }}
            >
              <CardContent>
                <Stack spacing={2}>
                  {doneChanges && (
                    <div
                      style={{
                        minHeight: 50,
                        backgroundColor: "#f9f9fb",
                        display: "flex",
                        alignItems: "center",
                        borderLeft: saveButtonDisabled
                          ? "4px solid " + "#d3302f"
                          : "4px solid #6cb3e6",
                        paddingLeft: 15,
                        paddingRight: 15,
                        borderRadius: "0 3px 3px 0",
                      }}
                    >
                      <Stack spacing={1}>
                        <Typography
                          variant="overline"
                          style={{ lineHeight: 1 }}
                        >
                          {saveButtonDisabled ? "Edited - errors" : "Edited"}
                        </Typography>
                        <Typography variant="caption" style={{ lineHeight: 1 }}>
                          unsaved changes
                        </Typography>
                      </Stack>
                    </div>
                  )}
                  {currentJourneyStaged.stage === "DRAFT" && (
                    <div
                      style={{
                        minHeight: 50,
                        backgroundColor:
                          currentJourney.stage === "DRAFT" && !doneChanges
                            ? "#f9f9fb"
                            : "white",
                        display: "flex",
                        alignItems: "center",
                        borderLeft: "4px solid " + "#f7b043",
                        paddingLeft: 15,
                        paddingRight: 15,
                        borderRadius: "0 3px 3px 0",
                        boxShadow:
                          currentJourney.stage === "PUBLISHED" || doneChanges
                            ? "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)"
                            : null,
                        cursor:
                          currentJourney.stage === "PUBLISHED" || doneChanges
                            ? "pointer"
                            : "default",
                      }}
                      onClick={() => {
                        if (doneChanges) {
                          setStageToSee("DRAFT")
                          setParentStageDialogOpen(true)
                        } else {
                          changeStage("DRAFT")
                        }
                      }}
                    >
                      <Stack spacing={1}>
                        <Typography
                          variant="overline"
                          style={{ lineHeight: 1 }}
                        >
                          Draft
                        </Typography>
                        <Typography variant="caption" style={{ lineHeight: 1 }}>
                          updated{" "}
                          {calculateTimeElapsed(currentJourneyStaged.updatedAt)}
                        </Typography>
                      </Stack>
                    </div>
                  )}
                  {currentJourneyLive &&
                  currentJourneyLive.stage === "PUBLISHED" ? (
                    <div
                      style={{
                        minHeight: 50,
                        backgroundColor:
                          currentJourney.stage === "PUBLISHED" && !doneChanges
                            ? "#f9f9fb"
                            : "white",
                        display: "flex",
                        alignItems: "center",
                        borderLeft: "4px solid " + "#3bb97a",
                        paddingLeft: 15,
                        paddingRight: 15,
                        borderRadius: "0 3px 3px 0",
                        boxShadow:
                          currentJourney.stage === "DRAFT" || doneChanges
                            ? "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)"
                            : null,
                        cursor:
                          currentJourney.stage === "DRAFT" || doneChanges
                            ? "pointer"
                            : "default",
                      }}
                      onClick={() => {
                        if (doneChanges) {
                          setStageToSee("PUBLISHED")
                          setParentStageDialogOpen(true)
                        } else {
                          changeStage("PUBLISHED")
                        }
                      }}
                    >
                      <Stack spacing={1}>
                        <Typography
                          variant="overline"
                          style={{ lineHeight: 1 }}
                        >
                          Live
                        </Typography>
                        <Typography variant="caption" style={{ lineHeight: 1 }}>
                          updated{" "}
                          {calculateTimeElapsed(
                            currentJourneyLive
                              ? currentJourneyLive.updatedAt
                              : currentJourneyStaged.updatedAt
                          )}
                        </Typography>
                      </Stack>
                    </div>
                  ) : null}
                </Stack>
              </CardContent>
            </Card>
            {/* select between slides and quiz */}
            <div
              style={{
                width: "100%",
                height: 56,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                boxShadow: "0px 0px 4px 1px rgba(0,0,0,0.14)",
                position: "relative",
              }}
            >
              <div
                style={{
                  height: 24,
                  width: 350,
                  borderRadius: 16,
                  border: "1px solid #bdbdbd",
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    height: 24,
                    width: "33.3%",
                    fontSize: 11,
                    position: "relative",
                    zIndex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    color: selectedSwitchPage === 0 ? "white" : null,
                    transition: "200ms",
                  }}
                  onClick={() => {
                    setSelectedSwitchPage(0)
                  }}
                >
                  <Typography
                    variant="overline"
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    General
                  </Typography>
                </div>
                <div
                  style={{
                    height: 24,
                    width: "33.3%",
                    fontSize: 11,
                    position: "relative",
                    zIndex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    color: selectedSwitchPage === 1 ? "white" : null,
                    transition: "200ms",
                  }}
                  onClick={() => {
                    setSelectedSwitchPage(1)
                  }}
                >
                  <Typography
                    variant="overline"
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    Episodes
                  </Typography>
                </div>
                <div
                  style={{
                    height: 24,
                    width: "33.4%",
                    fontSize: 11,
                    position: "relative",
                    zIndex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    color: selectedSwitchPage === 2 ? "white" : null,
                    transition: "200ms",
                  }}
                  onClick={() => {
                    setSelectedSwitchPage(2)
                  }}
                >
                  <Typography
                    variant="overline"
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    Translations
                  </Typography>
                </div>
                <div
                  style={{
                    height: 22,
                    width: "33.3%",
                    borderRadius: 16,
                    backgroundColor: "#5262ba",
                    position: "absolute",
                    left:
                      selectedSwitchPage === 0
                        ? 0
                        : selectedSwitchPage === 1
                        ? "33.3%"
                        : "66.7%",
                    top: 0,
                    transition: "250ms",
                  }}
                />
              </div>
            </div>
            {/* general journey card */}
            <div
              style={{ display: selectedSwitchPage === 0 ? "block" : "none" }}
            >
              <JourneyGeneralEdit />
            </div>
            {/* episodes card */}
            <div
              style={{ display: selectedSwitchPage === 1 ? "block" : "none" }}
            >
              <JourneyEpisodesEdit setSidebarOpen={setSidebarOpen} />
            </div>
            {/* languages card */}
            <div
              style={{ display: selectedSwitchPage === 2 ? "block" : "none" }}
            >
              <JourneyLanguagesEdit />
            </div>
          </Card>
        </Stack>
      </Grow>
      <LoadingBackdrop open={controllerLoading} />
      {/* change stage dialog */}
      <Dialog
        open={parentStageDialogOpen}
        onClose={() => {
          setParentStageDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-parent"
        aria-describedby="alert-dialog-description-parent"
      >
        <DialogTitle id="alert-dialog-title-parent">
          Are you sure you want to change stage?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-parent">
            All changes will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setParentStageDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              changeStage(stageToSee)
              setParentStageDialogOpen(false)
            }}
            autoFocus
          >
            View {stageToSee === "PUBLISHED" ? "LIVE" : "DRAFT"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* undo changes dialog */}
      <Dialog
        open={undoDialogOpen}
        onClose={() => {
          setUndoDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-translation"
        aria-describedby="alert-dialog-description-translation"
      >
        <DialogTitle id="alert-dialog-title-translation">
          Are you sure you want to undo changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-translation">
            All changes will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setUndoDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              cancelChanges()
              setUndoDialogOpen(false)
            }}
            autoFocus
          >
            Undo changes
          </Button>
        </DialogActions>
      </Dialog>
      {/* no thumbnail alert dialog */}
      <CantPerformActionDialog
        open={noThumbnailDialogOpen}
        setOpen={setNoThumbnailDialogOpen}
        content="You need to add a thumbnail in order to publish this Journey"
      />
      {/* archive journey dialog */}
      <Dialog
        open={archiveDialogOpen}
        onClose={() => {
          setArchiveDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-archive"
        aria-describedby="alert-dialog-description-archive"
      >
        <DialogTitle id="alert-dialog-title-archive">
          Are you sure you want to archive this Journey?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-archive">
            It will no longer be visible inside AWorld app
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setArchiveDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              setControllerLoading(true)
              setArchiveDialogOpen(false)

              const noErrors = await archiveAllJourney(currentJourney.id)

              setControllerLoading(false)
              if (noErrors) {
                navigate("/journeys", { state: { journeyArchived: true } })
              }
            }}
            autoFocus
          >
            Archive
          </Button>
        </DialogActions>
      </Dialog>
      {/* duplicate journey dialog */}
      <Dialog
        open={duplicateDialogOpen}
        onClose={() => {
          setDuplicateDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-duplicate"
        aria-describedby="alert-dialog-description-duplicate"
      >
        <DialogTitle id="alert-dialog-title-duplicate">
          {currentJourneyLive
            ? "Are you sure you want to duplicate this Journey?"
            : "This action cannot be performed"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-duplicate">
            {currentJourneyLive
              ? "A new Jounrney will be created with the same data of the current one's live version"
              : "You need to publish this Journey in order to duplicate it"}
          </DialogContentText>
        </DialogContent>
        {currentJourneyLive ? (
          <DialogActions>
            <Button
              disabled={controllerLoading}
              onClick={() => {
                setDuplicateDialogOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={controllerLoading}
              onClick={async () => {
                const result = await duplicateJourney()

                if (!result) {
                  setDuplicateDialogOpen(false)
                } else {
                  setDuplicateDialogOpen(false)
                  const createdJourney = result as Journey
                  navigate(`/journeys/${createdJourney.id}`)
                }
              }}
              autoFocus
            >
              Duplicate
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={() => {
                setDuplicateDialogOpen(false)
              }}
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        )}
      </Dialog>

      {/* ai translate dialog */}
      <AiTranslateJourneysDialog
        dialogOpen={aiTranslateDialogOpen}
        handleDialogChange={handleAiTranslateDialogChange}
        selectedJourneysForTranslation={selectedJourneyForTranslation}
        setSelectedJourneysForTranslation={setSelectedJourneyForTranslation}
      />
    </EditContainer>
  )
}

export default JourneyEdit
