import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { useContext, useState } from "react"
import { TeamsContext } from "../../controllers/teams"
import { AddCircleRounded, DeleteForeverRounded } from "@mui/icons-material"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import { NewsContext } from "../../controllers/news"
import News from "../../models/news"
import { LoadingButton } from "@mui/lab"
import CreateNewsDialog from "../news/createNewsDialog"

const TeamGeneralEditNewsTab = () => {
  const { editMode, currentTeam, currentTeamNewsList, setCurrentTeamNewsList } =
    useContext(TeamsContext)
  const { deleteNews, setLoading: setNewsLoading } = useContext(NewsContext)

  // create news dialog
  const [createNewsDialogOpen, setCreateNewsDialogOpen] =
    useState<boolean>(false)

  const handleCreateNewsDialogChange = () => {
    setCreateNewsDialogOpen((current) => !current)
  }

  // delete news
  const [newsToDelete, setNewsToDelete] = useState<News | null>(null)
  const [deletingNews, setDeletingNews] = useState<boolean>(false)
  const [deleteNewsDialogOpen, setDeleteNewsDialogOpen] =
    useState<boolean>(false)

  const handleDeleteNewsDialogChange = () => {
    setDeleteNewsDialogOpen((current) => !current)
  }

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          News
        </Typography>
        {!currentTeamNewsList.length ? (
          <Typography>No News for this Team</Typography>
        ) : (
          <Stack>
            {currentTeamNewsList.map((news, index) => (
              <Card
                key={news.id}
                style={{
                  minHeight: 60,
                  paddingInline: 24,
                }}
                variant="outlined"
                className="list-item-card"
                onClick={() => {
                  window.open(window.location.origin + "/news/" + news.id)
                }}
                sx={{
                  boxShadow: "none",
                  borderTopLeftRadius: index === 0 ? undefined : 0,
                  borderTopRightRadius: index === 0 ? undefined : 0,
                  borderBottomLeftRadius:
                    index === currentTeamNewsList.length - 1 ? undefined : 0,
                  borderBottomRightRadius:
                    index === currentTeamNewsList.length - 1 ? undefined : 0,
                  borderTop: index === 0 ? undefined : "none",
                }}
              >
                <Stack
                  direction="row"
                  style={{ height: 60, position: "relative" }}
                  alignItems="center"
                  spacing={4}
                >
                  <div
                    style={{
                      minWidth: 40,
                      height: 40,
                      borderRadius: 4,
                      backgroundImage: `url(${
                        news.document.items.find((item) => item.isDefault)
                          .image ?? noImagePlaceholder
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <Typography style={{ minWidth: 21 }}>
                    {news.document.items
                      .find((item) => item.isDefault)
                      .lang.toUpperCase()}
                  </Typography>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "50%",
                    }}
                  >
                    {news.document.items.find((item) => item.isDefault).title}
                  </Typography>
                  <IconButton
                    disabled={!editMode}
                    color="error"
                    style={{
                      position: "absolute",
                      right: -12,
                      top: 9,
                      zIndex: 1,
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      setNewsToDelete(news)
                      handleDeleteNewsDialogChange()
                    }}
                  >
                    <DeleteForeverRounded
                      style={{
                        width: 24,
                      }}
                    />
                  </IconButton>
                </Stack>
              </Card>
            ))}
          </Stack>
        )}
        <Button
          onClick={() => {
            setNewsLoading(false)
            handleCreateNewsDialogChange()
          }}
          variant="outlined"
          endIcon={<AddCircleRounded />}
          fullWidth
          disabled={!editMode}
          style={{ height: 40 }}
        >
          Add News
        </Button>
      </Stack>
      {/* create news for team */}
      <CreateNewsDialog
        dialogOpen={createNewsDialogOpen}
        setDialogOpen={setCreateNewsDialogOpen}
        handleDialogChange={handleCreateNewsDialogChange}
        topBarText="Add News"
        preSelectedTeam={{ id: currentTeam.id, name: currentTeam.name }}
        currentTeamNewsList={currentTeamNewsList}
        setCurrentTeamNewsList={setCurrentTeamNewsList}
      />
      {/* delete news dialog */}
      <Dialog
        open={deleteNewsDialogOpen}
        onClose={deletingNews ? null : handleDeleteNewsDialogChange}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to permanently delete this News?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The News "
            {newsToDelete
              ? newsToDelete.document.items.find((item) => item.isDefault).title
              : ""}
            " will be{" "}
            <span style={{ fontWeight: 600 }}>permanently deleted</span> and the
            action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteNewsDialogChange}
            disabled={deletingNews}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={deletingNews}
            onClick={async () => {
              setDeletingNews(true)

              const noErrors = await deleteNews(newsToDelete.id)
              if (noErrors) {
                const indexToRemove = currentTeamNewsList.findIndex(
                  (item) => item.id === newsToDelete.id
                )
                currentTeamNewsList.splice(indexToRemove, 1)
                setCurrentTeamNewsList([...currentTeamNewsList])
                setNewsToDelete(null)
                handleDeleteNewsDialogChange()
              }

              setDeletingNews(false)
            }}
            autoFocus
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TeamGeneralEditNewsTab
