import {
  Alert,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grow,
  IconButton,
  List,
  ListItem,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import EditContainer from "../../components/global/editContainer"
import EditHeading from "../../components/global/editHeading"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import PageSpinner from "../../components/global/pageSpinner"
import { EpisodesContext } from "../../controllers/episodes"
import { MainContext } from "../../controllers/main"
import { SyntheticEvent } from "react-draft-wysiwyg"
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded"
import EpisodeGeneralEdit from "../../components/episode/episodeGeneralEdit"
import EpisodeTranslationsEdit from "../../components/episode/episodeTranslationsEdit"
import { deepCopy } from "../../services/utilities/utility"
import { LanguagesContext } from "../../controllers/languages"
import "../../styles/episodeEdit.scss"
import CantPerformActionDialog from "../../components/global/cantPerformActionDialog"
import EpisodeGlobalPreview from "../../components/episode/episodeGlobalPreview"
import Episode from "../../models/episode"
import AiTranslateEpisodesDialog from "../../components/episode/aiTranslateEpisodesDialog"

const EpisodeEdit = () => {
  const { animation, setAnimation, showPadding } = useContext(MainContext)
  const { languagesForEpisodeTabs } = useContext(LanguagesContext)
  const {
    currentEpisode,
    getEpisodeDetails,
    currentEpisodeStaged,
    currentEpisodeLive,
    updateEpisodeParent,
    loading: controllerLoading,
    setLoading: setControllerLoading,
    getSlideConstraints,
    slideConstraintsList,
    changeParentStage,
    changeTranslationStage,
    addTranslationToCurrentEpisode,
    editMode,
    setEditMode,
    archiveEpisodeParent,
    getEpisodeJourneys,
    currentEpisodeJourneysList,
    duplicateEpisode,
    syncEpisode,
  } = useContext(EpisodesContext)
  const { episodeId } = useParams()
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    window.addEventListener("beforeunload", handleTabClose)

    return () => {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [])

  // loading
  const [loading, setLoading] = useState<boolean>(true)

  // page error
  const [pageError, setPageError] = useState<boolean>(false)

  // fetch current episode
  const fetchAll = async () => {
    let calls = [getEpisodeDetails(episodeId), getEpisodeJourneys(episodeId)]
    if (!slideConstraintsList.length) {
      calls.push(getSlideConstraints())
    }
    let noErrors = await Promise.all(calls)
    if (!noErrors.filter((item) => !item).length) {
      setLoading(false)
      setControllerLoading(false)
    } else {
      setPageError(true)
    }
  }

  useEffect(() => {
    fetchAll()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // save button disabled
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false)

  useEffect(() => {
    if (!loading && currentEpisode && currentEpisodeLive) {
      if (
        currentEpisode.text.length < 3 ||
        !currentEpisodeLive.translations.filter(
          (item) => item.lang === currentEpisode.lang
        )[0] ||
        (currentEpisodeLive.translations.filter(
          (item) => item.lang === currentEpisode.lang
        )[0] &&
          currentEpisodeLive.translations.filter(
            (item) => item.lang === currentEpisode.lang
          )[0].slides.length === 0) ||
        (!currentEpisode.doNotRecommend &&
          currentEpisode.recommendStartsAt &&
          !currentEpisode.recommendEndsAt) ||
        (!currentEpisode.doNotRecommend &&
          !currentEpisode.recommendStartsAt &&
          currentEpisode.recommendEndsAt) ||
        (currentEpisode.recommendStartsAt &&
          currentEpisode.recommendEndsAt &&
          new Date(currentEpisode.recommendStartsAt) >
            new Date(currentEpisode.recommendEndsAt))
      ) {
        setSaveButtonDisabled(true)
      } else {
        setSaveButtonDisabled(false)
      }
    } else if (!loading && currentEpisode) {
      if (
        (!currentEpisode.doNotRecommend &&
          currentEpisode.recommendStartsAt &&
          !currentEpisode.recommendEndsAt) ||
        (!currentEpisode.doNotRecommend &&
          !currentEpisode.recommendStartsAt &&
          currentEpisode.recommendEndsAt) ||
        (currentEpisode.recommendStartsAt &&
          currentEpisode.recommendEndsAt &&
          new Date(currentEpisode.recommendStartsAt) >
            new Date(currentEpisode.recommendEndsAt))
      ) {
        setSaveButtonDisabled(true)
      } else {
        setSaveButtonDisabled(false)
      }
    }
  }, [currentEpisode, currentEpisodeLive, loading])

  // slide language
  const [slideLanguage, setSlideLanguage] = useState<string>("")

  useEffect(() => {
    if (!loading) {
      setSlideLanguage(currentEpisode.lang)
    }
  }, [loading])

  // manage accordions
  const [expanded, setExpanded] = useState<number | false>(false)

  const handleChange =
    (panel: number) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  // change parent stage dialog
  const [parentStageDialogOpen, setParentStageDialogOpen] =
    useState<boolean>(false)
  const [stageToSee, setStageToSee] = useState<string>("")

  // change translation stage dialog
  const [translationStageDialogOpen, setTranslationStageDialogOpen] =
    useState<boolean>(false)

  // archive episode dialog
  const [archiveDialogOpen, setArchiveDialogOpen] = useState<boolean>(false)

  // tabs
  const [selectedTab, setSelectedTab] = useState<number>(0)

  // stage side bar
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)

  // add translation dialog
  const [addTranslationOpen, setAddTranslationOpen] = useState<boolean>(false)
  const [translationToAdd, setTranslationToAdd] = useState<
    { value: string; label: string; flag?: string } | undefined
  >()

  const handleAddTranslationClose = () => {
    setAddTranslationOpen(false)
    setTimeout(() => {
      setTranslationToAdd(undefined)
    }, 150)
  }

  const addTranslation = () => {
    setAddTranslationOpen(false)
    addTranslationToCurrentEpisode(translationToAdd.value)

    let translationsCopy: Array<any> = deepCopy(currentEpisode.translations)
    translationsCopy.push({ lang: translationToAdd.value })
    let tabIndex =
      languagesForEpisodeTabs
        .filter((item) => {
          for (let i = 0; i < translationsCopy.length; i++) {
            if (translationsCopy[i].lang === item.value) {
              return item
            }
          }
        })
        .findIndex((item) => item.value === translationToAdd.value) + 1

    setSelectedTab(tabIndex)
    setSlideLanguage(translationToAdd.value)
    setTimeout(() => {
      setTranslationToAdd(undefined)
    }, 150)
  }

  // save changes
  const saveChanges = async () => {
    let input = {
      id: currentEpisode.id,
      lang: currentEpisode.lang,
      image: currentEpisode.image,
      title: currentEpisode.title.trim(),
      text: currentEpisode.text.trim(),
      topics: currentEpisode.topics.map((item) => {
        return {
          primary: item.primary,
          id: item.topic.id,
        }
      }),
      sdgs: currentEpisode.sdgs.map((item) => {
        return {
          primary: item.primary,
          id: item.sdg.id,
        }
      }),
      sdgTargets: currentEpisode.sdgTargets.map((item) => {
        return {
          primary: item.primary,
          id: item.sdgTarget.id,
        }
      }),
      category: { id: currentEpisode.category.id },
      esg: currentEpisode.esg,
      doNotRecommend: currentEpisode.doNotRecommend,
      type: currentEpisode.type,
      specialType: currentEpisode.specialType,
      recommendStartsAt: currentEpisode.recommendStartsAt,
      recommendEndsAt: currentEpisode.recommendEndsAt,
    }

    await updateEpisodeParent(input)
  }

  // global preview
  const [globalPreviewOpen, setGlobalPreviewOpen] = useState<boolean>(false)
  const [currentPreviewSlide, setCurrentPreviewSlide] = useState<number>(0)
  const [currentPreviewLanguage, setCurrentPreviewLanguage] =
    useState<string>("en")

  useEffect(() => {
    if (currentEpisode) {
      setCurrentPreviewLanguage(currentEpisode.lang)
    }
  }, [currentEpisode])

  // info dialog
  const [infoDialogOpen, setInfoDialogOpen] = useState<boolean>(false)

  // can't archive dialog
  const [cantArchiveDialogOpen, setCantArchiveDialogOpen] =
    useState<boolean>(false)

  // json copied
  const [copied, setCopied] = useState<boolean>(false)
  const [copiedTimeoutId, setCopiedTimeoutId] = useState<any>()

  useEffect(() => {
    if (copiedTimeoutId) {
      clearTimeout(copiedTimeoutId)
    }

    if (copied) {
      let id = setTimeout(() => {
        setCopied(false)
      }, 1000)
      setCopiedTimeoutId(id)
    }

    return () => {
      if (copiedTimeoutId) {
        clearTimeout(copiedTimeoutId)
      }
    }
  }, [copied])

  // copy to clipboard feedback
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false)

  // duplicate episode dialog
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState<boolean>(false)

  // sync episode on notion
  const [syncing, setSyncing] = useState<boolean>(false)
  const [episodeSynced, setEpisodeSynced] = useState<boolean>(false)

  // ai translate dialog
  const [aiTranslateDialogOpen, setAiTranslateDialogOpen] = useState<boolean>(false)
  const handleAiTranslateDialogChange = () => {
    setAiTranslateDialogOpen((current) => !current)
  }

  // selected episode for translation
  const [selectedEpisodeForTranslation, setSelectedEpisodeForTranslation] = useState<
    { episode: Episode; sourceLang?: string; langs: string[] }[]
  >([])

  useEffect(() => {
    if (currentEpisodeStaged) { // Changed from currentEpisode to currentEpisodeStaged
      setSelectedEpisodeForTranslation([{
        episode: currentEpisodeStaged, // Changed from currentEpisode to currentEpisodeStaged
        sourceLang: currentEpisodeStaged.lang, // Changed from currentEpisode to currentEpisodeStaged
        langs: []
      }])
    }
  }, [currentEpisodeStaged]) // Changed from currentEpisode to currentEpisodeStaged

  return loading || !slideLanguage.length ? (
    <PageSpinner
      showBackButton={pageError}
      backButtonOnClick={() => {
        setAnimation(false)
        navigate("/episodes")
      }}
    />
  ) : (
    <EditContainer>
      <EditHeading
        backButtonOnClick={() => {
          setAnimation(false)
          setTimeout(() => {
            navigate("/episodes")
          }, 300)
        }}
        title={currentEpisodeStaged.title}
        id={currentEpisodeStaged.id}
        showButtons={false}
        showEditButton
        showArchiveButton
        archiveButtonOnClick={() => {
          if (currentEpisodeJourneysList.length) {
            setCantArchiveDialogOpen(true)
          } else {
            setArchiveDialogOpen(true)
          }
        }}
        editModeActive={editMode}
        setEditModeActive={setEditMode}
        showDuplicateButton
        duplicateButtonOnClick={() => {
          setDuplicateDialogOpen(true)
        }}
        showSyncButton={process.env.REACT_APP_ENV === "prod"}
        syncButtonOnClick={async () => {
          setSyncing(true)

          const result = await syncEpisode(episodeId)

          if (result) {
            setEpisodeSynced(true)
          }
          setSyncing(false)
        }}
        syncButtonLoading={syncing}
        showAiTranslateButton
        aiTranslateButtonOnClick={handleAiTranslateDialogChange}
      />
      <Grow
        in={animation}
        timeout={300}
        style={{ margin: showPadding === "yes" ? 16 : 0, marginTop: 0 }}
      >
        <Stack>
          {/* languages tabs */}
          <Stack direction="row" justifyContent="flex-start">
            <Card
              variant="outlined"
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: showPadding === "yes" ? null : 0,
                borderBottom: "none",
                borderLeft: showPadding === "yes" ? null : "none",
              }}
            >
              <Stack direction="row" justifyContent="flex-start">
                <Tabs
                  value={selectedTab}
                  onChange={(e: any, newValue: number) => {
                    setExpanded(false)
                    setSelectedTab(newValue)

                    let languagesCopy: { value: string; label: string }[] =
                      deepCopy(
                        languagesForEpisodeTabs.filter((item) => {
                          for (
                            let i = 0;
                            i < currentEpisode.translations.length;
                            i++
                          ) {
                            if (
                              currentEpisode.translations[i].lang === item.value
                            ) {
                              return item
                            }
                          }
                        })
                      )
                    languagesCopy.unshift(languagesForEpisodeTabs[0])
                    if (languagesCopy[newValue].value !== "general") {
                      setSlideLanguage(languagesCopy[newValue].value)
                    }
                  }}
                  aria-label="languages tabs"
                >
                  <Tab
                    key={languagesForEpisodeTabs[0].value}
                    label={languagesForEpisodeTabs[0].label}
                    style={{ minWidth: 180 }}
                  />
                  {languagesForEpisodeTabs
                    .filter((item) => {
                      for (
                        let i = 0;
                        i < currentEpisode.translations.length;
                        i++
                      ) {
                        if (
                          currentEpisode.translations[i].lang === item.value
                        ) {
                          return item
                        }
                      }
                    })
                    .map((item) => (
                      <Tab
                        key={item.value}
                        style={{ minWidth: 180 }}
                        label={
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <span>{item.flag}</span>
                            {item.value === currentEpisodeStaged.lang ? (
                              <Stack
                                direction="row"
                                spacing={0.5}
                                alignItems="center"
                              >
                                <span>{item.label}</span>
                                <span style={{ fontSize: 11, marginTop: 2 }}>
                                  (Master)
                                </span>
                              </Stack>
                            ) : (
                              <span>{item.label}</span>
                            )}
                          </Stack>
                        }
                      />
                    ))}
                </Tabs>
                <Divider orientation="vertical" variant="middle" flexItem />
                <div
                  style={{
                    minWidth: 70,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    disabled={!editMode}
                    aria-label="add language button"
                    component="span"
                    style={{ width: 48, height: 48 }}
                    onClick={() => {
                      setAddTranslationOpen(true)
                    }}
                  >
                    <AddCircleRoundedIcon />
                  </IconButton>
                </div>
              </Stack>
            </Card>
          </Stack>
          <Card sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            {/* parent episode card */}
            <div style={{ display: selectedTab === 0 ? "block" : "none" }}>
              <EpisodeGeneralEdit
                saveButtonDisabled={saveButtonDisabled}
                setStageToSee={setStageToSee}
                setParentStageDialogOpen={setParentStageDialogOpen}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                fetchAll={fetchAll}
                saveChanges={saveChanges}
                currentPreviewLanguage={currentPreviewLanguage}
                setCurrentPreviewLanguage={setCurrentPreviewLanguage}
                setGlobalPreviewOpen={setGlobalPreviewOpen}
                setInfoDialogOpen={setInfoDialogOpen}
                setFeedbackOpen={setFeedbackOpen}
              />
            </div>
            {/* translations cards */}
            <div style={{ display: selectedTab !== 0 ? "block" : "none" }}>
              <EpisodeTranslationsEdit
                slideLanguage={slideLanguage}
                setSlideLanguage={setSlideLanguage}
                expanded={expanded}
                setExpanded={setExpanded}
                handleChange={handleChange}
                setStageToSee={setStageToSee}
                setTranslationStageDialogOpen={setTranslationStageDialogOpen}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                setSelectedTab={setSelectedTab}
                fetchAll={fetchAll}
              />
            </div>
          </Card>
        </Stack>
      </Grow>
      <LoadingBackdrop open={controllerLoading} />
      {/* change parent stage dialog */}
      <Dialog
        open={parentStageDialogOpen}
        onClose={() => {
          setParentStageDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-parent"
        aria-describedby="alert-dialog-description-parent"
      >
        <DialogTitle id="alert-dialog-title-parent">
          Are you sure you want to change stage?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-parent">
            All changes will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setParentStageDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              changeParentStage(stageToSee)
              setParentStageDialogOpen(false)
            }}
            autoFocus
          >
            View {stageToSee === "PUBLISHED" ? "LIVE" : "DRAFT"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* change translation stage dialog */}
      <Dialog
        open={translationStageDialogOpen}
        onClose={() => {
          setTranslationStageDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-translation"
        aria-describedby="alert-dialog-description-translation"
      >
        <DialogTitle id="alert-dialog-title-translation">
          Are you sure you want to change stage?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-translation">
            All changes will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setTranslationStageDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              changeTranslationStage(slideLanguage, stageToSee)
              setTranslationStageDialogOpen(false)
            }}
            autoFocus
          >
            View {stageToSee === "PUBLISHED" ? "LIVE" : "DRAFT"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* add translation dialog */}
      <Dialog onClose={handleAddTranslationClose} open={addTranslationOpen}>
        <DialogTitle>Add translation</DialogTitle>
        {translationToAdd ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-add-translation">
              Are you sure you want to add{" "}
              <b>{translationToAdd.label.toUpperCase()}</b>?
            </DialogContentText>
          </DialogContent>
        ) : languagesForEpisodeTabs.filter((item) => {
            if (
              item.value !== "general" &&
              !currentEpisode.translations.filter(
                (translation) => translation.lang === item.value
              )[0]
            ) {
              return item
            }
          }).length ? (
          <List sx={{ pt: 0, minWidth: 300 }}>
            {languagesForEpisodeTabs
              .filter((item) => {
                if (
                  item.value !== "general" &&
                  !currentEpisode.translations.filter(
                    (translation) => translation.lang === item.value
                  )[0]
                ) {
                  return item
                }
              })
              .map((item) => (
                <ListItem
                  button
                  onClick={() => {
                    setTranslationToAdd(item)
                  }}
                  key={item.value}
                >
                  <Stack direction="row" spacing={1}>
                    <span>{item.flag}</span>
                    <span>{item.label.toUpperCase()}</span>
                  </Stack>
                </ListItem>
              ))}
          </List>
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-add-translation">
              You have already added all languages available
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleAddTranslationClose}>Cancel</Button>
          {translationToAdd && (
            <Button onClick={addTranslation}>
              Add {translationToAdd.label}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {/* archive episode dialog */}
      <Dialog
        open={archiveDialogOpen}
        onClose={() => {
          setArchiveDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-archive"
        aria-describedby="alert-dialog-description-archive"
      >
        <DialogTitle id="alert-dialog-title-archive">
          Are you sure you want to archive this Episode?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-archive">
            It will no longer be visible inside AWorld app
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setArchiveDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              setControllerLoading(true)
              setArchiveDialogOpen(false)

              const noErrors = await archiveEpisodeParent(currentEpisode.id)

              setControllerLoading(false)
              if (noErrors) {
                navigate("/episodes", { state: { episodeArchived: true } })
              }
            }}
            autoFocus
          >
            Archive
          </Button>
        </DialogActions>
      </Dialog>
      {/* can't archive episode dialog */}
      <CantPerformActionDialog
        open={cantArchiveDialogOpen}
        setOpen={setCantArchiveDialogOpen}
        content={`This Episode can't be archived because it appears in one or more
        Journeys. Click on the "Info" button to check`}
      />
      {/* info dialog */}
      <Dialog
        open={infoDialogOpen}
        onClose={() => {
          setInfoDialogOpen(false)
        }}
        aria-labelledby="dialog-info"
        aria-describedby="dialog-episode-info"
      >
        <DialogTitle id="dialog-info">Appears in</DialogTitle>
        <DialogContent>
          <Stack spacing={2} style={{ maxHeight: 384, overflowY: "scroll" }}>
            {!currentEpisodeJourneysList.filter(
              (item) => item.stage === "PUBLISHED"
            ).length ? (
              <Typography style={{ fontSize: "1.1rem" }}>
                This Episode doesn't appear in any Journey
              </Typography>
            ) : null}
            {currentEpisodeJourneysList
              .filter((item) => item.stage === "PUBLISHED")
              .map((item) => (
                <Card
                  key={item.id}
                  variant="outlined"
                  style={{ minHeight: 72, cursor: "pointer" }}
                  onClick={() => {
                    window.open("/journeys/" + item.id)
                  }}
                  className="journey-card"
                >
                  <CardContent style={{ paddingBottom: 16 }}>
                    <Stack direction="row" spacing={3} alignItems="center">
                      <Chip size="small" label="Live" color="success" />
                      {item.type === "Free" ? (
                        <Chip
                          size="small"
                          style={{ backgroundColor: "#dee3fc", width: 69.79 }}
                          label={item.type}
                        />
                      ) : (
                        <Chip
                          size="small"
                          style={{ backgroundColor: "#b6bff2" }}
                          label={item.type}
                        />
                      )}
                      <img
                        src={item.image}
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: 3,
                          objectFit: "cover",
                        }}
                      />
                      <div>{item.title}</div>
                    </Stack>
                  </CardContent>
                </Card>
              ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              setInfoDialogOpen(false)
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* global episode preview */}
      {currentEpisode.translations.filter(
        (item) => item.lang === currentPreviewLanguage
      )[0] && (
        <EpisodeGlobalPreview
          isGlobalPreviewOpen={globalPreviewOpen}
          setIsGlobalPreviewOpen={setGlobalPreviewOpen}
          backDisabled={currentPreviewSlide === 0}
          forwardDisabled={
            (!currentEpisode.translations.filter(
              (item) => item.lang === currentPreviewLanguage
            )[0].slides.length &&
              !currentEpisode.translations.filter(
                (item) => item.lang === currentPreviewLanguage
              )[0].quiz.length) ||
            currentPreviewSlide ===
              currentEpisode.translations.filter(
                (item) => item.lang === currentPreviewLanguage
              )[0].slides.length -
                1 +
                currentEpisode.translations.filter(
                  (item) => item.lang === currentPreviewLanguage
                )[0].quiz.length
          }
          currentPreviewSlide={currentPreviewSlide}
          setCurrentPreviewSlide={setCurrentPreviewSlide}
          currentPreviewLanguage={currentPreviewLanguage}
          setCurrentPreviewLanguage={setCurrentPreviewLanguage}
        />
      )}
      {/* copy to clickboard */}
      <Snackbar
        open={feedbackOpen}
        autoHideDuration={2000}
        onClose={() => {
          setFeedbackOpen(false)
        }}
      >
        <Alert severity="success">Copied to clipboard!</Alert>
      </Snackbar>
      {/* duplicate episode dialog */}
      <Dialog
        open={duplicateDialogOpen}
        onClose={() => {
          setDuplicateDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-duplicate"
        aria-describedby="alert-dialog-description-duplicate"
      >
        <DialogTitle id="alert-dialog-title-duplicate">
          {currentEpisodeLive
            ? "Are you sure you want to duplicate this Episode?"
            : "This action cannot be performed"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-duplicate">
            {currentEpisodeLive
              ? "A new Episode will be created with the same data of the current one's live version"
              : "You need to publish this Episode in order to duplicate it"}
          </DialogContentText>
        </DialogContent>
        {currentEpisodeLive ? (
          <DialogActions>
            <Button
              disabled={controllerLoading}
              onClick={() => {
                setDuplicateDialogOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={controllerLoading}
              onClick={async () => {
                const result = await duplicateEpisode()

                if (!result) {
                  setDuplicateDialogOpen(false)
                } else {
                  setDuplicateDialogOpen(false)
                  const createdEpisode = result as Episode
                  window.removeEventListener("beforeunload", handleTabClose)
                  navigate(`/episodes/${createdEpisode.id}`)
                  window.location.reload()
                }
              }}
              autoFocus
            >
              Duplicate
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={() => {
                setDuplicateDialogOpen(false)
              }}
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        )}
      </Dialog>
      {/* ai translate dialog */}
      <AiTranslateEpisodesDialog
        dialogOpen={aiTranslateDialogOpen}
        handleDialogChange={handleAiTranslateDialogChange}
        selectedEpisodesForTranslation={selectedEpisodeForTranslation}
        setSelectedEpisodesForTranslation={setSelectedEpisodeForTranslation}
      />
      <Snackbar
        open={episodeSynced}
        autoHideDuration={3000}
        onClose={() => {
          setEpisodeSynced(false)
        }}
      >
        <Alert severity="success">Episode synced on Notion</Alert>
      </Snackbar>
    </EditContainer>
  )
}

export default EpisodeEdit
