import { TableRow, TableCell, Typography, Chip, Stack } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { JobStatus, TranslationJobType } from "../../services/config/enum"
import {
  AccessTimeRounded,
  CheckCircleOutlineRounded,
  ErrorOutlineRounded,
  SyncRounded,
  DynamicFeedRounded,
} from "@mui/icons-material"
import { calculateTimeElapsed } from "../../services/utilities/utility"
import episodeIcon from "../../assets/images/episode-icon.png"
import ListTable from "../global/listTable"
import { Dispatch, SetStateAction, useContext } from "react"
import { MainContext } from "../../controllers/main"
import PrimaryBadge from "../../components/global/primaryBadge"
import { DateTime } from "luxon"
import TableSpinner from "../global/tableSpinner"

interface Job {
  id: string
  status: JobStatus
  jobType: TranslationJobType
  fileLocation?: string
  error?: string
  createdAt: string
  updatedAt: string
  translatedEntities?: {
    episodes?: Array<{ episode: { id: string } }>
    journeys?: Array<{ journey: { id: string } }>
  }
}

interface JobsListProps {
  jobs: Job[]
  jobType: TranslationJobType
  nextToken: string | null
  loadMoreLoading: boolean
  setLoadMoreLoading: Dispatch<SetStateAction<boolean>>
  loadMore: () => Promise<boolean>
  loading?: boolean
}

const formatJobType = (type: TranslationJobType) => {
  if (type === TranslationJobType.AI_TRANSLATE) {
    return "AI Translate"
  }
  return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()
}

const calculateExecutionTime = (
  createdAt: string,
  updatedAt: string,
  status: JobStatus
) => {
  const startTime = DateTime.fromISO(createdAt)
  const endTime =
    status === JobStatus.COMPLETED || status === JobStatus.ERROR
      ? DateTime.fromISO(updatedAt)
      : DateTime.now()

  const diff = endTime.diff(startTime, ["days", "hours", "minutes", "seconds"])
  const parts = []

  if (diff.days > 0) parts.push(`${diff.days}d`)
  if (diff.hours > 0) parts.push(`${diff.hours}h`)
  if (diff.minutes > 0) parts.push(`${diff.minutes}m`)
  if (diff.seconds > 0) parts.push(`${Math.floor(diff.seconds)}s`)

  return parts.join(" ")
}

const JobsList = ({
  jobs,
  jobType,
  nextToken,
  loadMoreLoading,
  setLoadMoreLoading,
  loadMore,
  loading,
}: JobsListProps) => {
  const navigate = useNavigate()
  const { showPadding } = useContext(MainContext)

  const getStatusIcon = (status: JobStatus) => {
    switch (status) {
      case JobStatus.PENDING:
        return <AccessTimeRounded style={{ fontSize: 14 }} />
      case JobStatus.RUNNING:
        return <SyncRounded style={{ fontSize: 14 }} />
      case JobStatus.COMPLETED:
        return <CheckCircleOutlineRounded style={{ fontSize: 14 }} />
      case JobStatus.ERROR:
        return <ErrorOutlineRounded style={{ fontSize: 14 }} />
    }
  }

  const getStatusProps = (
    status: JobStatus
  ): { color: "success" | "warning" | "error"; label: string } => {
    switch (status) {
      case JobStatus.COMPLETED:
        return {
          color: "success",
          label: status.charAt(0).toUpperCase() + status.slice(1).toLowerCase(),
        }
      case JobStatus.ERROR:
        return {
          color: "error",
          label: status.charAt(0).toUpperCase() + status.slice(1).toLowerCase(),
        }
      default:
        return {
          color: "warning",
          label: status.charAt(0).toUpperCase() + status.slice(1).toLowerCase(),
        }
    }
  }

  const getEntityCounts = (job: Job) => {
    const episodesCount = job.translatedEntities?.episodes?.length || 0
    const journeysCount = job.translatedEntities?.journeys?.length || 0
    return { episodesCount, journeysCount }
  }

  const filteredJobs = jobs.filter((job) => job.jobType === jobType)
  const height =
    showPadding === "yes" ? "calc(100vh - 197px)" : "calc(100vh - 175px)"

  if (loading) {
    return <TableSpinner height={height} />
  }

  if (filteredJobs.length === 0) {
    return (
      <Typography variant="body1" color="textSecondary" align="center" py={2}>
        No jobs found
      </Typography>
    )
  }

  return (
    <ListTable
      height={height}
      headingItems={[
        "ID",
        "Status",
        "Job Type",
        "Episodes",
        "Journeys",
        "Error",
        "Execution Time",
        "Created",
        "Updated",
      ]}
      nextToken={nextToken}
      loadingMoreItems={loadMoreLoading}
      setLoadingMoreItems={setLoadMoreLoading}
      loadMore={loadMore}
      tableBody={filteredJobs.map((job) => {
        const { episodesCount, journeysCount } = getEntityCounts(job)
        const statusProps = getStatusProps(job.status)
        return (
          <TableRow
            key={job.id}
            hover
            onClick={() => navigate(`/translationJobs/${job.id}`)}
            sx={{
              cursor: "pointer",
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell sx={{ py: 1.5 }}>{job.id}</TableCell>
            <TableCell sx={{ py: 1.5 }}>
              <Chip
                size="small"
                icon={getStatusIcon(job.status)}
                label={statusProps.label}
                color={statusProps.color}
              />
            </TableCell>
            <TableCell sx={{ py: 1.5 }} style={{ minWidth: 100 }}>
              <Chip
                size="small"
                style={{ backgroundColor: "#dee3fc" }}
                label={formatJobType(job.jobType)}
              />
            </TableCell>
            <TableCell sx={{ py: 1.5 }}>
              <PrimaryBadge
                count={episodesCount}
                icon={
                  <div
                    style={{
                      width: 12,
                      height: 12,
                      WebkitMask: `url(${episodeIcon}) center/contain no-repeat`,
                      mask: `url(${episodeIcon}) center/contain no-repeat`,
                      backgroundColor: "#00A0FD",
                      marginLeft: 1,
                      marginBottom: 3,
                    }}
                  />
                }
              />
            </TableCell>
            <TableCell sx={{ py: 1.5 }}>
              <PrimaryBadge
                count={journeysCount}
                icon={
                  <DynamicFeedRounded
                    style={{ fontSize: 14, color: "inherit" }}
                  />
                }
              />
            </TableCell>
            <TableCell sx={{ py: 1.5 }}>{job.error || "-"}</TableCell>
            <TableCell sx={{ py: 1.5 }} style={{ minWidth: 150 }}>
              {calculateExecutionTime(job.createdAt, job.updatedAt, job.status)}
            </TableCell>
            <TableCell sx={{ py: 1.5 }} style={{ minWidth: 130 }}>
              {calculateTimeElapsed(job.createdAt)}
            </TableCell>
            <TableCell sx={{ py: 1.5 }} style={{ minWidth: 130 }}>
              {calculateTimeElapsed(job.updatedAt)}
            </TableCell>
          </TableRow>
        )
      })}
    />
  )
}

export default JobsList
