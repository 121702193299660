import {
  Alert,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  LinearProgress,
  Snackbar,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Stack,
  TableCell,
  TableRow,
  Typography,
  Zoom,
} from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { EpisodesContext } from "../../controllers/episodes"
import { MainContext } from "../../controllers/main"
import {
  calculateTimeElapsed,
  capitalizeFirstCharacter,
  registerAnalyticsEvent,
} from "../../services/utilities/utility"
import CreateEpisodeDialog from "../../components/episode/createEpisodeDialog"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import EpisodesListTopBar from "../../components/episode/episodesListTopBar"
import { LoadingButton } from "@mui/lab"
import Episode from "../../models/episode"
import CreateEpisodeJSONDialog from "../../components/episode/createEpisodeJSONDialog"
import { FileDownloadRounded, TranslateRounded } from "@mui/icons-material"
import ExportEpisodesDialog from "../../components/episode/exportEpisodesDialog"
import AiTranslateEpisodesDialog from "../../components/episode/aiTranslateEpisodesDialog"
import TranslationsStackMultiple from "../../components/global/translationsStackMultiple"
import CopyToClipboardButton from "../../components/global/copyToClipboardButton"

const EpisodesList = ({
  forDialog = false,
  singleDialogSelection = false,
  forDialogLanguage,
  episodesToFilter = [],
  selectedEpisodes = [],
  setSelectedEpisodes,
}: {
  forDialog?: boolean
  singleDialogSelection?: boolean
  forDialogLanguage?: string
  episodesToFilter?: Episode[]
  selectedEpisodes?: Episode[]
  setSelectedEpisodes?: Dispatch<SetStateAction<Episode[]>>
}) => {
  const { setAnimation, showPadding, cmdPressed } = useContext(MainContext)
  const {
    loading,
    hasSearched,
    searchValue,
    setSearchValue,
    episodesList,
    getEpisodesList,
    episodesListNextToken,
    loadMoreEpisodes,
    searchEpisodes,
    searchLanguage,
    searchTopic,
    searchSdg,
    searchSdgTarget,
    searchCategory,
    searchEsg,
    searchDoNotRecommend,
    searchType,
    searchStatus,
    unArchiveEpisodeParent,
    updatingList,
    setUpdatingList,
  } = useContext(EpisodesContext)
  const navigate = useNavigate()
  const { state }: { state: any } = useLocation()

  // list background update
  const backgroundUpdate = () => {
    if (!loading && !hasSearched && !forDialog) {
      setUpdatingList(true)
      if (searchStatus === "active") {
        getEpisodesList(false)
      } else {
        getEpisodesList(false, true)
      }
    }
  }

  useEffect(() => {
    if (!episodesList.length) {
      getEpisodesList()
    }
    if (!state) {
      backgroundUpdate()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create episode dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // handle create episode from JSON dialog
  const [jsonDialogOpen, setJsonDialogOpen] = useState<boolean>(false)

  const handleJsonDialogChange = useCallback(() => {
    setJsonDialogOpen(!jsonDialogOpen)
  }, [jsonDialogOpen])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // unarchive dialog
  const [episodeToUnArchiveId, setEpisodeToUnArchiveId] = useState<string>("")
  const [unArchiveDialogOpen, setUnArchiveDialogOpen] = useState<boolean>(false)
  const [unArchiveSnackbarOpen, setUnArchiveSnackbarOpen] =
    useState<boolean>(false)
  const [unArchiveLoading, setUnArchiveLoading] = useState<boolean>(false)

  // archived snackbar
  const [archivedSnackbarOpen, setArchivedSnackbarOpen] = useState<boolean>(
    state ? true : false
  )

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "episodes_list" })
  }, [])

  // selected episodes to export for translation
  const [selectedEpisodesForExport, setSelectedEpisodesForExport] = useState<
    { episode: Episode; sourceLang?: string; langs: string[] }[]
  >([])

  // maximum selected episodes alert
  const [maximumEpisodesAlertOpen, setMaximumEpisodesAlertOpen] =
    useState<boolean>(false)

  // export episodes dialog
  const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false)
  const handleExportDialogChange = () => {
    setExportDialogOpen((current) => !current)
  }

  // ai translate episodes dialog
  const [aiTranslateDialogOpen, setAiTranslateDialogOpen] =
    useState<boolean>(false)
  const handleAiTranslateDialogChange = () => {
    setAiTranslateDialogOpen((current) => !current)
  }

  const actions = [
    {
      icon: <FileDownloadRounded />,
      name: "Export",
      onClick: handleExportDialogChange,
    },
    {
      icon: <TranslateRounded />,
      name: "AI Translation",
      onClick: handleAiTranslateDialogChange,
    },
  ]

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{ position: "absolute", top: 143, width: "100%", zIndex: 10 }}
        />
      )}
      <EpisodesListTopBar
        forDialog={forDialog}
        forDialogLanguage={forDialogLanguage}
        loading={loading || loadMoreLoading}
        textFieldValue={searchValue}
        textFieldPlaceholder="Search Episodes"
        addButtonLabel="Add Episode"
        textFieldOnChange={(e) => {
          setSearchValue(e.target.value)
          if (e.target.value.length === 0 && hasSearched) {
            setUpdatingList(true)

            if (
              !searchLanguage &&
              !searchTopic &&
              !searchSdg &&
              !searchSdgTarget &&
              !searchCategory &&
              !searchEsg &&
              !searchDoNotRecommend &&
              !searchType
            ) {
              if (searchStatus === "active") {
                getEpisodesList(false)
              } else {
                getEpisodesList(false, true)
              }
            } else {
              if (searchStatus === "active") {
                searchEpisodes({
                  title: "",
                  lang: searchLanguage,
                  topic: searchTopic,
                  sdg: searchSdg,
                  sdgTarget: searchSdgTarget,
                  category: searchCategory,
                  esg: searchEsg,
                  doNotRecommend: searchDoNotRecommend,
                  type: searchType,
                })
              } else {
                searchEpisodes({
                  title: "",
                  lang: searchLanguage,
                  topic: searchTopic,
                  sdg: searchSdg,
                  sdgTarget: searchSdgTarget,
                  category: searchCategory,
                  esg: searchEsg,
                  doNotRecommend: searchDoNotRecommend,
                  type: searchType,
                  archived: true,
                })
              }
            }
          }
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter" && searchValue.length > 2) {
            if (searchStatus === "active") {
              searchEpisodes({
                title: searchValue,
                lang: searchLanguage,
                topic: searchTopic,
                sdg: searchSdg,
                sdgTarget: searchSdgTarget,
                category: searchCategory,
                esg: searchEsg,
                doNotRecommend: searchDoNotRecommend,
                type: searchType,
              })
            } else {
              searchEpisodes({
                title: searchValue,
                lang: searchLanguage,
                topic: searchTopic,
                sdg: searchSdg,
                sdgTarget: searchSdgTarget,
                category: searchCategory,
                esg: searchEsg,
                doNotRecommend: searchDoNotRecommend,
                type: searchType,
                archived: true,
              })
            }
          }
        }}
        cancelButtonOnClick={() => {
          setSearchValue("")
          if (hasSearched) {
            setUpdatingList(true)

            if (
              !searchLanguage &&
              !searchTopic &&
              !searchSdg &&
              !searchSdgTarget &&
              !searchCategory &&
              !searchEsg &&
              !searchDoNotRecommend &&
              !searchType
            ) {
              if (searchStatus === "active") {
                getEpisodesList(false)
              } else {
                getEpisodesList(false, true)
              }
            } else {
              if (searchStatus === "active") {
                searchEpisodes({
                  title: "",
                  lang: searchLanguage,
                  topic: searchTopic,
                  sdg: searchSdg,
                  sdgTarget: searchSdgTarget,
                  category: searchCategory,
                  esg: searchEsg,
                  doNotRecommend: searchDoNotRecommend,
                  type: searchType,
                })
              } else {
                searchEpisodes({
                  title: "",
                  lang: searchLanguage,
                  topic: searchTopic,
                  sdg: searchSdg,
                  sdgTarget: searchSdgTarget,
                  category: searchCategory,
                  esg: searchEsg,
                  doNotRecommend: searchDoNotRecommend,
                  type: searchType,
                  archived: true,
                })
              }
            }
          }
        }}
        searchButtonOnClick={() => {
          if (searchStatus === "active") {
            searchEpisodes({
              title: searchValue,
              lang: searchLanguage,
              topic: searchTopic,
              sdg: searchSdg,
              sdgTarget: searchSdgTarget,
              category: searchCategory,
              esg: searchEsg,
              doNotRecommend: searchDoNotRecommend,
              type: searchType,
            })
          } else {
            searchEpisodes({
              title: searchValue,
              lang: searchLanguage,
              topic: searchTopic,
              sdg: searchSdg,
              sdgTarget: searchSdgTarget,
              category: searchCategory,
              esg: searchEsg,
              doNotRecommend: searchDoNotRecommend,
              type: searchType,
              archived: true,
            })
          }
        }}
        addButtonOnClick={handleDialogChange}
        addFromJsonButtonOnClick={handleJsonDialogChange}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 176px)"
          }
        />
      ) : (
        <div style={{ position: "relative" }} id="episodes-list-main-container">
          <ListTable
            height={
              showPadding === "yes"
                ? "calc(100vh - 208px)"
                : "calc(100vh - 176px)"
            }
            nextToken={episodesListNextToken}
            loadingMoreItems={loadMoreLoading}
            setLoadingMoreItems={setLoadMoreLoading}
            loadMore={
              searchStatus === "active"
                ? loadMoreEpisodes
                : async () => {
                    const result = await loadMoreEpisodes(true)
                    return result
                  }
            }
            scrollToTopButtonDisabled={selectedEpisodesForExport.length > 0}
            headingItems={
              episodesList.length &&
              episodesList[0].stage &&
              episodesList[0].stage !== "ARCHIVED"
                ? forDialog
                  ? [
                      "Stage",
                      "Thumbnail",
                      "Title & ID",
                      "Type",
                      "Translations",
                      "Category",
                      "Recommend",
                      "Topic",
                      "ESG",
                      "Updated",
                    ]
                  : [
                      "",
                      "Stage",
                      "Thumbnail",
                      "Title & ID",
                      "Type",
                      "Translations",
                      "Category",
                      "Recommend",
                      "Topic",
                      "ESG",
                      "Updated",
                    ]
                : [
                    "Stage",
                    "Thumbnail",
                    "Title & ID",
                    "Translations",
                    "Category",
                    "Recommend",
                    "Topic",
                    "ESG",
                    "Updated",
                    "",
                  ]
            }
            tableBody={episodesList
              .filter((item) => {
                if (
                  !episodesToFilter.filter((item2) => item2.id === item.id)[0]
                ) {
                  return item
                }
              })
              .map((item) => (
                <TableRow
                  hover={item.stage && item.stage !== "ARCHIVED"}
                  style={{
                    cursor:
                      item.stage && item.stage !== "ARCHIVED"
                        ? "pointer"
                        : "default",
                    opacity: !item.stage ? 0.5 : 1,
                    backgroundColor: selectedEpisodes.filter(
                      (episode: Episode) => episode.id === item.id
                    )[0]
                      ? "lightgray"
                      : null,
                  }}
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  onClick={() => {
                    if (searchStatus === "active" && item.stage && !forDialog) {
                      if (cmdPressed) {
                        window.open(`${window.location.href}/${item.id}`)
                      } else {
                        setAnimation(false)
                        setTimeout(() => {
                          navigate(`/episodes/${item.id}`)
                        }, 250)
                      }
                    } else if (forDialog) {
                      if (singleDialogSelection) {
                        setSelectedEpisodes([item])
                      } else {
                        if (
                          selectedEpisodes.filter(
                            (episode: Episode) => episode.id === item.id
                          )[0]
                        ) {
                          let indexToRemove = selectedEpisodes.findIndex(
                            (episode: Episode) => episode.id === item.id
                          )
                          selectedEpisodes.splice(indexToRemove, 1)
                        } else {
                          selectedEpisodes.push(item)
                        }
                        setSelectedEpisodes([...selectedEpisodes])
                      }
                    }
                  }}
                >
                  {!forDialog &&
                  episodesList.length &&
                  episodesList[0].stage &&
                  episodesList[0].stage !== "ARCHIVED" ? (
                    <TableCell
                      style={{ padding: 0, transition: "100ms" }}
                      className={
                        selectedEpisodesForExport.length
                          ? null
                          : "journeys-list-checkbox"
                      }
                    >
                      <Checkbox
                        id={"checkbox" + item.id}
                        checked={
                          selectedEpisodesForExport.filter(
                            (item2) => item2.episode.id === item.id
                          )[0]
                            ? true
                            : false
                        }
                        onClick={(e) => {
                          e.stopPropagation()
                          if (
                            !selectedEpisodesForExport.filter(
                              (item2) => item2.episode.id === item.id
                            ).length &&
                            selectedEpisodesForExport.length > 4
                          ) {
                            setMaximumEpisodesAlertOpen(true)
                            return
                          }
                          if (
                            selectedEpisodesForExport.filter(
                              (item2) => item2.episode.id === item.id
                            )[0]
                          ) {
                            const indexToRemove =
                              selectedEpisodesForExport.findIndex(
                                (item2) => item2.episode.id === item.id
                              )
                            selectedEpisodesForExport.splice(indexToRemove, 1)
                            // remove animation
                            if (selectedEpisodesForExport.length === 0) {
                              const animationDivs =
                                document.getElementsByClassName("animation-div")
                              for (let i = 0; i < animationDivs.length; i++) {
                                animationDivs[i].remove()
                              }
                            }
                          } else {
                            selectedEpisodesForExport.push({
                              episode: item,
                              sourceLang: item.lang,
                              langs: [],
                            })
                            // animation
                            if (selectedEpisodesForExport.length === 1) {
                              const checkbox = document.getElementById(
                                "checkbox" + item.id
                              )
                              const container = document.getElementById(
                                "episodes-list-main-container"
                              )
                              const divToAppend = document.createElement("div")
                              divToAppend.className = "animation-div"
                              divToAppend.style.opacity = "0"
                              divToAppend.style.top =
                                checkbox.getBoundingClientRect().top + "px"
                              divToAppend.style.left =
                                checkbox.getBoundingClientRect().left + "px"
                              container.appendChild(divToAppend)
                              setTimeout(() => {
                                const fab =
                                  document.getElementById("fab-export")
                                divToAppend.style.top =
                                  fab.getBoundingClientRect().top - 15 + "px"
                                divToAppend.style.left =
                                  fab.getBoundingClientRect().left - 15 + "px"
                                divToAppend.style.opacity = "1"
                              }, 50)
                              setTimeout(() => {
                                divToAppend.remove()
                              }, 650)
                            }
                          }
                          setSelectedEpisodesForExport([
                            ...selectedEpisodesForExport,
                          ])
                        }}
                        style={{
                          marginRight: -10,
                          transition: "100ms",
                        }}
                      />
                    </TableCell>
                  ) : null}
                  <TableCell style={{ position: "relative" }}>
                    {item.stage === "PUBLISHED" || item.hasSibling ? (
                      <Chip
                        size="small"
                        label="Live"
                        color="success"
                        style={{ position: "relative", zIndex: 1 }}
                      />
                    ) : item.stage === "ARCHIVED" ||
                      (searchStatus === "archived" && !item.stage) ? (
                      <Chip
                        size="small"
                        label="Archived"
                        color="default"
                        style={{ position: "relative", zIndex: 1 }}
                      />
                    ) : (
                      <Chip
                        size="small"
                        label="Draft"
                        color="warning"
                        style={{ position: "relative", zIndex: 1 }}
                      />
                    )}
                    {item.hasSibling ? (
                      <Chip
                        size="small"
                        label="Draft"
                        color="warning"
                        style={{ position: "absolute", left: 17 }}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        width: 42,
                        height: 42,
                        borderRadius: 4,
                        backgroundImage: `url(${
                          item.image ?? noImagePlaceholder
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Stack sx={{ gap: -5 }}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        style={{ height: 22.5 }}
                        gap={1}
                      >
                        <Typography
                          style={{
                            fontSize: 15,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: 320,
                          }}
                        >
                          {item.title}
                        </Typography>
                        {item.specialType && (
                          <Chip
                            size="small"
                            color="info"
                            label={capitalizeFirstCharacter(item.specialType)}
                            style={{
                              backgroundColor: "#5562B4",
                              height: 18,
                              fontSize: 11,
                              fontWeight: 600,
                            }}
                          />
                        )}
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        style={{ marginLeft: -3 }}
                      >
                        <CopyToClipboardButton
                          textToCopy={item.id}
                          size="small"
                        />
                        <Typography
                          variant="caption"
                          style={{
                            fontSize: 10,
                            color: "gray",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item.id}
                        </Typography>
                      </Stack>
                    </Stack>
                  </TableCell>
                  {searchStatus === "active" ? (
                    <TableCell>
                      {item.type === "Standard" ? (
                        <Chip
                          size="small"
                          style={{ backgroundColor: "#dee3fc" }}
                          label={item.type}
                        />
                      ) : item.type === "Custom" ? (
                        <Chip
                          size="small"
                          style={{ backgroundColor: "#adb4de" }}
                          label={item.type}
                        />
                      ) : (
                        <Chip
                          size="small"
                          style={{ backgroundColor: "#8d98e0" }}
                          label="None"
                        />
                      )}
                    </TableCell>
                  ) : null}
                  <TableCell>
                    <TranslationsStackMultiple
                      publishedLangs={item.publishedLangs}
                      draftedLangs={item.draftedLangs}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={item.category.name}
                      style={{
                        backgroundColor: item.category.backColor,
                        color: item.category.foreColor,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      color={
                        item.doNotRecommend === null ||
                        item.doNotRecommend === false
                          ? "success"
                          : "warning"
                      }
                      label={
                        item.doNotRecommend === null ||
                        item.doNotRecommend === false
                          ? "Yes"
                          : "No"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {item.topics &&
                    item.topics.filter((item) => item.primary)[0] ? (
                      <Chip
                        size="small"
                        label={
                          item.topics.filter((item) => item.primary)[0].topic
                            .name
                        }
                      />
                    ) : (
                      <Typography
                        style={{
                          fontSize: "0.8125rem",
                          color: "rgba(0, 0, 0, 0.3)",
                        }}
                      >
                        none
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.esg ? (
                      <Chip
                        size="small"
                        label={capitalizeFirstCharacter(item.esg)}
                      />
                    ) : (
                      <Typography
                        style={{
                          fontSize: "0.8125rem",
                          color: "rgba(0, 0, 0, 0.3)",
                        }}
                      >
                        none
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }}>
                    <Typography variant="caption">
                      {calculateTimeElapsed(item.updatedAt)}
                    </Typography>
                  </TableCell>
                  {(episodesList.length &&
                    episodesList[0].stage === "ARCHIVED") ||
                  (!episodesList[0].stage && searchStatus === "archived") ? (
                    <TableCell>
                      <Button
                        variant="contained"
                        size="small"
                        disabled={item.stage !== "ARCHIVED" || updatingList}
                        onClick={() => {
                          setEpisodeToUnArchiveId(item.id)
                          setUnArchiveDialogOpen(true)
                        }}
                      >
                        Unarchive
                      </Button>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
          />
          <div
            style={{
              position: "absolute",
              bottom: 9,
              right: 15,
            }}
          >
            <SpeedDial
              ariaLabel="Episode actions"
              sx={{
                width: 50,
              }}
              icon={
                <Zoom
                  id="fab-export"
                  in={selectedEpisodesForExport.length > 0}
                  className="center"
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: "100%",
                    backgroundColor: "#5562B4",
                    transitionDelay:
                      selectedEpisodesForExport.length > 0 ? "350ms" : "0ms",
                  }}
                >
                  <SpeedDialIcon />
                </Zoom>
              }
              FabProps={{
                style: {
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  boxShadow: "none",
                },
              }}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={action.onClick}
                />
              ))}
            </SpeedDial>
            <Fade
              in={selectedEpisodesForExport.length > 0}
              style={{
                width: 20,
                height: 20,
                position: "absolute",
                bottom: 30,
                right: 0,
                boxSizing: "border-box",
                zIndex: 1100,
                transitionDuration: "100ms",
                transitionDelay:
                  selectedEpisodesForExport.length > 0 ? "350ms" : "0ms",
              }}
            >
              <div
                className="center"
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: "100%",
                  backgroundColor: "#465094",
                }}
              >
                <Typography
                  style={{
                    color: "white",
                    fontWeight: 700,
                    fontSize: 11,
                  }}
                >
                  {selectedEpisodesForExport.length}
                </Typography>
              </div>
            </Fade>
          </div>
          <Checkbox
            indeterminate
            style={{
              position: "absolute",
              top: -2,
              left: 0,
              zIndex: 5,
              opacity: selectedEpisodesForExport.length ? 1 : 0,
              transition: "150ms",
            }}
            onClick={() => {
              setSelectedEpisodesForExport([])
            }}
          />
        </div>
      )}
      <CreateEpisodeDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
        topBarText="Add Episode"
      />
      <CreateEpisodeJSONDialog
        dialogOpen={jsonDialogOpen}
        setDialogOpen={setJsonDialogOpen}
        handleDialogChange={handleJsonDialogChange}
        topBarText="Add Episode from JSON"
      />
      <Dialog
        open={unArchiveDialogOpen}
        onClose={() => {
          setUnArchiveDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-archive"
        aria-describedby="alert-dialog-description-archive"
      >
        <DialogTitle id="alert-dialog-title-archive">
          Are you sure you want to unarchive this episode?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-archive">
            It will be unarchived and set to DRAFT stage
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setUnArchiveDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={async () => {
              setUnArchiveLoading(true)

              const noErrors = await unArchiveEpisodeParent(
                episodeToUnArchiveId
              )

              setUnArchiveLoading(false)
              setUnArchiveDialogOpen(false)
              if (noErrors) {
                setUnArchiveSnackbarOpen(true)
              }
            }}
            autoFocus
            loading={unArchiveLoading}
          >
            Unarchive
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={unArchiveSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => {
          setUnArchiveSnackbarOpen(false)
        }}
      >
        <Alert severity="success">
          Episode unarchived, list will be updated soon
        </Alert>
      </Snackbar>
      <Snackbar
        open={archivedSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => {
          setArchivedSnackbarOpen(false)
        }}
      >
        <Alert severity="success">
          Episode archived, list will be updated soon
        </Alert>
      </Snackbar>
      {/* maximum selected episodes alert */}
      <Dialog
        open={maximumEpisodesAlertOpen}
        onClose={() => {
          setMaximumEpisodesAlertOpen(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Maximum number reached
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A maximum of 5 Episodes at a time can be exported
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setMaximumEpisodesAlertOpen(false)
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* export episodes dialog */}
      <ExportEpisodesDialog
        dialogOpen={exportDialogOpen}
        handleDialogChange={handleExportDialogChange}
        selectedEpisodesForExport={selectedEpisodesForExport}
        setSelectedEpisodesForExport={setSelectedEpisodesForExport}
      />

      {/* ai translate episodes dialog */}
      <AiTranslateEpisodesDialog
        dialogOpen={aiTranslateDialogOpen}
        handleDialogChange={handleAiTranslateDialogChange}
        selectedEpisodesForTranslation={selectedEpisodesForExport}
        setSelectedEpisodesForTranslation={setSelectedEpisodesForExport}
      />
    </ListContainer>
  )
}

export default EpisodesList
