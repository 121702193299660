import { Chip, Stack } from "@mui/material"
import { LinkRounded } from "@mui/icons-material"
import { SurveyLinkMode } from "../../models/survey"

interface SurveyLinkIconProps {
  linkMode: SurveyLinkMode
  small?: boolean
}

const SurveyLinkIcon = ({ linkMode, small }: SurveyLinkIconProps) => {
  const getChipColor = () => {
    switch (linkMode) {
      case SurveyLinkMode.auto:
      case SurveyLinkMode.manual:
        return "success"
      case SurveyLinkMode.unlinked:
        return "error"
    }
  }

  return (
    <Chip
      label={
        <Stack alignItems="center" justifyContent="center" sx={{ width: 24 }}>
          <LinkRounded fontSize={small ? "small" : "medium"} />
        </Stack>
      }
      color={getChipColor()}
      size={small ? "small" : "medium"}
    />
  )
}

export default SurveyLinkIcon
