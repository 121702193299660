import { Chip, Stack, Typography } from "@mui/material"
import { SurveyLinkMode } from "../../models/survey"

interface SurveyLinkCounterProps {
  items: { linkMode: SurveyLinkMode }[]
}

const SurveyLinkCounter = ({ items }: SurveyLinkCounterProps) => {
  const linkedCount = items.filter(
    (item) => item.linkMode !== SurveyLinkMode.unlinked
  ).length

  const getChipColor = () => {
    if (linkedCount === items.length) return "success"
    if (linkedCount === 0) return "error"
    return "warning"
  }

  return (
    <Chip
      label={`${linkedCount}/${items.length}`}
      color={getChipColor()}
      size="small"
    />
  )
}

export default SurveyLinkCounter
