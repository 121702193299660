import { Alert, LinearProgress, Snackbar } from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useLocation } from "react-router-dom"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import { registerAnalyticsEvent } from "../../services/utilities/utility"
import { ActionsContext } from "../../controllers/actions"
import Action from "../../models/action"
import CreateActionDialog from "../../components/action/createActionDialog"
import ActionsListTopBar from "../../components/action/actionsListTopBar"
import { CountriesContext } from "../../controllers/countries"
import CreateActionJSONDialog from "../../components/action/createActionJSONDialog"
import ActionsListRow from "../../components/action/actionsListRow"

interface AutocompleteOption {
  label: string
  id: string
}

const ActionsList = ({
  forDialog = false,
  actionsToFilter = [],
  selectedActions = [],
  setSelectedActions,
  categoryFilter,
  alreadyAssignedFilter,
}: {
  forDialog?: boolean
  actionsToFilter?: Action[]
  selectedActions?: Action[]
  setSelectedActions?: Dispatch<SetStateAction<Action[]>>
  categoryFilter?: AutocompleteOption
  alreadyAssignedFilter?: boolean
}) => {
  const { showPadding } = useContext(MainContext)
  const { loading: countriesControllerLoading } = useContext(CountriesContext)
  const {
    loading,
    setLoading,
    updatingList,
    setUpdatingList,
    actionsList,
    getActionsList,
    actionsListNextToken,
    loadMoreActions,
    hasSearched,
    searchIsRepeatable,
    searchLang,
    searchSavingMetrics,
    searchSdg,
    searchSdgTarget,
    searchTimesPerDay,
    searchFeatured,
    searchCategory,
    setSearchCategory,
    searchType,
    searchAlreadyAssigned,
    setSearchAlreadyAssigned,
    searchTitle,
    setSearchTitle,
    searchActionsList,
  } = useContext(ActionsContext)
  const location = useLocation()

  // list background update
  const backgroundUpdate = () => {
    if (!actionsList.length) {
      getActionsList()
    } else if (!loading && !hasSearched) {
      setUpdatingList(true)
      getActionsList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // set category filter if present
    if (!categoryFilter && !alreadyAssignedFilter) {
      backgroundUpdate()
    } else {
      setLoading(false)
      if (categoryFilter) {
        setSearchCategory(categoryFilter)
      }
      if (alreadyAssignedFilter) {
        setSearchAlreadyAssigned(false)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create action dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // handle create episode from JSON dialog
  const [jsonDialogOpen, setJsonDialogOpen] = useState<boolean>(false)

  const handleJsonDialogChange = useCallback(() => {
    setJsonDialogOpen(!jsonDialogOpen)
  }, [jsonDialogOpen])

  // handle action deleted feedback
  const [actionDeletedFeedbackOpen, setActionDeletedFeedbackOpen] =
    useState<boolean>(false)

  useEffect(() => {
    if (location.state && (location.state as any).actionDeleted) {
      setActionDeletedFeedbackOpen(true)
    }
  }, [])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // register analytics event
  useEffect(() => {
    registerAnalyticsEvent({ type: "page_view", name: "actions_list" })
  }, [])

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 143,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <ActionsListTopBar
        forDialog={forDialog}
        loading={loading}
        textFieldValue={searchTitle}
        textFieldPlaceholder="Search Actions"
        addButtonLabel="Add Action"
        addButtonDisabled={countriesControllerLoading}
        textFieldOnChange={(e) => {
          setSearchTitle(e.target.value)
          if (e.target.value.length === 0 && hasSearched) {
            setUpdatingList(true)

            if (
              searchIsRepeatable === null &&
              !searchLang &&
              !searchSavingMetrics &&
              !searchSdg &&
              !searchSdgTarget &&
              !searchTimesPerDay &&
              !searchCategory &&
              !searchType &&
              searchAlreadyAssigned === null &&
              searchFeatured === null
            ) {
              getActionsList(false)
            } else {
              searchActionsList()
            }
          }
        }}
        textFieldOnKeyDown={(e) => {
          if (e.key === "Enter" && searchTitle.length > 2) {
            searchActionsList()
          }
        }}
        cancelButtonOnClick={() => {
          setSearchTitle("")
          if (hasSearched) {
            setUpdatingList(true)

            if (
              searchIsRepeatable === null &&
              !searchLang &&
              !searchSavingMetrics &&
              !searchSdg &&
              !searchSdgTarget &&
              !searchCategory &&
              !searchType &&
              searchAlreadyAssigned === null &&
              !searchTimesPerDay &&
              searchFeatured === null
            ) {
              getActionsList(false)
            } else {
              searchActionsList()
            }
          }
        }}
        searchButtonOnClick={() => {
          searchActionsList()
        }}
        addButtonOnClick={handleDialogChange}
        addFromJsonButtonOnClick={handleJsonDialogChange}
        categoryFilterDisabled={categoryFilter ? true : false}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 208px)"
              : "calc(100vh - 175px)"
          }
          headingItems={[
            "Image",
            "Title & ID",
            "Translations",
            "Type",
            "Savings",
            "Featured",
            "Recommend",
            "Category",
            "Updated",
          ]}
          nextToken={actionsListNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={
            !searchTitle.length &&
            searchIsRepeatable === null &&
            !searchLang &&
            !searchSavingMetrics &&
            !searchSdg &&
            !searchSdgTarget &&
            !searchTimesPerDay &&
            !searchCategory &&
            !searchType &&
            searchAlreadyAssigned === null &&
            searchFeatured === null
              ? loadMoreActions
              : async () => {
                  const result = await searchActionsList(false, true)
                  return result
                }
          }
          tableBody={actionsList
            .filter((item) => {
              if (!actionsToFilter.filter((item2) => item2.id === item.id)[0]) {
                return item
              }
            })
            .map((item: Action) => (
              <ActionsListRow
                key={item.id}
                item={item}
                selectedActions={selectedActions}
                setSelectedActions={setSelectedActions}
                forDialog={forDialog}
              />
            ))}
        />
      )}
      <CreateActionDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
        topBarText="Add Action"
      />
      <CreateActionJSONDialog
        dialogOpen={jsonDialogOpen}
        setDialogOpen={setJsonDialogOpen}
        handleDialogChange={handleJsonDialogChange}
        topBarText="Add Action from JSON"
      />
      <Snackbar
        open={actionDeletedFeedbackOpen}
        onClose={() => {
          setActionDeletedFeedbackOpen(false)
        }}
        autoHideDuration={3000}
      >
        <Alert severity="success">Action deleted</Alert>
      </Snackbar>
    </ListContainer>
  )
}

export default ActionsList
