import { Typography } from "@mui/material"
import { Dispatch, SetStateAction, useMemo } from "react"

const TAB_WIDTH = 90

const TeamGeneralEditTabs = ({
  selectedSwitchPage,
  setSelectedSwitchPage,
}: {
  selectedSwitchPage: number
  setSelectedSwitchPage: Dispatch<SetStateAction<number>>
}) => {
  // tabs
  const tabs = useMemo(
    () => ["General", "Groups", "Journeys", "News", "Challenges", "Surveys"],
    []
  )

  return (
    <div
      style={{
        width: "100%",
        height: 56,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        boxShadow: "0px 0px 4px 1px rgba(0,0,0,0.14)",
        position: "relative",
        zIndex: 10,
      }}
    >
      <div
        style={{
          height: 24,
          borderRadius: 16,
          border: "1px solid #bdbdbd",
          position: "relative",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={tab}
            label={tab}
            index={index}
            selectedSwitchPage={selectedSwitchPage}
            setSelectedSwitchPage={setSelectedSwitchPage}
          />
        ))}
        <div
          style={{
            height: 22,
            width: TAB_WIDTH,
            borderRadius: 16,
            backgroundColor: "#5262ba",
            position: "absolute",
            left: selectedSwitchPage * TAB_WIDTH,
            top: 0,
            transition: "250ms",
          }}
        />
      </div>
    </div>
  )
}

const Tab = ({
  label,
  index,
  selectedSwitchPage,
  setSelectedSwitchPage,
}: {
  label: string
  index: number
  selectedSwitchPage: number
  setSelectedSwitchPage: Dispatch<SetStateAction<number>>
}) => {
  return (
    <div
      style={{
        height: 24,
        width: TAB_WIDTH,
        fontSize: 11,
        position: "relative",
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        color: selectedSwitchPage === index ? "white" : null,
        transition: "200ms",
      }}
      onClick={() => {
        setSelectedSwitchPage(index)
      }}
    >
      <Typography
        variant="overline"
        style={{
          fontWeight: 500,
        }}
      >
        {label}
      </Typography>
    </div>
  )
}

export default TeamGeneralEditTabs
