import { CardContent, Stack, TextField, Typography } from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { MainContext } from "../../controllers/main"
import { NewsContext } from "../../controllers/news"
import DataCard from "../global/dataCard"
import { useNavigate } from "react-router-dom"

const NewsGeneralEdit = ({
  currentPreviewLanguage,
  setGlobalPreviewOpen,
}: {
  currentPreviewLanguage: string | null
  setGlobalPreviewOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const navigate = useNavigate()
  const { showPadding } = useContext(MainContext)
  const {
    currentNews,
    setCurrentNews,
    editMode,
    startsAtError,
    setStartsAtError,
    endsAtError,
    setEndsAtError,
  } = useContext(NewsContext)

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Data
        </Typography>
        <Stack direction="row" gap={1}>
          <DataCard
            title={
              currentNews.quizzes && currentNews.quizzes.length
                ? `Quizzes (${currentNews.quizzes.length})`
                : "Quizzes"
            }
            description={
              currentNews.document.items.find((item) => item.isDefault)
                .redirectUrl
                ? "This News cannot have Quizzes assigned because it has a redirect URL set."
                : "Quizzes assigned to this News."
            }
            buttonLabel="Open"
            buttonOnClick={() => {
              navigate(`${window.location.pathname}/quizzes`)
            }}
            buttonDisabled={
              currentNews.document.items.find((item) => item.isDefault)
                .redirectUrl
                ? true
                : false
            }
          />
          <DataCard
            title="Preview"
            description={
              currentPreviewLanguage &&
              currentNews.document.items.find(
                (item) => item.lang === currentPreviewLanguage
              ).redirectUrl
                ? "The preview for this News is not available because it has a redirect URL set."
                : "Preview of how this News appears inside the app."
            }
            buttonLabel="Open"
            buttonDisabled={
              currentPreviewLanguage &&
              currentNews.document.items.find(
                (item) => item.lang === currentPreviewLanguage
              ).redirectUrl
                ? true
                : false
            }
            buttonOnClick={() => {
              setGlobalPreviewOpen(true)
            }}
          />
        </Stack>
        <Typography variant="h6" className="card-title">
          Configuration
        </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            size="small"
            type="date"
            label="Start date"
            value={currentNews.startsAt.slice(
              0,
              currentNews.startsAt.indexOf("T")
            )}
            onChange={(e) => {
              setCurrentNews({
                ...currentNews,
                startsAt: new Date(e.target.value).toISOString(),
              })

              // check for errors
              if (new Date(e.target.value) > new Date(currentNews.endsAt)) {
                setStartsAtError(true)
              } else {
                setStartsAtError(false)
              }
            }}
            disabled={!editMode}
            error={startsAtError}
            helperText={
              startsAtError ? "Start date cannot be after end date" : ""
            }
          />
          <TextField
            fullWidth
            size="small"
            type="date"
            label="End date"
            value={currentNews.endsAt.slice(0, currentNews.endsAt.indexOf("T"))}
            onChange={(e) => {
              setCurrentNews({
                ...currentNews,
                endsAt: new Date(
                  e.target.value + "T21:59:59.000Z"
                ).toISOString(),
              })

              // check for errors
              if (new Date(e.target.value) < new Date(currentNews.startsAt)) {
                setEndsAtError(true)
              } else {
                setEndsAtError(false)
              }
            }}
            disabled={!editMode}
            error={endsAtError}
            helperText={
              endsAtError ? "End date cannot be before start date" : ""
            }
          />
        </Stack>
        {/* <TextField
          size="small"
          type="number"
          label="Points"
          value={currentNews.points ?? ""}
          onChange={(e) => {
            setCurrentNews({
              ...currentNews,
              points: !e.target.value ? null : parseInt(e.target.value),
            })

            // check for errors
            if (e.target.value && parseInt(e.target.value) === 0) {
              setPointsError(true)
            } else {
              setPointsError(false)
            }
          }}
          disabled={!editMode}
          error={pointsError}
          helperText={pointsError ? "Invalid value" : ""}
        /> */}
      </Stack>
    </CardContent>
  )
}

export default NewsGeneralEdit
