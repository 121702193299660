import { Dispatch, SetStateAction, useEffect, useState } from "react"
import {
  mediaConstraints,
  mediaRoomForError,
} from "../../services/config/constants"
import { MediaSize } from "../../services/config/enum"
import { deepCopy, logger, Status } from "../../services/utilities/utility"
import ImageDropzoneMultiple from "../global/imageDropzoneMultiple"

const MultipleUpload = ({
  droppedImages,
  setDroppedImages,
  imageCategory,
  height,
}: {
  droppedImages: {
    name: string
    size: number
    dataUrl: string
    sizeString: MediaSize | null
    hasError: boolean
  }[]
  setDroppedImages: Dispatch<
    SetStateAction<
      {
        name: string
        size: number
        dataUrl: string
        sizeString: MediaSize | null
        hasError: boolean
      }[]
    >
  >
  imageCategory: string
  height: string
}) => {
  // to detect if dropped images has changed
  const [droppedImagesChanged, setDroppedImagesChanged] =
    useState<boolean>(false)

  // calculate dropped images width and height and check constraints
  useEffect(() => {
    if (droppedImages.length && droppedImagesChanged) {
      let droppedImagesCopy: {
        name: string
        size: number
        dataUrl: string
        sizeString: MediaSize | null
        hasError: boolean
      }[] = deepCopy(droppedImages)

      for (let i = 0; i < droppedImagesCopy.length; i++) {
        let img = new Image()
        img.src = droppedImagesCopy[i].dataUrl
        img.onload = () => {
          let size =
            droppedImagesCopy[i].size / 1000 > 999
              ? Math.round(
                  (droppedImagesCopy[i].size / 1000000 + Number.EPSILON) * 10
                ) /
                  10 +
                "mb"
              : Math.round(droppedImagesCopy[i].size / 1000 + Number.EPSILON) +
                "kb"
          let width = img.width
          let height = img.height
          logger(Status.Info, "name", droppedImagesCopy[i].name)
          logger(Status.Info, "size", size)
          logger(Status.Info, "width", width + "px")
          logger(Status.Info, "height", height + "px")

          if (
            (width > mediaConstraints.content.XS.width + mediaRoomForError ||
              width < mediaConstraints.content.XS.width - mediaRoomForError ||
              height > mediaConstraints.content.XS.height + mediaRoomForError ||
              height <
                mediaConstraints.content.XS.height - mediaRoomForError) &&
            (width > mediaConstraints.content.S.width + mediaRoomForError ||
              width < mediaConstraints.content.S.width - mediaRoomForError ||
              height > mediaConstraints.content.S.height + mediaRoomForError ||
              height < mediaConstraints.content.S.height - mediaRoomForError) &&
            (width > mediaConstraints.content.M.width + mediaRoomForError ||
              width < mediaConstraints.content.M.width - mediaRoomForError ||
              height > mediaConstraints.content.M.height + mediaRoomForError ||
              height < mediaConstraints.content.M.height - mediaRoomForError) &&
            (width > mediaConstraints.content.L.width + mediaRoomForError ||
              width < mediaConstraints.content.L.width - mediaRoomForError ||
              height > mediaConstraints.content.L.height + mediaRoomForError ||
              height < mediaConstraints.content.L.height - mediaRoomForError) &&
            (width > mediaConstraints.content.XL.width + mediaRoomForError ||
              width < mediaConstraints.content.XL.width - mediaRoomForError ||
              height > mediaConstraints.content.XL.height + mediaRoomForError ||
              height <
                mediaConstraints.content.XL.height - mediaRoomForError) &&
            (width > mediaConstraints.content.XXL.width + mediaRoomForError ||
              width < mediaConstraints.content.XXL.width - mediaRoomForError ||
              height >
                mediaConstraints.content.XXL.height + mediaRoomForError ||
              height < mediaConstraints.content.XXL.height - mediaRoomForError)
          ) {
            droppedImagesCopy[i].hasError = true
          }

          if (
            width >= mediaConstraints.content.XS.width - mediaRoomForError &&
            width <= mediaConstraints.content.XS.width + mediaRoomForError &&
            height >= mediaConstraints.content.XS.height - mediaRoomForError &&
            height <= mediaConstraints.content.XS.height + mediaRoomForError
          ) {
            droppedImagesCopy[i].sizeString = MediaSize.xs
          } else if (
            width >= mediaConstraints.content.S.width - mediaRoomForError &&
            width <= mediaConstraints.content.S.width + mediaRoomForError &&
            height >= mediaConstraints.content.S.height - mediaRoomForError &&
            height <= mediaConstraints.content.S.height + mediaRoomForError
          ) {
            droppedImagesCopy[i].sizeString = MediaSize.s
          } else if (
            width >= mediaConstraints.content.M.width - mediaRoomForError &&
            width <= mediaConstraints.content.M.width + mediaRoomForError &&
            height >= mediaConstraints.content.M.height - mediaRoomForError &&
            height <= mediaConstraints.content.M.height + mediaRoomForError
          ) {
            droppedImagesCopy[i].sizeString = MediaSize.m
          } else if (
            width >= mediaConstraints.content.L.width - mediaRoomForError &&
            width <= mediaConstraints.content.L.width + mediaRoomForError &&
            height >= mediaConstraints.content.L.height - mediaRoomForError &&
            height <= mediaConstraints.content.L.height + mediaRoomForError
          ) {
            droppedImagesCopy[i].sizeString = MediaSize.l
          } else if (
            width >= mediaConstraints.content.XL.width - mediaRoomForError &&
            width <= mediaConstraints.content.XL.width + mediaRoomForError &&
            height >= mediaConstraints.content.XL.height - mediaRoomForError &&
            height <= mediaConstraints.content.XL.height + mediaRoomForError
          ) {
            droppedImagesCopy[i].sizeString = MediaSize.xl
          } else if (
            width >= mediaConstraints.content.XXL.width - mediaRoomForError &&
            width <= mediaConstraints.content.XXL.width + mediaRoomForError &&
            height >= mediaConstraints.content.XXL.height - mediaRoomForError &&
            height <= mediaConstraints.content.XXL.height + mediaRoomForError
          ) {
            droppedImagesCopy[i].sizeString = MediaSize.xxl
          }
        }
      }

      setDroppedImages(droppedImagesCopy)
      setDroppedImagesChanged(false)
    }
  }, [droppedImagesChanged])

  return (
    <ImageDropzoneMultiple
      title="Media"
      droppedImages={droppedImages}
      setDroppedImages={setDroppedImages}
      height={height}
      disabled={imageCategory ? false : true}
      disabledText="Select type first"
      setDroppedImagesChanged={setDroppedImagesChanged}
      style={{ minHeight: height }}
    />
  )
}

export default MultipleUpload
