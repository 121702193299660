import {
  Button,
  Card,
  CardContent,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction, useContext, useMemo } from "react"
import { TeamsContext } from "../../controllers/teams"
import { AddCircleRounded, CancelRounded } from "@mui/icons-material"
import { generateRandomString } from "easy-ts-utils"
import GroupCard from "./groupCard"

const TeamGeneralEditGroupsTab = ({
  searchGroupsValue,
  setSearchGroupsValue,
}: {
  searchGroupsValue: string
  setSearchGroupsValue: Dispatch<SetStateAction<string>>
}) => {
  const { currentTeam, setCurrentTeam, editMode } = useContext(TeamsContext)

  // search groups
  const groupsListMemo = useMemo(() => {
    if (searchGroupsValue) {
      return currentTeam.groups.filter((item) =>
        item.name.toLowerCase().includes(searchGroupsValue.toLowerCase())
      )
    }

    return currentTeam.groups
  }, [currentTeam, searchGroupsValue])

  return (
    <Stack spacing={2}>
      <Typography variant="h6" className="card-title">
        Groups
      </Typography>
      {!currentTeam.groups.length ? (
        <Typography>No groups for this Team</Typography>
      ) : null}
      {currentTeam.groups.length ? (
        <Stack>
          <Card
            style={{
              border: "1px solid #E5E8EB",
              borderBottomLeftRadius: !groupsListMemo.length ? undefined : 0,
              borderBottomRightRadius: !groupsListMemo.length ? undefined : 0,
              borderBottom: !groupsListMemo.length
                ? "1px solid #E5E8EB"
                : "none",
              boxShadow: "none",
            }}
          >
            <CardContent style={{ padding: "12px 24px" }}>
              <TextField
                fullWidth
                size="small"
                label="Search Groups"
                value={searchGroupsValue}
                onChange={(e) => {
                  setSearchGroupsValue(e.target.value)
                }}
                InputProps={{
                  endAdornment: searchGroupsValue.length > 0 && (
                    <InputAdornment position="start">
                      <div
                        style={{
                          marginTop: 6,
                          marginRight: -6,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSearchGroupsValue("")
                        }}
                      >
                        <CancelRounded style={{ width: 18 }} />
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </CardContent>
          </Card>
          {!groupsListMemo.length ? (
            <Typography style={{ marginTop: 16 }}>
              No results for "{searchGroupsValue}"
            </Typography>
          ) : null}
          {groupsListMemo.map((group, index) => (
            <GroupCard
              key={group.groupId}
              group={group}
              index={index}
              groupsListMemo={groupsListMemo}
            />
          ))}
        </Stack>
      ) : null}
      <Button
        fullWidth
        disabled={!editMode}
        endIcon={<AddCircleRounded />}
        variant="outlined"
        style={{
          maxHeight: 40,
          minHeight: 40,
        }}
        onClick={() => {
          const id = "temp-" + generateRandomString(16, "alphanumeric")

          currentTeam.groups.push({
            name: "New Group",
            groupId: id,
          })

          setCurrentTeam({ ...currentTeam })
        }}
      >
        Add group
      </Button>
    </Stack>
  )
}

export default TeamGeneralEditGroupsTab
