import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import EditContainer from "../../components/global/editContainer"
import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../controllers/main"
import { Entity } from "../../services/config/interfaces"
import { OpenInNewRounded } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { ActivationCodesContext } from "../../controllers/activationCodes"
import { Status, logger } from "../../services/utilities/utility"
import { useLazyQuery } from "@apollo/client"
import { challenge, teamGet } from "../../services/graphql/queries"
import ActivationCode from "../../models/activationCode"
import Team from "../../models/team"
import Challenge from "../../models/challenge"
import { useSearchParams } from "react-router-dom"

const Tools = () => {
  const [searchParams] = useSearchParams()
  const { animation, showPadding, setError, setErrorMessage } =
    useContext(MainContext)
  const { getActivationCode } = useContext(ActivationCodesContext)

  // entity to search by id
  const [entity, setEntity] = useState<Entity>({
    name: "Team",
    url: "/teams",
    idType: "uuidv4",
  })
  const [id, setId] = useState<string>("")
  const [isIdValid, setIsIdValid] = useState<boolean>(false)

  const entities: Entity[] = [
    {
      name: "Team",
      url: "/teams",
      idType: "uuidv4",
    },
    {
      name: "Episode",
      url: "/episodes",
      idType: "uuidv4",
    },
    {
      name: "Journey",
      url: "/journeys",
      idType: "uuidv4",
    },
    {
      name: "Business release",
      url: "/teamsjourneys",
      idType: "uid",
    },
    {
      name: "Action",
      url: "/actions",
      idType: "uuidv4",
    },
    {
      name: "Series",
      url: "/series",
      idType: "uuidv4",
    },
    {
      name: "Badge",
      url: "/badges",
      idType: "uuidv4",
    },
    {
      name: "News",
      url: "/news",
      idType: "uuidv4",
    },
    {
      name: "Challenge",
      url: "/challenges",
      idType: "uuidv4",
    },
    {
      name: "Category",
      url: "/tags/category",
      idType: "category",
    },
    {
      name: "Main Category",
      url: "/tags/maincategory",
      idType: "uuidv4",
    },
    {
      name: "SDG",
      url: "/tags/sdg",
      idType: "sdg",
    },
    {
      name: "SDG Target",
      url: "/tags/sdgtarget",
      idType: "sdgTarget",
    },
    {
      name: "Topic",
      url: "/tags/topic",
      idType: "uuidv4",
    },
    {
      name: "Channel",
      url: "/channels",
      idType: "uuidv4",
    },
  ]

  // check if id is valid when it changes
  useEffect(() => {
    if (entity.idType === "uuidv4") {
      const regex = new RegExp(
        /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89aB][0-9a-f]{3}-[0-9a-f]{12}$/i
      )
      if (regex.test(id)) {
        setIsIdValid(true)
      } else {
        setIsIdValid(false)
      }
    } else if (entity.idType === "uid") {
      const regex = new RegExp(/^[a-z0-9]+(?:-[a-z0-9]+)*$/)
      if (regex.test(id)) {
        setIsIdValid(true)
      } else {
        setIsIdValid(false)
      }
    } else if (entity.idType === "category") {
      const regex = new RegExp(/^[A-Za-z]+$/)
      if (regex.test(id) && id.includes("cat")) {
        setIsIdValid(true)
      } else {
        setIsIdValid(false)
      }
    } else if (entity.idType === "sdg") {
      const regex = new RegExp(/^[a-z0-9#]+$/)
      if (
        regex.test(id) &&
        id.includes("#") &&
        id.indexOf("#") === id.lastIndexOf("#")
      ) {
        setIsIdValid(true)
      } else {
        setIsIdValid(false)
      }
    } else if (entity.idType === "sdgTarget") {
      const regex = new RegExp(/^[a-z0-9.#]+$/)
      if (
        regex.test(id) &&
        id.includes("#") &&
        id.indexOf("#") === id.lastIndexOf("#")
      ) {
        setIsIdValid(true)
      } else {
        setIsIdValid(false)
      }
    }
  }, [id, entity])

  // activation code
  const [activationCode, setActivationCode] = useState<string>(
    searchParams.get("code") ?? ""
  )
  const [activationCodeError, setActivationCodeError] = useState<boolean>(false)
  const [activationCodeLoading, setActivationCodeLoading] =
    useState<boolean>(false)

  // get code info
  const [fetchedCode, setFetchedCode] = useState<ActivationCode | null>(null)
  const [fetchedTeam, setFetchedTeam] = useState<Team | null>(null)
  const [fetchedChallenge, setFetchedChallenge] = useState<Challenge | null>(
    null
  )

  const [teamGetQuery] = useLazyQuery(teamGet)
  const [challengeGetQuery] = useLazyQuery(challenge)

  const getCodeInfo = async () => {
    setFetchedCode(null)
    setFetchedTeam(null)
    setFetchedChallenge(null)
    setActivationCodeLoading(true)
    setActivationCodeError(false)

    const result = await getActivationCode(activationCode)

    if (!result) {
      setActivationCodeError(true)
    } else if (result && typeof result !== "boolean") {
      if (
        result.teamId ||
        (result.challengeId && result.challengeId !== "challenge_default")
      ) {
        try {
          if (
            result.teamId &&
            result.challengeId &&
            result.challengeId !== "challenge_default"
          ) {
            // both team and challenge
            logger(Status.Api, "QUERY teamGet", teamGet)
            logger(Status.Api, "QUERY challengeGet", challenge)
            const secondResult = await Promise.all([
              teamGetQuery({
                variables: { input: { id: result.teamId } },
                fetchPolicy: "no-cache",
              }),
              challengeGetQuery({
                variables: { input: { id: result.challengeId } },
                fetchPolicy: "no-cache",
              }),
            ])
            logger(
              Status.Info,
              `team ${result.teamId}`,
              secondResult[0].data.teamGet
            )
            logger(
              Status.Info,
              `challenge ${result.challengeId}`,
              secondResult[1].data.challengeGet
            )

            setFetchedTeam(secondResult[0].data.teamGet)
            setFetchedChallenge(secondResult[1].data.challengeGet)
          } else if (result.teamId) {
            // team only
            logger(Status.Api, "QUERY teamGet", teamGet)
            const { data } = await teamGetQuery({
              variables: { input: { id: result.teamId } },
              fetchPolicy: "no-cache",
            })
            logger(Status.Info, `team ${result.teamId}`, data.teamGet)

            setFetchedTeam(data.teamGet)
          } else if (
            result.challengeId &&
            result.challengeId !== "challenge_default"
          ) {
            // challenge only
            logger(Status.Api, "QUERY challengeGet", challenge)
            const { data } = await challengeGetQuery({
              variables: { input: { id: result.challengeId } },
              fetchPolicy: "no-cache",
            })
            logger(
              Status.Info,
              `challenge ${result.challengeId}`,
              data.challengeGet
            )

            setFetchedChallenge(data.challengeGet)
          }
        } catch (e) {
          if (e instanceof Error) {
            setError(true)
            setErrorMessage(e.message)
            logger(Status.Error, `teamGet or challengeGet`, e.message)
          }
        }
      }

      setFetchedCode(result)
    }

    setActivationCodeLoading(false)
  }

  // get code info right away if code query param is present
  useEffect(() => {
    if (searchParams.get("code")) {
      getCodeInfo()
    }
  }, [])

  return (
    <EditContainer style={{ paddingTop: 16 }}>
      <Grow
        in={animation}
        timeout={300}
        style={{ margin: showPadding === "yes" ? 16 : 0, marginTop: 0 }}
      >
        <Stack spacing={2}>
          {/* search by id */}
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Typography variant="h6" className="card-title">
                  Search by ID
                </Typography>
                <Stack direction="row" spacing={1}>
                  <FormControl size="small" style={{ width: 250 }}>
                    <InputLabel id="Type">Type</InputLabel>
                    <Select
                      labelId="Type"
                      label="Type"
                      value={entity.name}
                      onChange={(e) => {
                        setEntity(
                          entities.filter(
                            (item) => item.name === e.target.value
                          )[0]
                        )
                      }}
                    >
                      {entities.map((item) => (
                        <MenuItem key={item.name} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    className="search-by-id-text-field"
                    label="ID"
                    fullWidth
                    size="small"
                    value={id}
                    onChange={(e) => {
                      setId(e.target.value)
                    }}
                    helperText={
                      id.length && !isIdValid
                        ? "Invalid ID for the selected type"
                        : ""
                    }
                    error={id.length && !isIdValid ? true : false}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && isIdValid) {
                        setId("")
                        if (id.includes("#")) {
                          window.open(entity.url + "/" + encodeURIComponent(id))
                        } else {
                          window.open(entity.url + "/" + id)
                        }
                      }
                    }}
                  />
                  <Button
                    variant="contained"
                    endIcon={<OpenInNewRounded style={{ fontSize: 16 }} />}
                    style={{ width: 100 }}
                    disabled={!isIdValid}
                    onClick={() => {
                      setId("")
                      if (id.includes("#")) {
                        window.open(entity.url + "/" + encodeURIComponent(id))
                      } else {
                        window.open(entity.url + "/" + id)
                      }
                    }}
                  >
                    Open
                  </Button>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
          {/* get team activation code info */}
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Typography variant="h6" className="card-title">
                  Get activation code info
                </Typography>
                <Stack direction="row" spacing={1}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Activation code"
                    value={activationCode}
                    onChange={(e) => {
                      setActivationCodeError(false)
                      setFetchedCode(null)
                      setFetchedTeam(null)
                      setFetchedChallenge(null)
                      setActivationCode(e.target.value.toUpperCase())
                    }}
                    inputProps={{ maxLength: 6 }}
                    error={activationCodeError}
                    helperText={activationCodeError ? "Invalid code" : null}
                    onKeyDown={(e) => {
                      if (
                        e.key === "Enter" &&
                        activationCode.length >= 5 &&
                        !activationCodeLoading
                      ) {
                        getCodeInfo()
                      }
                    }}
                  />
                  <LoadingButton
                    variant="contained"
                    loading={activationCodeLoading}
                    style={{ minWidth: 85, maxHeight: 40 }}
                    disabled={activationCode.length < 5}
                    onClick={getCodeInfo}
                  >
                    Get info
                  </LoadingButton>
                </Stack>
                {fetchedCode ? (
                  <Stack>
                    <Typography>
                      <span style={{ fontWeight: 600 }}>Code type</span> ▸{" "}
                      {
                        fetchedCode.codeType
                          .replace(/([a-z])([A-Z])/g, "$1 $2")
                          .split(" ")[0]
                      }
                    </Typography>
                    {fetchedCode.wrappedCodes &&
                    fetchedCode.wrappedCodes.length ? (
                      <Stack direction="row" gap={0.5}>
                        <Typography style={{ fontWeight: 600 }}>
                          Wrapped codes
                        </Typography>{" "}
                        ▸{" "}
                        {fetchedCode.wrappedCodes.map((item, index, list) => (
                          <Stack direction="row" key={index}>
                            <Typography>{item}</Typography>
                            <IconButton
                              style={{ width: 24, height: 24, marginLeft: 4 }}
                              onClick={() => {
                                window.open(
                                  window.location.origin + "/tools?code=" + item
                                )
                              }}
                            >
                              <OpenInNewRounded style={{ fontSize: 14 }} />
                            </IconButton>
                            <Typography>
                              {index === list.length - 1 ? "" : ", "}
                            </Typography>
                          </Stack>
                        ))}
                      </Stack>
                    ) : null}
                    {fetchedTeam ? (
                      <Stack direction="row" spacing={0.5}>
                        <Typography>
                          <span style={{ fontWeight: 600 }}>Team</span> ▸{" "}
                          {fetchedTeam.name}
                        </Typography>
                        <IconButton
                          style={{ width: 24, height: 24 }}
                          onClick={() => {
                            window.open(
                              window.location.origin +
                                "/teams/" +
                                fetchedTeam.id
                            )
                          }}
                        >
                          <OpenInNewRounded style={{ fontSize: 14 }} />
                        </IconButton>
                      </Stack>
                    ) : null}
                    {fetchedTeam &&
                    fetchedCode.groupId &&
                    fetchedCode.groupId !== "group_default" ? (
                      <Typography>
                        <span style={{ fontWeight: 600 }}>Group</span> ▸{" "}
                        {
                          fetchedTeam.groups.find(
                            (item) => item.groupId === fetchedCode.groupId
                          ).name
                        }
                      </Typography>
                    ) : null}
                    {fetchedChallenge ? (
                      <Stack direction="row" spacing={0.5}>
                        <Typography>
                          <span style={{ fontWeight: 600 }}>Challenge</span> ▸{" "}
                          {fetchedChallenge.name}
                        </Typography>
                        <IconButton
                          style={{ width: 24, height: 24 }}
                          onClick={() => {
                            window.open(
                              window.location.origin +
                                "/challenges/" +
                                fetchedChallenge.id
                            )
                          }}
                        >
                          <OpenInNewRounded style={{ fontSize: 14 }} />
                        </IconButton>
                      </Stack>
                    ) : null}
                    {fetchedCode.domainsWhiteList &&
                    fetchedCode.domainsWhiteList.length ? (
                      <Typography>
                        <span style={{ fontWeight: 600 }}>
                          Domains whitelist
                        </span>{" "}
                        ▸{" "}
                        {fetchedCode.domainsWhiteList.map((item, index, list) =>
                          index === list.length - 1 ? item : item + ", "
                        )}
                      </Typography>
                    ) : null}
                    <Typography>
                      <span style={{ fontWeight: 600 }}>Code policy</span> ▸{" "}
                      {fetchedCode.policy}
                    </Typography>
                  </Stack>
                ) : null}
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Grow>
    </EditContainer>
  )
}

export default Tools
