import {
  Button,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { MainContext } from "../../controllers/main"
import { NewsQuizzesContext } from "../../controllers/newsQuizzes"
import NewsQuizDocument from "../../models/newsQuizDocument"

const NewsQuizTranslationsEdit = ({ itemIndex }: { itemIndex: number }) => {
  const { showPadding } = useContext(MainContext)
  const {
    currentNewsQuiz,
    setCurrentNewsQuiz,
    editMode,
    translationsErrors,
    setTranslationsErrors,
    copyDetailsFromDefault,
  } = useContext(NewsQuizzesContext)

  // current item
  const currentItem = currentNewsQuiz.document.items[itemIndex]

  // errors managment
  const [questionError, setQuestionError] = useState<boolean>(false)
  const [optionsError, setOptionsError] = useState<boolean>(false)
  const [correctAnswerError, setCorrectAnswerError] = useState<boolean>(false)

  // update translations errors on errors change
  useEffect(() => {
    if (questionError || optionsError || correctAnswerError) {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = true
    } else {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = false
    }

    setTranslationsErrors([...translationsErrors])
  }, [questionError, optionsError, correctAnswerError])

  // update errors on currentItem change
  useEffect(() => {
    if (!currentItem.question) {
      setQuestionError(true)
    } else {
      setQuestionError(false)
    }

    if (!currentItem.opt1 || !currentItem.opt2) {
      setOptionsError(true)
    } else {
      setOptionsError(false)
    }

    const correctAnswerIndex = parseInt(currentItem.correctAnswer.slice(3))
    if (!currentItem[`opt${correctAnswerIndex}`]) {
      setCorrectAnswerError(true)
    } else {
      setCorrectAnswerError(false)
    }
  }, [currentItem])

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" style={{ height: 28 }}>
          <Typography variant="h6" className="card-title">
            Details
          </Typography>
          <Stack
            alignItems="center"
            justifyContent="flex-end"
            style={{ width: "100%" }}
            direction="row"
            spacing={2}
          >
            <FormGroup style={{ marginRight: -16 }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editMode}
                    checked={currentItem.isDefault}
                    onChange={(e) => {
                      currentItem.isDefault = e.target.checked
                      if (e.target.checked) {
                        currentNewsQuiz.document.items.forEach(
                          (item, index) => {
                            if (index !== itemIndex) {
                              item.isDefault = false
                            }
                          }
                        )
                      }
                      setCurrentNewsQuiz({ ...currentNewsQuiz })
                    }}
                  />
                }
                label="Default"
              />
            </FormGroup>
            <Divider orientation="vertical" flexItem />
            <Button
              size="small"
              variant="outlined"
              style={{ height: 26, fontSize: 12 }}
              onClick={() => {
                copyDetailsFromDefault(itemIndex)
              }}
              disabled={
                currentItem.isDefault ||
                !editMode ||
                !currentNewsQuiz.document.items.filter((item) => item.isDefault)
                  .length
              }
            >
              Copy from default
            </Button>
          </Stack>
        </Stack>
        <Stack gap={2}>
          <TextField
            fullWidth
            size="small"
            label="Question"
            value={currentItem.question}
            onChange={(e) => {
              currentItem.question = e.target.value
              setCurrentNewsQuiz({ ...currentNewsQuiz })

              // errors check
              if (!e.target.value) {
                setQuestionError(true)
              } else {
                setQuestionError(false)
              }
            }}
            disabled={!editMode}
            error={questionError}
            helperText={questionError ? "This field is required" : ""}
            onBlur={() => {
              currentItem.question = currentItem.question.trim()
              setCurrentNewsQuiz({ ...currentNewsQuiz })
            }}
          />
          <Grid container columns={2} spacing={2}>
            {Array(4)
              .fill("")
              .map((_, index) => (
                <Grid key={index} item xs={1}>
                  <OptionTextField
                    currentItem={currentItem}
                    index={index + 1}
                    setOptionsError={setOptionsError}
                    setCorrectAnswerError={setCorrectAnswerError}
                  />
                </Grid>
              ))}
          </Grid>
          <FormControl
            disabled={!editMode}
            fullWidth
            size="small"
            error={correctAnswerError}
          >
            <InputLabel id="correct-answer">Correct answer</InputLabel>
            <Select
              labelId="correct-answer"
              label="Correct answer"
              value={currentItem.correctAnswer}
              onChange={(e) => {
                currentItem.correctAnswer = e.target.value
                setCurrentNewsQuiz({ ...currentNewsQuiz })

                // errors check
                if (!currentItem[e.target.value]) {
                  setCorrectAnswerError(true)
                } else {
                  setCorrectAnswerError(false)
                }
              }}
            >
              <MenuItem value="opt1">1</MenuItem>
              <MenuItem value="opt2">2</MenuItem>
              <MenuItem value="opt3">3</MenuItem>
              <MenuItem value="opt4">4</MenuItem>
            </Select>
            {correctAnswerError && (
              <FormHelperText>Select a valid answer</FormHelperText>
            )}
          </FormControl>
          <TextField
            fullWidth
            size="small"
            label="Explanation"
            value={currentItem.explanation ?? ""}
            onChange={(e) => {
              currentItem.explanation = e.target.value
              setCurrentNewsQuiz({ ...currentNewsQuiz })
            }}
            disabled={!editMode}
            onBlur={() => {
              if (currentItem.explanation) {
                currentItem.explanation = currentItem.explanation.trim()
                setCurrentNewsQuiz({ ...currentNewsQuiz })
              }
            }}
          />
        </Stack>
      </Stack>
    </CardContent>
  )
}

const OptionTextField = ({
  currentItem,
  index,
  setOptionsError,
  setCorrectAnswerError,
}: {
  currentItem: NewsQuizDocument
  index: number
  setOptionsError: Dispatch<SetStateAction<boolean>>
  setCorrectAnswerError: Dispatch<SetStateAction<boolean>>
}) => {
  const { currentNewsQuiz, setCurrentNewsQuiz, editMode } =
    useContext(NewsQuizzesContext)

  return (
    <TextField
      fullWidth
      size="small"
      label={`Answer ${index}`}
      value={currentItem[`opt${index}`]}
      onChange={(e) => {
        currentItem[`opt${index}`] = e.target.value
        setCurrentNewsQuiz({ ...currentNewsQuiz })

        // errors check
        if (!currentItem.opt1 || !currentItem.opt2) {
          setOptionsError(true)
        } else {
          setOptionsError(false)
        }

        const correctAnswerIndex = parseInt(currentItem.correctAnswer.slice(3))
        if (index === correctAnswerIndex && !currentItem[`opt${index}`]) {
          setCorrectAnswerError(true)
        } else {
          setCorrectAnswerError(false)
        }
      }}
      disabled={!editMode}
      error={
        (index === 1 && !currentItem.opt1) || (index === 2 && !currentItem.opt2)
      }
      helperText={
        (index === 1 && !currentItem.opt1) || (index === 2 && !currentItem.opt2)
          ? "This field is required"
          : ""
      }
      onBlur={() => {
        currentItem[`opt${index}`] = currentItem[`opt${index}`].trim()
        setCurrentNewsQuiz({ ...currentNewsQuiz })
      }}
    />
  )
}

export default NewsQuizTranslationsEdit
