import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import DataCard from "../global/dataCard"
import { TeamsContext } from "../../controllers/teams"
import { capitalizeFirstCharacter, enumAsArray } from "easy-ts-utils"
import {
  ActivationCodePolicy,
  ActivationCodeType,
  TeamAccessType,
  TeamOrganizationType,
  TeamStage,
  TeamStakeholderType,
} from "../../services/config/enum"
import {
  AddCircleRounded,
  ArrowForwardIosRounded,
  ChevronLeftRounded,
  ContentCopyRounded,
  DeleteForeverRounded,
  InfoRounded,
} from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import {
  copyTextToClipboard,
  deepCopy,
  DialogTransition,
  splitCamelCase,
} from "../../services/utilities/utility"
import Team from "../../models/team"
import TeamContractCard from "./teamContractCard"
import { LocationAutocompleteOption } from "../../services/config/interfaces"
import { CountriesContext } from "../../controllers/countries"
import CantPerformActionDialog from "../global/cantPerformActionDialog"
import CreateContractDialog from "./createContractDialog"
import { LoadingButton } from "@mui/lab"
import { ActivationCodesContext } from "../../controllers/activationCodes"
import { admins } from "../../services/config/constants"

const TeamGeneralEditGeneralTab = ({
  setFeedbackOpen,
  setGlobalPreviewOpen,
}: {
  setFeedbackOpen: Dispatch<SetStateAction<boolean>>
  setGlobalPreviewOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { setAnimation, user } = useContext(MainContext)
  const {
    currentTeam,
    setCurrentTeam,
    editMode,
    nameError,
    setNameError,
    currentTeamJourneysList,
    currentTeamNewsList,
    currentTeamChallengesList,
    currentTeamContractsList,
  } = useContext(TeamsContext)
  const {
    createActivationCode,
    loading: createActivationCodeLoading,
    deleteActivationCode,
  } = useContext(ActivationCodesContext)
  const {
    regionsList,
    subRegionsList,
    countriesList,
    loading: countriesControllerLoading,
  } = useContext(CountriesContext)
  const navigate = useNavigate()

  // if show only active contract or all contracts
  const [showAllContracts, setShowAllContracts] = useState<boolean>(false)

  // is primary alert
  const [isPrimaryAlertOpen, setIsPrimaryAlertOpen] = useState<boolean>(false)

  // contract incomplete alert (missing sellingPrice or targetMembers from current contract)
  const [contractIncompleteAlertOpen, setContractIncompleteAlertOpen] =
    useState<boolean>(false)

  // stage info dialog
  const [stageInfoDialogOpen, setStageInfoDialogOpen] = useState<boolean>(false)

  // cannot be home featured (missing sellingPrice or targetMembers from current contract or home document)
  const [cannotBeHomeFeaturedAlertOpen, setCannotBeHomeFeaturedAlertOpen] =
    useState<boolean>(false)

  // home featured info dialog
  const [homeFeaturedInfoDialogOpen, setHomeFeaturedInfoDialogOpen] =
    useState<boolean>(false)

  // add contract dialog
  const [addContractDialogOpen, setAddContractDialogOpen] =
    useState<boolean>(false)

  const handleAddContractDialogChange = () => {
    setAddContractDialogOpen((current) => !current)
  }

  // activation code dialog
  const [activationCodeDialogOpen, setActivationCodeDialogOpen] =
    useState<boolean>(false)

  const handleActivationCodeDialogChange = () => {
    setActivationCodeDialogOpen((current) => !current)
  }

  // delete activation code
  const [codeToDelete, setCodeToDelete] = useState<string | null>(null)
  const [deletingCode, setDeletingCode] = useState<boolean>(false)
  const [deleteCodeDialogOpen, setDeleteCodeDialogOpen] =
    useState<boolean>(false)

  const handleDeleteCodeDialogChange = () => {
    setDeleteCodeDialogOpen((current) => !current)
  }

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Data
        </Typography>
        <Stack direction="row" gap={1}>
          <DataCard
            title="Info"
            htmlDescription={`
              <p style="line-height: 1.3"><span style="font-weight: 700; font-size: 1rem">${
                currentTeam.userCount
              }</span> <span style="font-weight: 300; font-size: 0.9rem">members and </span>
              <span style="font-weight: 700; font-size: 1rem">${
                currentTeam.groups.length
              }</span> <span style="font-weight: 300; font-size: 0.9rem">${
              currentTeam.groups.length === 1 ? "group" : "groups"
            }</span></p>
              <p style="line-height: 1.3"><span style="font-weight: 700; font-size: 1rem">${
                currentTeamJourneysList.length
              }</span> <span style="font-weight: 300; font-size: 0.9rem">${
              currentTeamJourneysList.length === 1 ? "Journey" : "Journeys"
            }</span></p>
              <p style="line-height: 1.3"><span style="font-weight: 700; font-size: 1rem">${
                currentTeamNewsList.length
              }</span> <span style="font-weight: 300; font-size: 0.9rem">News</span></p>
              <p style="line-height: 1.3"><span style="font-weight: 700; font-size: 1rem">${
                currentTeamChallengesList.length
              }</span> <span style="font-weight: 300; font-size: 0.9rem">${
              currentTeamChallengesList.length === 1
                ? "Challenge"
                : "Challenges"
            }</span></p>
              `}
          />
          <DataCard
            title="Preview"
            description="Preview of how this Team appears inside the app."
            buttonLabel="Open"
            buttonOnClick={() => {
              setGlobalPreviewOpen(true)
            }}
          />
          <DataCard
            title="Members"
            description="List of all members and administrators of this Team."
            buttonOnClick={() => {
              setAnimation(false)
              setTimeout(() => {
                navigate(window.location.pathname + "/members")
              }, 250)
            }}
          />
          {user && admins.includes(user.email) ? (
            <DataCard
              title="Report"
              description="Report of this Team."
              buttonOnClick={() => {
                setAnimation(false)
                setTimeout(() => {
                  navigate(window.location.pathname + "/reportnew")
                }, 250)
              }}
            />
          ) : null}
          {user && admins.includes(user.email) ? (
            <DataCard
              title="Old Report"
              description="Old Looker Studio report of this Team."
              buttonOnClick={() => {
                setAnimation(false)
                setTimeout(() => {
                  navigate(window.location.pathname + "/report")
                }, 250)
              }}
            />
          ) : null}
        </Stack>
        <Typography variant="h6" className="card-title">
          Configuration
        </Typography>
        <Stack direction="row" spacing={1}>
          <TextField
            fullWidth
            size="small"
            label="Name"
            value={currentTeam.name}
            onChange={(e) => {
              currentTeam.name = e.target.value
              setCurrentTeam({ ...currentTeam })

              // errors check
              if (!e.target.value) {
                setNameError(true)
              } else {
                setNameError(false)
              }
            }}
            disabled={!editMode}
            error={nameError}
            helperText={nameError ? "This field is required" : ""}
          />
          <TextField
            fullWidth
            size="small"
            label="UID"
            value={currentTeam.uid}
            InputProps={{ readOnly: true }}
            disabled={!editMode}
          />
          <TextField
            fullWidth
            size="small"
            label="Old ID"
            value={currentTeam.oldId ?? "none"}
            InputProps={{
              readOnly: true,
              style: { color: currentTeam.oldId ? undefined : "#939EAA" },
            }}
            disabled={!editMode}
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          <FormControl fullWidth size="small">
            <InputLabel id="access-type">Access Type</InputLabel>
            <Select
              labelId="access-type"
              label="Access Type"
              value={currentTeam.accessType}
              onChange={(e) => {
                currentTeam.accessType = e.target.value as TeamAccessType
                setCurrentTeam({ ...currentTeam })
              }}
            >
              {enumAsArray(TeamAccessType).map((item: string, index) => (
                <MenuItem key={index} value={item}>
                  {item === TeamAccessType.public
                    ? "Public"
                    : item === TeamAccessType.sso
                    ? "SSO"
                    : item === TeamAccessType.activationCode
                    ? "Activation Code"
                    : capitalizeFirstCharacter(item)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="is-primary-select">Primary</InputLabel>
            <Select
              labelId="is-primary-select"
              label="Primary"
              value={currentTeam.isPrimary ? "yes" : "no"}
              onChange={() => {
                setIsPrimaryAlertOpen(true)
              }}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={1}>
          <FormControl
            fullWidth
            size="small"
            disabled={!editMode}
            style={{ position: "relative" }}
          >
            <InputLabel id="stage">Stage</InputLabel>
            <Select
              labelId="stage"
              label="Stage"
              value={currentTeam.stage}
              onChange={(e) => {
                // check if the current team contract has sellingPrice and targetMembers set
                const currentContract = currentTeamContractsList.find(
                  (item) =>
                    Math.max(
                      ...currentTeamContractsList.map((item) =>
                        new Date(item.activatedAt).getTime()
                      )
                    ) === new Date(item.activatedAt).getTime()
                )

                if (
                  (currentContract &&
                    currentContract.sellingPrice &&
                    currentContract.targetMembers) ||
                  e.target.value === TeamStage.backstage
                ) {
                  currentTeam.stage = e.target.value as TeamStage
                  setCurrentTeam({ ...currentTeam })
                } else {
                  setContractIncompleteAlertOpen(true)
                }
              }}
            >
              {enumAsArray(TeamStage).map((item: string, index) => (
                <MenuItem key={index} value={item}>
                  {capitalizeFirstCharacter(splitCamelCase(item))}
                </MenuItem>
              ))}
            </Select>
            <IconButton
              style={{
                position: "absolute",
                top: 4,
                right: 24,
              }}
              onClick={() => {
                setStageInfoDialogOpen(true)
              }}
            >
              <InfoRounded style={{ fontSize: 16 }} />
            </IconButton>
          </FormControl>
          {currentTeam.stage === TeamStage.featured ? (
            <TextField
              fullWidth
              size="small"
              label="Sorting"
              value={currentTeam.featuredSorting ?? ""}
              onChange={(e) => {
                if (e.target.value) {
                  currentTeam.featuredSorting = parseInt(e.target.value)
                } else {
                  currentTeam.featuredSorting = null
                }
                setCurrentTeam({ ...currentTeam })
              }}
              type="number"
              inputMode="numeric"
            />
          ) : null}
          <FormControl
            fullWidth
            size="small"
            disabled={!editMode}
            style={{ position: "relative" }}
          >
            <InputLabel id="is-home-featured-select">Home featured</InputLabel>
            <Select
              labelId="is-home-featured-select"
              label="Home featured"
              value={currentTeam.isHomeFeatured ? "yes" : "no"}
              onChange={(e) => {
                // check if the current team contract has sellingPrice and targetMembers set and if document has home
                const currentContract = currentTeamContractsList.find(
                  (item) =>
                    Math.max(
                      ...currentTeamContractsList.map((item) =>
                        new Date(item.activatedAt).getTime()
                      )
                    ) === new Date(item.activatedAt).getTime()
                )

                const defaultLangDocument = currentTeam.document.items.find(
                  (item) => item.isDefault
                )

                if (
                  (currentContract &&
                    currentContract.sellingPrice &&
                    currentContract.targetMembers &&
                    defaultLangDocument.home &&
                    defaultLangDocument.home.title &&
                    defaultLangDocument.home.subtitle &&
                    defaultLangDocument.home.image) ||
                  e.target.value === "no"
                ) {
                  setCurrentTeam((current) => {
                    return {
                      ...current,
                      isHomeFeatured: e.target.value === "yes",
                    }
                  })
                } else {
                  setCannotBeHomeFeaturedAlertOpen(true)
                }
              }}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
            <IconButton
              style={{
                position: "absolute",
                top: 4,
                right: 24,
              }}
              onClick={() => {
                setHomeFeaturedInfoDialogOpen(true)
              }}
            >
              <InfoRounded style={{ fontSize: 16 }} />
            </IconButton>
          </FormControl>
          {currentTeam.isHomeFeatured ? (
            <TextField
              fullWidth
              size="small"
              label="Home sorting"
              value={currentTeam.homeFeaturedSorting ?? ""}
              onChange={(e) => {
                if (e.target.value) {
                  currentTeam.homeFeaturedSorting = parseInt(e.target.value)
                } else {
                  currentTeam.homeFeaturedSorting = null
                }
                setCurrentTeam({ ...currentTeam })
              }}
              type="number"
              inputMode="numeric"
            />
          ) : null}
        </Stack>
        <Stack direction="row" spacing={1}>
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="organization-type">Organization Type</InputLabel>
            <Select
              labelId="organization-type"
              label="Organization Type"
              value={currentTeam.organizationType}
              onChange={(e) => {
                currentTeam.organizationType = e.target
                  .value as TeamOrganizationType
                setCurrentTeam({ ...currentTeam })
              }}
            >
              {enumAsArray(TeamOrganizationType).map((item: string, index) => (
                <MenuItem key={index} value={item}>
                  {capitalizeFirstCharacter(
                    item.replace(/([a-z0-9])([A-Z])/g, "$1 $2").toLowerCase()
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="stakeholder-type">Stakeholder Type</InputLabel>
            <Select
              labelId="stakeholder-type"
              label="Stakeholder Type"
              value={currentTeam.stakeholderType}
              onChange={(e) => {
                currentTeam.stakeholderType = e.target
                  .value as TeamStakeholderType
                setCurrentTeam({ ...currentTeam })
              }}
            >
              {enumAsArray(TeamStakeholderType).map((item: string, index) => (
                <MenuItem key={index} value={item}>
                  {capitalizeFirstCharacter(item)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="users-can-leave-select">Users can leave</InputLabel>
            <Select
              labelId="users-can-leave-select"
              label="Users can leave"
              value={currentTeam.canLeave ? "yes" : "no"}
              onChange={(e) => {
                const currentTeamCopy: Team = deepCopy(currentTeam)
                if (e.target.value === "yes") {
                  currentTeamCopy.canLeave = true
                } else {
                  currentTeamCopy.canLeave = false
                }
                setCurrentTeam(currentTeamCopy)
              }}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="suggest-footprint-select">
              Suggest footprint
            </InputLabel>
            <Select
              labelId="suggest-footprint-select"
              label="Suggest footprint"
              value={currentTeam.suggestFootprint ? "yes" : "no"}
              onChange={(e) => {
                const currentTeamCopy: Team = deepCopy(currentTeam)
                if (e.target.value === "yes") {
                  currentTeamCopy.suggestFootprint = true
                } else {
                  currentTeamCopy.suggestFootprint = false
                }
                setCurrentTeam(currentTeamCopy)
              }}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Typography variant="h6" className="card-title">
          Locations
        </Typography>
        <Autocomplete
          loading={countriesControllerLoading}
          disabled={!editMode}
          fullWidth
          blurOnSelect
          size="small"
          value={currentTeam.availableAtLocations ?? []}
          disablePortal
          multiple
          isOptionEqualToValue={(
            option: LocationAutocompleteOption,
            value: LocationAutocompleteOption
          ) => option.id === value.id}
          groupBy={(option: LocationAutocompleteOption) => option.__typename}
          options={[
            ...regionsList.map((item) => {
              return {
                id: item.id,
                name: item.name,
                __typename: "Region",
              }
            }),
            ...subRegionsList.map((item) => {
              return {
                id: item.id,
                name: item.name,
                __typename: "SubRegion",
              }
            }),
            ...countriesList.map((item) => {
              return {
                id: item.id,
                name: item.name,
                __typename: "Country",
              }
            }),
          ]}
          getOptionLabel={(option: LocationAutocompleteOption) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Available in (empty for everywhere)"
            />
          )}
          onChange={(e: any, newValues: LocationAutocompleteOption[]) => {
            setCurrentTeam((current) => {
              return {
                ...current,
                availableAtLocations: newValues.length ? newValues : null,
              }
            })
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )
          }}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                size="small"
                key={option.id}
                label={option.name}
              />
            ))
          }}
        />
        <Autocomplete
          loading={countriesControllerLoading}
          disabled={!editMode}
          fullWidth
          blurOnSelect
          size="small"
          value={currentTeam.restrictedAtLocations ?? []}
          disablePortal
          multiple
          isOptionEqualToValue={(
            option: LocationAutocompleteOption,
            value: LocationAutocompleteOption
          ) => option.id === value.id}
          groupBy={(option: LocationAutocompleteOption) => option.__typename}
          options={[
            ...regionsList.map((item) => {
              return {
                id: item.id,
                name: item.name,
                __typename: "Region",
              }
            }),
            ...subRegionsList.map((item) => {
              return {
                id: item.id,
                name: item.name,
                __typename: "SubRegion",
              }
            }),
            ...countriesList.map((item) => {
              return {
                id: item.id,
                name: item.name,
                __typename: "Country",
              }
            }),
          ]}
          getOptionLabel={(option: LocationAutocompleteOption) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="Not available in" />
          )}
          onChange={(e: any, newValues: LocationAutocompleteOption[]) => {
            setCurrentTeam((current) => {
              return {
                ...current,
                restrictedAtLocations: newValues.length ? newValues : null,
              }
            })
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )
          }}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                size="small"
                key={option.id}
                label={option.name}
              />
            ))
          }}
        />
        <Typography variant="h6" className="card-title">
          Contracts
        </Typography>
        {!currentTeamContractsList.length ? (
          <Typography>No contracts for this Team</Typography>
        ) : null}
        {currentTeamContractsList.length ? (
          <Stack>
            {(showAllContracts
              ? currentTeamContractsList
              : currentTeamContractsList.filter(
                  (item) =>
                    Math.max(
                      ...currentTeamContractsList.map((item) =>
                        new Date(item.activatedAt).getTime()
                      )
                    ) === new Date(item.activatedAt).getTime()
                )
            ).map((item, index, list) => (
              <TeamContractCard
                key={item.id}
                index={index}
                item={item}
                current={
                  Math.max(
                    ...currentTeamContractsList.map((item) =>
                      new Date(item.activatedAt).getTime()
                    )
                  ) === new Date(item.activatedAt).getTime()
                }
                list={list}
              />
            ))}
          </Stack>
        ) : null}
        <Stack direction="row" spacing={1}>
          {currentTeamContractsList.length > 1 && (
            <Button
              fullWidth
              disabled={!editMode}
              endIcon={
                <ChevronLeftRounded
                  style={{
                    transform: showAllContracts
                      ? "rotate(90deg)"
                      : "rotate(-90deg)",
                    transition: "200ms",
                  }}
                />
              }
              variant="outlined"
              style={{
                maxHeight: 40,
                minHeight: 40,
              }}
              onClick={() => {
                setShowAllContracts((current) => !current)
              }}
            >
              {showAllContracts ? "Show less" : "Show more"}
            </Button>
          )}
          <Button
            fullWidth
            disabled={!editMode}
            endIcon={<AddCircleRounded />}
            variant="outlined"
            style={{
              maxHeight: 40,
              minHeight: 40,
            }}
            onClick={handleAddContractDialogChange}
          >
            Add contract
          </Button>
        </Stack>
        {/* <Typography variant="h6" className="card-title">
        Visibility
      </Typography>
      <Stack direction="row" spacing={1}>
        <FormControl fullWidth size="small" disabled={!editMode}>
          <InputLabel id="leaderboard-visibility">Leaderboard</InputLabel>
          <Select
            labelId="leaderboard-visibility"
            label="Leaderboard"
            value={currentTeam.visibilityOptions.leaderboard}
            onChange={(e) => {
              currentTeam.visibilityOptions.leaderboard = e.target
                .value as TeamVisibilityOptionType
              setCurrentTeam({ ...currentTeam })
            }}
          >
            {enumAsArray(TeamVisibilityOptionType).map(
              (item: string, index) => (
                <MenuItem key={index} value={item}>
                  {capitalizeFirstCharacter(item)}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <FormControl fullWidth size="small" disabled={!editMode}>
          <InputLabel id="members-visibility">Members List</InputLabel>
          <Select
            labelId="members-visibility"
            label="Members List"
            value={currentTeam.visibilityOptions.members}
            onChange={(e) => {
              currentTeam.visibilityOptions.members = e.target
                .value as TeamVisibilityOptionType
              setCurrentTeam({ ...currentTeam })
            }}
          >
            {enumAsArray(TeamVisibilityOptionType).map(
              (item: string, index) => (
                <MenuItem key={index} value={item}>
                  {capitalizeFirstCharacter(item)}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <FormControl fullWidth size="small" disabled={!editMode}>
          <InputLabel id="metric-visibility">Metrics</InputLabel>
          <Select
            labelId="metric-visibility"
            label="Metrics"
            value={currentTeam.visibilityOptions.metric}
            onChange={(e) => {
              currentTeam.visibilityOptions.metric = e.target
                .value as TeamVisibilityOptionType
              setCurrentTeam({ ...currentTeam })
            }}
          >
            {enumAsArray(TeamVisibilityOptionType).map(
              (item: string, index) => (
                <MenuItem key={index} value={item}>
                  {capitalizeFirstCharacter(item)}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Stack> */}
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Activation codes
          </Typography>
          <Stack>
            {!currentTeam.accessCodes.filter(
              (item) => item.groupId === "group_default"
            ).length ? (
              <Typography>No activation codes for this Team</Typography>
            ) : null}
            {currentTeam.accessCodes.filter(
              (item) => item.groupId === "group_default"
            ).length
              ? currentTeam.accessCodes
                  .filter((item) => item.groupId === "group_default")
                  .slice(0, 3)
                  .map((item, index) => (
                    <Card
                      key={item.code}
                      variant="outlined"
                      sx={{
                        boxShadow: "none",
                        borderTopLeftRadius: index === 0 ? undefined : 0,
                        borderTopRightRadius: index === 0 ? undefined : 0,
                        borderBottomLeftRadius:
                          index ===
                          currentTeam.accessCodes
                            .filter((item) => item.groupId === "group_default")
                            .slice(0, 3).length -
                            1
                            ? undefined
                            : 0,
                        borderBottomRightRadius:
                          index ===
                          currentTeam.accessCodes
                            .filter((item) => item.groupId === "group_default")
                            .slice(0, 3).length -
                            1
                            ? undefined
                            : 0,
                        borderTop: index === 0 ? undefined : "none",
                      }}
                    >
                      <CardContent
                        style={{
                          position: "relative",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <Typography style={{ marginTop: 1 }}>
                          {item.code}
                        </Typography>
                        <IconButton
                          sx={{
                            width: 40,
                            height: 40,
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            copyTextToClipboard(item.code)
                            setFeedbackOpen(true)
                          }}
                          style={{
                            position: "absolute",
                            right: 65.5,
                            top: 2.5,
                          }}
                        >
                          <ContentCopyRounded sx={{ fontSize: 20 }} />
                        </IconButton>
                        <IconButton
                          sx={{
                            width: 40,
                            height: 40,
                          }}
                          color="error"
                          onClick={(e) => {
                            e.stopPropagation()
                            setCodeToDelete(item.code)
                            handleDeleteCodeDialogChange()
                          }}
                          style={{
                            position: "absolute",
                            right: 11.5,
                            top: 2.5,
                          }}
                        >
                          <DeleteForeverRounded style={{ width: 24 }} />
                        </IconButton>
                      </CardContent>
                    </Card>
                  ))
              : null}
          </Stack>
          {currentTeam.accessCodes.filter(
            (item) => item.groupId === "group_default"
          ).length > 3 ? (
            <Button
              fullWidth
              disabled={!editMode}
              endIcon={<ArrowForwardIosRounded style={{ fontSize: 16 }} />}
              variant="outlined"
              style={{
                maxHeight: 40,
                minHeight: 40,
              }}
              onClick={() => {
                setAnimation(false)
                setTimeout(() => {
                  navigate(window.location.pathname + "/activationcodes")
                }, 250)
              }}
            >
              View{" "}
              {currentTeam.accessCodes.filter(
                (item) => item.groupId === "group_default"
              ).length - 3}{" "}
              more
            </Button>
          ) : null}
          <Button
            fullWidth
            disabled={!editMode}
            endIcon={<AddCircleRounded />}
            variant="outlined"
            style={{
              maxHeight: 40,
              minHeight: 40,
            }}
            onClick={handleActivationCodeDialogChange}
          >
            Add activation code
          </Button>
        </Stack>
      </Stack>
      {/* primary change dialog */}
      <Dialog open={isPrimaryAlertOpen} TransitionComponent={DialogTransition}>
        <DialogTitle>Are you sure you want to proceed?</DialogTitle>
        <DialogContent>
          <Typography>
            Although it is possible to reverse this change in the future, the
            operation may take some time to complete and be visible to all team
            members. Are you sure you want to proceed? If you have any doubts,
            contact an administrator.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setIsPrimaryAlertOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setCurrentTeam({
                ...currentTeam,
                isPrimary:
                  currentTeam.isPrimary === null
                    ? true
                    : !currentTeam.isPrimary,
              })
              setIsPrimaryAlertOpen(false)
            }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      {/* cannot set team to live or featured if current contract is missing sellingPrice or targetMembers */}
      <CantPerformActionDialog
        open={contractIncompleteAlertOpen}
        setOpen={setContractIncompleteAlertOpen}
        content='To be able to set the stage of this Team to "Live", "Featured" or "Hidden" you need to add selling price and target members to the current contract.'
      />
      {/* stage info dialog */}
      <Dialog open={stageInfoDialogOpen} TransitionComponent={DialogTransition}>
        <DialogTitle>What are the stages?</DialogTitle>
        <DialogContent>
          <div style={{ marginLeft: 15 }}>
            <ul>
              <li>
                <strong>Backstage</strong>: the Team is not visible anywhere.
              </li>
              <li>
                <strong>Hidden</strong>: used for integrations; the Team doesn't
                appear in the app.
              </li>
              <li>
                <strong>Live</strong>: the Team is visible in the app.
              </li>
              <li>
                <strong>Featured</strong>: the Team appears in the app's group
                missions section and search suggestions.
              </li>
            </ul>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setStageInfoDialogOpen(false)
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* cannot set team to home featured if current contract is missing sellingPrice or targetMembers or home document is missing */}
      <CantPerformActionDialog
        open={cannotBeHomeFeaturedAlertOpen}
        setOpen={setCannotBeHomeFeaturedAlertOpen}
        content='To be able to set the stage of this Team to "Home featured" you need to add selling price and target members to the current contract and set "Home" details for the default language.'
      />
      {/* home featured info dialog */}
      <Dialog
        open={homeFeaturedInfoDialogOpen}
        TransitionComponent={DialogTransition}
      >
        <DialogTitle>What does it mean?</DialogTitle>
        <DialogContent>
          <Typography>
            Set this field to "Yes" to make this Team appear in the app's home
            carousel.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setHomeFeaturedInfoDialogOpen(false)
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* create team contract dialog */}
      <CreateContractDialog
        dialogOpen={addContractDialogOpen}
        setDialogOpen={setAddContractDialogOpen}
        handleDialogChange={handleAddContractDialogChange}
      />
      {/* add activation code dialog */}
      <Dialog
        open={activationCodeDialogOpen}
        onClose={
          createActivationCodeLoading ? null : handleActivationCodeDialogChange
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to add a new activation code to this Team?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If there are already any, they will not be overwritten
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleActivationCodeDialogChange}
            disabled={createActivationCodeLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={createActivationCodeLoading}
            onClick={async () => {
              const result = await createActivationCode(
                ActivationCodeType.TeamActivationCode,
                ActivationCodePolicy.IterationUnlimited,
                null,
                { teamId: currentTeam.id }
              )
              if (typeof result !== "boolean") {
                currentTeam.accessCodes.push(result)
                setCurrentTeam({ ...currentTeam })
                handleActivationCodeDialogChange()
              }
            }}
            autoFocus
          >
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* delete activation code dialog */}
      <Dialog
        open={deleteCodeDialogOpen}
        onClose={deletingCode ? null : handleDeleteCodeDialogChange}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to permanently delete this activation code?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The activation code{" "}
            <span style={{ fontWeight: 600 }}>{codeToDelete}</span> will be
            permanently deleted and it will no longer be able to be used to
            access the current Team
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteCodeDialogChange}
            disabled={deletingCode}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={deletingCode}
            onClick={async () => {
              setDeletingCode(true)

              const noErrors = await deleteActivationCode(codeToDelete)
              if (noErrors) {
                const indexToRemove = currentTeam.accessCodes.findIndex(
                  (item) => item.code === codeToDelete
                )
                currentTeam.accessCodes.splice(indexToRemove, 1)
                setCurrentTeam({ ...currentTeam })

                setCodeToDelete(null)
                handleDeleteCodeDialogChange()
              }

              setDeletingCode(false)
            }}
            autoFocus
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TeamGeneralEditGeneralTab
