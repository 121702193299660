import {
  Button,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material"
import { useContext, useEffect, useState, useRef } from "react"
import { MainContext } from "../../controllers/main"
import { LoadingButton } from "@mui/lab"
import {
  deepCopy,
  stripHtml,
  isValidURL,
  testYtURL,
  copyTextToClipboard,
} from "../../services/utilities/utility"
import { SurveysContext } from "../../controllers/surveys"
import { LanguagesContext } from "../../controllers/languages"
import Survey, { SurveyProvider, SurveyLinkMode } from "../../models/survey"
import { BodySliceType, Stage } from "../../services/config/enum"
import SurveyLinkChip from "./surveyLinkChip"
import BlockEditor from "../blockEditor/blockEditor"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import ImageSlice from "../../models/bodySlices/imageSlice"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import {
  validateSurveyFormUrl,
  cleanTypeformUrl,
} from "../../services/utilities/surveyUtils"
import {
  OpenInNewRounded,
  ContentCopyRounded,
  VisibilityRounded,
  VisibilityOffRounded,
  LinkRounded,
} from "@mui/icons-material"

const SurveyTranslationsEdit = ({ itemIndex }: { itemIndex: number }) => {
  const { showPadding } = useContext(MainContext)
  const { languagesForEpisodeTabs } = useContext(LanguagesContext)
  const {
    currentSurvey,
    setCurrentSurvey,
    editMode,
    translationsErrors,
    setTranslationsErrors,
    copyDetailsFromDefault,
    copyBodyFromDefault,
    linkSurvey,
  } = useContext(SurveysContext)

  // current item
  const currentItem = currentSurvey.document.items[itemIndex]

  // errors managment
  const [titleError, setTitleError] = useState<boolean>(false)
  const [formIdError, setFormIdError] = useState<boolean>(false)
  const [formUrlError, setFormUrlError] = useState<boolean>(false)
  const [bodyError, setBodyError] = useState<boolean>(false)

  // Track if formUrl was manually edited
  const formUrlManuallyEdited = useRef(false)
  // Track if formUrl is being updated
  const isFormUrlUpdating = useRef(false)

  // Copy tooltip states
  const [showCopyTooltip, setShowCopyTooltip] = useState(false)
  const [showSecretCopyTooltip, setShowSecretCopyTooltip] = useState(false)
  // Show/hide secret state
  const [showSecret, setShowSecret] = useState(false)
  const [linking, setLinking] = useState<SurveyLinkMode | null>(null)
  const [manualLinkDialogOpen, setManualLinkDialogOpen] = useState(false)
  const [hasReadGuide, setHasReadGuide] = useState(false)

  // Handle linking
  const handleLink = async (mode: SurveyLinkMode) => {
    if (!currentItem.formId || !currentItem.formUrl) return

    if (mode === SurveyLinkMode.manual) {
      setManualLinkDialogOpen(true)
      return
    }

    setLinking(mode)
    const success = await linkSurvey(mode, [currentItem.lang])
    setLinking(null)
  }

  const handleManualLink = async () => {
    setLinking(SurveyLinkMode.manual)
    const success = await linkSurvey(SurveyLinkMode.manual, [currentItem.lang])
    setLinking(null)
    if (success) {
      setManualLinkDialogOpen(false)
      setHasReadGuide(false)
    }
  }

  // Check if auto linking is available
  const canAutoLink = currentSurvey.provider === SurveyProvider.typeform

  // update translations errors on errors change
  useEffect(() => {
    if (titleError || formIdError || formUrlError || bodyError) {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = true
    } else {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = false
    }

    setTranslationsErrors([...translationsErrors])
  }, [titleError, formIdError, formUrlError, bodyError])

  // update errors on currentItem change
  useEffect(() => {
    setTitleError(currentItem.title.length < 3)
    setFormIdError(!currentItem.formId)
    setFormUrlError(
      !isFormUrlUpdating.current &&
        (!currentItem.formUrl ||
          (currentItem.formId &&
            !validateSurveyFormUrl(currentItem.formUrl, currentItem.formId)))
    )

    // Check for body errors
    let errorInBody = false
    if (currentItem.body) {
      currentItem.body.forEach((bodyItem) => {
        if (
          (bodyItem.sliceType === BodySliceType.BodyTextSlice &&
            !stripHtml(bodyItem.text).length) ||
          (bodyItem.sliceType === BodySliceType.BodyQuoteSlice &&
            !bodyItem.quoteText.length) ||
          (bodyItem.sliceType === BodySliceType.BodyImageSlice &&
            (!bodyItem.imgUrl.length || !isValidURL(bodyItem.imgUrl))) ||
          (bodyItem.sliceType === BodySliceType.BodyEmbedSlice &&
            (!bodyItem.embedUrl.length || !testYtURL(bodyItem.embedUrl)))
        ) {
          errorInBody = true
        }
      })
    }
    setBodyError(errorInBody)
  }, [currentItem])

  // Handle copy webhook URL
  const handleCopyWebhookUrl = () => {
    if (currentItem.webhookUrl) {
      copyTextToClipboard(currentItem.webhookUrl)
      setShowCopyTooltip(true)
      setTimeout(() => setShowCopyTooltip(false), 1500)
    }
  }

  // Handle copy webhook secret
  const handleCopyWebhookSecret = () => {
    if (currentItem.webhookSecret) {
      copyTextToClipboard(currentItem.webhookSecret)
      setShowSecretCopyTooltip(true)
      setTimeout(() => setShowSecretCopyTooltip(false), 1500)
    }
  }

  // Format secret for display
  const getDisplaySecret = () => {
    if (!currentItem.webhookSecret) return ""
    return showSecret
      ? currentItem.webhookSecret
      : "•".repeat(currentItem.webhookSecret.length)
  }

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Stack spacing={2} sx={{ mb: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            style={{ height: 30 }}
          >
            <Typography variant="h6" className="card-title">
              Link Status
            </Typography>
            <SurveyLinkChip linkMode={currentItem.linkMode} small />
            {editMode && currentItem.linkMode === SurveyLinkMode.unlinked && (
              <>
                {canAutoLink && (
                  <LoadingButton
                    size="small"
                    variant="outlined"
                    loading={linking === SurveyLinkMode.auto}
                    startIcon={<LinkRounded />}
                    onClick={() => handleLink(SurveyLinkMode.auto)}
                    disabled={
                      !currentItem.formId ||
                      !currentItem.formUrl ||
                      linking === SurveyLinkMode.manual
                    }
                  >
                    Link Automatically
                  </LoadingButton>
                )}
                <LoadingButton
                  size="small"
                  variant="outlined"
                  loading={linking === SurveyLinkMode.manual}
                  startIcon={<LinkRounded />}
                  onClick={() => handleLink(SurveyLinkMode.manual)}
                  disabled={
                    !currentItem.formId ||
                    !currentItem.formUrl ||
                    linking === SurveyLinkMode.auto
                  }
                >
                  Link Manually
                </LoadingButton>
              </>
            )}
          </Stack>
          {(!currentItem.formId || !currentItem.formUrl) && (
            <Typography variant="caption" color="textSecondary">
              Form ID and URL are required for linking
            </Typography>
          )}
        </Stack>
        <Stack direction="row" style={{ height: 28 }}>
          <Typography variant="h6" className="card-title">
            Details
          </Typography>
          <Stack
            alignItems="center"
            justifyContent="flex-end"
            style={{ width: "100%" }}
            direction="row"
            spacing={2}
          >
            <FormGroup style={{ marginRight: -16 }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editMode}
                    checked={currentItem.isDefault}
                    onChange={(e) => {
                      const currentSurveyCopy: Survey = deepCopy(currentSurvey)
                      currentSurveyCopy.document.items[itemIndex].isDefault =
                        e.target.checked
                      if (e.target.checked) {
                        currentSurveyCopy.document.items.forEach(
                          (item, index) => {
                            if (index !== itemIndex) {
                              item.isDefault = false
                            }
                          }
                        )
                      }
                      setCurrentSurvey(currentSurveyCopy)
                    }}
                  />
                }
                label="Default"
              />
            </FormGroup>
            <Divider orientation="vertical" flexItem />
            <Button
              size="small"
              variant="outlined"
              style={{ height: 26, fontSize: 12 }}
              onClick={() => {
                copyDetailsFromDefault(itemIndex)
              }}
              disabled={
                currentItem.isDefault ||
                !editMode ||
                !currentSurvey.document.items.filter((item) => item.isDefault)
                  .length
              }
            >
              Copy from default
            </Button>
          </Stack>
        </Stack>
        <TextField
          fullWidth
          size="small"
          label="Title"
          value={currentItem.title}
          onChange={(e) => {
            const currentSurveyCopy: Survey = deepCopy(currentSurvey)
            currentSurveyCopy.document.items[itemIndex].title = e.target.value
            setCurrentSurvey(currentSurveyCopy)

            setTitleError(e.target.value.length < 3)
          }}
          disabled={!editMode}
          error={titleError}
          helperText={titleError ? "Minimum length is 3 characters" : ""}
          onBlur={() => {
            if (currentSurvey.document.items[itemIndex].title) {
              const currentSurveyCopy: Survey = deepCopy(currentSurvey)
              currentSurveyCopy.document.items[itemIndex].title =
                currentSurveyCopy.document.items[itemIndex].title.trim()
              setCurrentSurvey(currentSurveyCopy)
            }
          }}
        />
        <TextField
          fullWidth
          size="small"
          label="Form ID"
          value={currentItem.formId}
          onChange={(e) => {
            formUrlManuallyEdited.current = false
            const currentSurveyCopy = deepCopy(currentSurvey)
            const newFormId = e.target.value
            currentSurveyCopy.document.items[itemIndex].formId = newFormId

            // Update URLs if not manually edited
            if (!formUrlManuallyEdited.current) {
              isFormUrlUpdating.current = true

              // Replace old formId at the end of form URL
              if (currentItem.formUrl.endsWith(currentItem.formId)) {
                currentSurveyCopy.document.items[itemIndex].formUrl =
                  currentItem.formUrl.slice(0, -currentItem.formId.length) +
                  newFormId
              }

              // Replace formId parameter in webhook URL if it exists
              if (currentItem.webhookUrl) {
                try {
                  const webhookUrl = new URL(currentItem.webhookUrl)
                  webhookUrl.searchParams.set("formId", newFormId)
                  currentSurveyCopy.document.items[itemIndex].webhookUrl =
                    webhookUrl.toString()
                } catch (e) {
                  // Invalid URL, skip update
                }
              }
            }

            setCurrentSurvey(currentSurveyCopy)
            isFormUrlUpdating.current = false
            setFormIdError(!newFormId)
          }}
          disabled={!editMode}
          error={formIdError}
          helperText={formIdError ? "Form ID is required" : ""}
          onBlur={() => {
            if (currentSurvey.document.items[itemIndex].formId) {
              const currentSurveyCopy: Survey = deepCopy(currentSurvey)
              currentSurveyCopy.document.items[itemIndex].formId =
                currentSurveyCopy.document.items[itemIndex].formId.trim()
              setCurrentSurvey(currentSurveyCopy)
            }
          }}
        />
        <TextField
          fullWidth
          size="small"
          label="Form URL"
          value={currentItem.formUrl}
          onChange={(e) => {
            formUrlManuallyEdited.current = true
            const currentSurveyCopy: Survey = deepCopy(currentSurvey)
            const newUrl =
              currentSurvey.provider === SurveyProvider.typeform
                ? cleanTypeformUrl(e.target.value)
                : e.target.value
            currentSurveyCopy.document.items[itemIndex].formUrl = newUrl
            setCurrentSurvey(currentSurveyCopy)
          }}
          onBlur={() => {
            if (currentSurvey.document.items[itemIndex].formUrl) {
              const currentSurveyCopy: Survey = deepCopy(currentSurvey)
              const cleanedUrl =
                currentSurvey.provider === SurveyProvider.typeform
                  ? cleanTypeformUrl(
                      currentSurveyCopy.document.items[itemIndex].formUrl
                    )
                  : currentSurveyCopy.document.items[itemIndex].formUrl
              currentSurveyCopy.document.items[itemIndex].formUrl =
                cleanedUrl.trim()
              setCurrentSurvey(currentSurveyCopy)
            }
          }}
          disabled={!editMode}
          error={formUrlError}
          helperText={
            formUrlError
              ? !currentItem.formUrl
                ? "Form URL is required"
                : "Form URL must include Form ID"
              : ""
          }
          InputProps={{
            endAdornment: currentItem.formUrl && (
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  window.open(currentItem.formUrl)
                }}
              >
                <OpenInNewRounded
                  style={{
                    fontSize: 15,
                    opacity: 0.9,
                  }}
                />
              </Button>
            ),
          }}
        />
        <TextField
          fullWidth
          size="small"
          label="Webhook URL"
          value={
            currentItem.webhookUrl ||
            (currentSurvey.stage === Stage.DRAFT
              ? "Will be generated after saving"
              : "")
          }
          disabled={true}
          InputProps={{
            endAdornment: currentItem.webhookUrl && (
              <Tooltip open={showCopyTooltip} title="Copied!" placement="top">
                <Button
                  onClick={(e) => {
                    e.stopPropagation()
                    handleCopyWebhookUrl()
                  }}
                >
                  <ContentCopyRounded
                    style={{
                      fontSize: 15,
                      opacity: 0.9,
                    }}
                  />
                </Button>
              </Tooltip>
            ),
          }}
        />
        {currentSurvey.provider === SurveyProvider.typeform &&
          currentSurvey.useSecret && (
            <TextField
              fullWidth
              size="small"
              label="Webhook Secret"
              value={
                currentItem.webhookSecret
                  ? getDisplaySecret()
                  : currentSurvey.stage === Stage.DRAFT
                  ? "Will be generated after saving"
                  : ""
              }
              disabled={true}
              InputProps={{
                endAdornment: currentItem.webhookSecret && (
                  <Stack direction="row" spacing={0}>
                    <IconButton
                      onClick={() => setShowSecret(!showSecret)}
                      size="small"
                    >
                      {showSecret ? (
                        <VisibilityOffRounded style={{ fontSize: 15 }} />
                      ) : (
                        <VisibilityRounded style={{ fontSize: 15 }} />
                      )}
                    </IconButton>
                    <Tooltip
                      open={showSecretCopyTooltip}
                      title="Copied!"
                      placement="top"
                    >
                      <Button
                        onClick={(e) => {
                          e.stopPropagation()
                          handleCopyWebhookSecret()
                        }}
                      >
                        <ContentCopyRounded
                          style={{
                            fontSize: 15,
                            opacity: 0.9,
                          }}
                        />
                      </Button>
                    </Tooltip>
                  </Stack>
                ),
              }}
            />
          )}
        <Stack spacing={2}>
          <Stack direction="row" style={{ height: 28 }}>
            <Typography variant="h6" className="card-title">
              Body
            </Typography>
            <Stack
              alignItems="center"
              justifyContent="flex-end"
              style={{ width: "100%" }}
              direction="row"
              spacing={2}
            >
              <Button
                size="small"
                variant="outlined"
                style={{ height: 26, fontSize: 12 }}
                onClick={() => {
                  copyBodyFromDefault(itemIndex)
                }}
                disabled={
                  currentItem.isDefault ||
                  !editMode ||
                  !currentSurvey.document.items.filter((item) => item.isDefault)
                    .length
                }
              >
                Copy from default
              </Button>
            </Stack>
          </Stack>
          <BlockEditor
            body={currentItem.body || []}
            setBody={(
              body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
            ) => {
              const currentSurveyCopy: Survey = deepCopy(currentSurvey)
              currentSurveyCopy.document.items[itemIndex].body = body
              setCurrentSurvey(currentSurveyCopy)
            }}
            editMode={editMode}
          />
        </Stack>
      </Stack>

      {/* Manual link dialog */}
      <Dialog
        open={manualLinkDialogOpen}
        onClose={() => setManualLinkDialogOpen(false)}
      >
        <DialogTitle>Manual Link Instructions</DialogTitle>
        <DialogContent style={{ minWidth: 375 }}>
          <Stack gap={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>
                {
                  languagesForEpisodeTabs.find(
                    (lang) => lang.value === currentItem.lang
                  ).label
                }
              </Typography>
              <SurveyLinkChip linkMode={currentItem.linkMode} small />
            </Stack>
            <Typography>
              Please follow the manual linking guide carefully before
              proceeding:
            </Typography>
            <Button
              href="https://www.notion.so/aworld/Guide-Configurazione-Survey-174a4dfc5534803195d1c94dc6de1978?pvs=4#174a4dfc5534800186fecbd086e72e6d"
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
              startIcon={<OpenInNewRounded />}
            >
              Open Manual Linking Guide
            </Button>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasReadGuide}
                  onChange={(e) => setHasReadGuide(e.target.checked)}
                />
              }
              label="I confirm that I have read and followed all the instructions in the guide meticulously"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setManualLinkDialogOpen(false)
              setHasReadGuide(false)
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={linking === SurveyLinkMode.manual}
            onClick={handleManualLink}
            disabled={!hasReadGuide}
          >
            I Have Linked the Form
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </CardContent>
  )
}

export default SurveyTranslationsEdit
