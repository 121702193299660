import { Chip } from "@mui/material"
import { SurveyLinkMode } from "../../models/survey"

interface SurveyLinkChipProps {
  linkMode: SurveyLinkMode
  small?: boolean
}

const SurveyLinkChip = ({ linkMode, small }: SurveyLinkChipProps) => {
  const getChipColor = () => {
    switch (linkMode) {
      case SurveyLinkMode.auto:
        return "success"
      case SurveyLinkMode.manual:
        return "warning"
      case SurveyLinkMode.unlinked:
        return "error"
    }
  }

  const getChipLabel = () => {
    switch (linkMode) {
      case SurveyLinkMode.auto:
        return "Auto"
      case SurveyLinkMode.manual:
        return "Manual"
      case SurveyLinkMode.unlinked:
        return "Unlinked"
    }
  }

  return (
    <Chip
      label={getChipLabel()}
      color={getChipColor()}
      size={small ? "small" : "medium"}
    />
  )
}

export default SurveyLinkChip
