import { CardContent, Stack, TextField, Typography } from "@mui/material"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import { NewsQuizzesContext } from "../../controllers/newsQuizzes"

const NewsQuizGeneralEdit = () => {
  const { showPadding } = useContext(MainContext)
  const { currentNewsQuiz, editMode } = useContext(NewsQuizzesContext)

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Configuration
        </Typography>
        <TextField
          size="small"
          label="ID"
          disabled={!editMode}
          value={currentNewsQuiz.id}
          InputProps={{ readOnly: true }}
        />
      </Stack>
    </CardContent>
  )
}

export default NewsQuizGeneralEdit
