import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { mediaJobGet } from "../services/graphql/queries"
import { suggestMediaMetadata } from "../services/graphql/mutations"
import { logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import axios from "axios"
import { JobStatus } from "../services/config/enum"
import MediaJob from "../models/mediaJob"

interface MediaJobsContextInterface {
  loading: boolean
  suggestMetadata: (input: {
    title?: string
    fileType: string
    file: {
      name: string
      dataUrl: string
    }
  }) => Promise<boolean>
  stopPollingJob: () => void
  polledJob: MediaJob | null
}

const MediaJobsContext = createContext<MediaJobsContextInterface>({
  loading: false,
  suggestMetadata: async () => true,
  stopPollingJob: () => {},
  polledJob: null,
})

const POLLING_INTERVAL = 1000
const POLLING_TIMEOUT = 10000

const MediaJobsController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage } = useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(false)
  const [polledJob, setPolledJob] = useState<MediaJob | null>(null)

  // refs for cleanup
  const pollingInterval = useRef<NodeJS.Timer | null>(null)
  const timeoutId = useRef<NodeJS.Timer | null>(null)

  // queries and mutations
  const [mediaJobGetQuery] = useLazyQuery(mediaJobGet)
  const [suggestMediaMetadataMutation] = useMutation(suggestMediaMetadata)

  // get job details
  const getJobDetails = useCallback(
    async (jobId: string) => {
      try {
        logger(Status.Api, "QUERY mediaJobGet", mediaJobGet)
        const { data } = await mediaJobGetQuery({
          variables: { input: { id: jobId } },
          fetchPolicy: "no-cache",
        })
        return data.mediaJobGet
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `mediaJobGet`, e.message)
        }
        return null
      }
    },
    [mediaJobGetQuery, setError, setErrorMessage]
  )

  // suggest metadata
  const suggestMetadata = async (input: {
    title?: string
    fileType: string
    file: {
      name: string
      dataUrl: string
    }
  }) => {
    // clear last job
    stopPollingJob()

    try {
      setLoading(true)

      logger(Status.Api, "MUTATION mediaMetadataSuggest", suggestMediaMetadata)
      const { data } = await suggestMediaMetadataMutation({
        variables: {
          input: {
            title: input.title,
            fileType: input.fileType,
          },
        },
      })
      logger(Status.Info, `s3 url: ${data.mediaMetadataSuggest.s3Url}`)
      logger(Status.Info, `job id: ${data.mediaMetadataSuggest.jobId}`)

      // s3 put
      logger(Status.Api, "axios S3 PUT")
      const blob = await fetch(input.file.dataUrl).then((r) => r.blob())
      let config = {
        method: "put",
        url: data.mediaMetadataSuggest.s3Url,
        headers: {
          "Content-Type":
            input.file.name.slice(input.file.name.lastIndexOf(".") + 1) ===
            "svg"
              ? `image/${input.file.name.slice(
                  input.file.name.lastIndexOf(".") + 1
                )}+xml`
              : `image/${input.file.name.slice(
                  input.file.name.lastIndexOf(".") + 1
                )}`,
        },
        data: blob,
      }
      await axios(config)

      const jobId = data.mediaMetadataSuggest.jobId

      // Start polling after successful S3 upload
      startPollingJob(jobId)

      logger(Status.Info, `job started with id: ${jobId}`)

      return true
    } catch (e: unknown) {
      setLoading(false)
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `mediaMetadataSuggest`, e.message)
      }

      return false
    }
  }

  const checkJobStatus = useCallback(
    async (jobId: string, startTime: number) => {
      try {
        // Check for timeout
        if (Date.now() - startTime > POLLING_TIMEOUT) {
          if (pollingInterval.current) {
            clearInterval(pollingInterval.current)
            pollingInterval.current = null
          }
          setPolledJob((prev: any) => ({
            ...prev,
            status: JobStatus.ERROR,
            error: `Operation timed out after ${
              POLLING_TIMEOUT / 1000
            } seconds`,
          }))
          return
        }

        const jobDetails = await getJobDetails(jobId)
        if (!jobDetails) return

        // Update job details first
        setPolledJob(jobDetails)

        // Then handle completion states
        if (
          jobDetails.status === JobStatus.COMPLETED ||
          jobDetails.status === JobStatus.ERROR
        ) {
          if (pollingInterval.current) {
            clearInterval(pollingInterval.current)
            pollingInterval.current = null
          }
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        if (pollingInterval.current) {
          clearInterval(pollingInterval.current)
          pollingInterval.current = null
        }
        setPolledJob((prev: any) => ({
          ...prev,
          status: JobStatus.ERROR,
          error:
            error instanceof Error
              ? error.message
              : "Failed to check job status",
        }))
        setError(true)
        setErrorMessage(
          error instanceof Error ? error.message : "Failed to check job status"
        )
      }
    },
    [getJobDetails]
  )

  // Stop polling job details
  const stopPollingJob = useCallback(() => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current)
      pollingInterval.current = null
    }
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
      timeoutId.current = null
    }
    setPolledJob(null)
  }, [])

  // Start polling a job for details
  const startPollingJob = useCallback(
    async (jobId: string) => {
      try {
        // Clear any existing polling
        stopPollingJob()

        const startTime = Date.now()

        // Initial check
        await checkJobStatus(jobId, startTime)

        // Set up polling interval
        pollingInterval.current = setInterval(
          () => checkJobStatus(jobId, startTime),
          POLLING_INTERVAL
        )
      } catch (error) {
        setLoading(false)
        setPolledJob((prev: any) => ({
          ...prev,
          status: JobStatus.ERROR,
          error:
            error instanceof Error
              ? error.message
              : "Failed to start job polling",
        }))
        setError(true)
        setErrorMessage(
          error instanceof Error ? error.message : "Failed to check job status"
        )
      }
    },
    [checkJobStatus, stopPollingJob]
  )

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current)
        pollingInterval.current = null
      }
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
        timeoutId.current = null
      }
    }
  }, [])

  return (
    <MediaJobsContext.Provider
      value={{
        loading,
        suggestMetadata,
        stopPollingJob,
        polledJob,
      }}
    >
      {children}
    </MediaJobsContext.Provider>
  )
}

export { MediaJobsController, MediaJobsContext }
