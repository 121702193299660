import { useLazyQuery } from "@apollo/client"
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  translationsExportUrlGet,
  translationsImportUrlGet,
  translationsJobDetailsGet,
} from "../services/graphql/queries"
import { logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import axios from "axios"
import { JobStatus } from "../services/config/enum"

interface TranslationsContextInterface {
  exportEpisodesTranslations: (input: {
    episodes: { episodeId: string; sourceLang: string; langs: string[] }[]
    formatHtml: boolean
    stripHtml: boolean
  }) => Promise<boolean>
  exportJourneysTranslations: (input: {
    journeys: { journeyId: string; sourceLang: string; langs: string[] }[]
    formatHtml: boolean
    stripHtml: boolean
  }) => Promise<boolean>
  importTranslations: (
    input: {
      contentType: string
      fileName: string
    },
    dataUrl: string
  ) => Promise<boolean>
  getJobDetails: (jobId: string) => Promise<any>
  startPollingJob: (jobId: string) => void
  stopPollingJob: () => void
  currentJobStatus: JobStatus | null
  currentJobError: string | null
  currentJobId: string | null
  jobRunning: boolean
  polledJob: any
  loading: boolean
}

const TranslationsContext = createContext<TranslationsContextInterface>({
  exportEpisodesTranslations: async () => true,
  exportJourneysTranslations: async () => true,
  importTranslations: async () => true,
  getJobDetails: async () => null,
  startPollingJob: () => {},
  stopPollingJob: () => {},
  currentJobStatus: null,
  currentJobError: null,
  currentJobId: null,
  jobRunning: false,
  polledJob: null,
  loading: true,
})

const POLLING_INTERVAL = 5000 // 5 seconds
const POLLING_TIMEOUT = 60000 // 1 minute

const TranslationsController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage } = useContext(MainContext)

  // states
  const [currentJobId, setCurrentJobId] = useState<string | null>(null)
  const [currentJobStatus, setCurrentJobStatus] = useState<JobStatus | null>(null)
  const [currentJobError, setCurrentJobError] = useState<string | null>(null)
  const [jobRunning, setJobRunning] = useState<boolean>(false)
  const [polledJob, setPolledJob] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(true)

  // refs for cleanup
  const pollingInterval = useRef<NodeJS.Timer | null>(null)
  const timeoutId = useRef<NodeJS.Timer | null>(null)

  // queries
  const [translationsExportUrlGetQuery] = useLazyQuery(translationsExportUrlGet)
  const [translationsImportUrlGetQuery] = useLazyQuery(translationsImportUrlGet)
  const [translationsJobDetailsGetQuery] = useLazyQuery(translationsJobDetailsGet)

  // get job details
  const getJobDetails = useCallback(async (jobId: string) => {
    try {
      logger(Status.Api, "QUERY translationsJobDetailsGet", translationsJobDetailsGet)
      const { data } = await translationsJobDetailsGetQuery({
        variables: { input: { id: jobId } },
        fetchPolicy: "no-cache",
      })
      return data.translationsJobGet
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `translationsJobDetailsGet`, e.message)
      }
      return null
    }
  }, [translationsJobDetailsGetQuery, setError, setErrorMessage])

  // export episodes translations
  const exportEpisodesTranslations = async (input: {
    episodes: { episodeId: string; sourceLang: string; langs: string[] }[]
    formatHtml: boolean
    stripHtml: boolean
  }) => {
    try {
      logger(
        Status.Api,
        "QUERY translationsExportUrlGet",
        translationsExportUrlGet
      )
      const { data } = await translationsExportUrlGetQuery({
        variables: { input: input },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `s3 url: ${data.translationsExportUrlGet.s3Url}`)

      // download file
      const link = document.createElement("a")
      link.href = data.translationsExportUrlGet.s3Url
      link.click()

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `translationsExportUrlGet`, e.message)
      }

      return false
    }
  }

  // export journeys translations
  const exportJourneysTranslations = async (input: {
    journeys: { journeyId: string; sourceLang: string; langs: string[] }[]
    formatHtml: boolean
    stripHtml: boolean
  }) => {
    try {
      logger(
        Status.Api,
        "QUERY translationsExportUrlGet",
        translationsExportUrlGet
      )
      const { data } = await translationsExportUrlGetQuery({
        variables: { input: input },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `s3 url: ${data.translationsExportUrlGet.s3Url}`)

      // download file
      const link = document.createElement("a")
      link.href = data.translationsExportUrlGet.s3Url
      link.click()

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `translationsExportUrlGet`, e.message)
      }

      return false
    }
  }

  // import translations
  const importTranslations = async (
    input: {
      contentType: string
      fileName: string
    },
    dataUrl: string
  ) => {
    // clear last import
    stopPollingJob()

    try {
      logger(
        Status.Api,
        "QUERY translationsImportUrlGet",
        translationsImportUrlGet
      )
      const { data } = await translationsImportUrlGetQuery({
        variables: { input: input },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, `s3 url: ${data.translationsImportUrlGet.s3Url}`)
      logger(Status.Info, `job id: ${data.translationsImportUrlGet.jobId}`)

      // s3 put
      logger(Status.Api, "axios S3 PUT")
      const blob = await fetch(dataUrl).then((r) => r.blob())
      let config = {
        method: "put",
        url: data.translationsImportUrlGet.s3Url,
        data: blob,
      }
      await axios(config)

      setJobRunning(true)
      setCurrentJobId(data.translationsImportUrlGet.jobId)

      logger(Status.Info, "job started")

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `translationsExportUrlGet`, e.message)
      }

      return false
    }
  }

  const checkJobStatus = useCallback(async (jobId: string) => {
    const jobDetails = await getJobDetails(jobId)
    if (jobDetails) {
      setCurrentJobStatus(jobDetails.status)
      setCurrentJobError(jobDetails.error)
      setPolledJob(jobDetails)
      setLoading(false)

      if (jobDetails.status === JobStatus.COMPLETED || jobDetails.status === JobStatus.ERROR) {
        setJobRunning(false)
        if (pollingInterval.current) {
          clearInterval(pollingInterval.current)
          pollingInterval.current = null
        }
        if (timeoutId.current) {
          clearTimeout(timeoutId.current)
          timeoutId.current = null
        }
      }
    }
  }, [getJobDetails])

  // Stop polling job details
  const stopPollingJob = useCallback(() => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current)
      pollingInterval.current = null
    }
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
      timeoutId.current = null
    }
    setJobRunning(false)
    setCurrentJobId(null)
    setCurrentJobStatus(null)
    setCurrentJobError(null)
    setPolledJob(null)
    setLoading(true)
  }, [])

  // Start polling a job for details
  const startPollingJob = useCallback(async (jobId: string) => {
    // Clear any existing polling
    stopPollingJob()
    
    // Set the job ID and start polling
    setCurrentJobId(jobId)
    setJobRunning(true)

    // Initial check
    await checkJobStatus(jobId)

    // Set up polling interval
    pollingInterval.current = setInterval(() => checkJobStatus(jobId), POLLING_INTERVAL)

    // Set up timeout
    timeoutId.current = setTimeout(() => {
      stopPollingJob()
      setPolledJob((prev: any) => ({
        ...prev,
        status: JobStatus.ERROR,
        error: "TIMEOUT"
      }))
    }, POLLING_TIMEOUT)
  }, [checkJobStatus, stopPollingJob])

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current)
      }
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
    }
  }, [])

  return (
    <TranslationsContext.Provider
      value={{
        exportEpisodesTranslations,
        exportJourneysTranslations,
        importTranslations,
        getJobDetails,
        startPollingJob,
        stopPollingJob,
        currentJobStatus,
        currentJobError,
        currentJobId,
        jobRunning,
        polledJob,
        loading,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  )
}

export { TranslationsController, TranslationsContext }
