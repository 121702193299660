import { Dispatch, SetStateAction, useEffect } from "react"
import {
  mediaConstraints,
  mediaRoomForError,
  otherImagesMaxConstraint,
  otherImagesMinConstraint,
} from "../../services/config/constants"
import { logger, Status } from "../../services/utilities/utility"
import ImageDropzone from "../global/imageDropzone"

const SingleUpload = ({
  droppedImage,
  setDroppedImage,
  droppedImageError,
  setDroppedImageError,
  droppedImageErrorType,
  setDroppedImageErrorType,
  imageCategory,
  height,
}: {
  droppedImage: {
    name: string
    size: number
    dataUrl: string
  } | null
  setDroppedImage: Dispatch<
    SetStateAction<{
      name: string
      size: number
      dataUrl: string
    } | null>
  >
  droppedImageError: boolean
  setDroppedImageError: Dispatch<SetStateAction<boolean>>
  droppedImageErrorType: string
  setDroppedImageErrorType: Dispatch<SetStateAction<string>>
  imageCategory: string | null
  height: string
}) => {
  // calculate dropped image width and height and check constraints
  useEffect(() => {
    if (droppedImage && droppedImage.dataUrl.length) {
      let img = new Image()
      img.src = droppedImage.dataUrl
      img.onload = () => {
        let size =
          droppedImage.size / 1000 > 999
            ? Math.round((droppedImage.size / 1000000 + Number.EPSILON) * 10) /
                10 +
              "mb"
            : Math.round(droppedImage.size / 1000 + Number.EPSILON) + "kb"
        let width = img.width
        let height = img.height
        logger(Status.Info, "name", droppedImage.name)
        logger(Status.Info, "size", size)
        logger(Status.Info, "width", width + "px")
        logger(Status.Info, "height", height + "px")

        let shouldSetErrorsToFalse = true

        if (imageCategory === "background") {
          if (
            width > mediaConstraints.background.width + mediaRoomForError ||
            width < mediaConstraints.background.width - mediaRoomForError ||
            height > mediaConstraints.background.height + mediaRoomForError ||
            height < mediaConstraints.background.height - mediaRoomForError
          ) {
            setDroppedImageError(true)
            setDroppedImageErrorType(
              `Background images must have a size of ${mediaConstraints.background.width}x${mediaConstraints.background.height} pixels`
            )
            shouldSetErrorsToFalse = false
          }
        }
        if (imageCategory === "decor") {
          if (
            (width > mediaConstraints.decor1.width + mediaRoomForError ||
              width < mediaConstraints.decor1.width - mediaRoomForError ||
              height > mediaConstraints.decor1.height + mediaRoomForError ||
              height < mediaConstraints.decor1.height - mediaRoomForError) &&
            (width > mediaConstraints.decor2.width + mediaRoomForError ||
              width < mediaConstraints.decor2.width - mediaRoomForError ||
              height > mediaConstraints.decor2.height + mediaRoomForError ||
              height < mediaConstraints.decor2.height - mediaRoomForError)
          ) {
            setDroppedImageError(true)
            setDroppedImageErrorType(
              `Decor images must have a size of ${mediaConstraints.decor1.width}x${mediaConstraints.decor1.height} or ${mediaConstraints.decor2.width}x${mediaConstraints.decor2.height} pixels`
            )
            shouldSetErrorsToFalse = false
          }
        }
        if (imageCategory === "thumbnail") {
          if (
            width > mediaConstraints.thumbnail.width + mediaRoomForError ||
            width < mediaConstraints.thumbnail.width - mediaRoomForError ||
            height > mediaConstraints.thumbnail.height + mediaRoomForError ||
            height < mediaConstraints.thumbnail.height - mediaRoomForError
          ) {
            setDroppedImageError(true)
            setDroppedImageErrorType(
              `Thumbnail images must have a size of ${mediaConstraints.thumbnail.width}x${mediaConstraints.thumbnail.height} pixels`
            )
            shouldSetErrorsToFalse = false
          }
        }
        if (
          imageCategory === "action" ||
          imageCategory === "actionGroup" ||
          imageCategory === "actionGroupBadge"
        ) {
          if (
            width !== height ||
            droppedImage.name.slice(droppedImage.name.lastIndexOf(".")) !==
              ".svg"
          ) {
            setDroppedImageError(true)
            setDroppedImageErrorType(
              `Action images must be square and in svg format`
            )
            shouldSetErrorsToFalse = false
          }
        }
        if (imageCategory === "challenge") {
          if (
            width > mediaConstraints.challenge.width + mediaRoomForError ||
            width < mediaConstraints.challenge.width - mediaRoomForError ||
            height > mediaConstraints.challenge.height + mediaRoomForError ||
            height < mediaConstraints.challenge.height - mediaRoomForError
          ) {
            setDroppedImageError(true)
            setDroppedImageErrorType(
              `Challenge images must have a size of ${mediaConstraints.challenge.width}x${mediaConstraints.challenge.height} pixels`
            )
            shouldSetErrorsToFalse = false
          }
        }
        if (imageCategory === "other") {
          if (
            width > otherImagesMaxConstraint ||
            width < otherImagesMinConstraint ||
            height > otherImagesMaxConstraint ||
            height < otherImagesMinConstraint
          ) {
            setDroppedImageError(true)
            setDroppedImageErrorType(
              `Minimum size for generic images is ${otherImagesMinConstraint}x${otherImagesMinConstraint} pixels and maximum is ${otherImagesMaxConstraint}x${otherImagesMaxConstraint} pixels`
            )
            shouldSetErrorsToFalse = false
          }
        }

        if (shouldSetErrorsToFalse) {
          setDroppedImageError(false)
          setDroppedImageErrorType("")
        }
      }
    } else {
      setDroppedImageError(false)
      setDroppedImageErrorType("")
    }
  }, [droppedImage])

  return (
    <ImageDropzone
      title="Media"
      droppedImage={droppedImage}
      setDroppedImage={setDroppedImage}
      height={height}
      disabled={imageCategory ? false : true}
      disabledText="Select type first"
      error={droppedImageError}
      errorType={droppedImageErrorType}
      imageCategory={imageCategory}
      style={{ minHeight: height }}
    />
  )
}

export default SingleUpload
