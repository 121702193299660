import {
  Button,
  CardContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { BadgesContext } from "../../controllers/badges"
import { MainContext } from "../../controllers/main"
import Badge from "../../models/badge"
// import EmbedSlice from "../../models/bodySlices/embedSlice"
// import ImageSlice from "../../models/bodySlices/imageSlice"
// import QuoteSlice from "../../models/bodySlices/quoteSlice"
// import TextSlice from "../../models/bodySlices/textSlice"
// import BlockEditor from "../blockEditor/blockEditor"
import { BodySliceType } from "../../services/config/enum"
import {
  deepCopy,
  isValidURL,
  stripHtml,
  testYtURL,
} from "../../services/utilities/utility"
import constraints from "../../services/config/constraints"

const BadgeTranslationsEdit = ({ itemIndex }: { itemIndex: number }) => {
  const { showPadding } = useContext(MainContext)
  const {
    currentBadge,
    setCurrentBadge,
    editMode,
    translationsErrors,
    setTranslationsErrors,
    copyDetailsFromDefault,
    // copyBodyFromDefault,
  } = useContext(BadgesContext)

  // current item
  const currentItem = currentBadge.document.items[itemIndex]

  // errors managment
  const [titleError, setTitleError] = useState<boolean>(false) // error for title
  const [bodyError, setBodyError] = useState<boolean>(false) // error for body

  // update translations errors on errors change
  useEffect(() => {
    if (titleError || bodyError) {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = true
    } else {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = false
    }

    setTranslationsErrors([...translationsErrors])
  }, [titleError, bodyError])

  // update errors on currentItem change
  useEffect(() => {
    if (
      currentItem.title.length < constraints.badge.title.min ||
      currentItem.title.length > constraints.badge.title.max
    ) {
      setTitleError(true)
    } else {
      setTitleError(false)
    }

    let errorInBody = false
    if (currentItem.body) {
      currentItem.body.forEach((bodyItem) => {
        if (
          (bodyItem.sliceType === BodySliceType.BodyTextSlice &&
            !stripHtml(bodyItem.text).length) ||
          (bodyItem.sliceType === BodySliceType.BodyQuoteSlice &&
            !bodyItem.quoteText.length) ||
          (bodyItem.sliceType === BodySliceType.BodyImageSlice &&
            (!bodyItem.imgUrl.length || !isValidURL(bodyItem.imgUrl))) ||
          (bodyItem.sliceType === BodySliceType.BodyEmbedSlice &&
            (!bodyItem.embedUrl.length || !testYtURL(bodyItem.embedUrl)))
        ) {
          errorInBody = true
        }
      })
    }
    if (errorInBody) {
      setBodyError(true)
    } else {
      setBodyError(false)
    }
  }, [currentItem])

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" style={{ height: 28 }}>
          <Typography variant="h6" className="card-title">
            Details
          </Typography>
          <Stack
            alignItems="center"
            justifyContent="flex-end"
            style={{ width: "100%" }}
            direction="row"
            spacing={2}
          >
            <FormGroup style={{ marginRight: -16 }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editMode}
                    checked={currentItem.isDefault}
                    onChange={(e) => {
                      const currentBadgeCopy: Badge = deepCopy(currentBadge)
                      currentBadgeCopy.document.items[itemIndex].isDefault =
                        e.target.checked
                      if (e.target.checked) {
                        currentBadgeCopy.document.items.forEach(
                          (item, index) => {
                            if (index !== itemIndex) {
                              item.isDefault = false
                            }
                          }
                        )
                      }
                      setCurrentBadge(currentBadgeCopy)
                    }}
                  />
                }
                label="Default"
              />
            </FormGroup>
            <Divider orientation="vertical" flexItem />
            <Button
              size="small"
              variant="outlined"
              style={{ height: 26, fontSize: 12 }}
              onClick={() => {
                copyDetailsFromDefault(itemIndex)
              }}
              disabled={
                currentItem.isDefault ||
                !editMode ||
                !currentBadge.document.items.filter((item) => item.isDefault)
                  .length
              }
            >
              Copy from default
            </Button>
          </Stack>
        </Stack>
        <TextField
          fullWidth
          size="small"
          label="Title"
          value={currentItem.title}
          onChange={(e) => {
            const currentBadgeCopy: Badge = deepCopy(currentBadge)
            currentBadgeCopy.document.items[itemIndex].title = e.target.value
            setCurrentBadge(currentBadgeCopy)

            // errors check
            if (
              e.target.value.length < constraints.badge.title.min ||
              e.target.value.length > constraints.badge.title.max
            ) {
              setTitleError(true)
            } else {
              setTitleError(false)
            }
          }}
          disabled={!editMode}
          error={titleError}
          helperText={
            currentItem.title.length < constraints.badge.title.min
              ? `Minimum length is ${constraints.badge.title.min} characters`
              : currentItem.title.length > constraints.badge.title.max
              ? `Maximum length is ${constraints.badge.title.max} characters`
              : ""
          }
          onBlur={() => {
            if (currentBadge.document.items[itemIndex].title) {
              const currentBadgeCopy: Badge = deepCopy(currentBadge)
              currentBadgeCopy.document.items[itemIndex].title =
                currentBadgeCopy.document.items[itemIndex].title.trim()
              setCurrentBadge(currentBadgeCopy)
            }
          }}
        />
        {/* <Stack spacing={2}>
          <Stack direction="row" style={{ height: 28 }}>
            <Typography variant="h6" className="card-title">
              Body
            </Typography>
            <Stack
              alignItems="center"
              justifyContent="flex-end"
              style={{ width: "100%" }}
              direction="row"
              spacing={2}
            >
              <Button
                size="small"
                variant="outlined"
                style={{ height: 26, fontSize: 12 }}
                onClick={() => {
                  copyBodyFromDefault(itemIndex)
                }}
                disabled={
                  currentItem.isDefault ||
                  !editMode ||
                  !currentBadge.document.items.filter((item) => item.isDefault)
                    .length
                }
              >
                Copy from default
              </Button>
            </Stack>
          </Stack>
          <BlockEditor
            body={currentItem.body ?? []}
            setBody={(
              body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
            ) => {
              const currentBadgeCopy: Badge = deepCopy(currentBadge)
              currentBadgeCopy.document.items[itemIndex].body = body
              setCurrentBadge(currentBadgeCopy)
            }}
            editMode={editMode}
          />
        </Stack> */}
      </Stack>
    </CardContent>
  )
}

export default BadgeTranslationsEdit
