import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { newsQuizGet, newsQuizList } from "../services/graphql/queries"
import { deepCopy, logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import NewsQuiz from "../models/newsQuiz"
import {
  newsQuizDelete,
  newsQuizUpsert,
  upsertDocumentNewsQuiz,
} from "../services/graphql/mutations"
import NewsQuizDocument from "../models/newsQuizDocument"

interface NewsQuizzesContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  newsQuizzesList: NewsQuiz[]
  getNewsQuizzesList: (newsId: string) => void
  doneChanges: boolean
  cancelChanges: () => void
  currentNewsQuiz: NewsQuiz
  setCurrentNewsQuiz: Dispatch<SetStateAction<NewsQuiz>>
  getCurrentNewsQuiz: (quizId: string) => Promise<boolean>
  preChangesCurrentNewsQuiz: NewsQuiz
  addTranslation: (translationToAdd: string) => void
  upsertNewsQuizParent: () => Promise<boolean>
  hasError: boolean
  translationsErrors: { lang: string; hasErrors: boolean }[]
  setTranslationsErrors: Dispatch<
    SetStateAction<{ lang: string; hasErrors: boolean }[]>
  >
  upsertNewsQuizDocument: () => Promise<boolean>
  copyDetailsFromDefault: (itemToCopyToIndex: number) => void
  createNewsQuiz: (input: { newsId: string }) => Promise<NewsQuiz | null>
  createNewsQuizDocument: (input: {
    parentId: string
    type: string
    newsQuizDocumentItems: {
      default: boolean
      lang: string
      question: string
      opt1: string
      opt2: string
      opt3: string
      opt4: string
      correctAnswer: string
      explanation?: string
    }[]
  }) => Promise<{ items: NewsQuizDocument[]; parentId: string } | null>
  newsQuizzesListNextToken: string | null
  loadMoreNewsQuizzes: (newsId: string) => Promise<boolean>
  deleteNewsQuiz: (newsToDeleteId: string) => Promise<boolean>
}

const NewsQuizzesContext = createContext<NewsQuizzesContextInterface>({
  loading: true,
  setLoading: () => {},
  editMode: false,
  setEditMode: () => {},
  newsQuizzesList: [],
  getNewsQuizzesList: () => {},
  doneChanges: false,
  cancelChanges: () => {},
  currentNewsQuiz: undefined,
  setCurrentNewsQuiz: () => {},
  getCurrentNewsQuiz: async () => true,
  preChangesCurrentNewsQuiz: undefined,
  addTranslation: () => {},
  upsertNewsQuizParent: async () => true,
  hasError: false,
  translationsErrors: [],
  setTranslationsErrors: () => {},
  upsertNewsQuizDocument: async () => true,
  copyDetailsFromDefault: () => {},
  createNewsQuiz: async () => null,
  createNewsQuizDocument: async () => null,
  newsQuizzesListNextToken: null,
  loadMoreNewsQuizzes: async () => true,
  deleteNewsQuiz: async () => true,
})

const NewsQuizzesController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage } = useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [editMode, setEditMode] = useState<boolean>(true) // if all page should be disabled or not
  const [newsQuizzesList, setNewsQuizzesList] = useState<NewsQuiz[]>([]) // list of all news quizzes
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes
  const [currentNewsQuiz, setCurrentNewsQuiz] = useState<NewsQuiz>() // current news quiz
  const [preChangesCurrentNewsQuiz, setPreChangesCurrentNewsQuiz] =
    useState<NewsQuiz>() // fetched current news quiz
  const [newsQuizzesListNextToken, setNewsQuizzesListNextToken] = useState<
    string | null
  >(null) // next token for news quizzes list

  // errors for news edit and create
  const [translationsErrors, setTranslationsErrors] = useState<
    { lang: string; hasErrors: boolean }[]
  >([]) // errors array for translations
  const hasError = translationsErrors.some((item) => item.hasErrors) // if there are errors or not

  // reset all errors
  const resetErrors = () => {
    const newTranslationsErrors: { lang: string; hasErrors: boolean }[] = []
    currentNewsQuiz.document.items.forEach((item) => {
      newTranslationsErrors.push({ lang: item.lang, hasErrors: false })
    })
    setTranslationsErrors(newTranslationsErrors)
  }

  // queries
  const [newsQuizListQuery] = useLazyQuery(newsQuizList)
  const [newsQuizGetQuery] = useLazyQuery(newsQuizGet)

  // mutations
  const [newsQuizUpsertMutation] = useMutation(newsQuizUpsert)
  const [upsertDocumentNewsQuizMutation] = useMutation(upsertDocumentNewsQuiz)
  const [newsQuizDeleteMutation] = useMutation(newsQuizDelete)

  // get news quizzes list
  const getNewsQuizzesList = useCallback(
    async (newsId: string) => {
      setLoading(true)

      try {
        logger(Status.Api, "QUERY newsQuizList", newsQuizList)
        const { data } = await newsQuizListQuery({
          variables: {
            input: {
              newsId,
              limit: Math.round(window.innerHeight / 74) + 10,
            },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "news quizzes list", data.newsQuizList.items)

        setNewsQuizzesList(data.newsQuizList.items)
        setNewsQuizzesListNextToken(data.newsQuizList.nextToken)

        setLoading(false)
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `newsQuizList`, e.message)
        }
        setLoading(false)
      }
    },
    [setError, setErrorMessage]
  )

  // load more news quizzes
  const loadMoreNewsQuizzes = async (newsId: string) => {
    try {
      logger(Status.Api, "QUERY newsQuizList", newsQuizList)
      const { data } = await newsQuizListQuery({
        variables: {
          input: {
            newsId,
            nextToken: newsQuizzesListNextToken,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "news quizzes list", [
        ...newsQuizzesList,
        ...data.newsQuizList.items,
      ])

      setNewsQuizzesList([...newsQuizzesList, ...data.newsQuizList.items])
      setNewsQuizzesListNextToken(data.newsQuizList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `newsQuizList`, e.message)
      }
      return false
    }
  }

  // get current news quiz
  const getCurrentNewsQuiz = useCallback(
    async (quizId: string) => {
      try {
        logger(Status.Api, "QUERY newsQuizGet", newsQuizGet)
        const { data } = await newsQuizGetQuery({
          variables: { input: { id: quizId } },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `news quiz ${quizId}`, data.newsQuizGet)

        setCurrentNewsQuiz(data.newsQuizGet)
        setPreChangesCurrentNewsQuiz(deepCopy(data.newsQuizGet))
        data.newsQuizGet.document.items.forEach((item: NewsQuizDocument) => {
          translationsErrors.push({ lang: item.lang, hasErrors: false })
        })
        setTranslationsErrors([...translationsErrors])

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `newsQuizGet`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // upsert news quiz parent
  const upsertNewsQuizParent = async () => {
    try {
      const input = {
        id: currentNewsQuiz.id,
        newsId: currentNewsQuiz.newsId,
        order: 0,
      }

      logger(Status.Api, "MUTATION newsQuizUpsert", newsQuizUpsert)
      const { data } = await newsQuizUpsertMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `news quiz ${data.newsQuizUpsert.id} upserted`,
        data.newsQuizUpsert
      )

      setCurrentNewsQuiz((current) => {
        return {
          ...data.newsQuizUpsert,
          document: current.document,
        }
      })
      setPreChangesCurrentNewsQuiz((current) => {
        return {
          ...data.newsQuizUpsert,
          document: current.document,
        }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `newsQuizUpsert`, e.message)
      }
      return false
    }
  }

  // upsert news quiz document
  const upsertNewsQuizDocument = async () => {
    try {
      // parse data for inpout
      const currentNewsCopy = deepCopy(currentNewsQuiz)
      currentNewsCopy.document.items.forEach((item: any) => {
        item.default = item.isDefault
        delete item.isDefault
        delete item.type
        delete item.updatedAt
        delete item.__typename
        if (!item.explanation) {
          delete item.explanation
        }
      })

      const input = {
        newsQuizDocumentItems: currentNewsCopy.document.items,
        parentId: currentNewsCopy.document.parentId,
        type: "NewsQuiz",
      }

      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentNewsQuiz)
      const { data } = await upsertDocumentNewsQuizMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `news quiz ${data.documentUpsert.parentId} document upserted`,
        data.documentUpsert
      )

      setCurrentNewsQuiz((current) => {
        return {
          ...current,
          document: data.documentUpsert,
        }
      })
      setPreChangesCurrentNewsQuiz((current) => {
        return {
          ...current,
          document: deepCopy(data.documentUpsert),
        }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // create news quiz
  const createNewsQuiz = async (input: { newsId: string }) => {
    try {
      logger(Status.Api, "MUTATION newsQuizUpsert", newsQuizUpsert)
      const { data } = await newsQuizUpsertMutation({
        variables: { input: { ...input, order: 0 } },
      })
      logger(Status.Info, `news created`, data.newsQuizUpsert)

      return data.newsQuizUpsert as NewsQuiz
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `newsQuizUpsert`, e.message)
      }
      return null
    }
  }

  // create news quiz document
  const createNewsQuizDocument = async (input: {
    parentId: string
    type: string
    newsQuizDocumentItems: {
      default: boolean
      lang: string
      question: string
      opt1: string
      opt2: string
      opt3: string
      opt4: string
      correctAnswer: string
      explanation?: string
    }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentNewsQuiz)
      const { data } = await upsertDocumentNewsQuizMutation({
        variables: { input: input },
      })
      logger(Status.Info, `document created`, data.documentUpsert)

      return data.documentUpsert
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return null
    }
  }

  // delete news quiz
  const deleteNewsQuiz = async (newsToDeleteId: string) => {
    try {
      logger(Status.Api, "MUTATION newsQuizDelete", newsQuizDelete)
      const { data } = await newsQuizDeleteMutation({
        variables: { input: { id: newsToDeleteId } },
      })
      logger(Status.Info, `news quiz ${data.newsQuizDelete.id} deleted`)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `newsQuizDelete`, e.message)
      }
      return false
    }
  }

  // add translation
  const addTranslation = (translationToAdd: string) => {
    const documentToAdd: NewsQuizDocument = {
      isDefault: false,
      lang: translationToAdd,
      question: "",
      opt1: "",
      opt2: "",
      opt3: "",
      opt4: "",
      correctAnswer: "",
      type: "NewsQuiz",
    }

    translationsErrors.push({
      lang: translationToAdd,
      hasErrors: false,
    })
    setTranslationsErrors([...translationsErrors])

    currentNewsQuiz.document.items.push(documentToAdd)
    setCurrentNewsQuiz({ ...currentNewsQuiz })
  }

  // copy details from default translation
  const copyDetailsFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentNewsQuiz.document.items.find(
      (item) => item.isDefault
    )

    currentNewsQuiz.document.items[itemToCopyToIndex] = {
      ...deepCopy(defaultItem),
      lang: currentNewsQuiz.document.items[itemToCopyToIndex].lang,
      isDefault: currentNewsQuiz.document.items[itemToCopyToIndex].isDefault,
    }

    setCurrentNewsQuiz({ ...currentNewsQuiz })
  }

  // cancel changes
  const cancelChanges = () => {
    setCurrentNewsQuiz(deepCopy(preChangesCurrentNewsQuiz))
    resetErrors()
  }

  // check if user has done changes
  useEffect(() => {
    if (
      JSON.stringify(currentNewsQuiz) !==
      JSON.stringify(preChangesCurrentNewsQuiz)
    ) {
      setDoneChanges(true)
    } else {
      setDoneChanges(false)
    }
  }, [currentNewsQuiz])

  return (
    <NewsQuizzesContext.Provider
      value={{
        loading,
        setLoading,
        editMode,
        setEditMode,
        newsQuizzesList,
        getNewsQuizzesList,
        doneChanges,
        cancelChanges,
        currentNewsQuiz,
        setCurrentNewsQuiz,
        getCurrentNewsQuiz,
        preChangesCurrentNewsQuiz,
        addTranslation,
        upsertNewsQuizParent,
        hasError,
        translationsErrors,
        setTranslationsErrors,
        upsertNewsQuizDocument,
        copyDetailsFromDefault,
        createNewsQuiz,
        createNewsQuizDocument,
        newsQuizzesListNextToken,
        loadMoreNewsQuizzes,
        deleteNewsQuiz,
      }}
    >
      {children}
    </NewsQuizzesContext.Provider>
  )
}

export { NewsQuizzesController, NewsQuizzesContext }
