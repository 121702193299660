import {
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { useContext, useState } from "react"
import { TeamsContext } from "../../controllers/teams"
import { AddCircleRounded, DeleteForeverRounded } from "@mui/icons-material"
import { capitalizeFirstCharacter } from "../../services/utilities/utility"
import { SurveysContext } from "../../controllers/surveys"
import Survey from "../../models/survey"
import { CreateSurveyDialog } from "../survey"
import { LoadingButton } from "@mui/lab"

const TeamGeneralEditSurveysTab = () => {
  const {
    editMode,
    currentTeam,
    currentTeamSurveysList,
    setCurrentTeamSurveysList,
  } = useContext(TeamsContext)
  const { deleteSurvey, setLoading: setSurveysLoading } =
    useContext(SurveysContext)

  // create survey dialog
  const [createSurveyDialogOpen, setCreateSurveyDialogOpen] =
    useState<boolean>(false)

  const handleCreateSurveyDialogChange = () => {
    setCreateSurveyDialogOpen((current) => !current)
  }

  // delete survey
  const [surveyToDelete, setSurveyToDelete] = useState<Survey | null>(null)
  const [deletingSurvey, setDeletingSurvey] = useState<boolean>(false)
  const [deleteSurveyDialogOpen, setDeleteSurveyDialogOpen] =
    useState<boolean>(false)

  const handleDeleteSurveyDialogChange = () => {
    setDeleteSurveyDialogOpen((current) => !current)
  }

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Surveys
        </Typography>
        {!currentTeamSurveysList.length ? (
          <Typography>No Surveys for this Team</Typography>
        ) : (
          <Stack>
            {currentTeamSurveysList.map((survey, index) => (
              <Card
                key={survey.id}
                style={{
                  minHeight: 60,
                  paddingInline: 24,
                }}
                variant="outlined"
                className="list-item-card"
                onClick={() => {
                  window.open(window.location.origin + "/surveys/" + survey.id)
                }}
                sx={{
                  boxShadow: "none",
                  borderTopLeftRadius: index === 0 ? undefined : 0,
                  borderTopRightRadius: index === 0 ? undefined : 0,
                  borderBottomLeftRadius:
                    index === currentTeamSurveysList.length - 1 ? undefined : 0,
                  borderBottomRightRadius:
                    index === currentTeamSurveysList.length - 1 ? undefined : 0,
                  borderTop: index === 0 ? undefined : "none",
                }}
              >
                <Stack
                  direction="row"
                  style={{
                    height: 60,
                    position: "relative",
                    paddingRight: 50,
                  }}
                  alignItems="center"
                  spacing={4}
                >
                  {survey.suspended ? (
                    <Chip size="small" label="Suspended" color="error" />
                  ) : survey.stage === "PUBLISHED" || survey.hasSibling ? (
                    <Chip
                      size="small"
                      label="Live"
                      color="success"
                      style={{ position: "relative", zIndex: 1 }}
                    />
                  ) : survey.stage === "ARCHIVED" ? (
                    <Chip
                      size="small"
                      label="Archived"
                      style={{ position: "relative", zIndex: 1 }}
                    />
                  ) : (
                    <Chip
                      size="small"
                      label="Draft"
                      color="warning"
                      style={{ position: "relative", zIndex: 1 }}
                    />
                  )}
                  {survey.hasSibling ? (
                    <Chip
                      size="small"
                      label="Draft"
                      color="warning"
                      style={{ position: "absolute", left: -29 }}
                    />
                  ) : null}
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      flex: 1,
                    }}
                  >
                    {survey.handle}
                  </Typography>
                  {new Date(survey.startsAt) > new Date() ? (
                    <Chip size="small" label="Not Started" color="default" />
                  ) : new Date(survey.endsAt) >= new Date() ? (
                    <Chip size="small" label="Current" color="success" />
                  ) : (
                    <Chip size="small" label="Ended" color="error" />
                  )}
                  <Chip
                    label={capitalizeFirstCharacter(
                      survey.provider.toLowerCase()
                    )}
                    size="small"
                  />
                  <IconButton
                    disabled={!editMode}
                    color="error"
                    style={{
                      position: "absolute",
                      right: -12,
                      top: 9,
                      zIndex: 1,
                    }}
                  >
                    <DeleteForeverRounded
                      style={{
                        width: 24,
                      }}
                    />
                  </IconButton>
                </Stack>
              </Card>
            ))}
          </Stack>
        )}
        <Button
          variant="outlined"
          endIcon={<AddCircleRounded />}
          fullWidth
          disabled={!editMode}
          style={{ height: 40 }}
          onClick={() => {
            setSurveysLoading(false)
            handleCreateSurveyDialogChange()
          }}
        >
          Add Survey
        </Button>
      </Stack>
      {/* create challenge for team */}
      <CreateSurveyDialog
        dialogOpen={createSurveyDialogOpen}
        setDialogOpen={setCreateSurveyDialogOpen}
        handleDialogChange={handleCreateSurveyDialogChange}
        preSelectedTeam={currentTeam}
        currentTeamSurveysList={currentTeamSurveysList}
        setCurrentTeamSurveysList={setCurrentTeamSurveysList}
      />
      {/* delete challenge dialog */}
      <Dialog
        open={deleteSurveyDialogOpen}
        onClose={deletingSurvey ? null : handleDeleteSurveyDialogChange}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to permanently delete this Survey?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The Survey "{surveyToDelete ? surveyToDelete.handle : ""}" will be{" "}
            <span style={{ fontWeight: 600 }}>permanently deleted</span> and the
            action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteSurveyDialogChange}
            disabled={deletingSurvey}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={deletingSurvey}
            onClick={async () => {
              setDeletingSurvey(true)

              const noErrors = await deleteSurvey(surveyToDelete.id)
              if (noErrors) {
                const indexToRemove = currentTeamSurveysList.findIndex(
                  (item) => item.id === surveyToDelete.id
                )
                currentTeamSurveysList.splice(indexToRemove, 1)
                setCurrentTeamSurveysList([...currentTeamSurveysList])
                setSurveyToDelete(null)
                handleDeleteSurveyDialogChange()
              }

              setDeletingSurvey(false)
            }}
            autoFocus
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TeamGeneralEditSurveysTab
