import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import ActionsDocument from "../models/actionsDocument"
import {
  deleteAction as deleteSingleAction,
  upsertAction,
  upsertDocumentAction,
} from "../services/graphql/mutations"
import { action, listActions, searchActions } from "../services/graphql/queries"
import { deepCopy, logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import { lowercaseFirstCharacter } from "../services/utilities/utility"
import Action from "../models/action"
import { ActionType } from "../services/config/enum"
import ActionSavingMetrics from "../models/actionSavingMetrics"
import Category from "../models/category"

interface AutocompleteOption {
  label: string
  id: string
}

interface ActionsContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  actionsList: Action[]
  setActionsList: Dispatch<SetStateAction<Action[]>>
  getActionsList: (withLoading?: boolean) => void
  doneChanges: boolean
  cancelChanges: () => void
  currentAction: Action
  setCurrentAction: Dispatch<SetStateAction<Action>>
  getCurrentAction: (actionId: string) => Promise<boolean>
  preChangesCurrentAction: Action
  addTranslation: (translationToAdd: string) => void
  upsertActionParent: (customInput?: {
    id: string
    handle: string
    image: string
    featured: boolean
    timesPerDay: number
    isRepeatable: boolean
    savingMetrics: ActionSavingMetrics[]
    category: Category
    doNotRecommend?: boolean
  }) => Promise<boolean>
  hasError: boolean
  handleError: boolean
  setHandleError: Dispatch<SetStateAction<boolean>>
  timesPerDayError: boolean
  setTimesPerDayError: Dispatch<SetStateAction<boolean>>
  translationsErrors: { lang: string; hasErrors: boolean }[]
  setTranslationsErrors: Dispatch<
    SetStateAction<{ lang: string; hasErrors: boolean }[]>
  >
  upsertActionDocument: (customAction?: Action) => Promise<boolean>
  copyDetailsFromDefault: (itemToCopyToIndex: number) => void
  copyBodyFromDefault: (itemToCopyToIndex: number) => void
  createAction: (input: {
    handle: string
    isRepeatable: boolean
    image: string
    category: {
      id: string
    }
    savingMetrics: {
      metrics: {
        action: number
        activity: number
        co2Saving: number
        energySaving: number
        waterSaving: number
      }
      location: {
        id: string
        type: "Country" | "Region" | "SubRegion" | "DefaultLocation"
      }
    }[]
    sdgs: { primary: boolean; id: string }[]
    sdgTargets: { primary: boolean; id: string }[]
    timesPerDay: number
    featured: boolean
    teamId: string
  }) => Promise<Action | boolean>
  createActionDocument: (input: {
    parentId: string
    type: string
    actionDocumentItems: {
      body: object[]
      default: boolean
      lang: string
      title: string
      assumptions?: string
    }[]
  }) => Promise<boolean>
  actionsListNextToken: string | null
  loadMoreActions: () => Promise<boolean>
  hasSearched: boolean
  setHasSearched: Dispatch<SetStateAction<boolean | null>>
  searchIsRepeatable: boolean | null
  setSearchIsRepeatable: Dispatch<SetStateAction<boolean | null>>
  searchLang: AutocompleteOption | null
  setSearchLang: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchSavingMetrics: AutocompleteOption[] | null
  setSearchSavingMetrics: Dispatch<SetStateAction<AutocompleteOption[] | null>>
  searchSdg: AutocompleteOption | null
  setSearchSdg: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchSdgTarget: AutocompleteOption | null
  setSearchSdgTarget: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchCategory: AutocompleteOption | null
  setSearchCategory: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchTimesPerDay: number | null
  setSearchTimesPerDay: Dispatch<SetStateAction<number | null>>
  searchTitle: string
  setSearchTitle: Dispatch<SetStateAction<string>>
  searchFeatured: boolean | null
  setSearchFeatured: Dispatch<SetStateAction<boolean | null>>
  searchType: AutocompleteOption
  setSearchType: Dispatch<SetStateAction<AutocompleteOption>>
  searchAlreadyAssigned: boolean | null
  setSearchAlreadyAssigned: Dispatch<SetStateAction<boolean | null>>
  searchActionsList: (
    withLoading?: boolean,
    withNextToken?: boolean
  ) => Promise<boolean>
  savingMetricsError: {
    locationError: boolean
    actionsError: boolean
    co2Error: boolean
    waterError: boolean
    energyError: boolean
  }[]
  setSavingMetricsError: Dispatch<
    SetStateAction<
      {
        locationError: boolean
        actionsError: boolean
        co2Error: boolean
        waterError: boolean
        energyError: boolean
      }[]
    >
  >
  resetFilters: () => void
  deleteAction: (actionToDeleteId: string) => Promise<boolean>
  categoryError: boolean
  setCategoryError: Dispatch<SetStateAction<boolean>>
  getAllActionsList: (titlesLanguage: string) => Promise<string[][]>
}

const ActionsContext = createContext<ActionsContextInterface>({
  loading: true,
  setLoading: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  editMode: false,
  setEditMode: () => {},
  actionsList: [],
  setActionsList: () => {},
  getActionsList: () => {},
  doneChanges: false,
  cancelChanges: () => {},
  currentAction: new Action(),
  setCurrentAction: () => {},
  getCurrentAction: async () => true,
  preChangesCurrentAction: new Action(),
  addTranslation: () => {},
  upsertActionParent: async () => true,
  hasError: false,
  handleError: false,
  setHandleError: () => {},
  timesPerDayError: false,
  setTimesPerDayError: () => {},
  translationsErrors: [],
  setTranslationsErrors: () => {},
  upsertActionDocument: async () => true,
  copyDetailsFromDefault: () => {},
  copyBodyFromDefault: () => {},
  createAction: async () => new Action(),
  createActionDocument: async () => true,
  actionsListNextToken: null,
  loadMoreActions: async () => true,
  hasSearched: false,
  setHasSearched: () => {},
  searchIsRepeatable: null,
  setSearchIsRepeatable: () => {},
  searchLang: null,
  setSearchLang: () => {},
  searchSavingMetrics: null,
  setSearchSavingMetrics: () => {},
  searchSdg: null,
  setSearchSdg: () => {},
  searchSdgTarget: null,
  setSearchSdgTarget: () => {},
  searchCategory: null,
  setSearchCategory: () => {},
  searchTimesPerDay: null,
  setSearchTimesPerDay: () => {},
  searchTitle: "",
  setSearchTitle: () => {},
  searchFeatured: null,
  setSearchFeatured: () => {},
  searchType: null,
  setSearchType: () => {},
  searchAlreadyAssigned: null,
  setSearchAlreadyAssigned: () => {},
  searchActionsList: async () => true,
  savingMetricsError: [],
  setSavingMetricsError: () => {},
  resetFilters: () => {},
  deleteAction: async () => true,
  categoryError: false,
  setCategoryError: () => {},
  getAllActionsList: async () => [],
})

const ActionsController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [updatingList, setUpdatingList] = useState<boolean>(false) // to show loader
  const [editMode, setEditMode] = useState<boolean>(true) // if all page should be disabled or not
  const [actionsList, setActionsList] = useState<Action[]>([]) // list of all actions
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes
  const [currentAction, setCurrentAction] = useState<Action>(new Action()) // current action
  const [preChangesCurrentAction, setPreChangesCurrentAction] =
    useState<Action>(new Action()) // fetched current action
  const [actionsListNextToken, setActionsListNextToken] = useState<
    string | null
  >(null) // next token for actions list
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not

  // search states
  const [searchIsRepeatable, setSearchIsRepeatable] = useState<boolean | null>(
    null
  )
  const [searchLang, setSearchLang] = useState<AutocompleteOption | null>(null)
  const [searchSavingMetrics, setSearchSavingMetrics] = useState<
    AutocompleteOption[] | null
  >(null)
  const [searchSdg, setSearchSdg] = useState<AutocompleteOption | null>(null)
  const [searchSdgTarget, setSearchSdgTarget] =
    useState<AutocompleteOption | null>(null)
  const [searchCategory, setSearchCategory] =
    useState<AutocompleteOption | null>(null)
  const [searchTimesPerDay, setSearchTimesPerDay] = useState<number | null>(
    null
  )
  const [searchFeatured, setSearchFeatured] = useState<boolean | null>(null)
  const [searchType, setSearchType] = useState<AutocompleteOption | null>(null)
  const [searchAlreadyAssigned, setSearchAlreadyAssigned] = useState<
    boolean | null
  >(null)
  const [searchTitle, setSearchTitle] = useState<string>("")

  // errors for action edit
  const [handleError, setHandleError] = useState<boolean>(false) // error for action handle
  const [timesPerDayError, setTimesPerDayError] = useState<boolean>(false) // error for action times per day
  const [categoryError, setCategoryError] = useState<boolean>(false) // error for action category
  const [savingMetricsError, setSavingMetricsError] = useState<
    {
      locationError: boolean
      actionsError: boolean
      co2Error: boolean
      waterError: boolean
      energyError: boolean
    }[]
  >([]) // error for saving metrics
  const [translationsErrors, setTranslationsErrors] = useState<
    { lang: string; hasErrors: boolean }[]
  >([]) // errors array for translations
  const hasError: boolean =
    handleError ||
    timesPerDayError ||
    categoryError ||
    savingMetricsError.filter(
      (item) =>
        item.actionsError ||
        item.co2Error ||
        item.energyError ||
        item.locationError ||
        item.waterError
    ).length !== 0 ||
    translationsErrors.filter((item) => item.hasErrors).length !== 0 // if there are errors or not

  // reset all errors
  const resetErrors = () => {
    setHandleError(false)
    setTimesPerDayError(false)
    setCategoryError(false)
    const newSavingMetricsErrors: {
      locationError: boolean
      actionsError: boolean
      co2Error: boolean
      waterError: boolean
      energyError: boolean
    }[] = []
    currentAction.savingMetrics.forEach((item) => {
      newSavingMetricsErrors.push({
        locationError: false,
        actionsError: false,
        co2Error: false,
        waterError: false,
        energyError: false,
      })
    })
    setSavingMetricsError(newSavingMetricsErrors)
    const newTranslationsErrors: { lang: string; hasErrors: boolean }[] = []
    currentAction.document.items.forEach((item) => {
      newTranslationsErrors.push({ lang: item.lang, hasErrors: false })
    })
    setTranslationsErrors(newTranslationsErrors)
  }

  // queries
  const [actionsListQuery] = useLazyQuery(listActions)
  const [actionQuery] = useLazyQuery(action)
  const [searchActionsQuery] = useLazyQuery(searchActions)

  // mutations
  const [upsertActionMutation] = useMutation(upsertAction)
  const [upsertDocumentMutation] = useMutation(upsertDocumentAction)
  const [deleteActionMutation] = useMutation(deleteSingleAction)

  // get actions list
  const getActionsList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Actions")[0].loading = true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY actionsList", listActions)
        const { data } = await actionsListQuery({
          variables: {
            input: { limit: Math.round(window.innerHeight / 74) + 10 },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "actions list", data.actionsList.items)

        setActionsList(data.actionsList.items)
        setActionsListNextToken(data.actionsList.nextToken)

        setHasSearched(false)
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Actions")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Actions")[0].loading = false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `actionsList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Actions")[0].status =
          "error"
        panelStatus.filter((item) => item.name === "Actions")[0].loading = false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // load more actions
  const loadMoreActions = async () => {
    try {
      logger(Status.Api, "QUERY actionsList", listActions)
      const { data } = await actionsListQuery({
        variables: { input: { nextToken: actionsListNextToken } },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "actions list", [
        ...actionsList,
        ...data.actionsList.items,
      ])

      setActionsList([...actionsList, ...data.actionsList.items])
      setActionsListNextToken(data.actionsList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `actionsList`, e.message)
      }
      return false
    }
  }

  // search actions
  const searchActionsList = async (
    withLoading = true,
    withNextToken = false
  ) => {
    if (withLoading) {
      setUpdatingList(true)
    }

    try {
      const input: {
        limit: number
        isRepeatable?: boolean
        lang?: string
        nextToken?: string
        savingMetrics?: string[]
        sdg?: string
        sdgTarget?: string
        timesPerDay?: number
        title?: string
        featured?: boolean
        alreadyAssigned?: boolean
        category?: string
        teamId?: string
      } = {
        limit: Math.round(window.innerHeight / 74) + 10,
        isRepeatable: searchIsRepeatable,
        lang: searchLang ? searchLang.id : null,
        savingMetrics: searchSavingMetrics
          ? searchSavingMetrics.map((item) => item.id)
          : null,
        sdg: searchSdg ? searchSdg.id : null,
        sdgTarget: searchSdgTarget ? searchSdgTarget.id : null,
        timesPerDay: searchTimesPerDay,
        title: searchTitle,
        featured: searchFeatured,
        alreadyAssigned: searchAlreadyAssigned,
        category: searchCategory ? searchCategory.id : null,
        teamId:
          searchType && searchType.id === ActionType.Standard
            ? "team_default"
            : searchType && searchType.id === ActionType.Autolog
            ? "team_motion_tag"
            : null,
      }

      if (hasSearched && withNextToken) {
        input.nextToken = actionsListNextToken
      }

      logger(Status.Api, "QUERY actionsSearch", searchActions)
      const { data } = await searchActionsQuery({
        variables: { input: input },
        fetchPolicy: "no-cache",
      })

      if (input.nextToken) {
        logger(Status.Info, "actions list", [
          ...actionsList,
          ...data.actionsSearch.items,
        ])

        setActionsList([...actionsList, ...data.actionsSearch.items])
        setActionsListNextToken(data.actionsSearch.nextToken)
      } else {
        logger(Status.Info, "actions list", data.actionsSearch.items)

        setActionsList(data.actionsSearch.items)
        setActionsListNextToken(data.actionsSearch.nextToken)
      }

      setHasSearched(true)
      if (withLoading) {
        setUpdatingList(false)
      }
      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `actionsSearch`, e.message)
      }
      if (withLoading) {
        setUpdatingList(false)
      }
      return false
    }
  }

  // get current action
  const getCurrentAction = useCallback(
    async (actionId: string) => {
      try {
        logger(Status.Api, "QUERY actionGet", action)
        const { data } = await actionQuery({
          variables: { input: { id: actionId } },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `action ${actionId}`, data.actionGet)

        setCurrentAction(data.actionGet)
        setPreChangesCurrentAction(deepCopy(data.actionGet))
        data.actionGet.document.items.forEach((item: ActionsDocument) => {
          translationsErrors.push({ lang: item.lang, hasErrors: false })
        })
        setTranslationsErrors([...translationsErrors])
        data.actionGet.savingMetrics.forEach((item: any) => {
          savingMetricsError.push({
            locationError: false,
            actionsError: false,
            co2Error: false,
            waterError: false,
            energyError: false,
          })
        })
        setSavingMetricsError([...savingMetricsError])

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `actionGet`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // upsert action parent
  const upsertActionParent = async (customInput?: {
    id: string
    handle: string
    image: string
    featured: boolean
    timesPerDay: number
    isRepeatable: boolean
    savingMetrics: ActionSavingMetrics[]
    category: Category
    doNotRecommend?: boolean
  }) => {
    try {
      if (customInput) {
        // logic for parent updates from list
        logger(Status.Api, "MUTATION actionUpsert", upsertAction)
        const { data } = await upsertActionMutation({
          variables: {
            input: {
              ...customInput,
              category: {
                id: customInput.category.id,
              },
              doNotRecommend: customInput.doNotRecommend,
              savingMetrics: customInput.savingMetrics.map((item) => {
                return {
                  metrics: {
                    action: item.metrics.action,
                    activity: item.metrics.activity,
                    co2Saving: item.metrics.co2Saving,
                    energySaving: item.metrics.energySaving,
                    waterSaving: item.metrics.waterSaving,
                  },
                  location: {
                    id: item.location.id,
                    type: item.location.__typename,
                  },
                }
              }),
            },
          },
        })
        logger(
          Status.Info,
          `action ${data.actionUpsert.id} upserted`,
          data.actionUpsert
        )

        // update list locally
        if (actionsList.some((team) => team.id === customInput.id)) {
          const itemToUpdate = actionsList.find(
            (team) => team.id === customInput.id
          )
          itemToUpdate.featured = data.actionUpsert.featured
          itemToUpdate.doNotRecommend = data.actionUpsert.doNotRecommend

          setActionsList([...actionsList])
        }
      } else {
        const input = {
          id: currentAction.id,
          handle: currentAction.handle,
          image: currentAction.image,
          isRepeatable: currentAction.isRepeatable,
          savingMetrics: currentAction.savingMetrics.map((item) => {
            return {
              metrics: {
                action: item.metrics.action,
                activity: item.metrics.activity,
                co2Saving: item.metrics.co2Saving,
                energySaving: item.metrics.energySaving,
                waterSaving: item.metrics.waterSaving,
              },
              location: {
                id: item.location.id,
                type: item.location.__typename,
              },
            }
          }),
          timesPerDay: currentAction.timesPerDay,
          sdgs: [
            ...currentAction.sdgs.map((sdg) => {
              return { id: sdg.sdg.id, primary: sdg.primary }
            }),
          ],
          sdgTargets: [
            ...currentAction.sdgTargets.map((sdgTarget) => {
              return { id: sdgTarget.sdgTarget.id, primary: sdgTarget.primary }
            }),
          ],
          category: {
            id: currentAction.category.id,
          },
          featured: currentAction.featured,
          doNotRecommend: currentAction.doNotRecommend,
        }

        logger(Status.Api, "MUTATION actionUpsert", upsertAction)
        const { data } = await upsertActionMutation({
          variables: { input: input },
        })
        logger(
          Status.Info,
          `action ${data.actionUpsert.id} upserted`,
          data.actionUpsert
        )

        currentAction.id = data.actionUpsert.id
        currentAction.updatedAt = data.actionUpsert.updatedAt
        currentAction.document.parentId = data.actionUpsert.document.parentId
        currentAction.handle = data.actionUpsert.handle
        currentAction.category = data.actionUpsert.category
        currentAction.isRepeatable = data.actionUpsert.isRepeatable
        currentAction.savingMetrics = data.actionUpsert.savingMetrics
        currentAction.timesPerDay = data.actionUpsert.timesPerDay
        currentAction.sdgs = data.actionUpsert.sdgs
        currentAction.sdgTargets = data.actionUpsert.sdgTargets
        currentAction.image = data.actionUpsert.image
        currentAction.featured = data.actionUpsert.featured
        currentAction.doNotRecommend = data.actionUpsert.doNotRecommend
        setCurrentAction({ ...currentAction })
        setPreChangesCurrentAction(deepCopy({ ...currentAction }))
      }

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `actionUpsert`, e.message)
      }
      return false
    }
  }

  // upsert action document
  const upsertActionDocument = async (customAction?: Action) => {
    try {
      const actionToUpsertDocumentOf = customAction ?? currentAction

      // parse data for input
      const currentActionCopy = deepCopy(actionToUpsertDocumentOf)
      currentActionCopy.document.items.forEach((item: any) => {
        if (item.body) {
          const newBody = []
          item.body.forEach((bodyItem: any) => {
            const { sliceType, __typename, ...rest } = bodyItem
            newBody.push({
              [lowercaseFirstCharacter(bodyItem.sliceType)]: {
                ...rest,
              },
            })
          })
          item.body = newBody
        }

        item.default = item.isDefault
        delete item.isDefault
        delete item.type
        delete item.updatedAt
        delete item.parentId
        delete item.__typename
      })

      const input = {
        actionDocumentItems: currentActionCopy.document.items,
        parentId: currentActionCopy.document.parentId,
        type: "Action",
      }

      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentAction)
      const { data } = await upsertDocumentMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `action ${data.documentUpsert.parentId} document upserted`,
        data.documentUpsert
      )

      if (!customAction) {
        const currentActionSecondCopy: Action = deepCopy(currentAction)
        currentActionSecondCopy.document = data.documentUpsert
        setCurrentAction(currentActionSecondCopy)
        setPreChangesCurrentAction(deepCopy(currentActionSecondCopy))
      }

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // create action
  const createAction = async (input: {
    handle: string
    isRepeatable: boolean
    image: string
    category: {
      id: string
    }
    savingMetrics: {
      metrics: {
        action: number
        activity: number
        co2Saving: number
        energySaving: number
        waterSaving: number
      }
      location: {
        id: string
        type: "Country" | "Region" | "SubRegion" | "DefaultLocation"
      }
    }[]
    sdgs: { primary: boolean; id: string }[]
    sdgTargets: { primary: boolean; id: string }[]
    timesPerDay: number
    featured: boolean
    teamId: string
  }) => {
    try {
      logger(Status.Api, "MUTATION actionUpsert", upsertAction)
      const { data } = await upsertActionMutation({
        variables: { input: input },
      })
      logger(Status.Info, `action created`, data.actionUpsert)

      return data.actionUpsert
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `actionUpsert`, e.message)
      }
      return false
    }
  }

  // create action document
  const createActionDocument = async (input: {
    parentId: string
    type: string
    actionDocumentItems: {
      body: object[]
      default: boolean
      lang: string
      title: string
      assumptions?: string
    }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentAction)
      const { data } = await upsertDocumentMutation({
        variables: { input: input },
      })
      logger(Status.Info, `document created`, data.documentUpsert)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // delete action
  const deleteAction = async (actionToDeleteId: string) => {
    try {
      logger(Status.Api, "MUTATION actionDelete", deleteSingleAction)
      const { data } = await deleteActionMutation({
        variables: { input: { id: actionToDeleteId } },
      })
      logger(
        Status.Info,
        `action ${data.actionDelete.id} deleted: ${data.actionDelete.deleted}`
      )

      return data.actionDelete.deleted
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `actionDelete`, e.message)
      }
      return false
    }
  }

  // get all actions list for csv download
  const getAllActionsList = async (titlesLanguage: string) => {
    try {
      let nextToken: string = null
      let list = []

      if (
        searchIsRepeatable ||
        searchLang ||
        searchSavingMetrics ||
        searchSdg ||
        searchSdgTarget ||
        searchTimesPerDay ||
        searchTitle ||
        searchFeatured ||
        searchCategory ||
        searchType
      ) {
        do {
          const input: {
            limit: number
            nextToken: string
            isRepeatable?: boolean
            lang?: string
            savingMetrics?: string[]
            sdg?: string
            sdgTarget?: string
            timesPerDay?: number
            title?: string
            featured?: boolean
            alreadyAssigned?: boolean
            category?: string
            teamId?: string
          } = {
            limit: 50,
            nextToken: nextToken,
            isRepeatable: searchIsRepeatable,
            lang: searchLang ? searchLang.id : null,
            savingMetrics: searchSavingMetrics
              ? searchSavingMetrics.map((item) => item.id)
              : null,
            sdg: searchSdg ? searchSdg.id : null,
            sdgTarget: searchSdgTarget ? searchSdgTarget.id : null,
            timesPerDay: searchTimesPerDay,
            title: searchTitle,
            featured: searchFeatured,
            alreadyAssigned: searchAlreadyAssigned,
            category: searchCategory ? searchCategory.id : null,
            teamId:
              searchType && searchType.id === ActionType.Standard
                ? "team_default"
                : searchType && searchType.id === ActionType.Autolog
                ? "team_motion_tag"
                : null,
          }

          logger(Status.Api, "QUERY actionsSearch", searchActions)
          const { data } = await searchActionsQuery({
            variables: {
              input: input,
            },
            fetchPolicy: "no-cache",
          })
          list.push(...data.actionsSearch.items)
          nextToken = data.actionsSearch.nextToken
        } while (nextToken !== null)
      } else {
        do {
          logger(Status.Api, "QUERY actionsList", listActions)
          const { data } = await actionsListQuery({
            variables: {
              input: { limit: 50, nextToken: nextToken },
            },
            fetchPolicy: "no-cache",
          })
          list.push(...data.actionsList.items)
          nextToken = data.actionsList.nextToken
        } while (nextToken !== null)
      }

      let data = [
        [
          "id",
          "title",
          "translations",
          "co2 (kg)",
          "energy (kWh)",
          "water (l)",
          "featured",
          "category",
          "points",
        ],
      ]
      for (let i = 0; i < list.length; i++) {
        data.push([
          list[i].id,
          list[i].document.items.find(
            (item: any) => item.lang === titlesLanguage
          )
            ? list[i].document.items.filter(
                (item: any) => item.lang === titlesLanguage
              )[0].title
            : list[i].document.items.filter((item: any) => item.isDefault)[0]
                .title,
          list[i].document.items.map((item: any) => item.lang),
          list[i].savingMetrics[0].metrics.co2Saving,
          list[i].savingMetrics[0].metrics.energySaving,
          list[i].savingMetrics[0].metrics.waterSaving,
          list[i].featured ? "yes" : "no",
          list[i].category.name,
          list[i].points,
        ])
      }
      logger(Status.Info, `all actions list`, data)

      return data
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        if (
          searchIsRepeatable ||
          searchLang ||
          searchSavingMetrics ||
          searchSdg ||
          searchSdgTarget ||
          searchTimesPerDay ||
          searchTitle ||
          searchFeatured ||
          searchCategory ||
          searchType
        ) {
          logger(Status.Error, `actionsSearch`, e.message)
        } else {
          logger(Status.Error, `actionsList`, e.message)
        }
      }
    }
  }

  // add translation
  const addTranslation = (translationToAdd: string) => {
    const documentToAdd: ActionsDocument = {
      body: [],
      isDefault: false,
      lang: translationToAdd,
      title: "Title",
      type: "Action",
      assumptions: null,
    }

    translationsErrors.push({
      lang: translationToAdd,
      hasErrors: false,
    })
    setTranslationsErrors([...translationsErrors])

    currentAction.document.items.push(documentToAdd)
    setCurrentAction({ ...currentAction })
  }

  // copy details from default translation
  const copyDetailsFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentAction.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentAction.document.items[itemToCopyToIndex].title = defaultItem.title
    currentAction.document.items[itemToCopyToIndex].assumptions =
      defaultItem.assumptions

    setCurrentAction({ ...currentAction })
  }

  // copy body from default translation
  const copyBodyFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentAction.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentAction.document.items[itemToCopyToIndex].body = defaultItem.body

    setCurrentAction({ ...currentAction })
  }

  // cancel changes
  const cancelChanges = () => {
    setCurrentAction(deepCopy(preChangesCurrentAction))
    resetErrors()
  }

  // check if user has done changes
  useEffect(() => {
    const currentActionCopy: Action = deepCopy(currentAction)
    const preChangesCurrentActionCopy: Action = deepCopy(
      preChangesCurrentAction
    )

    delete currentActionCopy.category
    delete preChangesCurrentActionCopy.category

    if (
      JSON.stringify(currentActionCopy) !==
        JSON.stringify(preChangesCurrentActionCopy) ||
      (currentAction.category && !preChangesCurrentAction.category) ||
      (!currentAction.category && preChangesCurrentAction.category) ||
      (currentAction.category &&
        preChangesCurrentAction.category &&
        currentAction.category.id !== preChangesCurrentAction.category.id)
    ) {
      setDoneChanges(true)
    } else {
      setDoneChanges(false)
    }
  }, [currentAction])

  // reset list filters
  const resetFilters = () => {
    setSearchIsRepeatable(null)
    setSearchLang(null)
    setSearchSavingMetrics(null)
    setSearchSdg(null)
    setSearchSdgTarget(null)
    setSearchTimesPerDay(null)
    setSearchCategory(null)
    setSearchFeatured(null)
    setSearchType(null)
    setSearchAlreadyAssigned(null)
    setSearchTitle("")
  }

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "Actions")[0].updateFunction =
      getActionsList
  }, [])

  return (
    <ActionsContext.Provider
      value={{
        loading,
        setLoading,
        updatingList,
        setUpdatingList,
        editMode,
        setEditMode,
        actionsList,
        setActionsList,
        getActionsList,
        doneChanges,
        cancelChanges,
        currentAction,
        setCurrentAction,
        getCurrentAction,
        preChangesCurrentAction,
        addTranslation,
        upsertActionParent,
        hasError,
        handleError,
        setHandleError,
        timesPerDayError,
        setTimesPerDayError,
        translationsErrors,
        setTranslationsErrors,
        upsertActionDocument,
        copyDetailsFromDefault,
        copyBodyFromDefault,
        createAction,
        createActionDocument,
        actionsListNextToken,
        loadMoreActions,
        hasSearched,
        setHasSearched,
        searchIsRepeatable,
        setSearchIsRepeatable,
        searchLang,
        setSearchLang,
        searchSavingMetrics,
        setSearchSavingMetrics,
        searchSdg,
        setSearchSdg,
        searchSdgTarget,
        setSearchSdgTarget,
        searchCategory,
        setSearchCategory,
        searchType,
        setSearchType,
        searchTimesPerDay,
        setSearchTimesPerDay,
        searchTitle,
        setSearchTitle,
        searchFeatured,
        setSearchFeatured,
        searchAlreadyAssigned,
        setSearchAlreadyAssigned,
        searchActionsList,
        savingMetricsError,
        setSavingMetricsError,
        resetFilters,
        deleteAction,
        categoryError,
        setCategoryError,
        getAllActionsList,
      }}
    >
      {children}
    </ActionsContext.Provider>
  )
}

export { ActionsController, ActionsContext }
