import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Dialog,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { DialogTransition } from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { useNavigate, useParams } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import { LanguagesContext } from "../../controllers/languages"
import { NewsQuizzesContext } from "../../controllers/newsQuizzes"

const CreateNewsQuizDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
}) => {
  const { setAnimation } = useContext(MainContext)
  const { languages, languagesForEpisodeTabs } = useContext(LanguagesContext)
  const { loading, setLoading, createNewsQuiz, createNewsQuizDocument } =
    useContext(NewsQuizzesContext)
  const navigate = useNavigate()
  const { newsId } = useParams()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // default translation
  const [defaultTranslation, setDefaultTranslation] = useState<string>("")

  // document
  const [question, setQuestion] = useState<string>("")
  const [opt1, setOpt1] = useState<string>("")
  const [opt2, setOpt2] = useState<string>("")
  const [opt3, setOpt3] = useState<string>("")
  const [opt4, setOpt4] = useState<string>("")
  const [correctAnswer, setCorrectAnswer] = useState<string>("")
  const [explanation, setExplanation] = useState<string>("")

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Language",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Default language
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="language-select">Default language</InputLabel>
            <Select
              labelId="language-select"
              label="Default language"
              value={defaultTranslation}
              onChange={(e) => {
                setDefaultTranslation(e.target.value)
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      ),
    },
    {
      label: "Details",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Details in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <TextField
            size="small"
            label="Question"
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value)
            }}
            onBlur={() => {
              setQuestion((current) => current.trim())
            }}
          />
          <Stack direction="row" gap={2}>
            <TextField
              size="small"
              fullWidth
              label="Answer 1"
              value={opt1}
              onChange={(e) => {
                setOpt1(e.target.value)
              }}
              onBlur={() => {
                setOpt1((current) => current.trim())
              }}
            />
            <TextField
              size="small"
              fullWidth
              label="Answer 2"
              value={opt2}
              onChange={(e) => {
                setOpt2(e.target.value)
              }}
              onBlur={() => {
                setOpt2((current) => current.trim())
              }}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <TextField
              size="small"
              fullWidth
              label="Answer 3"
              value={opt3}
              onChange={(e) => {
                setOpt3(e.target.value)
              }}
              onBlur={() => {
                setOpt3((current) => current.trim())
              }}
            />
            <TextField
              size="small"
              fullWidth
              label="Answer 4"
              value={opt4}
              onChange={(e) => {
                setOpt4(e.target.value)
              }}
              onBlur={() => {
                setOpt4((current) => current.trim())
              }}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="correct-answer">Correct answer</InputLabel>
              <Select
                labelId="correct-answer"
                label="Correct answer"
                value={correctAnswer}
                onChange={(e) => {
                  setCorrectAnswer(e.target.value)
                }}
              >
                <MenuItem value="opt1">1</MenuItem>
                <MenuItem value="opt2">2</MenuItem>
                <MenuItem value="opt3">3</MenuItem>
                <MenuItem value="opt4">4</MenuItem>
              </Select>
            </FormControl>
            <TextField
              size="small"
              fullWidth
              label="Explanation"
              value={explanation}
              onChange={(e) => {
                setExplanation(e.target.value)
              }}
              onBlur={() => {
                setExplanation((current) => current.trim())
              }}
            />
          </Stack>
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setDefaultTranslation("")
        setQuestion("")
        setOpt1("")
        setOpt2("")
        setOpt3("")
        setOpt4("")
        setCorrectAnswer("")
        setExplanation("")
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (defaultTranslation) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [defaultTranslation])

  // create news quiz
  const createNewNewsQuiz = async () => {
    setLoading(true)

    const result = await createNewsQuiz({
      newsId,
    })

    if (result) {
      const documentInput: {
        parentId: string
        type: string
        newsQuizDocumentItems: {
          default: boolean
          lang: string
          question: string
          opt1: string
          opt2: string
          opt3: string
          opt4: string
          correctAnswer: string
          explanation?: string
        }[]
      } = {
        parentId: result.id,
        type: "NewsQuiz",
        newsQuizDocumentItems: [
          {
            default: true,
            lang: defaultTranslation,
            question: question,
            opt1: opt1,
            opt2: opt2,
            opt3: opt3,
            opt4: opt4,
            correctAnswer: correctAnswer,
          },
        ],
      }

      if (explanation) {
        documentInput.newsQuizDocumentItems[0].explanation = explanation
      }

      const secondResult = await createNewsQuizDocument(documentInput)

      if (secondResult) {
        setDialogOpen(false)
        setAnimation(false)
        setTimeout(() => {
          navigate(`${window.location.pathname}/${result.id}`)
        }, 250)
      }
    } else {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText="Add News Quiz"
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            Add News Quiz
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 && !defaultTranslation.length) ||
                  (currentSlide === 1 &&
                    (!question || !opt1 || !opt2 || !correctAnswer)) ||
                  (currentSlide === 1 && correctAnswer === "opt3" && !opt3) ||
                  (currentSlide === 1 && correctAnswer === "opt4" && !opt4)
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? createNewNewsQuiz
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateNewsQuizDialog
