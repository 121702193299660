import {
  Button,
  CardActions,
  CardContent,
  Paper,
  Stack,
  Typography,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Chip,
} from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { DialogTransition } from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { LanguagesContext } from "../../controllers/languages"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import Episode from "../../models/episode"
import { useMutation } from "@apollo/client"
import { translationsAiTranslate } from "../../services/graphql/mutations"
import { MainContext } from "../../controllers/main"
import { logger, Status } from "../../services/utilities/utility"
import { useNavigate } from "react-router-dom"
import BetaBadge from "../global/betaBadge"

const AiTranslateEpisodesDialog = ({
  dialogOpen,
  handleDialogChange,
  selectedEpisodesForTranslation,
  setSelectedEpisodesForTranslation,
}: {
  dialogOpen: boolean
  handleDialogChange: () => void
  selectedEpisodesForTranslation: {
    episode: Episode
    sourceLang?: string
    langs: string[]
  }[]
  setSelectedEpisodesForTranslation: Dispatch<
    SetStateAction<
      {
        episode: Episode
        sourceLang?: string
        langs: string[]
      }[]
    >
  >
}) => {
  const { languages } = useContext(LanguagesContext)
  const { setError, setErrorMessage } = useContext(MainContext)
  const navigate = useNavigate()

  // mutations
  const [translationsAiTranslateMutation] = useMutation(translationsAiTranslate)

  // loading
  const [translating, setTranslating] = useState<boolean>(false)

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Languages",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Languages
          </Typography>
          <Stack spacing={2} style={{ maxHeight: 306, overflowY: "auto" }}>
            {selectedEpisodesForTranslation.map((item) => (
              <Stack key={item.episode.id} spacing={1}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <img
                    src={item.episode.image ?? noImagePlaceholder}
                    style={{ width: 35, height: 35, borderRadius: 4 }}
                    alt={item.episode.title}
                  />
                  <Typography>{item.episode.title}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <FormControl size="small" style={{ width: 220 }}>
                    <InputLabel id="Source language">
                      Source language
                    </InputLabel>
                    <Select
                      labelId="Source language"
                      label="Source language"
                      value={item.sourceLang ?? ""}
                      onChange={(e) => {
                        item.sourceLang = e.target.value
                        item.langs = []
                        setSelectedEpisodesForTranslation([
                          ...selectedEpisodesForTranslation,
                        ])
                      }}
                    >
                      {Array.from(
                        new Set([
                          ...(item.episode.publishedLangs?.map(
                            ({ lang }) => lang
                          ) || []),
                          ...(item.episode.draftedLangs?.map(
                            ({ lang }) => lang
                          ) || []),
                        ])
                      ).map((lang) => (
                        <MenuItem value={lang} key={lang}>
                          {lang.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Autocomplete
                    disabled={!item.sourceLang}
                    fullWidth
                    getOptionDisabled={(option: string) =>
                      option === item.sourceLang?.toUpperCase()
                    }
                    multiple
                    size="small"
                    value={item.langs}
                    disablePortal
                    options={languages.map((lang) => lang.toUpperCase())}
                    renderInput={(params) => (
                      <TextField {...params} label="Target languages" />
                    )}
                    onChange={(e: any, newValues: string[]) => {
                      item.langs = newValues
                      setSelectedEpisodesForTranslation([
                        ...selectedEpisodesForTranslation,
                      ])
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    )}
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          size="small"
                          key={option}
                          label={option}
                        />
                      ))
                    }}
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // start translation
  const startTranslation = async () => {
    setTranslating(true)

    try {
      logger(
        Status.Api,
        "MUTATION translationsAiTranslate",
        translationsAiTranslate
      )

      const { data } = await translationsAiTranslateMutation({
        variables: {
          input: {
            episodes: selectedEpisodesForTranslation.map((item) => ({
              episodeId: item.episode.id,
              sourceLang: item.sourceLang,
              langs: item.langs.map((lang) => lang.toLowerCase()),
            })),
          },
        },
      })

      logger(
        Status.Info,
        "translations job created",
        data.translationsAiTranslate
      )

      setSelectedEpisodesForTranslation([])
      handleDialogChange()

      // Navigate to job details
      if (data.translationsAiTranslate?.jobId) {
        navigate(`/translationJobs/${data.translationsAiTranslate.jobId}`)
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, "translationsAiTranslate", e.message)
      }
    }

    setTranslating(false)
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={handleDialogChange}
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={handleDialogChange}
        topBarText="AI Translation"
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Stack
            gap={1.5}
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ width: "100%", paddingTop: 16 }}
          >
            <Typography variant="h4" textAlign="center">
              AI Translation
            </Typography>
            <BetaBadge />
          </Stack>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={() => {
                  if (!slides[currentSlide - 1]) {
                    handleDialogChange()
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                disabled={
                  selectedEpisodesForTranslation &&
                  selectedEpisodesForTranslation.length &&
                  selectedEpisodesForTranslation.filter(
                    (item) => !item.sourceLang || !item.langs.length
                  ).length > 0
                    ? true
                    : false
                }
                onClick={
                  !slides[currentSlide + 1]
                    ? startTranslation
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Start Translation" : "Next"}
              </Button>
            </div>
          </CardActions>
        </Paper>
      </div>
      <LoadingBackdrop open={translating} />
    </Dialog>
  )
}

export default AiTranslateEpisodesDialog
