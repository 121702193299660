import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grow,
  IconButton,
  List,
  ListItemButton,
  Snackbar,
  Stack,
  Tab,
  Tabs,
} from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import EditContainer from "../../components/global/editContainer"
import EditHeading from "../../components/global/editHeading"
import PageSpinner from "../../components/global/pageSpinner"
import { MainContext } from "../../controllers/main"
import "../../styles/channelEdit.scss"
import { LanguagesContext } from "../../controllers/languages"
import { AddCircleRounded } from "@mui/icons-material"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import CantPerformActionDialog from "../../components/global/cantPerformActionDialog"
import { TeamsContext } from "../../controllers/teams"
import TeamGeneralEdit from "../../components/teams/teamGeneralEdit"
import TeamTranslationsEdit from "../../components/teams/teamTranslationsEdit"
import TeamGlobalPreview from "../../components/teams/teamGlobalPreview"
import { ChannelsContext } from "../../controllers/channels"

const TeamEdit = () => {
  const { animation, setAnimation, setChangesSaved, showPadding } =
    useContext(MainContext)
  const { languagesForEpisodeTabs } = useContext(LanguagesContext)
  const {
    loading: controllerLoading,
    setLoading: setControllerLoading,
    editMode,
    setEditMode,
    doneChanges,
    cancelChanges,
    currentTeam,
    getCurrentTeam,
    preChangesCurrentTeam,
    addTranslation,
    hasError,
    upsertTeamParent,
    upsertTeamDocument,
    getTeamJourneys,
    getTeamNews,
    getTeamChallenges,
    getTeamSurveys,
    getTeamContracts,
    deleteTeam,
  } = useContext(TeamsContext)
  const { channelsList } = useContext(ChannelsContext)
  const { teamId } = useParams()
  const navigate = useNavigate()

  // prevent tab close
  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault()
      console.log("beforeunload event triggered")
      return (event.returnValue = "Are you sure you want to exit?")
    }

    window.addEventListener("beforeunload", handleTabClose)

    return () => {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [])

  // loading
  const [loading, setLoading] = useState<boolean>(true)

  // page error
  const [pageError, setPageError] = useState<boolean>(false)

  // fetch current team and team journeys
  const fetchAll = async () => {
    const noErrors = await Promise.all([
      getCurrentTeam(teamId),
      getTeamJourneys(teamId),
      getTeamNews(teamId),
      getTeamChallenges(teamId),
      getTeamSurveys(teamId),
      getTeamContracts(teamId),
    ])
    if (!noErrors.some((item) => !item)) {
      setLoading(false)
      setControllerLoading(false)
    } else {
      setPageError(true)
    }
  }

  useEffect(() => {
    fetchAll()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // tabs
  const [selectedTab, setSelectedTab] = useState<number>(0)

  // add translation dialog
  const [addTranslationOpen, setAddTranslationOpen] = useState<boolean>(false)
  const [translationToAdd, setTranslationToAdd] = useState<
    { value: string; label: string; flag?: string } | undefined
  >()

  const handleAddTranslationClose = () => {
    setAddTranslationOpen(false)
    setTimeout(() => {
      setTranslationToAdd(undefined)
    }, 150)
  }

  // no default translation alert dialog
  const [
    noDefaultTranslationAlertDialogOpen,
    setNoDefaultTranslationAlertDialogOpen,
  ] = useState<boolean>(false)

  // copied to clipboard feedback
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false)

  // delete team dialog
  const [deleteTeamDialogOpen, setDeleteTeamDialogOpen] =
    useState<boolean>(false)

  const handleDeleteTeamDialog = useCallback(() => {
    setDeleteTeamDialogOpen((current) => !current)
  }, [])

  // global preview
  const [globalPreviewOpen, setGlobalPreviewOpen] = useState<boolean>(false)
  const [currentPreviewLanguage, setCurrentPreviewLanguage] =
    useState<string>("en")

  useEffect(() => {
    if (currentTeam) {
      setCurrentPreviewLanguage(
        currentTeam.document.items.find((item) => item.isDefault).lang
      )
    }
  }, [currentTeam])

  return loading ? (
    <PageSpinner
      showBackButton={pageError}
      backButtonOnClick={() => {
        setAnimation(false)
        navigate("/teams")
      }}
    />
  ) : (
    <EditContainer>
      <EditHeading
        saveButtonLoading={controllerLoading}
        backButtonOnClick={() => {
          setAnimation(false)
          setTimeout(() => {
            navigate("/teams")
          }, 300)
        }}
        title={
          preChangesCurrentTeam.document.items.filter(
            (documentItem) => documentItem.isDefault
          )[0].title
        }
        id={preChangesCurrentTeam.id}
        showButtons
        showEditButton
        editModeActive={editMode}
        setEditModeActive={setEditMode}
        saveButtonDisabled={!doneChanges || hasError}
        undoButtonDisabled={!doneChanges}
        doneChanges={doneChanges}
        undoButtonOnClick={() => {
          if (selectedTab > preChangesCurrentTeam.document.items.length) {
            setSelectedTab(preChangesCurrentTeam.document.items.length)
          }
          cancelChanges()
        }}
        saveButtonOnClick={async () => {
          if (
            currentTeam.document.items.filter((item) => item.isDefault).length
          ) {
            setControllerLoading(true)
            let noErrors = await upsertTeamParent()
            if (noErrors) {
              noErrors = await upsertTeamDocument()
              if (noErrors) {
                setControllerLoading(false)
                setChangesSaved(true)
              } else {
                setControllerLoading(false)
              }
            } else {
              setControllerLoading(false)
            }
          } else {
            setNoDefaultTranslationAlertDialogOpen(true)
          }
        }}
        showDeleteButton={process.env.REACT_APP_ENV === "dev"}
        deleteButtonOnClick={handleDeleteTeamDialog}
        showBadge={channelsList.some(
          (channel) => channel.teamId === currentTeam.id
        )}
        badgeLabel="Integration"
      />
      <Grow
        in={animation}
        timeout={300}
        style={{
          margin: showPadding === "yes" ? 16 : 0,
          marginTop: 0,
        }}
      >
        <Stack>
          {/* languages tabs */}
          <Stack direction="row" justifyContent="flex-start">
            <Card
              variant="outlined"
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: showPadding === "yes" ? null : 0,
                borderBottom: "none",
                borderLeft: showPadding === "yes" ? null : "none",
              }}
            >
              <Stack direction="row" justifyContent="flex-start">
                <Tabs
                  value={selectedTab}
                  onChange={(e: any, newValue: number) => {
                    setSelectedTab(newValue)
                  }}
                  aria-label="languages tabs"
                >
                  <Tab label="General" style={{ minWidth: 180 }} />
                  {currentTeam.document.items.map((item) => (
                    <Tab
                      key={item.lang}
                      style={{ minWidth: 180 }}
                      label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <span>
                            {
                              languagesForEpisodeTabs.filter(
                                (arrayItem) => arrayItem.value === item.lang
                              )[0].flag
                            }
                          </span>
                          {currentTeam.document.items.filter(
                            (documentItem) => documentItem.lang === item.lang
                          )[0].isDefault ? (
                            <Stack
                              direction="row"
                              spacing={0.5}
                              alignItems="center"
                            >
                              <span>
                                {
                                  languagesForEpisodeTabs.filter(
                                    (arrayItem) => arrayItem.value === item.lang
                                  )[0].label
                                }
                              </span>
                              <span style={{ fontSize: 10, marginTop: 2 }}>
                                (Default)
                              </span>
                            </Stack>
                          ) : (
                            <span>
                              {
                                languagesForEpisodeTabs.filter(
                                  (arrayItem) => arrayItem.value === item.lang
                                )[0].label
                              }
                            </span>
                          )}
                        </Stack>
                      }
                    />
                  ))}
                </Tabs>
                <Divider orientation="vertical" variant="middle" flexItem />
                <div
                  style={{
                    minWidth: 70,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    disabled={!editMode}
                    aria-label="add language button"
                    component="span"
                    style={{ width: 48, height: 48 }}
                    onClick={() => {
                      setAddTranslationOpen(true)
                    }}
                  >
                    <AddCircleRounded />
                  </IconButton>
                </div>
              </Stack>
            </Card>
          </Stack>
          <Card style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            {!selectedTab ? (
              <TeamGeneralEdit
                setFeedbackOpen={setFeedbackOpen}
                setGlobalPreviewOpen={setGlobalPreviewOpen}
              />
            ) : (
              <TeamTranslationsEdit itemIndex={selectedTab - 1} />
            )}
          </Card>
        </Stack>
      </Grow>
      <LoadingBackdrop open={controllerLoading} />
      {/* add translation dialog */}
      <Dialog onClose={handleAddTranslationClose} open={addTranslationOpen}>
        <DialogTitle>Add translation</DialogTitle>
        {translationToAdd ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-add-translation">
              Are you sure you want to add{" "}
              <b>{translationToAdd.label.toUpperCase()}</b>?
            </DialogContentText>
          </DialogContent>
        ) : languagesForEpisodeTabs.filter((item) => {
            if (
              item.value !== "general" &&
              !currentTeam.document.items.filter(
                (translationItem) => translationItem.lang === item.value
              )[0]
            ) {
              return item
            }
          }).length ? (
          <List sx={{ pt: 0, minWidth: 300 }}>
            {languagesForEpisodeTabs
              .filter((item) => {
                if (
                  item.value !== "general" &&
                  !currentTeam.document.items.filter(
                    (translationItem) => translationItem.lang === item.value
                  )[0]
                ) {
                  return item
                }
              })
              .map((item) => (
                <ListItemButton
                  onClick={() => {
                    setTranslationToAdd(item)
                  }}
                  key={item.value}
                >
                  <Stack direction="row" spacing={1}>
                    <span>{item.flag}</span>
                    <span>{item.label.toUpperCase()}</span>
                  </Stack>
                </ListItemButton>
              ))}
          </List>
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-add-translation">
              You have already added all languages available
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleAddTranslationClose}>Cancel</Button>
          {translationToAdd && (
            <Button
              onClick={() => {
                addTranslation(translationToAdd.value)
                handleAddTranslationClose()
                setSelectedTab(currentTeam.document.items.length)
              }}
            >
              Add {translationToAdd.label}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {/* delete challenge dialog */}
      <Dialog onClose={handleDeleteTeamDialog} open={deleteTeamDialogOpen}>
        <DialogTitle>Delete Team</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-delete-challenge">
            Are you sure you want to delete this Team? The operation is
            irreversible
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={controllerLoading} onClick={handleDeleteTeamDialog}>
            Cancel
          </Button>
          <Button
            disabled={controllerLoading}
            color="error"
            onClick={async () => {
              setControllerLoading(true)

              const noErrors = await deleteTeam(currentTeam.id)

              if (noErrors) {
                setTimeout(() => {
                  setControllerLoading(false)
                  navigate("/teams", { state: { teamDeleted: true } })
                }, 1000)
              } else {
                handleDeleteTeamDialog()
              }
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* global episode preview */}
      {currentTeam.document.items.find(
        (item) => item.lang === currentPreviewLanguage
      ) && (
        <TeamGlobalPreview
          isGlobalPreviewOpen={globalPreviewOpen}
          setIsGlobalPreviewOpen={setGlobalPreviewOpen}
          currentPreviewLanguage={currentPreviewLanguage}
          setCurrentPreviewLanguage={setCurrentPreviewLanguage}
        />
      )}
      {/* no default translation alert dialog */}
      <CantPerformActionDialog
        open={noDefaultTranslationAlertDialogOpen}
        setOpen={setNoDefaultTranslationAlertDialogOpen}
        content="There must be a default translation"
      />
      {/* copied to clipboard */}
      <Snackbar
        open={feedbackOpen}
        autoHideDuration={2000}
        onClose={() => {
          setFeedbackOpen(false)
        }}
      >
        <Alert severity="success">Copied to clipboard!</Alert>
      </Snackbar>
    </EditContainer>
  )
}

export default TeamEdit
