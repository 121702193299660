import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Dialog,
  MenuItem,
  Select,
  Autocomplete,
  Chip,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  DialogTransition,
  capitalizeFirstCharacter,
  enumAsArray,
  isValidURL,
  lowercaseFirstCharacter,
  stripHtml,
  testYtURL,
} from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import { LanguagesContext } from "../../controllers/languages"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import ImageSlice from "../../models/bodySlices/imageSlice"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import BlockEditor from "../blockEditor/blockEditor"
import {
  BodySliceType,
  TeamAccessType,
  TeamOrganizationType,
  TeamStakeholderType,
  TeamVisibilityOptionType,
} from "../../services/config/enum"
import { TeamsContext } from "../../controllers/teams"
import slugify from "react-slugify"
import ImagePicker from "../global/imagePicker"
import Team from "../../models/team"
import ISODatePicker from "../global/isoDatePicker"
import { CountriesContext } from "../../controllers/countries"
import { LocationAutocompleteOption } from "../../services/config/interfaces"

const CreateTeamDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
  topBarText,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
  topBarText: string
}) => {
  const { setAnimation } = useContext(MainContext)
  const { languages, languagesForEpisodeTabs } = useContext(LanguagesContext)
  const {
    loading,
    setLoading,
    createTeam,
    createTeamDocument,
    createTeamContract,
  } = useContext(TeamsContext)
  const {
    regionsList,
    subRegionsList,
    countriesList,
    loading: countriesControllerLoading,
  } = useContext(CountriesContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // configuration
  const [name, setName] = useState<string>("")
  const [accessType, setAccessType] = useState<TeamAccessType>(
    TeamAccessType.public
  )
  const [isPrimary, setIsPrimary] = useState<boolean>(false)
  const [stakeholderType, setStakeholderType] = useState<TeamStakeholderType>(
    TeamStakeholderType.general
  )
  const [organizationType, setOrganizationType] =
    useState<TeamOrganizationType>(TeamOrganizationType.unknown)
  const [canLeave, setCanLeave] = useState<boolean>(true)
  const [suggestFootprint, setSuggestFootprint] = useState<boolean>(false)

  // locations
  const [availableAtLocations, setAvailableAtLocations] = useState<
    | {
        id: string
        name: string
        __typename: "Country" | "Region" | "SubRegion" | "DefaultLocation"
      }[]
    | null
  >(null)
  const [restrictedAtLocations, setRestrictedAtLocations] = useState<
    | {
        id: string
        name: string
        __typename: "Country" | "Region" | "SubRegion" | "DefaultLocation"
      }[]
    | null
  >(null)

  // contract
  const [startDate, setStartDate] = useState<string>("")
  const [endDate, setEndDate] = useState<string>("")
  const [sellingPrice, setSellingPrice] = useState<number | null>(null)
  const [targetMembers, setTargetMembers] = useState<number | null>(null)

  // check for contract error
  const [contractError, setContractError] = useState<boolean>(false)

  useEffect(() => {
    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      setContractError(true)
    } else {
      setContractError(false)
    }
  }, [startDate, endDate])

  // visibility
  const [leaderboardVisibility, setLeaderboardVisibility] =
    useState<TeamVisibilityOptionType>(TeamVisibilityOptionType.none)
  const [membersVisibility, setMembersVisibility] =
    useState<TeamVisibilityOptionType>(TeamVisibilityOptionType.members)
  const [metricVisibility, setMetricVisibility] =
    useState<TeamVisibilityOptionType>(TeamVisibilityOptionType.members)

  // document
  const [defaultTranslation, setDefaultTranslation] = useState<string>("")
  const [title, setTitle] = useState<string>("")
  const [logo, setLogo] = useState<string>("")
  const [cover, setCover] = useState<string>("")
  const [body, setBody] = useState<
    (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
  >([])

  // check errors in body
  const [bodyError, setBodyError] = useState<boolean>(false)

  useEffect(() => {
    let errorInBody = false
    body.forEach((bodyItem) => {
      if (
        (bodyItem.sliceType === BodySliceType.BodyTextSlice &&
          !stripHtml(bodyItem.text).length) ||
        (bodyItem.sliceType === BodySliceType.BodyQuoteSlice &&
          !bodyItem.quoteText.length) ||
        (bodyItem.sliceType === BodySliceType.BodyImageSlice &&
          (!bodyItem.imgUrl.length || !isValidURL(bodyItem.imgUrl))) ||
        (bodyItem.sliceType === BodySliceType.BodyEmbedSlice &&
          (!bodyItem.embedUrl.length || !testYtURL(bodyItem.embedUrl)))
      ) {
        errorInBody = true
      }
    })
    if (errorInBody) {
      setBodyError(true)
    } else {
      setBodyError(false)
    }
  }, [body])

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Configuration",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Configuration
          </Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              size="small"
              label="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              onBlur={() => {
                setName((current) => current.trim())
              }}
            />
            <TextField
              fullWidth
              size="small"
              label="UID"
              value={slugify(name)}
              InputProps={{ readOnly: true }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="access-type">Access Type</InputLabel>
              <Select
                labelId="access-type"
                label="Access Type"
                value={accessType}
                onChange={(e) => {
                  setAccessType(e.target.value as TeamAccessType)
                }}
              >
                {enumAsArray(TeamAccessType).map((item: string, index) => (
                  <MenuItem key={index} value={item}>
                    {item === TeamAccessType.public
                      ? "Public"
                      : item === TeamAccessType.sso
                      ? "SSO"
                      : item === TeamAccessType.activationCode
                      ? "Activation Code"
                      : capitalizeFirstCharacter(item)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="is-primary-select">Primary</InputLabel>
              <Select
                labelId="is-primary-select"
                label="Primary"
                value={isPrimary ? "yes" : "no"}
                onChange={(e) => {
                  setIsPrimary(e.target.value === "yes")
                }}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="organization-type">Organization Type</InputLabel>
              <Select
                labelId="organization-type"
                label="Organization Type"
                value={organizationType}
                onChange={(e) => {
                  setOrganizationType(e.target.value as TeamOrganizationType)
                }}
              >
                {enumAsArray(TeamOrganizationType).map(
                  (item: string, index) => (
                    <MenuItem key={index} value={item}>
                      {capitalizeFirstCharacter(
                        item
                          .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
                          .toLowerCase()
                      )}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="stakeholder-type">Stakeholder Type</InputLabel>
              <Select
                labelId="stakeholder-type"
                label="Stakeholder Type"
                value={stakeholderType}
                onChange={(e) => {
                  setStakeholderType(e.target.value as TeamStakeholderType)
                }}
              >
                {enumAsArray(TeamStakeholderType).map((item: string, index) => (
                  <MenuItem key={index} value={item}>
                    {capitalizeFirstCharacter(item)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="users-can-leave-select">
                Users can leave
              </InputLabel>
              <Select
                labelId="users-can-leave-select"
                label="Users can leave"
                value={canLeave ? "yes" : "no"}
                onChange={(e) => {
                  if (e.target.value === "yes") {
                    setCanLeave(true)
                  } else {
                    setCanLeave(false)
                  }
                }}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="suggest-footprint-select">
                Suggest footprint
              </InputLabel>
              <Select
                labelId="suggest-footprint-select"
                label="Suggest footprint"
                value={suggestFootprint ? "yes" : "no"}
                onChange={(e) => {
                  if (e.target.value === "yes") {
                    setSuggestFootprint(true)
                  } else {
                    setSuggestFootprint(false)
                  }
                }}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      ),
    },
    {
      label: "Locations",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Locations
          </Typography>
          <Autocomplete
            loading={countriesControllerLoading}
            fullWidth
            blurOnSelect
            size="small"
            value={availableAtLocations ?? []}
            disablePortal
            multiple
            isOptionEqualToValue={(
              option: LocationAutocompleteOption,
              value: LocationAutocompleteOption
            ) => option.id === value.id}
            groupBy={(option: LocationAutocompleteOption) => option.__typename}
            options={[
              ...regionsList.map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                  __typename: "Region",
                }
              }),
              ...subRegionsList.map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                  __typename: "SubRegion",
                }
              }),
              ...countriesList.map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                  __typename: "Country",
                }
              }),
            ]}
            getOptionLabel={(option: LocationAutocompleteOption) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Available in (empty for everywhere)"
              />
            )}
            onChange={(e: any, newValues: LocationAutocompleteOption[]) => {
              setAvailableAtLocations(newValues.length ? newValues : null)
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  size="small"
                  key={option.id}
                  label={option.name}
                />
              ))
            }}
          />
          <Autocomplete
            loading={countriesControllerLoading}
            fullWidth
            blurOnSelect
            size="small"
            value={restrictedAtLocations ?? []}
            disablePortal
            multiple
            isOptionEqualToValue={(
              option: LocationAutocompleteOption,
              value: LocationAutocompleteOption
            ) => option.id === value.id}
            groupBy={(option: LocationAutocompleteOption) => option.__typename}
            options={[
              ...regionsList.map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                  __typename: "Region",
                }
              }),
              ...subRegionsList.map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                  __typename: "SubRegion",
                }
              }),
              ...countriesList.map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                  __typename: "Country",
                }
              }),
            ]}
            getOptionLabel={(option: LocationAutocompleteOption) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Not available in" />
            )}
            onChange={(e: any, newValues: LocationAutocompleteOption[]) => {
              setRestrictedAtLocations(newValues.length ? newValues : null)
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  size="small"
                  key={option.id}
                  label={option.name}
                />
              ))
            }}
          />
        </Stack>
      ),
    },
    {
      label: "Contract",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Contract
          </Typography>
          <Stack direction="row" gap={2}>
            <ISODatePicker
              label="Start date"
              date={startDate}
              setDate={setStartDate}
              utc={false}
            />
            <ISODatePicker
              label="End date"
              date={endDate}
              setDate={setEndDate}
              error={contractError}
              utc={false}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <TextField
              fullWidth
              size="small"
              label="Selling price"
              type="number"
              value={sellingPrice ?? ""}
              onChange={(e) => {
                if (e.target.value) {
                  setSellingPrice(parseFloat(e.target.value))
                } else {
                  setSellingPrice(null)
                }
              }}
              error={sellingPrice !== null && sellingPrice < 0}
              helperText={
                sellingPrice !== null && sellingPrice < 0 ? "Invalid value" : ""
              }
            />
            <TextField
              fullWidth
              size="small"
              label="Target members"
              type="number"
              value={targetMembers ?? ""}
              onChange={(e) => {
                if (e.target.value) {
                  setTargetMembers(parseFloat(e.target.value))
                } else {
                  setTargetMembers(null)
                }
              }}
              error={targetMembers !== null && targetMembers < 0}
              helperText={
                targetMembers !== null && targetMembers < 0
                  ? "Invalid value"
                  : ""
              }
            />
          </Stack>
        </Stack>
      ),
    },
    // {
    //   label: "Visibility",
    //   component: (
    //     <Stack spacing={2}>
    //       <Typography variant="h6" className="card-title">
    //         Visibility
    //       </Typography>
    //       <FormControl fullWidth size="small">
    //         <InputLabel id="leaderboard-visibility">Leaderboard</InputLabel>
    //         <Select
    //           labelId="leaderboard-visibility"
    //           label="Leaderboard"
    //           value={leaderboardVisibility}
    //           onChange={(e) => {
    //             setLeaderboardVisibility(
    //               e.target.value as TeamVisibilityOptionType
    //             )
    //           }}
    //         >
    //           {enumAsArray(TeamVisibilityOptionType).map(
    //             (item: string, index) => (
    //               <MenuItem key={index} value={item}>
    //                 {capitalizeFirstCharacter(item)}
    //               </MenuItem>
    //             )
    //           )}
    //         </Select>
    //       </FormControl>
    //       <FormControl fullWidth size="small">
    //         <InputLabel id="members-visibility">Members List</InputLabel>
    //         <Select
    //           labelId="members-visibility"
    //           label="Members List"
    //           value={membersVisibility}
    //           onChange={(e) => {
    //             setMembersVisibility(e.target.value as TeamVisibilityOptionType)
    //           }}
    //         >
    //           {enumAsArray(TeamVisibilityOptionType).map(
    //             (item: string, index) => (
    //               <MenuItem key={index} value={item}>
    //                 {capitalizeFirstCharacter(item)}
    //               </MenuItem>
    //             )
    //           )}
    //         </Select>
    //       </FormControl>
    //       <FormControl fullWidth size="small">
    //         <InputLabel id="metric-visibility">Metrics</InputLabel>
    //         <Select
    //           labelId="metric-visibility"
    //           label="Metrics"
    //           value={metricVisibility}
    //           onChange={(e) => {
    //             setMetricVisibility(e.target.value as TeamVisibilityOptionType)
    //           }}
    //         >
    //           {enumAsArray(TeamVisibilityOptionType).map(
    //             (item: string, index) => (
    //               <MenuItem key={index} value={item}>
    //                 {capitalizeFirstCharacter(item)}
    //               </MenuItem>
    //             )
    //           )}
    //         </Select>
    //       </FormControl>
    //     </Stack>
    //   ),
    // },
    {
      label: "Language",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Default language
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="language-select">Default language</InputLabel>
            <Select
              labelId="language-select"
              label="Default language"
              value={defaultTranslation}
              onChange={(e) => {
                setDefaultTranslation(e.target.value)
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      ),
    },
    {
      label: "Details",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Details in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <TextField
            size="small"
            label="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            onBlur={() => {
              if (title) {
                setTitle((current) => current.trim())
              }
            }}
          />
          <Stack direction="row" spacing={2} style={{ width: "100%" }}>
            <ImagePicker
              title="Logo"
              pickedImage={logo}
              setPickedImage={(selectedImage: {
                url: string
                id: string
                description?: string
              }) => {
                setLogo(selectedImage.url)
              }}
              filters={{ category: "thumbnail" }}
              disableCategoryFilter
              height={190}
              width="100%"
              placeholderHeight={120}
              placeholderWidth="35%"
            />
            <ImagePicker
              title="Cover"
              pickedImage={cover}
              setPickedImage={(selectedImage: {
                url: string
                id: string
                description?: string
              }) => {
                setCover(selectedImage.url)
              }}
              filters={{ category: "other" }}
              disableCategoryFilter
              height={190}
              width="100%"
              placeholderHeight={100}
              placeholderWidth="80%"
            />
          </Stack>
        </Stack>
      ),
    },
    {
      label: "Body",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Body in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <div
            style={{
              height: 260,
              maxHeight: 260,
              overflowY: "auto",
            }}
          >
            <BlockEditor
              body={body}
              setBody={(
                body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
              ) => {
                setBody([...body])
              }}
            />
          </div>
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setName("")
        setAccessType(TeamAccessType.public)
        setIsPrimary(false)
        setStakeholderType(TeamStakeholderType.general)
        setOrganizationType(TeamOrganizationType.unknown)
        setCanLeave(true)
        setSuggestFootprint(false)
        setAvailableAtLocations(null)
        setRestrictedAtLocations(null)
        setStartDate("")
        setEndDate("")
        setLeaderboardVisibility(TeamVisibilityOptionType.none)
        setMembersVisibility(TeamVisibilityOptionType.members)
        setMetricVisibility(TeamVisibilityOptionType.members)
        setDefaultTranslation("")
        setTitle("")
        setLogo("")
        setCover("")
        setBody([])
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (name) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [name])

  // create team
  const createNewTeam = async () => {
    setLoading(true)

    const input: {
      accessType: TeamAccessType
      name: string
      uid: string
      stakeholderType: TeamStakeholderType
      organizationType: TeamOrganizationType
      canLeave: boolean
      visibilityOptions: {
        leaderboard: TeamVisibilityOptionType
        members: TeamVisibilityOptionType
        metric: TeamVisibilityOptionType
      }
      isPrimary: boolean
      suggestFootprint: boolean
      availableAtLocations:
        | {
            id: string
            type: "Country" | "Region" | "SubRegion" | "DefaultLocation"
          }[]
        | null
      restrictedAtLocations:
        | {
            id: string
            type: "Country" | "Region" | "SubRegion" | "DefaultLocation"
          }[]
        | null
    } = {
      accessType: accessType,
      name: name,
      uid: slugify(name),
      stakeholderType: stakeholderType,
      organizationType: organizationType,
      canLeave: canLeave,
      visibilityOptions: {
        leaderboard: leaderboardVisibility,
        members: membersVisibility,
        metric: metricVisibility,
      },
      isPrimary: isPrimary,
      suggestFootprint: suggestFootprint,
      availableAtLocations: availableAtLocations
        ? availableAtLocations.map((item) => {
            return {
              id: item.id,
              type: item.__typename,
            }
          })
        : null,
      restrictedAtLocations: restrictedAtLocations
        ? restrictedAtLocations.map((item) => {
            return {
              id: item.id,
              type: item.__typename,
            }
          })
        : null,
    }

    const result = await createTeam(input)

    if (result !== false) {
      const newTeam = result as Team

      // create contract if startDate and endDate are set
      if (startDate && endDate) {
        await createTeamContract(
          newTeam.id,
          startDate.split("T")[0],
          endDate.split("T")[0],
          sellingPrice,
          targetMembers
        )
      }

      let bodyToSet = []
      const newBody = []
      body.forEach((bodyItem: any) => {
        const { sliceType, ...rest } = bodyItem
        newBody.push({
          [lowercaseFirstCharacter(bodyItem.sliceType)]: {
            ...rest,
          },
        })
      })
      bodyToSet = newBody

      const documentInput: {
        parentId: string
        type: string
        teamDocumentItems: {
          default: boolean
          lang: string
          title: string
          logo: string
          cover: string
          body: object[]
        }[]
      } = {
        parentId: newTeam.id,
        type: "Team",
        teamDocumentItems: [
          {
            default: true,
            lang: defaultTranslation,
            title: title,
            logo: logo,
            cover: cover,
            body: bodyToSet,
          },
        ],
      }

      await createTeamDocument(documentInput)

      setDialogOpen(false)
      setAnimation(false)
      setTimeout(() => {
        navigate(`/teams/${newTeam.id}`)
      }, 250)
    } else {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText={topBarText}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            {topBarText}
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 && !name) ||
                  (currentSlide === 2 && contractError) ||
                  (currentSlide === 2 && startDate && !endDate) ||
                  (currentSlide === 2 && endDate && !startDate) ||
                  (currentSlide === 2 &&
                    sellingPrice !== null &&
                    sellingPrice < 0) ||
                  (currentSlide === 2 &&
                    targetMembers !== null &&
                    targetMembers < 0) ||
                  (currentSlide === 3 && !defaultTranslation) ||
                  (currentSlide === 4 && !title) ||
                  (currentSlide === 4 && !logo) ||
                  (currentSlide === 5 && bodyError)
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? () => {
                        createNewTeam()
                      }
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateTeamDialog
