import {
  AppBar,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react"
import { TeamsContext } from "../../controllers/teams"
import {
  AddCircleRounded,
  CancelRounded,
  CloseRounded,
  DeleteRounded,
  DragHandleRounded,
} from "@mui/icons-material"
import CantPerformActionDialog from "../global/cantPerformActionDialog"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import Journey from "../../models/journey"
import { JourneysContext } from "../../controllers/journeys"
import { DialogTransition } from "../../services/utilities/utility"
import JourneysList from "../../views/journey/journeysList"

const TeamGeneralEditJourneysTab = ({
  searchJourneysValue,
  setSearchJourneysValue,
}: {
  searchJourneysValue: string
  setSearchJourneysValue: Dispatch<SetStateAction<string>>
}) => {
  const { editMode, currentTeamJourneysList, setCurrentTeamJourneysList } =
    useContext(TeamsContext)
  const {
    setSearchValue,
    setSearchLanguage,
    setSearchTopic,
    setSearchSdg,
    setSearchSdgTarget,
    setSearchType,
    setJourneysList,
  } = useContext(JourneysContext)

  // team journeys
  const [selectedJourneys, setSelectedJourneys] = useState<Journey[]>([])
  const [journeysToFilter, setJourneysToFilter] = useState<Journey[]>([])
  const [addJourneysDialogOpen, setAddJourneysDialogOpen] =
    useState<boolean>(false)

  // can't add journeys dialog
  const [cantAddJourneyDialogOpen, setCantAddJourneyDialogOpen] =
    useState<boolean>(false)

  // reset journeys list filters
  const resetJourneysFilters = () => {
    setSearchValue("")
    setSearchLanguage(null)
    setSearchTopic(null)
    setSearchSdg(null)
    setSearchSdgTarget(null)
    setSearchType(["Free", "Business"])
  }

  // drag and drop
  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result

    if (destination.index === source.index) {
      return
    }

    let sourceItem = currentTeamJourneysList[source.index]

    let indexToDelete = currentTeamJourneysList.findIndex(
      (item) => item.journey.id === draggableId
    )
    currentTeamJourneysList.splice(indexToDelete, 1)
    currentTeamJourneysList.splice(destination.index, 0, sourceItem)

    setCurrentTeamJourneysList([...currentTeamJourneysList])
  }

  // premium journeys max number and dialog
  const journeysMaxNumber = 80
  const [maxNumberDialogOpen, setMaxNumberDialogOpen] = useState<boolean>(false)

  // search journeys
  const journeysListMemo = useMemo(() => {
    if (searchJourneysValue) {
      return currentTeamJourneysList.filter((item) =>
        item.journey.title
          .toLowerCase()
          .includes(searchJourneysValue.toLowerCase())
      )
    }

    return currentTeamJourneysList
  }, [currentTeamJourneysList, searchJourneysValue])

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Journeys
        </Typography>
        {!currentTeamJourneysList.length ? (
          <Typography>No Journeys for this Team</Typography>
        ) : (
          <Stack>
            <Card
              style={{
                border: "1px solid #E5E8EB",
                borderBottomLeftRadius: !journeysListMemo.length
                  ? undefined
                  : 0,
                borderBottomRightRadius: !journeysListMemo.length
                  ? undefined
                  : 0,
                borderBottom: !journeysListMemo.length
                  ? "1px solid #E5E8EB"
                  : "none",
                boxShadow: "none",
              }}
            >
              <CardContent style={{ padding: "12px 24px" }}>
                <TextField
                  fullWidth
                  size="small"
                  label="Search Journeys"
                  value={searchJourneysValue}
                  onChange={(e) => {
                    setSearchJourneysValue(e.target.value)
                  }}
                  InputProps={{
                    endAdornment: searchJourneysValue.length > 0 && (
                      <InputAdornment position="start">
                        <div
                          style={{
                            marginTop: 6,
                            marginRight: -6,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSearchJourneysValue("")
                          }}
                        >
                          <CancelRounded style={{ width: 18 }} />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </CardContent>
            </Card>
            {!journeysListMemo.length ? (
              <Typography style={{ marginTop: 16 }}>
                No results for "{searchJourneysValue}"
              </Typography>
            ) : null}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="column-1">
                {(provided) => (
                  <Stack {...provided.droppableProps} ref={provided.innerRef}>
                    {journeysListMemo.map((item, index) => (
                      <Draggable
                        key={item.journey.id.toString()}
                        draggableId={item.journey.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <Card
                            key={item.journey.id}
                            style={{
                              minHeight: 60,
                              paddingLeft: 14,
                            }}
                            variant="outlined"
                            className="list-item-card"
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            onClick={() => {
                              window.open(
                                window.location.origin +
                                  "/journeys/" +
                                  item.journey.id
                              )
                            }}
                            sx={{
                              boxShadow: "none",
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                              borderBottomLeftRadius:
                                index === journeysListMemo.length - 1
                                  ? undefined
                                  : 0,
                              borderBottomRightRadius:
                                index === journeysListMemo.length - 1
                                  ? undefined
                                  : 0,
                              borderTop: index === 0 ? undefined : "none",
                            }}
                          >
                            <Stack
                              direction="row"
                              style={{ height: 60, position: "relative" }}
                              alignItems="center"
                              spacing={4}
                            >
                              <div
                                style={{
                                  width: 30,
                                  height: 60,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  transition: "100ms",
                                  cursor: "grab",
                                  marginLeft: editMode ? 20 : -31,
                                  marginRight: -8,
                                }}
                                {...provided.dragHandleProps}
                              >
                                <DragHandleRounded />
                              </div>
                              <div
                                style={{
                                  position: "relative",
                                  width: 48,
                                }}
                              >
                                {item.journey.stage === "PUBLISHED" ||
                                item.journey.hasSibling ? (
                                  <Chip
                                    size="small"
                                    label="Live"
                                    color="success"
                                    style={{
                                      position: "relative",
                                      zIndex: 1,
                                    }}
                                  />
                                ) : item.journey.stage === "ARCHIVED" ? (
                                  <Chip
                                    size="small"
                                    label="Archived"
                                    style={{
                                      position: "relative",
                                      zIndex: 1,
                                    }}
                                  />
                                ) : (
                                  <Chip
                                    size="small"
                                    label="Draft"
                                    color="warning"
                                    style={{
                                      position: "relative",
                                      zIndex: 1,
                                    }}
                                  />
                                )}
                                {item.journey.hasSibling ? (
                                  <Chip
                                    size="small"
                                    label="Draft"
                                    color="warning"
                                    style={{
                                      position: "absolute",
                                      left: 1,
                                      top: 1,
                                    }}
                                  />
                                ) : null}
                              </div>
                              <div
                                style={{
                                  minWidth: 40,
                                  height: 40,
                                  borderRadius: 4,
                                  backgroundImage: `url(${
                                    item.journey.image ?? noImagePlaceholder
                                  })`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                }}
                              />
                              <Typography style={{ minWidth: 21 }}>
                                {item.journey.lang.toUpperCase()}
                              </Typography>
                              <Typography
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "50%",
                                }}
                              >
                                {item.journey.title}
                              </Typography>
                              <IconButton
                                disabled={!editMode}
                                color="error"
                                style={{
                                  position: "absolute",
                                  right: 12,
                                  top: 9,
                                  zIndex: 1,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  currentTeamJourneysList.splice(index, 1)
                                  setCurrentTeamJourneysList([
                                    ...currentTeamJourneysList,
                                  ])
                                }}
                              >
                                <DeleteRounded
                                  style={{
                                    width: 24,
                                  }}
                                />
                              </IconButton>
                            </Stack>
                          </Card>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>
          </Stack>
        )}
        <Button
          onClick={() => {
            setJourneysList([])
            setJourneysToFilter(
              currentTeamJourneysList.map((item) => item.journey)
            )
            setAddJourneysDialogOpen(true)
          }}
          variant="outlined"
          endIcon={<AddCircleRounded />}
          fullWidth
          disabled={!editMode}
          style={{ height: 40 }}
        >
          Add Journeys
        </Button>
      </Stack>
      {/* can't add journeys for max number dialog */}
      <CantPerformActionDialog
        open={maxNumberDialogOpen}
        setOpen={setMaxNumberDialogOpen}
        content={`The maximum number of Journeys is ${journeysMaxNumber}`}
      />
      {/* add journey dialog */}
      <Dialog
        fullScreen
        open={addJourneysDialogOpen}
        onClose={() => {
          setAddJourneysDialogOpen(false)
          setSelectedJourneys([])
          resetJourneysFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setAddJourneysDialogOpen(false)
                setSelectedJourneys([])
                resetJourneysFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Journeys
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                // check if there are draft journey in selection
                for (let i = 0; i < selectedJourneys.length; i++) {
                  if (
                    selectedJourneys[i].stage === "DRAFT" &&
                    !selectedJourneys[i].hasSibling
                  ) {
                    setAddJourneysDialogOpen(false)
                    setSelectedJourneys([])
                    resetJourneysFilters()
                    setTimeout(() => {
                      setCantAddJourneyDialogOpen(true)
                    }, 150)
                    return
                  }
                }

                // check if premium journeys max number has been reached
                if (
                  currentTeamJourneysList.length + selectedJourneys.length >
                  journeysMaxNumber
                ) {
                  setAddJourneysDialogOpen(false)
                  setSelectedJourneys([])
                  resetJourneysFilters()
                  setTimeout(() => {
                    setMaxNumberDialogOpen(true)
                  }, 150)
                  return
                }

                setCurrentTeamJourneysList([
                  ...currentTeamJourneysList,
                  ...selectedJourneys.map((item) => {
                    return {
                      sorting: 0,
                      journey: item,
                    }
                  }),
                ])

                setAddJourneysDialogOpen(false)
                setSelectedJourneys([])
                resetJourneysFilters()
              }}
              disabled={!selectedJourneys.length}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <JourneysList
            forDialog
            forDialogType={["Business"]}
            selectedJourneys={selectedJourneys}
            setSelectedJourneys={setSelectedJourneys}
            journeysToFilter={journeysToFilter}
          />
        </Paper>
      </Dialog>
      {/* can't add journeys dialog */}
      <Dialog
        open={cantAddJourneyDialogOpen}
        onClose={() => {
          setCantAddJourneyDialogOpen(false)
        }}
        aria-labelledby="alert-dialog-title-cant-add-journey"
        aria-describedby="alert-dialog-description-cant-add-journey"
      >
        <DialogTitle id="alert-dialog-title-cant-add-journey">
          These Journeys cannot be added
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-cant-add-journey">
            There are one or more Journeys that are not published
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCantAddJourneyDialogOpen(false)
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TeamGeneralEditJourneysTab
