import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useContext,
  useState,
} from "react"
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Fade,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import { ChevronLeftRounded, UndoRounded } from "@mui/icons-material"
import { MainContext } from "../../controllers/main"
import { logger, Status } from "../../services/utilities/utility"
import BetaBadge from "./betaBadge"

const EditHeadingFade = ({
  showBackButton = true,
  backButtonOnClick,
  title,
  saveButtonDisabled,
  undoButtonDisabled,
  saveButtonOnClick,
  undoButtonOnClick,
  saveButtonLoading = false,
  showButtons = true,
  showEditButton = false,
  showArchiveButton = false,
  archiveButtonOnClick,
  showPreviewButton = false,
  previewButtonDisabled,
  showPreviewButtonOnClick,
  editModeActive = false,
  setEditModeActive,
  doneChanges,
  showImportButton = false,
  importButtonDisabled = false,
  importButtonOnClick,
  withBetaBadge,
}: {
  showBackButton?: boolean
  backButtonOnClick?: MouseEventHandler<HTMLButtonElement>
  title: string
  saveButtonDisabled?: boolean
  undoButtonDisabled?: boolean
  saveButtonOnClick?: MouseEventHandler<HTMLButtonElement>
  undoButtonOnClick?: MouseEventHandler<HTMLButtonElement>
  saveButtonLoading?: boolean
  showButtons?: boolean
  showEditButton?: boolean
  showArchiveButton?: boolean
  archiveButtonOnClick?: MouseEventHandler<HTMLButtonElement>
  showPreviewButton?: boolean
  previewButtonDisabled?: boolean
  showPreviewButtonOnClick?: MouseEventHandler<HTMLButtonElement>
  editModeActive?: boolean
  setEditModeActive?: Dispatch<SetStateAction<boolean>>
  doneChanges?: boolean
  showImportButton?: boolean
  importButtonDisabled?: boolean
  importButtonOnClick?: MouseEventHandler<HTMLButtonElement>
  withBetaBadge?: boolean
}) => {
  const { animation } = useContext(MainContext)

  // undo dialog
  const [undoDialogOpen, setUndoDialogOpen] = useState<boolean>(false)

  return (
    <Fade
      in={animation}
      timeout={350}
      style={{ marginTop: 16, paddingLeft: 16, paddingRight: 16 }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        style={{ minHeight: 36 }}
      >
        {showBackButton && (
          <Button variant="outlined" onClick={backButtonOnClick}>
            <ChevronLeftRounded />
          </Button>
        )}
        <Typography
          variant="h6"
          style={{
            width: "calc(100% - 290px)",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {title}
          {withBetaBadge && <BetaBadge style={{ marginLeft: 10 }} />}
        </Typography>
        {showImportButton && (
          <div
            style={{
              width: "-webkit-fill-available",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              onClick={importButtonOnClick}
              disabled={importButtonDisabled}
            >
              Import
            </Button>
          </div>
        )}
        {showEditButton && (
          <div
            style={{
              width: "-webkit-fill-available",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              gap: 16,
            }}
          >
            {showPreviewButton && (
              <Button
                variant="outlined"
                onClick={showPreviewButtonOnClick}
                disabled={previewButtonDisabled}
              >
                Preview
              </Button>
            )}
            {showArchiveButton && (
              <Button
                variant="contained"
                onClick={archiveButtonOnClick}
                disabled={!editModeActive}
              >
                Archive
              </Button>
            )}
            {showButtons && (
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
              >
                <Button
                  onClick={() => {
                    setUndoDialogOpen(true)
                  }}
                  disabled={
                    undoButtonDisabled ||
                    saveButtonLoading ||
                    !editModeActive ||
                    !doneChanges
                  }
                >
                  <UndoRounded />
                </Button>
                <Button
                  variant="contained"
                  disabled={
                    saveButtonDisabled ||
                    saveButtonLoading ||
                    !editModeActive ||
                    !doneChanges
                  }
                  onClick={
                    saveButtonOnClick
                      ? saveButtonOnClick
                      : () => {
                          logger(Status.Warning, "No actions")
                        }
                  }
                >
                  Save changes
                </Button>
              </ButtonGroup>
            )}
            <FormControlLabel
              labelPlacement="start"
              control={<Switch />}
              label="Edit"
              style={{ marginLeft: 0, minWidth: 86 }}
              checked={editModeActive}
              onChange={() => {
                setEditModeActive(!editModeActive)
              }}
            />
          </div>
        )}
        {showButtons && (
          <Dialog
            open={undoDialogOpen}
            onClose={() => {
              setUndoDialogOpen(false)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to undo all changes?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                All changes will be lost
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setUndoDialogOpen(false)
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={(e) => {
                  undoButtonOnClick(e)
                  setUndoDialogOpen(false)
                }}
                autoFocus
              >
                Undo
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Stack>
    </Fade>
  )
}

export default EditHeadingFade
