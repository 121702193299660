import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  surveyUpsert,
  surveyPublish,
  surveyDraftDelete,
  surveyLink,
  upsertDocumentSurvey,
} from "../services/graphql/mutations"
import {
  surveysSearch,
  surveyStagedGet,
  surveysStagedList,
} from "../services/graphql/queries"
import {
  deepCopy,
  logger,
  Status,
  lowercaseFirstCharacter,
} from "../services/utilities/utility"
import { MainContext } from "./main"
import { Stage } from "../services/config/enum"
import { AutocompleteOption } from "../services/config/interfaces"
import Survey, { SurveyProvider, SurveyLinkMode } from "../models/survey"

interface SurveysContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  surveysList: Survey[]
  setSurveysList: Dispatch<SetStateAction<Survey[]>>
  getSurveysList: (withLoading?: boolean) => void
  doneChanges: boolean
  cancelChanges: () => void
  currentSurvey: Survey
  setCurrentSurvey: Dispatch<SetStateAction<Survey>>
  getCurrentSurvey: (surveyId: string) => Promise<boolean>
  preChangesCurrentSurvey: Survey
  upsertSurvey: (suspended: boolean) => Promise<boolean>
  hasError: boolean
  translationsErrors: { lang: string; hasErrors: boolean }[]
  setTranslationsErrors: Dispatch<
    SetStateAction<{ lang: string; hasErrors: boolean }[]>
  >
  upsertSurveyDocument: (publish?: boolean) => Promise<boolean>
  copyDetailsFromDefault: (itemToCopyToIndex: number) => void
  copyBodyFromDefault: (itemToCopyToIndex: number) => void
  createSurvey: (input: {
    handle: string
    provider: SurveyProvider
    teamId?: string
    projectId?: string
    useSecret?: boolean
    storeResponses: boolean
    startsAt: string
    endsAt: string
  }) => Promise<Survey | null>
  createSurveyDocument: (input: {
    parentId: string
    type: string
    surveyDocumentItems: {
      default: boolean
      lang: string
      title: string
      formId: string
      formUrl: string
      body?: any[]
      linkMode: SurveyLinkMode
    }[]
  }) => Promise<boolean>
  deleteSurvey: (id: string) => Promise<boolean>
  surveysListNextToken: string | null
  loadMoreSurveys: () => Promise<boolean>
  hasSearched: boolean
  setHasSearched: Dispatch<SetStateAction<boolean>>
  searchProvider: AutocompleteOption | null
  setSearchProvider: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchStage: AutocompleteOption | null
  setSearchStage: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchSuspended: boolean | null
  setSearchSuspended: Dispatch<SetStateAction<boolean | null>>
  searchHandle: string
  setSearchHandle: Dispatch<SetStateAction<string>>
  searchSurveysList: (
    withLoading?: boolean,
    withNextToken?: boolean
  ) => Promise<boolean>
  resetFilters: () => void
  handleError: boolean
  setHandleError: Dispatch<SetStateAction<boolean>>
  publishSurvey: () => Promise<boolean>
  addTranslation: (translationToAdd: string) => void
  linkSurvey: (linkMode: SurveyLinkMode, langs: string[]) => Promise<boolean>
  canPublish: () => boolean
}

const SurveysContext = createContext<SurveysContextInterface>({
  loading: true,
  setLoading: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  editMode: false,
  setEditMode: () => {},
  surveysList: [],
  setSurveysList: () => {},
  getSurveysList: () => {},
  doneChanges: false,
  cancelChanges: () => {},
  currentSurvey: null,
  setCurrentSurvey: () => {},
  getCurrentSurvey: async () => true,
  preChangesCurrentSurvey: null,
  upsertSurvey: async () => true,
  hasError: false,
  translationsErrors: [],
  setTranslationsErrors: () => {},
  upsertSurveyDocument: async () => true,
  copyDetailsFromDefault: () => {},
  copyBodyFromDefault: () => {},
  createSurvey: async () => null,
  createSurveyDocument: async () => true,
  deleteSurvey: async () => true,
  surveysListNextToken: null,
  loadMoreSurveys: async () => true,
  hasSearched: false,
  setHasSearched: () => {},
  searchProvider: null,
  setSearchProvider: () => {},
  searchStage: null,
  setSearchStage: () => {},
  searchSuspended: null,
  setSearchSuspended: () => {},
  searchHandle: "",
  setSearchHandle: () => {},
  searchSurveysList: async () => true,
  resetFilters: () => {},
  handleError: false,
  setHandleError: () => {},
  publishSurvey: async () => true,
  addTranslation: () => {},
  linkSurvey: async () => true,
  canPublish: () => true,
})

const SurveysController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true)
  const [updatingList, setUpdatingList] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(true)
  const [surveysList, setSurveysList] = useState<Survey[]>([])
  const [doneChanges, setDoneChanges] = useState<boolean>(false)
  const [currentSurvey, setCurrentSurvey] = useState<Survey>()
  const [preChangesCurrentSurvey, setPreChangesCurrentSurvey] =
    useState<Survey>()
  const [surveysListNextToken, setSurveysListNextToken] = useState<
    string | null
  >(null)
  const [hasSearched, setHasSearched] = useState<boolean>(false)

  // search states
  const [searchProvider, setSearchProvider] =
    useState<AutocompleteOption | null>(null)
  const [searchStage, setSearchStage] = useState<AutocompleteOption | null>(
    null
  )
  const [searchSuspended, setSearchSuspended] = useState<boolean | null>(null)
  const [searchHandle, setSearchHandle] = useState<string>("")

  // errors
  const [handleError, setHandleError] = useState<boolean>(false)
  const [translationsErrors, setTranslationsErrors] = useState<
    { lang: string; hasErrors: boolean }[]
  >([])
  const hasError: boolean =
    handleError ||
    translationsErrors.filter((item) => item.hasErrors).length !== 0

  // reset all errors
  const resetErrors = () => {
    setHandleError(false)
    const newTranslationsErrors: { lang: string; hasErrors: boolean }[] = []
    if (currentSurvey?.document?.items) {
      currentSurvey.document.items.forEach((item) => {
        newTranslationsErrors.push({ lang: item.lang, hasErrors: false })
      })
    }
    setTranslationsErrors(newTranslationsErrors)
  }

  // queries
  const [surveyStagedGetQuery] = useLazyQuery(surveyStagedGet)
  const [surveysStagedListQuery] = useLazyQuery(surveysStagedList)
  const [surveysSearchQuery] = useLazyQuery(surveysSearch)

  // mutations
  const [surveyUpsertMutation] = useMutation(surveyUpsert)
  const [surveyPublishMutation] = useMutation(surveyPublish)
  const [surveyDraftDeleteMutation] = useMutation(surveyDraftDelete)
  const [upsertDocumentMutation] = useMutation(upsertDocumentSurvey)
  const [surveyLinkMutation] = useMutation(surveyLink)

  // get surveys list
  const getSurveysList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Surveys")[0].loading = true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY surveysStagedList", surveysStagedList)
        const { data } = await surveysStagedListQuery({
          variables: {
            input: {
              limit: Math.round(window.innerHeight / 74) + 10,
            },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "surveys list", data.surveysStagedList.items)

        setSurveysList(data.surveysStagedList.items)
        setSurveysListNextToken(data.surveysStagedList.nextToken)

        setHasSearched(false)
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Surveys")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Surveys")[0].loading = false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `surveysList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Surveys")[0].status =
          "error"
        panelStatus.filter((item) => item.name === "Surveys")[0].loading = false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // load more surveys
  const loadMoreSurveys = async () => {
    try {
      logger(Status.Api, "QUERY surveysStagedList", surveysStagedList)
      const { data } = await surveysStagedListQuery({
        variables: {
          input: {
            limit: Math.round(window.innerHeight / 74) + 10,
            nextToken: surveysListNextToken,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "surveys list", [
        ...surveysList,
        ...data.surveysList.items,
      ])

      setSurveysList([...surveysList, ...data.surveysList.items])
      setSurveysListNextToken(data.surveysList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `surveysList`, e.message)
      }
      return false
    }
  }

  // search surveys
  const searchSurveysList = async (
    withLoading = true,
    withNextToken = false
  ) => {
    if (withLoading) {
      setUpdatingList(true)
    }

    try {
      const input: {
        limit: number
        text?: string
        provider?: SurveyProvider
        stage?: Stage
        suspended?: boolean
        nextToken?: string
      } = {
        limit: Math.round(window.innerHeight / 74) + 10,
        text: searchHandle,
        provider: searchProvider
          ? (searchProvider.id as SurveyProvider)
          : undefined,
        stage: searchStage ? (searchStage.id as Stage) : undefined,
        suspended: searchSuspended,
      }

      if (hasSearched && withNextToken) {
        input.nextToken = surveysListNextToken
      }

      logger(Status.Api, "QUERY surveysSearch", surveysSearch)
      const { data } = await surveysSearchQuery({
        variables: { input },
        fetchPolicy: "no-cache",
      })

      if (input.nextToken) {
        logger(Status.Info, "surveys list", [
          ...surveysList,
          ...data.surveysSearch.items,
        ])

        setSurveysList([...surveysList, ...data.surveysSearch.items])
        setSurveysListNextToken(data.surveysSearch.nextToken)
      } else {
        logger(Status.Info, "surveys list", data.surveysSearch.items)

        setSurveysList(data.surveysSearch.items)
        setSurveysListNextToken(data.surveysSearch.nextToken)
      }

      setHasSearched(true)
      setUpdatingList(false)
      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `surveysSearch`, e.message)
      }
      setUpdatingList(false)
      return false
    }
  }

  // get current survey
  const getCurrentSurvey = useCallback(
    async (surveyId: string) => {
      try {
        logger(Status.Api, "QUERY surveyStagedGet", surveyStagedGetQuery)
        const { data } = await surveyStagedGetQuery({
          variables: { input: { id: surveyId } },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `survey ${surveyId}`, data.surveyStagedGet)

        setCurrentSurvey(data.surveyStagedGet)
        setPreChangesCurrentSurvey(deepCopy(data.surveyStagedGet))

        // Initialize translation errors
        const newTranslationsErrors: { lang: string; hasErrors: boolean }[] = []
        data.surveyStagedGet.document.items.forEach((item: any) => {
          newTranslationsErrors.push({ lang: item.lang, hasErrors: false })
        })
        setTranslationsErrors(newTranslationsErrors)

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `surveyStagedGet`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // create survey
  const createSurvey = async (input: {
    handle: string
    provider: SurveyProvider
    teamId?: string
    projectId?: string
    useSecret?: boolean
    storeResponses: boolean
    startsAt: string
    endsAt: string
  }) => {
    try {
      logger(Status.Api, "MUTATION surveyUpsert", surveyUpsert)
      const { data } = await surveyUpsertMutation({
        variables: { input },
      })
      logger(Status.Info, "survey created", data.surveyUpsert)

      return data.surveyUpsert
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `surveyUpsert`, e.message)
      }
      return null
    }
  }

  // create survey document
  const createSurveyDocument = async (input: {
    parentId: string
    type: string
    surveyDocumentItems: {
      default: boolean
      lang: string
      title: string
      formId: string
      formUrl: string
      linkMode?: SurveyLinkMode
    }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentSurvey)
      // Add linkMode if not provided
      const surveyDocumentItems = input.surveyDocumentItems.map((item) => ({
        ...item,
        linkMode: item.linkMode || SurveyLinkMode.unlinked,
      }))

      const { data } = await upsertDocumentMutation({
        variables: {
          input: {
            ...input,
            surveyDocumentItems,
          },
        },
      })
      logger(Status.Info, "document created", data.documentUpsert)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // upsert survey
  const upsertSurvey = async (suspended: boolean) => {
    try {
      const input = {
        id: currentSurvey.id,
        handle: currentSurvey.handle,
        provider: currentSurvey.provider,
        teamId: currentSurvey.teamId,
        projectId: currentSurvey.projectId,
        suspended,
        useSecret: currentSurvey.useSecret,
        storeResponses: currentSurvey.storeResponses,
        startsAt: currentSurvey.startsAt,
        endsAt: currentSurvey.endsAt,
      }

      logger(Status.Api, "MUTATION surveyUpsert", surveyUpsert)
      const { data } = await surveyUpsertMutation({
        variables: { input },
      })
      logger(
        Status.Info,
        `survey ${data.surveyUpsert.id} upserted`,
        data.surveyUpsert
      )

      setCurrentSurvey((current) => {
        return { ...data.surveyUpsert, document: current.document }
      })
      setPreChangesCurrentSurvey((current) => {
        return {
          ...deepCopy(data.surveyUpsert),
          document: current.document,
        }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `surveyUpsert`, e.message)
      }
      return false
    }
  }

  // upsert survey document
  const upsertSurveyDocument = async (publish = false) => {
    try {
      // parse data for input
      const currentSurveyCopy = deepCopy(currentSurvey)
      currentSurveyCopy.document.items.forEach((item: any) => {
        // Transform body slices to match GraphQL schema
        if (item.body) {
          const newBody = []
          item.body.forEach((bodyItem: any) => {
            const { sliceType, __typename, ...rest } = bodyItem
            newBody.push({
              [lowercaseFirstCharacter(bodyItem.sliceType)]: {
                ...rest,
              },
            })
          })
          item.body = newBody
        }

        item.default = item.isDefault
        delete item.isDefault
        delete item.type
        delete item.updatedAt
        delete item.parentId
        delete item.webhookUrl
        delete item.webhookSecret // Remove webhookSecret as it's read-only
        delete item.__typename
      })

      const input = {
        surveyDocumentItems: currentSurveyCopy.document.items,
        parentId: `#id#${currentSurveyCopy.id}#stage#${
          publish ? "PUBLISHED" : "DRAFT"
        }`,
        type: "Survey",
      }

      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentSurvey)
      const { data } = await upsertDocumentMutation({
        variables: { input },
      })
      logger(
        Status.Info,
        `survey ${currentSurvey.id} document upserted`,
        data.documentUpsert
      )

      setCurrentSurvey((current) => {
        return { ...current, document: data.documentUpsert }
      })
      setPreChangesCurrentSurvey((current) => {
        return { ...current, document: deepCopy(data.documentUpsert) }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // Check if survey can be published (no unlinked languages)
  const canPublish = useCallback(() => {
    if (!currentSurvey?.document?.items) return false
    return !currentSurvey.document.items.some(
      (item) => item.linkMode === SurveyLinkMode.unlinked
    )
  }, [currentSurvey])

  // Link survey
  const linkSurvey = async (linkMode: SurveyLinkMode, langs: string[]) => {
    try {
      logger(Status.Api, "MUTATION surveyLink", surveyLink)
      const { data } = await surveyLinkMutation({
        variables: {
          input: {
            id: currentSurvey.id,
            linkMode,
            langs,
          },
        },
      })
      logger(Status.Info, `survey ${currentSurvey.id} linked`, data.surveyLink)

      setCurrentSurvey((current) => ({
        ...current,
        document: data.surveyLink.document,
      }))
      setPreChangesCurrentSurvey((current) => ({
        ...current,
        document: deepCopy(data.surveyLink.document),
      }))

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `surveyLink`, e.message)
      }
      return false
    }
  }

  // publish survey
  const publishSurvey = async () => {
    // Check if all languages are linked
    if (!canPublish()) {
      setError(true)
      setErrorMessage("Cannot publish survey with unlinked languages")
      return false
    }

    try {
      logger(Status.Api, "MUTATION surveyPublish", surveyPublish)
      await Promise.all([
        surveyPublishMutation({
          variables: { input: { id: currentSurvey.id } },
        }),
        upsertSurveyDocument(true),
      ])
      logger(Status.Info, `survey ${currentSurvey.id} published`)

      // update current survey locally
      setCurrentSurvey((current) => {
        return { ...current, stage: Stage.PUBLISHED }
      })
      setPreChangesCurrentSurvey((current) => {
        return { ...current, stage: Stage.PUBLISHED }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `surveyPublish`, e.message)
      }
      return false
    }
  }

  // delete survey
  const deleteSurvey = async (id: string) => {
    try {
      logger(Status.Api, "MUTATION surveyDraftDelete", surveyDraftDelete)
      await surveyDraftDeleteMutation({
        variables: { input: { id } },
      })
      logger(Status.Info, `survey ${id} deleted`)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `surveyDraftDelete`, e.message)
      }
      return false
    }
  }

  // add translation
  const addTranslation = (translationToAdd: string) => {
    const documentToAdd = {
      parentId: currentSurvey.document.parentId,
      isDefault: false,
      lang: translationToAdd,
      type: "Survey",
      title: "Title",
      formId: "",
      formUrl: "",
      body: [],
      linkMode: SurveyLinkMode.unlinked,
    }

    translationsErrors.push({
      lang: translationToAdd,
      hasErrors: false,
    })
    setTranslationsErrors([...translationsErrors])

    currentSurvey.document.items.push(documentToAdd)
    setCurrentSurvey({ ...currentSurvey })
  }

  // copy details from default translation
  const copyDetailsFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = deepCopy(
      currentSurvey.document.items.filter((item) => item.isDefault)[0]
    )

    currentSurvey.document.items[itemToCopyToIndex].title = defaultItem.title
    currentSurvey.document.items[itemToCopyToIndex].formId = defaultItem.formId
    currentSurvey.document.items[itemToCopyToIndex].formUrl =
      defaultItem.formUrl

    setCurrentSurvey({ ...currentSurvey })
  }

  // copy body from default translation
  const copyBodyFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = deepCopy(
      currentSurvey.document.items.filter((item) => item.isDefault)[0]
    )

    currentSurvey.document.items[itemToCopyToIndex].body =
      defaultItem.body || []
    setCurrentSurvey({ ...currentSurvey })
  }

  // cancel changes
  const cancelChanges = () => {
    setCurrentSurvey(deepCopy(preChangesCurrentSurvey))
    resetErrors()
  }

  // check if user has done changes
  useEffect(() => {
    if (currentSurvey) {
      const currentSurveyCopy = deepCopy(currentSurvey)
      const preChangesCurrentSurveyCopy = deepCopy(preChangesCurrentSurvey)

      if (currentSurveyCopy.team) {
        delete currentSurveyCopy.team.document
        delete currentSurveyCopy.team.__typename
      }
      if (preChangesCurrentSurveyCopy.team) {
        delete preChangesCurrentSurveyCopy.team.document
        delete preChangesCurrentSurveyCopy.team.__typename
      }

      if (
        JSON.stringify(currentSurveyCopy) !==
        JSON.stringify(preChangesCurrentSurveyCopy)
      ) {
        setDoneChanges(true)
      } else {
        setDoneChanges(false)
      }
    }
  }, [currentSurvey])

  // reset list filters
  const resetFilters = () => {
    setSearchProvider(null)
    setSearchStage(null)
    setSearchSuspended(null)
    setSearchHandle("")
  }

  // add update function to panel status
  useEffect(() => {
    const surveysPanel = panelStatus.find((item) => item.name === "Surveys")
    if (surveysPanel) {
      surveysPanel.updateFunction = getSurveysList
      setPanelStatus([...panelStatus])
    }
  }, [])

  return (
    <SurveysContext.Provider
      value={{
        loading,
        setLoading,
        updatingList,
        setUpdatingList,
        editMode,
        setEditMode,
        surveysList,
        setSurveysList,
        getSurveysList,
        doneChanges,
        cancelChanges,
        currentSurvey,
        setCurrentSurvey,
        getCurrentSurvey,
        preChangesCurrentSurvey,
        upsertSurvey,
        hasError,
        translationsErrors,
        setTranslationsErrors,
        upsertSurveyDocument,
        copyDetailsFromDefault,
        copyBodyFromDefault,
        createSurvey,
        createSurveyDocument,
        deleteSurvey,
        surveysListNextToken,
        loadMoreSurveys,
        hasSearched,
        setHasSearched,
        searchProvider,
        setSearchProvider,
        searchStage,
        setSearchStage,
        searchSuspended,
        setSearchSuspended,
        searchHandle,
        setSearchHandle,
        searchSurveysList,
        resetFilters,
        handleError,
        setHandleError,
        publishSurvey,
        addTranslation,
        linkSurvey,
        canPublish,
      }}
    >
      {children}
    </SurveysContext.Provider>
  )
}

export { SurveysController, SurveysContext }
