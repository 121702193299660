import {
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { useContext, useState } from "react"
import { TeamsContext } from "../../controllers/teams"
import { AddCircleRounded, DeleteForeverRounded } from "@mui/icons-material"
import noImagePlaceholder from "../../assets/images/no-image-placeholder.jpeg"
import { LoadingButton } from "@mui/lab"
import { parseStringDate } from "../../services/utilities/utility"
import { ChallengesContext } from "../../controllers/challenges"
import Challenge from "../../models/challenge"
import CreateChallengeDialog from "../challenge/createChallengeDialog"

const TeamGeneralEditChallengesTab = () => {
  const {
    editMode,
    currentTeam,
    currentTeamChallengesList,
    setCurrentTeamChallengesList,
  } = useContext(TeamsContext)
  const { deleteChallenge, setLoading: setChallengesLoading } =
    useContext(ChallengesContext)

  // create challenge dialog
  const [createChallengeDialogOpen, setCreateChallengeDialogOpen] =
    useState<boolean>(false)

  const handleCreateChallengeDialogChange = () => {
    setCreateChallengeDialogOpen((current) => !current)
  }

  // delete challenge
  const [challengeToDelete, setChallengeToDelete] = useState<Challenge | null>(
    null
  )
  const [deletingChallenge, setDeletingChallenge] = useState<boolean>(false)
  const [deleteChallengeDialogOpen, setDeleteChallengeDialogOpen] =
    useState<boolean>(false)

  const handleDeleteChallengeDialogChange = () => {
    setDeleteChallengeDialogOpen((current) => !current)
  }

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Challenges
        </Typography>
        {!currentTeamChallengesList.length ? (
          <Typography>No Challenges for this Team</Typography>
        ) : (
          <Stack>
            {currentTeamChallengesList.map((challenge, index) => (
              <Card
                key={challenge.id}
                style={{
                  minHeight: 60,
                  paddingInline: 24,
                }}
                variant="outlined"
                className="list-item-card"
                onClick={() => {
                  window.open(
                    window.location.origin + "/challenges/" + challenge.id
                  )
                }}
                sx={{
                  boxShadow: "none",
                  borderTopLeftRadius: index === 0 ? undefined : 0,
                  borderTopRightRadius: index === 0 ? undefined : 0,
                  borderBottomLeftRadius:
                    index === currentTeamChallengesList.length - 1
                      ? undefined
                      : 0,
                  borderBottomRightRadius:
                    index === currentTeamChallengesList.length - 1
                      ? undefined
                      : 0,
                  borderTop: index === 0 ? undefined : "none",
                }}
              >
                <Stack
                  direction="row"
                  style={{ height: 60, position: "relative" }}
                  alignItems="center"
                  spacing={4}
                >
                  <div
                    style={{
                      minWidth: 40,
                      height: 40,
                      borderRadius: 4,
                      backgroundImage: `url(${
                        challenge.document.items.find((item) => item.isDefault)
                          .image ?? noImagePlaceholder
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <Typography style={{ minWidth: 21 }}>
                    {challenge.document.items
                      .find((item) => item.isDefault)
                      .lang.toUpperCase()}
                  </Typography>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "40%",
                      maxWidth: "40%",
                    }}
                  >
                    {challenge.name}
                  </Typography>
                  <div style={{ width: 80 }}>
                    {new Date(challenge.startsAt) > new Date() ? (
                      <Chip size="small" label="Not started" color="default" />
                    ) : new Date(challenge.endsAt) >= new Date() ? (
                      <Chip size="small" label="Current" color="success" />
                    ) : (
                      <Chip size="small" label="Ended" color="error" />
                    )}
                  </div>
                  <Stack style={{ gap: -3 }}>
                    <Typography variant="caption">
                      from {parseStringDate(challenge.startsAt!)}
                    </Typography>
                    <Typography variant="caption">
                      to {parseStringDate(challenge.endsAt!)}
                    </Typography>
                  </Stack>
                  <IconButton
                    disabled={!editMode}
                    color="error"
                    style={{
                      position: "absolute",
                      right: -12,
                      top: 9,
                      zIndex: 1,
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      setChallengeToDelete(challenge)
                      handleDeleteChallengeDialogChange()
                    }}
                  >
                    <DeleteForeverRounded
                      style={{
                        width: 24,
                      }}
                    />
                  </IconButton>
                </Stack>
              </Card>
            ))}
          </Stack>
        )}
        <Button
          onClick={() => {
            setChallengesLoading(false)
            handleCreateChallengeDialogChange()
          }}
          variant="outlined"
          endIcon={<AddCircleRounded />}
          fullWidth
          disabled={!editMode}
          style={{ height: 40 }}
        >
          Add Challenge
        </Button>
      </Stack>
      {/* create challenge for team */}
      <CreateChallengeDialog
        dialogOpen={createChallengeDialogOpen}
        setDialogOpen={setCreateChallengeDialogOpen}
        handleDialogChange={handleCreateChallengeDialogChange}
        topBarText="Add Challenge"
        preSelectedTeam={currentTeam}
        currentTeamChallengesList={currentTeamChallengesList}
        setCurrentTeamChallengesList={setCurrentTeamChallengesList}
      />
      {/* delete challenge dialog */}
      <Dialog
        open={deleteChallengeDialogOpen}
        onClose={deletingChallenge ? null : handleDeleteChallengeDialogChange}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to permanently delete this Challenge?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The Challenge "{challengeToDelete ? challengeToDelete.name : ""}"
            will be <span style={{ fontWeight: 600 }}>permanently deleted</span>{" "}
            and the action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteChallengeDialogChange}
            disabled={deletingChallenge}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={deletingChallenge}
            onClick={async () => {
              setDeletingChallenge(true)

              const noErrors = await deleteChallenge(challengeToDelete.id)
              if (noErrors) {
                const indexToRemove = currentTeamChallengesList.findIndex(
                  (item) => item.id === challengeToDelete.id
                )
                currentTeamChallengesList.splice(indexToRemove, 1)
                setCurrentTeamChallengesList([...currentTeamChallengesList])
                setChallengeToDelete(null)
                handleDeleteChallengeDialogChange()
              }

              setDeletingChallenge(false)
            }}
            autoFocus
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TeamGeneralEditChallengesTab
