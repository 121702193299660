import {
  Button,
  CardContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { ActionsContext } from "../../controllers/actions"
import { MainContext } from "../../controllers/main"
import Action from "../../models/action"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import ImageSlice from "../../models/bodySlices/imageSlice"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import { BodySliceType } from "../../services/config/enum"
import {
  deepCopy,
  isValidURL,
  stripHtml,
  testYtURL,
} from "../../services/utilities/utility"
import BlockEditor from "../blockEditor/blockEditor"
import ActionsHtmlEditor from "./actionsHTMLEditor"
import constraints from "../../services/config/constraints"

const ActionTranslationsEdit = ({
  itemIndex,
  updateEditor,
  setUpdateEditor,
}: {
  itemIndex: number
  updateEditor: boolean
  setUpdateEditor: Dispatch<SetStateAction<boolean>>
}) => {
  const { showPadding } = useContext(MainContext)
  const {
    currentAction,
    setCurrentAction,
    editMode,
    translationsErrors,
    setTranslationsErrors,
    copyDetailsFromDefault,
    copyBodyFromDefault,
  } = useContext(ActionsContext)

  // current item
  const currentItem = currentAction.document.items[itemIndex]

  // errors managment
  const [titleError, setTitleError] = useState<boolean>(false) // error for title
  const [taglineError, setTaglineError] = useState<boolean>(false) // error for tagline
  const [redirectUrlError, setRedirectUrlError] = useState<boolean>(false) // error for redirect url
  const [bodyError, setBodyError] = useState<boolean>(false) // error for body

  // update translations errors on errors change
  useEffect(() => {
    if (titleError || taglineError || redirectUrlError || bodyError) {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = true
    } else {
      translationsErrors.filter(
        (item) => item.lang === currentItem.lang
      )[0].hasErrors = false
    }

    setTranslationsErrors([...translationsErrors])
  }, [titleError, taglineError, redirectUrlError, bodyError])

  // update errors on currentItem change
  useEffect(() => {
    if (
      currentItem.title.length < constraints.action.title.min ||
      currentItem.title.length > constraints.action.title.max
    ) {
      setTitleError(true)
    } else {
      setTitleError(false)
    }

    let errorInBody = false
    currentItem.body.forEach((bodyItem) => {
      if (
        (bodyItem.sliceType === BodySliceType.BodyTextSlice &&
          !stripHtml(bodyItem.text).length) ||
        (bodyItem.sliceType === BodySliceType.BodyQuoteSlice &&
          !bodyItem.quoteText.length) ||
        (bodyItem.sliceType === BodySliceType.BodyImageSlice &&
          (!bodyItem.imgUrl.length || !isValidURL(bodyItem.imgUrl))) ||
        (bodyItem.sliceType === BodySliceType.BodyEmbedSlice &&
          (!bodyItem.embedUrl.length || !testYtURL(bodyItem.embedUrl)))
      ) {
        errorInBody = true
      }
    })
    if (errorInBody) {
      setBodyError(true)
    } else {
      setBodyError(false)
    }
  }, [currentItem])

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" style={{ height: 28 }}>
          <Typography variant="h6" className="card-title">
            Details
          </Typography>
          <Stack
            alignItems="center"
            justifyContent="flex-end"
            style={{ width: "100%" }}
            direction="row"
            spacing={2}
          >
            <FormGroup style={{ marginRight: -16 }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!editMode}
                    checked={currentItem.isDefault}
                    onChange={(e) => {
                      const currentActionCopy: Action = deepCopy(currentAction)
                      currentActionCopy.document.items[itemIndex].isDefault =
                        e.target.checked
                      if (e.target.checked) {
                        currentActionCopy.document.items.forEach(
                          (item, index) => {
                            if (index !== itemIndex) {
                              item.isDefault = false
                            }
                          }
                        )
                      }
                      setCurrentAction(currentActionCopy)
                    }}
                  />
                }
                label="Default"
              />
            </FormGroup>
            <Divider orientation="vertical" flexItem />
            <Button
              size="small"
              variant="outlined"
              style={{ height: 26, fontSize: 12 }}
              onClick={() => {
                copyDetailsFromDefault(itemIndex)
                setUpdateEditor((current) => !current)
              }}
              disabled={
                currentItem.isDefault ||
                !editMode ||
                !currentAction.document.items.filter((item) => item.isDefault)
                  .length
              }
            >
              Copy from default
            </Button>
          </Stack>
        </Stack>
        <TextField
          fullWidth
          size="small"
          label="Title"
          value={currentItem.title}
          onChange={(e) => {
            const currentActionCopy: Action = deepCopy(currentAction)
            currentActionCopy.document.items[itemIndex].title = e.target.value
            setCurrentAction(currentActionCopy)

            // errors check
            if (
              e.target.value.length < constraints.action.title.min ||
              e.target.value.length > constraints.action.title.max
            ) {
              setTitleError(true)
            } else {
              setTitleError(false)
            }
          }}
          disabled={!editMode}
          error={titleError}
          helperText={
            currentItem.title.length < constraints.action.title.min
              ? `Minimum length is ${constraints.action.title.min} characters`
              : currentItem.title.length > constraints.action.title.max
              ? `Maximum length is ${constraints.action.title.max} characters`
              : ""
          }
          onBlur={() => {
            if (currentAction.document.items[itemIndex].title) {
              const currentActionCopy: Action = deepCopy(currentAction)
              currentActionCopy.document.items[itemIndex].title =
                currentActionCopy.document.items[itemIndex].title.trim()
              setCurrentAction(currentActionCopy)
            }
          }}
        />
        <ActionsHtmlEditor
          text={currentItem.assumptions}
          setText={(newValue) => {
            const currentActionCopy: Action = deepCopy(currentAction)
            currentActionCopy.document.items[itemIndex].assumptions = newValue
            setCurrentAction(currentActionCopy)
          }}
          itemIndex={itemIndex}
          updateEditor={updateEditor}
          label="Assumptions"
          options={["inline", "link", "history"]}
          inlineOptions={["bold", "italic", "superscript", "subscript"]}
        />
        <Stack spacing={2}>
          <Stack direction="row" style={{ height: 28 }}>
            <Typography variant="h6" className="card-title">
              Body
            </Typography>
            <Stack
              alignItems="center"
              justifyContent="flex-end"
              style={{ width: "100%" }}
              direction="row"
              spacing={2}
            >
              <Button
                size="small"
                variant="outlined"
                style={{ height: 26, fontSize: 12 }}
                onClick={() => {
                  copyBodyFromDefault(itemIndex)
                }}
                disabled={
                  currentItem.isDefault ||
                  !editMode ||
                  !currentAction.document.items.filter((item) => item.isDefault)
                    .length
                }
              >
                Copy from default
              </Button>
            </Stack>
          </Stack>
          <BlockEditor
            body={currentItem.body}
            setBody={(
              body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
            ) => {
              const currentActionCopy: Action = deepCopy(currentAction)
              currentActionCopy.document.items[itemIndex].body = body
              setCurrentAction(currentActionCopy)
            }}
            editMode={editMode}
          />
        </Stack>
      </Stack>
    </CardContent>
  )
}

export default ActionTranslationsEdit
